
import React, { useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PostModel, PostType } from '~/openapi/typescript-axios/version';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminPostEditValue {
  postType: PostType|null;
  postModel?: PostModel;
  setPostModel: (postModel?: PostModel) => void;
}

const AdminPostEditContext = React.createContext<AdminPostEditValue | null>(null);

export function useAdminPostEdit(): AdminPostEditValue {
  const state = useContext(AdminPostEditContext);

  if (!state) {
    throw new Error('useAdminPostEdit must be used within AdminPostEditProvider');
  }

  return state;
}

export function AdminPostEditProvider({ children }: Props) {
  const search = useLocation().search;
  const postType = new URLSearchParams(search).get('postType') as PostType|null;

  const { postId } = useParams<any>();

  const { getPost } = useLocalbridgePostApi();
  const [ postModel, setPostModel ] = useState<PostModel>();

  const initPost = useCallback(async () => {
    if (!postId) {
      return;
    }

    const postModelTmp = await getPost({postId});
    setPostModel(postModelTmp?.postModel);
  }, [postId, getPost, setPostModel]);

  useEffect(() => {
    initPost();
  }, [initPost]);

  const providerValue = {
    postType,
    postModel,
    setPostModel,
  };

  return (
    <AdminPostEditContext.Provider value={providerValue}>
      {children}
    </AdminPostEditContext.Provider>
  );
}
