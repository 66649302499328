import { nanoid } from 'nanoid';
import { useCallback, useEffect } from 'react';
import {
  BookingModel,
  BookingSource,
  GroupModel,
  PostModel,
  PostType,
  QnaMail,
  QnaMailQuestionType,
  UniversityCollegeStudentGrade,
  UserGender,
  UserJobDropdown,
  UserModel,
  WorkshopModel,
} from '~/openapi/typescript-axios/version';
import { useLocalbridgeApi } from '~/providers/LocalbridgeApiProvider';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';

export const TestValidation = () => {
  const { validateBookings } = useLocalbridgeBookingApi();
  const { validateGroups } = useLocalbridgeGroupApi();
  const { validateWorkshops } = useLocalbridgeWorkshopApi();
  const { validateUsers } = useLocalbridgeUserApi();
  const { validatePosts } = useLocalbridgePostApi();
  const { localbridgeQnaValidate } = useLocalbridgeApi();

  const doValidationTest = useCallback(async () => {
    const now = Number(new Date());

    const qnaMail: QnaMail = {
      groupName: '',
      teamName: '',
      senderName: '',
      emailFrom: '',
      emailFromConfirm: '',
      phoneNumberFrom: '',
      questionType: QnaMailQuestionType.Others,
      title: '',
      content: '',
    };

    const resultQnaMail = await localbridgeQnaValidate({
      isOnSubmit: false, qnaMail,
    });
    console.log(resultQnaMail);

    const bookingModel: BookingModel = {
      id: nanoid(),
      createdAt: now,
      updatedAt: now,
      nickName: '',
      icon: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      studentGrade: null,
      photos: [],
      interests: [],
      description: '',
      job: '',
      educationalInstitutionName: '',
      studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
      name: '',
      nameFurigana: '',
      email: '',
      emailToken: {uniGram: [], biGram: [], triGram: []},
      gender: UserGender.Male,
      phoneNumber: '',
      cityId: nanoid(),
      townId: nanoid(),
      detailAddress1: '',
      detailAddress2: '',
      postNumber: '',
      localAssociationIds: [],
      localCategorys: [],
      birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
      userId: '',
      workshopId: '',
      groupId: nanoid(),
      keyPoint: '',
      source: '' as BookingSource,
    };

    const resultBookings = await validateBookings({
      isOnSubmit: false, bookingRequests: [{ bookingModel }]
    });
    console.log(resultBookings);

    const groupModel: GroupModel = {
      id: nanoid(),
      createdAt: now,
      updatedAt: now,
      photos: [],
      name: '',
      nameToken: {uniGram: [], biGram: [], triGram: []},
      delegateName: '',
      delegateNameFurigana: '',
      groupEmail: '',
      groupPhoneNumber: '',
      homepage: '',
      cityId: nanoid(),
      townId: nanoid(),
      detailAddress1: '',
      detailAddress2: '',
      postNumber: '',
      userIdsManagerRole: [],
      passportIcon: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      mainPhoto: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      icon: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      description: '',
    };

    const resultGroups = await validateGroups({isOnSubmit: false, groupRequests: [{ groupModel }]});
    console.log(resultGroups);

    const workshopModel: WorkshopModel = {
      id: nanoid(),
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: '',
      titleToken: {uniGram: [], biGram: [], triGram: []},
      overview: '',
      overviewToken: {uniGram: [], biGram: [], triGram: []},
      detail: '',
      detailToken: {uniGram: [], biGram: [], triGram: []},
      notice: '',
      cityId: nanoid(),
      townId: nanoid(),
      detailAddress1: '',
      detailAddress2: '',
      groupId: nanoid(),
      workshopStartAt: now,
      workshopEndAt: now,
      bookingStartAt: now,
      bookingEndAt: now,
      mainPhoto: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
      participationFee: 1000,
      isHidden: false,
      isWaitingApproved: false,
      isApproved: false,
      isInTopCarouselList: false,
      isFinishedWriting: false,
    };

    const resultWorkshops = await validateWorkshops({isOnSubmit: false, workshopRequests: [{ workshopModel }]});
    console.log(resultWorkshops);

    const userModel: UserModel = {
      id: nanoid(),
      createdAt: now,
      updatedAt: now,
      photos: [],
      interests: [],
      icon: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      name: '',
      nickName: '',
      nameFurigana: '',
      studentGrade: UniversityCollegeStudentGrade.UniversityCollege1,
      description: '',
      job: UserJobDropdown.StudentUniversityBachelor,
      educationalInstitutionName: '',
      studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
      email: '',
      emailToken: {uniGram: [], biGram: [], triGram: []},
      gender: UserGender.Male,
      phoneNumber: '',
      cityId: '',
      townId: '',
      detailAddress1: '',
      detailAddress2: '',
      postNumber: '',
      localAssociationIds: [],
      localCategorys: [],
      birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
    };
    
    const resultUsers = await validateUsers({isOnSubmit: false, userRequests: [{ userModel }]});
    console.log(resultUsers);
    
    const postModel: PostModel = {
      id: nanoid(),
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: '',
      titleToken: {uniGram: [], biGram: [], triGram: []},
      content: '',
      contentToken: {uniGram: [], biGram: [], triGram: []},
      contentData: '',
      cityId: '',
      townId: '',
      postType: PostType.Attractive,
      groupId: nanoid(),
      passportId: nanoid(),
      userId: nanoid(),
      workshopId: nanoid(),
      tags: [],
      isHidden: false,
      isWaitingApproved: false,
      isApproved: false,
      isInTopCarouselList: false,
      isFinishedWriting: false,
      // userReaction: {},
      workshopStartAt: now,
      workshopEndAt: now,
      mainPhoto: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
    };

    const resultPosts = await validatePosts({isOnSubmit: false, postRequests: [{ postModel }]});
    console.log(resultPosts);
  }, [
    localbridgeQnaValidate,
    validateBookings,
    validateGroups,
    validateWorkshops,
    validateUsers,
    validatePosts,
  ]);

  useEffect(() => {
    doValidationTest();
  }, [doValidationTest]);

  return (
    <></>
  );
};
