import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { ROUTES } from '~/constants/routes';
import moment from 'moment';
import { useNoticeEdit } from './Provider';
import { NewsListTag } from '../notices/components/newsListTag';

type Props = {};

const NoticeRenderer: React.FC<Props> = () => {
  const { noticeModel } = useNoticeEdit();

  if (!noticeModel) {
    return <div>ニュースが見つかりません。</div>;
  }

  return (
    <>
      <LocalbridgeNavbar />

      <div>
        <LocalbridgeBody bgColor={'#fdfaf1'}>
          <div className={'news_detail'}>
            <div className={'news_detail__container'}>
              <div className={'news_detail__contents'}>
                <p className={'news_detail__date'}>{moment(noticeModel.createdAt).format('YYYY.MM.DD')}</p>
                <h1 className={'news_detail__title'}>{noticeModel.title}</h1>
                <div className={'news_detail__texts'}>
                  <p className={'news_detail__text'}>{noticeModel.content}</p>
                </div>
                <div className={'news_detail_tag'}>
                  {noticeModel.tags?.map((tag, index) => {
                    return (
                      <div key={index} className={'news_detail_tag__item'}>
                        <img className={'news_detail_tag__ico'} src="/assets/img/common/ico_tag.svg" alt="" />
                        <p key={index} className={'news_detail_tag__text'}>
                          {tag}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <a href={ROUTES.NOTICES} className={`${'news_detail__back'} ${'news_detail__back__is_pc'}`}>
                おしらせ一覧へ戻る
              </a>
            </div>

            <NewsListTag tags={noticeModel.tags} />

            <a href={ROUTES.NOTICES} className={`${'news_detail__back'} ${'news_detail__back__is_sp'}`}>
              おしらせ一覧へ戻る
            </a>
          </div>
        </LocalbridgeBody>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default NoticeRenderer;
