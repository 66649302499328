
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface NoticesNewsListTagValue {

}

const NoticesNewsListTagContext = React.createContext<NoticesNewsListTagValue | null>(null);

export function useNoticesNewsListTag(): NoticesNewsListTagValue {
  const state = useContext(NoticesNewsListTagContext);

  if (!state) {
    throw new Error('useNoticesNewsListTag must be used within NoticesNewsListTagProvider');
  }

  return state;
}

export function NoticesNewsListTagProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <NoticesNewsListTagContext.Provider value={providerValue}>
      {children}
    </NoticesNewsListTagContext.Provider>
  );
}
