import React, { useContext, ReactNode, useState } from 'react';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { AlignType } from 'react-bootstrap/esm/types';
import { StyledToasterFormDropDownButtonContext } from './Styled';

type Props = {
  children: ReactNode;
  id?: string;
  dropDownButtonClassName?: string;
  dropDownButtonStyle?: any;
  dropDownMenuStyle?: any;
  dropDownMenuWidth?: string;
  dropDownButtonId?: string;
  disabled?: boolean;
  isHiddenCarret?: boolean;
  isHiddenButton?: boolean;
  align?: AlignType;
  variant?: string;
  size?: 'sm' | 'lg';
  drop?: DropDirection;
  textAlign?: string;
  title: ReactNode;
  dropDownButtonOnBlur?: React.FocusEventHandler<HTMLElement>;
  dropDownButtonOnClick?: React.MouseEventHandler<HTMLElement>;
};

interface ToasterFormDropDownButtonValue {
  isToasterFormDropDownButton: boolean;
  setIsToasterFormDropDownButton: (isToasterFormDropDownButton: boolean) => void;
}

const ToasterFormDropDownButtonContext = React.createContext<ToasterFormDropDownButtonValue | null>(null);

export function useToasterFormDropDownButton(): ToasterFormDropDownButtonValue {
  const state = useContext(ToasterFormDropDownButtonContext);

  if (!state) {
    throw new Error('useToasterFormDropDownButton must be used within ToasterFormDropDownButtonProvider');
  }

  return state;
}

export function ToasterFormDropDownButton({
  children,
  id,
  dropDownButtonClassName,
  dropDownButtonStyle,
  dropDownButtonId,
  dropDownMenuWidth,
  disabled = false,
  isHiddenCarret = false,
  isHiddenButton = false,
  align = 'start',
  variant = 'light',
  size = 'sm',
  drop = 'down',
  textAlign = 'center',
  title,
  dropDownButtonOnBlur,
  dropDownButtonOnClick,
}: Props) {
  const [isToasterFormDropDownButton, setIsToasterFormDropDownButton] = useState<boolean>(false);

  const providerValue = {
    isToasterFormDropDownButton,
    setIsToasterFormDropDownButton,
  };

  return (
    <StyledToasterFormDropDownButtonContext
      textAlign={textAlign}
      isHiddenCarret={isHiddenCarret}
      isHiddenButton={isHiddenButton}
      dropDownMenuWidth={dropDownMenuWidth}
    >
      <ToasterFormDropDownButtonContext.Provider value={providerValue}>
        <div
          id={id}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            margin: 0,
            display: 'inline-block',
            width: '100%',
            height: '100%',
          }}
          onClick={(event) => {
            event.preventDefault();
            (event.currentTarget.firstChild?.firstChild as HTMLButtonElement)?.click();
          }}
        >
          <DropdownButton
            className={dropDownButtonClassName}
            style={dropDownButtonStyle}
            id={dropDownButtonId}
            disabled={disabled}
            align={align}
            variant={variant}
            size={size}
            as={ButtonGroup}
            drop={drop}
            title={title}
            onBlur={dropDownButtonOnBlur}
            onClick={dropDownButtonOnClick}
          >
            {children}
          </DropdownButton>
        </div>
      </ToasterFormDropDownButtonContext.Provider>
    </StyledToasterFormDropDownButtonContext>
  );
}
