import React, { useContext, ReactNode, useCallback } from 'react';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';
import { ROUTES } from '~/constants/routes';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminSignInValue {
  doSendPasswordResetEmail: (email: string) => Promise<void>,
}

const AdminSignInContext = React.createContext<AdminSignInValue | null>(null);

export function useAdminSignIn(): AdminSignInValue {
  const state = useContext(AdminSignInContext);

  if (!state) {
    throw new Error('useAdminSignIn must be used within AdminSignInProvider');
  }

  return state;
}

export function AdminSignInProvider({ children }: Props) {
  const { localbridgeUserUpdatePasswordResetSendMail } = useLocalbridgeUserApi();

  const doSendPasswordResetEmail = useCallback(async (email: string) => {
    await localbridgeUserUpdatePasswordResetSendMail({
      env: {LOCALBRIDGE_PATH, FUNCTION_V},
      params: {
        email,
        origin: window.location.origin,
        route: ROUTES.USER_UPDATE_PASSWORD_RESET,
      },
    });
  }, [localbridgeUserUpdatePasswordResetSendMail]);

  const providerValue = {
    doSendPasswordResetEmail,
  };

  return (
    <AdminSignInContext.Provider value={providerValue}>
      {children}
    </AdminSignInContext.Provider>
  );
}
