
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AboutNavbarValue {

}

const AboutNavbarContext = React.createContext<AboutNavbarValue | null>(null);

export function useAboutNavbar(): AboutNavbarValue {
  const state = useContext(AboutNavbarContext);

  if (!state) {
    throw new Error('useAboutNavbar must be used within AboutNavbarProvider');
  }

  return state;
}

export function AboutNavbarProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AboutNavbarContext.Provider value={providerValue}>
      {children}
    </AboutNavbarContext.Provider>
  );
}
