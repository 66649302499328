import React, { useCallback, useMemo } from 'react';
import './index.scss';
import WorkshopCard from '~/components/workshopCard';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { PaginationBox } from '~/components/paginationBox';
import SearchBoxWorkshop, { SearchBoxWorkshopOption } from '~/components/searchBoxWorkshop';
import LocalbridgeBody from '~/components/localbridgeBody';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { useWorkshops } from './Provider';
import { WorkshopModel } from '~/openapi/typescript-axios/version';

type Props = {};

const WorkshopsRenderer: React.FC<Props> = () => {
  const {
    workshopsList,
    firstWorkshopRequestsSearch,
    workshopsPageCurrent,
    workshopsPageLast,
    setWorkshopRequestsSearch,
    setWorkshopsPageCurrent,
  } = useWorkshops();

  const workshops: Array<WorkshopModel> = useMemo(() => {
    return workshopsList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === workshopsPageCurrent;
    })?.data || [];
  }, [workshopsPageCurrent, workshopsList]);

  const onSubmit = useCallback((searchOption: SearchBoxWorkshopOption) => {
    setWorkshopRequestsSearch({...firstWorkshopRequestsSearch, ...searchOption});
  }, [firstWorkshopRequestsSearch, setWorkshopRequestsSearch]);

  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title='イベント一覧' enTitle='Events' />
      <LocalbridgeBody bgColor={'#edfcef'}>
        <SearchBoxWorkshop title={'イベント検索'} onSubmit={onSubmit} />
        <div className={'event_list'}>
          {workshops.map((workshop) => (
            <WorkshopCard key={workshop.id} workshopModel={workshop} />
          ))}
        </div>
        <div className={'event_list__pagination'}>
          <PaginationBox
            forcePage={workshopsPageCurrent}
            pageCount={workshopsPageLast}
            onPageChange={setWorkshopsPageCurrent}
          />
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default WorkshopsRenderer;
