
import React, { useContext, ReactNode } from 'react';
import { UserPublic } from '~/openapi/typescript-axios/version';

type Props = {
  children: ReactNode;
  userPublic: UserPublic;
};

interface UserOtherPersonProfileValue {

}

const UserOtherPersonProfileContext = React.createContext<UserOtherPersonProfileValue | null>(null);

export function useUserOtherPersonProfile(): UserOtherPersonProfileValue {
  const state = useContext(UserOtherPersonProfileContext);

  if (!state) {
    throw new Error('useUserOtherPersonProfile must be used within UserOtherPersonProfileProvider');
  }

  return state;
}

export function UserOtherPersonProfileProvider({ children, userPublic }: Props) {
  const providerValue = {
    userPublic
  };

  return (
    <UserOtherPersonProfileContext.Provider value={providerValue}>
      {children}
    </UserOtherPersonProfileContext.Provider>
  );
}
