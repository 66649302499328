import React from 'react';

type Props = {};

const GroupWorkshopBookingsRenderer: React.FC<Props> = () => {
  return (
    <></>
  );
};

export default GroupWorkshopBookingsRenderer;
