type Props = {
  purpose: string;
  checked?: boolean;
  onSelectPurpose: (purpose: string) => void;
};

const SelectCategory: React.FC<Props> = ({purpose, checked, onSelectPurpose}) => {
  return (
    <div
      className={'workshop_booking_form_checkbox__item'}
      onClick={() => {
        onSelectPurpose(purpose);
      }}
    >
      <input
        className={'workshop_booking_form_checkbox__btn'}
        type='checkbox'
        checked={checked}
        onChange={() => {}}
      />
      <label className={'workshop_booking_form_checkbox__text'}>
        {purpose}
      </label>
    </div>
  );
};

export default SelectCategory;