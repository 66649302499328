import React from 'react';
import { UserMyProfileProvider } from './Provider';
import UserMyProfileRenderer from './Renderer';
import { UserModel } from '~/openapi/typescript-axios/version';

type Props = {
  userModel: UserModel,
};

export const UserMyProfile: React.FC<Props> = ({userModel}) => {
  return (
    <UserMyProfileProvider userModel={userModel}>
      <UserMyProfileRenderer />
    </UserMyProfileProvider>
  );
};
