import React from 'react';
import { AdminPostsProvider } from './Provider';
import AdminPostsRenderer from './Renderer';

export const AdminPosts = () => {
  return (
    <AdminPostsProvider>
      <AdminPostsRenderer />
    </AdminPostsProvider>
  );
};
