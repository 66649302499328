import React, { useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { NoticeModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminNoticeEditValue {
  noticeModel?: NoticeModel;
  setNoticeModel: (noticeModel?: NoticeModel) => void;
}

const AdminNoticeEditContext = React.createContext<AdminNoticeEditValue | null>(null);

export function useAdminNoticeEdit(): AdminNoticeEditValue {
  const state = useContext(AdminNoticeEditContext);

  if (!state) {
    throw new Error('useAdminNoticeEdit must be used within AdminNoticeEditProvider');
  }

  return state;
}

export function AdminNoticeEditProvider({ children }: Props) {
  const { noticeId } = useParams<any>();

  const { getNotice } = useLocalbridgeNoticeApi();
  const [ noticeModel, setNoticeModel ] = useState<NoticeModel>();

  const initNotice = useCallback(async () => {
    if (!noticeId) {
      return;
    }

    const noticeModelTmp = await getNotice({noticeId});
    setNoticeModel(noticeModelTmp?.noticeModel);
  }, [noticeId, getNotice, setNoticeModel]);

  useEffect(() => {
    initNotice();
  }, [initNotice]);

  const providerValue = {
    noticeModel,
    setNoticeModel,
  };

  return (
    <AdminNoticeEditContext.Provider value={providerValue}>
      {children}
    </AdminNoticeEditContext.Provider>
  );
}
