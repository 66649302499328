import React from 'react';
import { AdminGroupEditWithUserProvider } from './Provider';
import AdminGroupEditWithUserRenderer from './Renderer';

export const AdminGroupEditWithUser = () => {
  return (
    <AdminGroupEditWithUserProvider>
      <AdminGroupEditWithUserRenderer />
    </AdminGroupEditWithUserProvider>
  );
};
