import { useCallback, useMemo, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';

type Props = {
  townId?: string;
  townIndex: number;
  disabled?: boolean;
  onChangeTownId: (townId: string) => void;
};

const SelectTown: React.FC<Props> = ({townId, townIndex, disabled, onChangeTownId}) => {
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();

  const [ selectedCityId, setSelectedCityId ] = useState<string>();

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === selectedCityId);
  }, [cities, selectedCityId]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    selectedCityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === townId);
  }, [towns, townId]);

  return (
    <div className='d-flex gap-2'>
      <DropdownButton
        disabled={disabled}
        variant={'white'}
        style={{width: 'fit-content', padding: 0}}
        className={'border'}
        as={ButtonGroup}
        drop={'down'}
        title={
          <span className='border-0 p-0'>
            {selectedCity?.cityName || '選択してください'}
          </span>
        }
      >
        <div className={'row m-1'}>
          <Dropdown.Item
            className='btn btn-sm p-1 text-center'
            onClick={() => setSelectedCityId(undefined)}
          >選択なし</Dropdown.Item>

          {cities.filter((town) => town.id !== '000000').map((city) => (
            <Dropdown.Item
              key={`townIndex-${townIndex}-cities-${city.id}`}
              className='btn btn-sm p-1 text-center'
              onClick={() => {
                const isSameCity = selectedCityId === city.id;
                if (!isSameCity) {
                  onChangeTownId('');
                }

                setSelectedCityId(city.id);
              }}
            >{city.cityName}</Dropdown.Item>
          ))}
        </div>
      </DropdownButton>

      <DropdownButton
        disabled={disabled || !selectedCity}
        variant={'white'}
        style={{width: 'fit-content', padding: 0}}
        className={'border'}
        as={ButtonGroup}
        drop={'down'}
        title={
          <span className='user_edit_item__input border-0 p-0'>
            {selectedTown?.associationName || '選択してください'}
          </span>
        }
      >
        <div className={'row m-1'}>
          <Dropdown.Item
            className='btn btn-sm p-1 text-center'
            onClick={() => onChangeTownId('')}
          >選択なし</Dropdown.Item>

          {towns.filter((town) => town.id !== '000000').map((town) => (
            <Dropdown.Item
              key={`townIndex-${townIndex}-towns-${town.id}`}
              className='btn btn-sm p-1 text-center'
              onClick={() => {
                onChangeTownId(town.id);
              }}
            >{town.associationName}</Dropdown.Item>
          ))}
        </div>
      </DropdownButton>
    </div>
  );
};

export default SelectTown;