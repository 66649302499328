import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  LOCALBRIDGE_PATH,
  firestore,
} from '~/constants/firebase';
import {
  GroupRequestsDelete,
  GroupRequestCreate,
  GroupRequestUpdate,
  GroupRequestsValidate,
  GroupResponsesWithPager,
  GroupModel,
  GroupRequestRead,
  GroupResponseRead,
  GroupRequestsSearch,
  testEmptyInput,
  testStringId,
  testStringMax80,
  ExistGroupNameMessage,
  testStringMax40000,
  testDateReversedStartAndEnd,
  testStringMax200,
  testStringWrongEmailFormat,
} from '~/openapi/typescript-axios/version';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { getSearchStringQuery } from './helpers/getSearchStringQuery';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeGroupApiValue {
  validateGroups: (groupRequestsValidate: GroupRequestsValidate) => Promise<MessageResponse>;
  createGroups: (groupRequestCreates: Array<GroupRequestCreate>) => Promise<void>;
  getGroup: (groupRequestRead: GroupRequestRead) => Promise<GroupResponseRead|null>;
  updateGroup: (groupRequestUpdate: GroupRequestUpdate) => Promise<void>;
  deleteGroups: (deleteGroupsRequest: GroupRequestsDelete) => Promise<void>;
  searchGroups: (
    userRequestsSearch: GroupRequestsSearch
  ) => Promise<Array<GroupResponsesWithPager>>;
}

const LocalbridgeGroupApiContext = React.createContext<LocalbridgeGroupApiValue | null>(null);

export function useLocalbridgeGroupApi(): LocalbridgeGroupApiValue {
  const state = useContext(LocalbridgeGroupApiContext);

  if (!state) {
    throw new Error('useLocalbridgeGroupApi must be used within LocalbridgeGroupApiProvider');
  }

  return state;
}

export function LocalbridgeGroupApiProvider({ children }: Props) {
  const validateGroups = useCallback(async (groupRequestsValidate: GroupRequestsValidate) => {
    const groupModels = groupRequestsValidate.groupRequests.map((groupRequest) => {
      return groupRequest.groupModel;
    });

    const groupValidator = new Validator<GroupModel>('GroupModel', {
      id: [
        async (group) => testEmptyInput(group.id),
        async (group) => testStringMax80(group.id),
        async (group) => testStringId(group.id),
      ],
      name: [
        async (group) => testEmptyInput(group.name),
        async (group) => testStringMax80(group.name),
        async (group) => {
          const groupDocs = await getDocs(query(
            collection(firestore, `${LOCALBRIDGE_PATH}/users`),
            where('name', '==', group.name),
          ));

          return groupDocs.docs.filter((doc) => doc.id !== group.id).length
            ? ExistGroupNameMessage : '';
        },
      ],
      groupEmail: [
        async (group) => testEmptyInput(group.groupEmail),
        async (group) => testStringWrongEmailFormat(group.groupEmail),
      ],
      delegateName: [
        async (group) => testEmptyInput(group.delegateName),
        async (group) => testStringMax80(group.delegateName),
      ],
      delegateNameFurigana: [
        async (group) => testEmptyInput(group.delegateName),
        async (group) => testStringMax80(group.delegateName),
      ],
      managerEmail: [
        async (group) => testEmptyInput(group.managerEmail),
        async (group) => testStringWrongEmailFormat(group.groupEmail),
      ],
      managerName: [
        async (group) => testEmptyInput(group.managerName),
        async (group) => testStringMax80(group.managerName),
      ],
      photos: [
      ],
      createdAt: [
        async (group) => testEmptyInput(group.createdAt),
        async (group) => testDateReversedStartAndEnd(group.createdAt, group.updatedAt),
      ],
      updatedAt: [
        async (group) => testEmptyInput(group.updatedAt),
      ],
      cityId: [
        async (group) => testEmptyInput(group.cityId),
        async (group) => testStringMax80(group.cityId),
        async (group) => testStringId(group.cityId),
      ],
      townId: [
        async (group) => testEmptyInput(group.townId),
        async (group) => testStringMax80(group.townId),
        async (group) => testStringId(group.townId),
      ],
      detailAddress1: [
        async (group) => testEmptyInput(group.detailAddress1),
        async (group) => testStringMax200(group.detailAddress1),
      ],
      detailAddress2: [
        async (group) => testEmptyInput(group.detailAddress2),
        async (group) => testStringMax200(group.detailAddress2),
      ],
      userIdsManagerRole: [
        async (group) => testEmptyInput(group.userIdsManagerRole),
        async (group) => testStringId(group.userIdsManagerRole),
      ],
      mainPhoto: [
      ],
      icon: [
      ],
      description: [
        async (group) => testStringMax40000(group.description),
      ]
    });

    return groupValidator.validate(groupModels);
  }, []);

  const createGroups = useCallback(async (groupRequestCreates: Array<GroupRequestCreate>) => {
    if (!groupRequestCreates.length) {
      return;
    }

    const messageResponses = await validateGroups({
      isOnSubmit: true,
      groupRequests: groupRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const groupRequestCreate of groupRequestCreates) {
      const { id } = groupRequestCreate.groupModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/groups/${id}`), {
        ...Object.assign({}, groupRequestCreate.groupModel)
      });
    }

    await batch.commit();
  }, [validateGroups]);

  const getGroup = useCallback(async (groupRequestRead: GroupRequestRead) => {
    const { groupId } = groupRequestRead;
    const groupModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/groups/${groupId}`))).data() as GroupModel|null;
    return groupModel ? {groupModel} : null;
  }, []);

  const updateGroup = useCallback(async (groupRequestUpdate: GroupRequestUpdate) => {
    const messageResponses = await validateGroups({
      isOnSubmit: true,
      groupRequests: [groupRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = groupRequestUpdate.groupModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/groups/${id}`), {
      ...Object.assign({}, groupRequestUpdate.groupModel),
    });
  }, [validateGroups]);

  const deleteGroups = useCallback(async (deleteGroupsRequest: GroupRequestsDelete) => {
    const groupIds = deleteGroupsRequest?.groupIds || [];

    if (!groupIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const groupId of groupIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/groups/${groupId}`));
    }

    await batch.commit();
  }, []);

  const searchGroups = useCallback(async (userRequestsSearch: GroupRequestsSearch) => {
    const {
      size,
      page,
      groupIds,
      cityId,
      townId,
      createdFrom,
      createdTo,
      searchString,
    } = userRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/groups`),
      and(
        ...[
          cityId ? where('cityId', '==', cityId) : null,
          townId ? where('townId', '==', townId) : null,
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
          searchString ? getSearchStringQuery(searchString, ['nameToken']) : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
        or(...(groupIds || [])?.map((groupId) => where('id', '==', groupId))),
      ),
      !searchString ? orderBy('createdAt', 'desc') : limit(1000),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<GroupModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<GroupResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const providerValue = {
    validateGroups,
    createGroups,
    getGroup,
    updateGroup,
    deleteGroups,
    searchGroups,
  };

  return (
    <LocalbridgeGroupApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeGroupApiContext.Provider>
  );
}
