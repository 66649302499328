import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { GroupRequestsSearch, GroupResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminGroupsValue {
  groupsList: Array<GroupResponsesWithPager>;
  groupsPageCurrent: number;
  groupsPageLast: number;
  setGroupsList: (groupsList: Array<GroupResponsesWithPager>) => void;
  setGroupRequestsSearch: (groupRequestsSearch: GroupRequestsSearch) => void;
  setGroupsPageCurrent: (groupsPageCurrent: number) => void;
}

const AdminGroupsContext = React.createContext<AdminGroupsValue | null>(null);

export function useAdminGroups(): AdminGroupsValue {
  const state = useContext(AdminGroupsContext);

  if (!state) {
    throw new Error('useAdminGroups must be used within AdminGroupsProvider');
  }

  return state;
}

export function AdminGroupsProvider({ children }: Props) {
  const { searchGroups } = useLocalbridgeGroupApi();

  const firstGroupRequestsSearch: GroupRequestsSearch = {
    size: 10,
  };
  const [ groupRequestsSearch, setGroupRequestsSearch ] = useState<GroupRequestsSearch>(firstGroupRequestsSearch);
  const [ groupsList, setGroupsList ] = useState<Array<GroupResponsesWithPager>>([]);
  const [ groupsPageCurrent, setGroupsPageCurrent ] = useState<number>(0);
  const [ groupsPageLast, setGroupsPageLast ] = useState<number>(0);

  const getGroupsList = useCallback(async () => {
    if (!groupRequestsSearch) {
      return;
    }

    const groupsListTmp = await searchGroups(groupRequestsSearch);
    const [groupsWithPager] = groupsListTmp;

    if (groupsWithPager?.pager) {
      const { totalCount, size } = groupsWithPager.pager;
      setGroupsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }
    setGroupsList(groupsListTmp);
  }, [groupRequestsSearch, searchGroups, setGroupsList]);

  useEffect(() => {
    getGroupsList();
  }, [getGroupsList]);

  const providerValue = {
    groupsList,
    groupsPageCurrent,
    groupsPageLast,
    setGroupsList,
    setGroupRequestsSearch,
    setGroupsPageCurrent,
  };

  return (
    <AdminGroupsContext.Provider value={providerValue}>
      {children}
    </AdminGroupsContext.Provider>
  );
}
