import React, { useContext, ReactNode, useCallback } from 'react';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';
import { ROUTES } from '~/constants/routes';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';

type Props = {
  children: ReactNode;
};

interface SignInValue {
  doSendPasswordResetEmail: (email: string) => Promise<void>,
}

const SignInContext = React.createContext<SignInValue | null>(null);

export function useSignIn(): SignInValue {
  const state = useContext(SignInContext);

  if (!state) {
    throw new Error('useSignIn must be used within SignInProvider');
  }

  return state;
}

export function SignInProvider({ children }: Props) {
  const { localbridgeUserUpdatePasswordResetSendMail } = useLocalbridgeUserApi();

  const doSendPasswordResetEmail = useCallback(async (email: string) => {
    await localbridgeUserUpdatePasswordResetSendMail({
      env: {LOCALBRIDGE_PATH, FUNCTION_V},
      params: {
        email,
        origin: window.location.origin,
        route: ROUTES.USER_UPDATE_PASSWORD_RESET,
      },
    });
  }, [localbridgeUserUpdatePasswordResetSendMail]);

  const providerValue = {
    doSendPasswordResetEmail,
  };

  return (
    <SignInContext.Provider value={providerValue}>
      {children}
    </SignInContext.Provider>
  );
}
