import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PostType } from '~/openapi/typescript-axios/version';
import { PostRequestsSearch, PostResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminPostsValue {
  postType: string|null;
  postsList: Array<PostResponsesWithPager>;
  postsPageCurrent: number;
  postsPageLast: number;
  setPostRequestsSearch: (postRequestsSearch: PostRequestsSearch) => void;
  setPostsPageCurrent: (postsPageCurrent: number) => void;
}

const AdminPostsContext = React.createContext<AdminPostsValue | null>(null);

export function useAdminPosts(): AdminPostsValue {
  const state = useContext(AdminPostsContext);

  if (!state) {
    throw new Error('useAdminPosts must be used within AdminPostsProvider');
  }

  return state;
}

export function AdminPostsProvider({ children }: Props) {
  const search = useLocation().search;

  // eslint-disable-next-line
  const searchParams = new URLSearchParams(search);
  const { searchPosts } = useLocalbridgePostApi();
  const [ firstPostRequestsSearch, setFirstPostRequestsSearch ] = useState<PostRequestsSearch>({
    size: 9,
    postType: PostType.Attractive,
  });

  const [ postRequestsSearch, setPostRequestsSearch ] = useState<any>({});

  const [ postsList, setPostsList ] = useState<Array<PostResponsesWithPager>>([]);
  const [ postsPageCurrent, setPostsPageCurrent ] = useState<number>(0);
  const [ postsPageLast, setPostsPageLast ] = useState<number>(0);

  const searchPostsList = useCallback(async () => {
    try {
      const postsListTmp = await searchPosts({...postRequestsSearch, ...firstPostRequestsSearch});
      const [postsWithPager] = postsListTmp;

      if (postsWithPager?.pager) {
        const { totalCount, size } = postsWithPager.pager;
        setPostsPageLast(Math.floor((totalCount - 1) / size) + 1);
      }
      setPostsList(postsListTmp);
    } catch (error) {
      console.log(error);
    }
  }, [firstPostRequestsSearch, postRequestsSearch, searchPosts, setPostsList]);
 
  useEffect(() => {
    searchPostsList();
  }, [searchPostsList]);

  useEffect(() => {
    const postTypeTmp = searchParams.get('postType') as PostType;
    if (firstPostRequestsSearch.postType === postTypeTmp) {
      return;
    }

    firstPostRequestsSearch.postType = postTypeTmp;
    setFirstPostRequestsSearch(Object.assign({}, firstPostRequestsSearch));
  }, [searchParams, firstPostRequestsSearch, setFirstPostRequestsSearch]);

  const providerValue = {
    postType: firstPostRequestsSearch.postType,
    postsList,
    postsPageCurrent,
    postsPageLast,
    setPostRequestsSearch,
    setPostsPageCurrent,
  };

  return (
    <AdminPostsContext.Provider value={providerValue}>
      {children}
    </AdminPostsContext.Provider>
  );
}
