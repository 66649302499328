import React, { useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AssociationModel, BookingModelWithEmailConfirm, BookingSource, GroupModel, UserGender } from '~/openapi/typescript-axios/version';
import { useAppState } from '~/providers/AppStateProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';
import { WorkshopCreateBookingState } from './enums';
import { nanoid } from 'nanoid';
import { useAuth } from '~/providers/AuthProvider';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';

type Props = {
  children: ReactNode;
};

interface WorkshopBookingsCreateValue {
  town?: AssociationModel;
  workshopCreateBookingState: WorkshopCreateBookingState;
  bookingModelWithEmailConfirm: BookingModelWithEmailConfirm;
  groupModel?: GroupModel;
  setWorkshopCreateBookingState: (workshopCreateBookingState: WorkshopCreateBookingState) => void;
  setBookingModelWithEmailConfirm: (bookingModelWithEmailConfirm: BookingModelWithEmailConfirm) => void;
  resetPage: () => Promise<void>;
}

const WorkshopBookingsCreateContext = React.createContext<WorkshopBookingsCreateValue | null>(null);

export function useWorkshopBookingsCreate(): WorkshopBookingsCreateValue {
  const state = useContext(WorkshopBookingsCreateContext);

  if (!state) {
    throw new Error('useWorkshopBookingsCreate must be used within WorkshopBookingsCreateProvider');
  }

  return state;
}

export function WorkshopBookingsCreateProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { workshopId } = useParams<any>();
  const { thisUser } = useAuth();
  const { workshopModel } = useParentWorkshop();
  const [ town, setTown ] = useState<AssociationModel>();
  const [ workshopCreateBookingState, setWorkshopCreateBookingState ] = useState<WorkshopCreateBookingState>(
    WorkshopCreateBookingState.WorkshopBookingForm
  );

  const now = Number(new Date());
  const [ bookingModelWithEmailConfirm, setBookingModelWithEmailConfirm ] = useState<BookingModelWithEmailConfirm>({
    id: nanoid(),
    createdAt: now,
    updatedAt: now,
    photos: [],
    userId: '',
    interests: [],
    icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    name: '',
    nickName: '',
    nameFurigana: '',
    studentGrade: '',
    description: '',
    job: '',
    educationalInstitutionName: '',
    studentStartAt: [],
    email: '',
    emailConfirm: '',
    emailToken: getSearchTokenMap(''),
    gender: '' as UserGender,
    phoneNumber: '',
    cityId: '',
    townId: '',
    detailAddress1: '',
    detailAddress2: '',
    postNumber: '',
    localAssociationIds: [],
    localCategorys: [],
    birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
    workshopId: '',
    groupId: '',
    keyPoint: '',
    source: '' as BookingSource,
    message: '',
    purposes: [],
  });

  const [ groupModel, setGroupModel ] = useState<GroupModel>();

  const { searchAssociations } = useLocalbridgeAssociationApi();
  const { getGroup } = useLocalbridgeGroupApi();

  const resetPage = useCallback(async () => {
    if (workshopModel?.townId) {
      const associationResponsesWithPager = await searchAssociations({
        associationIds: [workshopModel?.townId],
      });
      setTown(associationResponsesWithPager?.[0]?.data?.[0]);

      const groupResponsesWithPager = await getGroup({
        groupId: workshopModel.groupId,
      });
      setGroupModel(groupResponsesWithPager?.groupModel);      
    }
  }, [workshopModel, getGroup, searchAssociations, setTown, setGroupModel]);

  useEffect(() => {
    if (thisUser
      && workshopModel
      && thisUser?.id !== bookingModelWithEmailConfirm.userId
      && workshopModel?.id !== bookingModelWithEmailConfirm.workshopId
    ) {
      bookingModelWithEmailConfirm.userId = thisUser.id;
      bookingModelWithEmailConfirm.name = thisUser.name || '',
      bookingModelWithEmailConfirm.nickName = thisUser.nickName || '',
      bookingModelWithEmailConfirm.nameFurigana = thisUser.nameFurigana || '',
      bookingModelWithEmailConfirm.studentGrade = thisUser.studentGrade || '',
      bookingModelWithEmailConfirm.job = thisUser.job || '',
      bookingModelWithEmailConfirm.gender = thisUser.gender;
      bookingModelWithEmailConfirm.educationalInstitutionName = thisUser.educationalInstitutionName;
      bookingModelWithEmailConfirm.studentStartAt = thisUser.studentStartAt;
      bookingModelWithEmailConfirm.email = thisUser.email;
      bookingModelWithEmailConfirm.emailToken = thisUser.emailToken;
      bookingModelWithEmailConfirm.gender = thisUser.gender;
      bookingModelWithEmailConfirm.phoneNumber = thisUser.phoneNumber;
      bookingModelWithEmailConfirm.detailAddress1 = thisUser.detailAddress1;
      bookingModelWithEmailConfirm.detailAddress2 = thisUser.detailAddress2;
      bookingModelWithEmailConfirm.postNumber = thisUser.postNumber;
      bookingModelWithEmailConfirm.localAssociationIds = thisUser.localAssociationIds;
      bookingModelWithEmailConfirm.localCategorys = thisUser.localCategorys;
      bookingModelWithEmailConfirm.birthDay = thisUser.birthDay;

      bookingModelWithEmailConfirm.workshopId = workshopModel.id;
      bookingModelWithEmailConfirm.groupId = workshopModel.groupId;
      bookingModelWithEmailConfirm.cityId = workshopModel.cityId;
      bookingModelWithEmailConfirm.townId = workshopModel.townId;

      for (const key in bookingModelWithEmailConfirm) {
        if (bookingModelWithEmailConfirm[key] === undefined) {
          delete bookingModelWithEmailConfirm[key];
        }
      }

      setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
    }
  }, [thisUser, workshopModel, bookingModelWithEmailConfirm]);

  useEffect(() => {
    if (params.workshopId !== workshopId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { workshopId }));
    }
  }, [workshopId, params, setParams]);

  useEffect(() => {
    resetPage();
  }, [resetPage]);

  const providerValue = {
    town,
    workshopCreateBookingState,
    bookingModelWithEmailConfirm,
    groupModel,
    setWorkshopCreateBookingState,
    setBookingModelWithEmailConfirm,
    resetPage,
  };

  return (
    <WorkshopBookingsCreateContext.Provider value={providerValue}>
      {children}
    </WorkshopBookingsCreateContext.Provider>
  );
}
