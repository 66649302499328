
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface UserLeftSidebarValue {

}

const UserLeftSidebarContext = React.createContext<UserLeftSidebarValue | null>(null);

export function useUserLeftSidebar(): UserLeftSidebarValue {
  const state = useContext(UserLeftSidebarContext);

  if (!state) {
    throw new Error('useUserLeftSidebar must be used within UserLeftSidebarProvider');
  }

  return state;
}

export function UserLeftSidebarProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <UserLeftSidebarContext.Provider value={providerValue}>
      {children}
    </UserLeftSidebarContext.Provider>
  );
}
