import React from 'react';
import { UserPostProvider } from './Provider';
import UserPostRenderer from './Renderer';

export const UserPost = () => {
  return (
    <UserPostProvider>
      <UserPostRenderer />
    </UserPostProvider>
  );
};
