import React, {
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { AssociationModel, BookingModel, WorkshopModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';

type Props = {
  children: ReactNode;
};

interface UserBookingValue {
  bookingModel?: BookingModel;
  workshopModel?: WorkshopModel;
  town?: AssociationModel,
  setBookingModel: (bookingModel?: BookingModel) => void;
}

const UserBookingContext = React.createContext<UserBookingValue | null>(null);

export function useUserBooking(): UserBookingValue {
  const state = useContext(UserBookingContext);

  if (!state) {
    throw new Error('useUserBooking must be used within UserBookingProvider');
  }

  return state;
}

export function UserBookingProvider({ children }: Props) {
  const { bookingId } = useParams<any>();

  const { searchAssociations } = useLocalbridgeAssociationApi();
  const { getBooking } = useLocalbridgeBookingApi();
  const { getWorkshop } = useLocalbridgeWorkshopApi();
  const [ bookingModel, setBookingModel ] = useState<BookingModel>();
  const [ workshopModel, setWorkshopModel ] = useState<WorkshopModel>();
  const [ town, setTown ] = useState<AssociationModel>();

  const initBooking = useCallback(async () => {
    if (!bookingId) {
      return;
    }

    const bookingModelTmp = await getBooking({bookingId});
    setBookingModel(bookingModelTmp?.bookingModel);

    if (bookingModelTmp?.bookingModel.workshopId) {
      const workshopModelTmp = await getWorkshop({workshopId: bookingModelTmp?.bookingModel.workshopId});
      setWorkshopModel(workshopModelTmp?.workshopModel);

      if (workshopModelTmp?.workshopModel?.townId) {
        const associationModelTmp = await searchAssociations({
          associationIds: [workshopModelTmp.workshopModel.townId]
        });
        setTown(associationModelTmp[0]?.data?.[0]);
      }
    }
  }, [
    bookingId,
    getBooking,
    getWorkshop,
    searchAssociations,
    setWorkshopModel,
    setBookingModel,
    setTown,
  ]);

  useEffect(() => {
    initBooking();
  }, [initBooking]);

  const providerValue = {
    bookingModel,
    workshopModel,
    town,
    setBookingModel,
  };

  return (
    <UserBookingContext.Provider value={providerValue}>
      {children}
    </UserBookingContext.Provider>
  );
}
