import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkshopResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';
import { useParentGroup } from '~/providers/ParentGroupProvider';

type Props = {
  children: ReactNode;
};

interface GroupWorkshopsValue {
  workshopsList: Array<WorkshopResponsesWithPager>;
  workshopsPageCurrent: number;
  workshopsPageLast: number;
  setWorkshopsPageCurrent: (workshopsPageCurrent: number) => void;
}

const GroupWorkshopsContext = React.createContext<GroupWorkshopsValue | null>(null);

export function useGroupWorkshops(): GroupWorkshopsValue {
  const state = useContext(GroupWorkshopsContext);

  if (!state) {
    throw new Error('useGroupWorkshops must be used within GroupWorkshopsProvider');
  }

  return state;
}

export function GroupWorkshopsProvider({ children }: Props) {
  const { groupId } = useParams<any>();
  const { groupModel } = useParentGroup();

  const { searchWorkshops } = useLocalbridgeWorkshopApi();

  const [ workshopsList, setWorkshopsList ] = useState<Array<WorkshopResponsesWithPager>>([]);
  const [ workshopsPageCurrent, setWorkshopsPageCurrent ] = useState<number>(0);
  const [ workshopsPageLast, setWorkshopsPageLast ] = useState<number>(0);

  const getWorkshopsList = useCallback(async () => {
    if (!groupModel?.id !== groupId) {
      return;
    }

    const workshopsListTmp = await searchWorkshops({groupId});
    const [workshopsWithPager] = workshopsListTmp;

    if (workshopsWithPager?.pager) {
      const { totalCount, size } = workshopsWithPager.pager;
      setWorkshopsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }
    setWorkshopsList(workshopsListTmp);
  }, [groupModel, groupId, searchWorkshops, setWorkshopsList]);

  useEffect(() => {
    getWorkshopsList();
  }, [getWorkshopsList]);

  const providerValue = {
    workshopsList,
    workshopsPageCurrent,
    workshopsPageLast,
    setWorkshopsPageCurrent,
  };

  return (
    <GroupWorkshopsContext.Provider value={providerValue}>
      {children}
    </GroupWorkshopsContext.Provider>
  );
}
