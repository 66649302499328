import React from 'react';
import { UserBookingsProvider } from './Provider';
import UserBookingsRenderer from './Renderer';

export const UserBookings = () => {
  return (
    <UserBookingsProvider>
      <UserBookingsRenderer />
    </UserBookingsProvider>
  );
};
