import React from 'react';
import { LocalbridgeNavbarProvider } from './Provider';
import LocalbridgeNavbarRenderer from './Renderer';

export const LocalbridgeNavbar = () => {
  return (
    <LocalbridgeNavbarProvider>
      <LocalbridgeNavbarRenderer />
    </LocalbridgeNavbarProvider>
  );
};
