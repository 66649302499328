
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface StartingGuideValue {

}

const StartingGuideContext = React.createContext<StartingGuideValue | null>(null);

export function useStartingGuide(): StartingGuideValue {
  const state = useContext(StartingGuideContext);

  if (!state) {
    throw new Error('useStartingGuide must be used within StartingGuideProvider');
  }

  return state;
}

export function StartingGuideProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <StartingGuideContext.Provider value={providerValue}>
      {children}
    </StartingGuideContext.Provider>
  );
}
