import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { onDisconnect, ref, set } from 'firebase/database';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import { PhotoMeta, PostModel } from '~/openapi/typescript-axios/version';
import { getImageWidthHeight } from '~/helpers/getImageWidthHeight';
import { nanoid } from 'nanoid';
import { LOCALBRIDGE_PATH, database } from '~/constants/firebase';
import { uploadFileWithProgress } from '~/helpers/uploader';
import { StyledPostEditor } from './Styled';

const FontAttributor = Quill.import('attributors/style/font');
const FontSize = Quill.import('formats/size');
const BlockPrototype = Quill.import('blots/block');

// Set Default Font
class CustomBlock extends BlockPrototype {
  constructor(domNode, value) {
    super(domNode, value);
    this.format('size', '9px');
    this.format('font', 'Noto Sans JP');
  }

  static tagName = 'P';

  format(name: string, value: string) {
    if (name === 'size') {
      console.log(name, value);
      this.domNode.style.fontSize = value;
    } else if (name === 'font') {
      console.log(name, value);
      this.domNode.style.fontFamily = value;
    } else {
      super.format(name, value);
    }
  }
}
FontAttributor.whitelist = ['Noto Sans JP', 'Noto Serif JP', 'Arial', 'Georgia', 'Impact'];
FontSize.whitelist = [
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
  '35',
  '40',
  '45',
  '50',
];
Quill.register(FontAttributor, true);
Quill.register(FontSize, true);
Quill.register(CustomBlock, true);
Quill.register({
  'modules/emoji': Emoji,
  'modules/imageDropAndPaste': QuillImageDropAndPaste,
});

type Props = {
  postModel: PostModel;
  setPostModel: (postModel: PostModel) => void;
};

const PostEditor: React.FC<Props> = ({
  postModel,
  setPostModel,
}) => {
  const [quill, setQuill] = useState<Quill>();
  const quillRef = useRef<HTMLDivElement>(null);
  const resizePopUpRef = useRef<HTMLDivElement>(null);

  const handleUploadFile = useCallback(async (file) => {
    if (!quill || !postModel) {
      return;
    }

    const { width, height } = await getImageWidthHeight(file);
    const photoMeta: PhotoMeta = {
      id: nanoid(), width, height, size: file.size, name: file.name, url: '',
    };

    const postRef = [
      LOCALBRIDGE_PATH,
      `posts/${postModel.id}`,
    ].join('/');

    const photoRef = [
      postRef,
      `photos/${photoMeta.id}`,
    ].join('/');

    const tmpPhotoRef = [
      postRef,
      `tmpPhotos/${photoMeta.id}`,
    ].join('/');
    console.log(photoRef);

    const tempPhotoDatabase = ref(database, tmpPhotoRef);
    onDisconnect(tempPhotoDatabase).remove();

    const downloadUrl = await uploadFileWithProgress(photoRef, file);
    if (!downloadUrl) {
      return;
    }

    photoMeta.url = downloadUrl;
    if (!postModel.mainPhoto?.url) {
      postModel.mainPhoto = photoMeta;
    }

    await set(ref(database, tmpPhotoRef), photoMeta);

    postModel.photos.push(photoMeta);
    setPostModel(Object.assign({}, postModel));

    const range = quill.getSelection();
    if (!range){
      return;
    }

    quill.clipboard.dangerouslyPasteHTML(
      range.index,
      `<img src="${downloadUrl}" alt="Image" />`
    );

    setPostModel(Object.assign({}, postModel));
  }, [postModel, quill, setPostModel]);

  const imageHandler = useCallback(async (imageDataUrl, type, imageData) => {
    const file = imageData.toFile();
    await handleUploadFile(file);
  }, [handleUploadFile]);

  // eslint-disable-next-line
  const onPostEditorChange = useCallback((eventName: string, ...args) => {
    if (!quill || !quillRef.current) {
      return;
    }

    const delta = quill.getContents();

    const blocks = delta.map((op) => op.insert).filter((insert) => typeof insert === 'string');
    postModel.content = blocks.join('\n');

    const editorHtml = quillRef.current.querySelector('.ql-editor')?.innerHTML || '';
    postModel.contentData = editorHtml;
    console.log(editorHtml);

    setPostModel(Object.assign({}, postModel));
  }, [quill, postModel, setPostModel]);

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const [file] = input.files || [];

      if (!file) {
        return;
      }

      handleUploadFile(file);
    };
  }, [handleUploadFile]);

  function openPopup(image: HTMLImageElement) {
    if (!resizePopUpRef.current) {
      return;
    }

    const rect = image.getBoundingClientRect();

    resizePopUpRef.current.style.display = 'block';
    resizePopUpRef.current.style.top = rect.top + window.scrollY + 'px';
    resizePopUpRef.current.style.left = rect.left + 'px';
  }

  function closePopup() {
    if (!resizePopUpRef.current) {
      return;
    }
    
    resizePopUpRef.current.style.display = 'none';
  }

  const modules = useMemo(() => ({
    imageDropAndPaste: {
      handler: imageHandler,
    },
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],
        ['blockquote', 'code-block'],
      
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],

        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': FontAttributor.whitelist }],
        [{ 'size': FontSize.whitelist }],
        [{ 'align': [] }],

        ['link', 'image'],
      
        ['clean'],
      ],
    },
  }), [imageHandler]);

  useEffect(() => {
    if (!quillRef.current || quill) {
      return;
    }

    const editor = new Quill(quillRef.current, {
      modules,
      theme: 'snow',
    });
    editor.clipboard.dangerouslyPasteHTML(postModel.contentData);
    setQuill(editor);
  }, [quillRef, quill, modules, postModel.contentData]);

  useEffect(() => {
    if (quill) {
      quill.on('editor-change', onPostEditorChange);
      quill.root.addEventListener('click', (event) => {
        const clickedImage = (event.target as HTMLElement);
        if ((event.target as HTMLElement)?.tagName.toLowerCase() === 'img') {
          openPopup(clickedImage as HTMLImageElement);
        }
      });

      return () => {
        quill.off('editor-change', onPostEditorChange);
      };
    }
  }, [quill, onPostEditorChange]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    quill.getModule('toolbar').addHandler('image', () => {
      selectLocalImage();
    });
  }, [quill, selectLocalImage]);

  return (
    <StyledPostEditor ref={quillRef}>
      <div ref={resizePopUpRef}>
        <span onClick={closePopup}>&times;</span>
        <p>Drag the corners to resize</p>
      </div>
    </StyledPostEditor>
  );
};

export default PostEditor;

