export const ROUTES = {
  HOME:                           '/',
  SIGN_IN:                        '/sign/in',
  SIGN_OUT:                       '/sign/out',
  SIGN_UP:                        '/sign/up',
  SIGN_UP_EMAIL_CONFIRMATION:     '/sign/up/emailVarification',
  WORKSHOPS:                      '/workshops',
  WORKSHOP:                       '/workshop/:workshopId',
  WORKSHOP_BOOKINGS_CREATE:       '/workshop/:workshopId/bookings/create',
  POSTS:                          '/posts',
  POST:                           '/post/:postId',
  NOTICES:                        '/notices',
  NOTICE:                         '/notice/:noticeId',
  QNA:                            '/qna',
  STARTING_GUIDE:                 '/startingGuide',
  TERMS_OF_SERVICE:               '/termsOfService',
  PRIVACY_POLICY:                 '/privacyPolicy',
  COOKIE_POLICY:                  '/cookiePolicy',
  ABOUT_COMMUNITY:                '/about/community',
  ABOUT_PASSPORT:                 '/about/passport',
  ABOUT_CONTRIBUTION:             '/about/contribution',
  ABOUT_POST:                     '/about/post',

  GROUP:                          '/group/:groupId',
  GROUP_SIGN_UP:                  '/group/sign/up',
  GROUP_EDIT_WITH_USER:           '/group/:groupId/user/:userId/editWithUser',
  GROUP_WORKSHOPS:                '/group/:groupId/workshops',
  GROUP_WORKSHOPS_CREATE:         '/group/:groupId/workshops/create',
  GROUP_WORKSHOP_EDIT:            '/group/:groupId/workshop/:workshopId/edit',

  USER:                           '/user/:userId',
  USER_BOOKINGS:                  '/user/:userId/bookings',
  USER_BOOKING:                   '/user/:userId/booking/:bookingId',
  USER_EDIT:                      '/user/:userId/edit',
  USER_POSTS:                     '/user/:userId/posts',
  USER_POSTS_CREATE:              '/user/:userId/passport/:passportId/workshop/:workshopId/posts/create',
  USER_POST:                      '/user/:userId/post/:postId',
  USER_POST_EDIT:                 '/user/:userId/post/:postId/edit',
  USER_UPDATE_EMAIL_CONFIRMATION: '/user/:userId/update/emailVarification',
  USER_UPDATE_PASSWORD_RESET:     '/user/:userId/update/passwordReset',

  ADMIN:                          '/admin',
  ADMIN_SIGN_IN:                  '/admin/sign/in',
  ADMIN_SIGN_OUT:                 '/admin/sign/out',
  ADMIN_GROUPS:                   '/admin/groups',
  ADMIN_GROUPS_CREATE_WITH_USER:  '/admin/groups/createWithUser',
  ADMIN_GROUP_EDIT_WITH_USER:     '/admin/group/:groupId/user/:userId/editWithUser',
  ADMIN_BANNERS:                  '/admin/banners',
  ADMIN_NOTICES:                  '/admin/notices',
  ADMIN_NOTICES_CREATE:           '/admin/notices/create',
  ADMIN_NOTICE_EDIT:              '/admin/notice/:noticeId/edit',
  ADMIN_POSTS:                    '/admin/posts',
  ADMIN_POST_EDIT:                '/admin/post/:postId/edit',
  ADMIN_USERS:                    '/admin/users',
  ADMIN_USER_EDIT:                '/admin/user/:userId/edit',
  ADMIN_PASSPORTS:                '/admin/passports',
  ADMIN_PASSPORT_EDIT:            '/admin/passport/:passportId/edit',
  ADMIN_WORKSHOPS:                '/admin/workshops',

  ADMIN_WORKSHOP_EDIT:            '/admin/workshop/:workshopId/edit',
  TEST_VALIDATION:                '/test/validation',
  TEST_SEARCHING:                 '/test/searching',
  TEST_POST_EDITOR:               '/test/postEditor',
};
