import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface LocalbridgeNavbarValue {

}

const LocalbridgeNavbarContext = React.createContext<LocalbridgeNavbarValue | null>(null);

export function useLocalbridgeNavbar(): LocalbridgeNavbarValue {
  const state = useContext(LocalbridgeNavbarContext);

  if (!state) {
    throw new Error('useLocalbridgeNavbar must be used within LocalbridgeNavbarProvider');
  }

  return state;
}

export function LocalbridgeNavbarProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <LocalbridgeNavbarContext.Provider value={providerValue}>
      {children}
    </LocalbridgeNavbarContext.Provider>
  );
}
