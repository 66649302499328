import React from 'react';
import { UserProvider } from './Provider';
import UserRenderer from './Renderer';

export const User = () => {
  return (
    <UserProvider>
      <UserRenderer />
    </UserProvider>
  );
};
