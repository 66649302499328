import React from 'react';
import { WorkshopProvider } from './Provider';
import WorkshopRenderer from './Renderer';

export const Workshop = () => {
  return (
    <WorkshopProvider>
      <WorkshopRenderer />
    </WorkshopProvider>
  );
};
