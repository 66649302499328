import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { UserLeftSidebar } from '../components/leftSidebar';

type Props = {};

const UserPostRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
        <UserLeftSidebar />
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default UserPostRenderer;
