import React from 'react';
import { AdminWorkshopsProvider } from './Provider';
import AdminWorkshopsRenderer from './Renderer';

export const AdminWorkshops = () => {
  return (
    <AdminWorkshopsProvider>
      <AdminWorkshopsRenderer />
    </AdminWorkshopsProvider>
  );
};
