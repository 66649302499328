import React from 'react';
import { NoticeProvider } from './Provider';
import NoticeRenderer from './Renderer';

export const Notice = () => {
  return (
    <NoticeProvider>
      <NoticeRenderer />
    </NoticeProvider>
  );
};
