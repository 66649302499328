import React from 'react';
import { AdminPostEditProvider } from './Provider';
import AdminPostEditRenderer from './Renderer';

export const AdminPostEdit = () => {
  return (
    <AdminPostEditProvider>
      <AdminPostEditRenderer />
    </AdminPostEditProvider>
  );
};
