import React from 'react';
import { UserPostsCreateProvider } from './Provider';
import UserPostsCreateRenderer from './Renderer';

export const UserPostsCreate = () => {
  return (
    <UserPostsCreateProvider>
      <UserPostsCreateRenderer />
    </UserPostsCreateProvider>
  );
};
