import React from 'react';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { ROUTES } from '~/constants/routes';
import { useHistory } from 'react-router-dom';

type Props = {};

const SignUpSubmitted: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <>
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'sign_up_complete'}>
          <h1 className={'sign_up_complete__title'}>新規会員登録</h1>
          <p className={'sign_up_complete__title_en'}>Sign up</p>
          <section className={'sign_up_complete_contents'}>
            <h2 className={'sign_up_complete_contents__title'}>新規会員登録が完了しました</h2>
            <p className={'sign_up_complete_contents__text'}>ご登録いただきありがとうございます。</p>

            <div className={'sign_up_complete_contents__btn'}>
              <a onClick={() => history.push(ROUTES.HOME)}>TOPページに戻る</a>
            </div>
          </section>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default SignUpSubmitted;
