
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AboutPostValue {

}

const AboutPostContext = React.createContext<AboutPostValue | null>(null);

export function useAboutPost(): AboutPostValue {
  const state = useContext(AboutPostContext);

  if (!state) {
    throw new Error('useAboutPost must be used within AboutPostProvider');
  }

  return state;
}

export function AboutPostProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AboutPostContext.Provider value={providerValue}>
      {children}
    </AboutPostContext.Provider>
  );
}
