import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';
import { GroupModel, PassportModel, PostModel, PostType, UserPublic } from '~/openapi/typescript-axios/version';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { useLocalbridgePassportApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePassportApiProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';

type Props = {
  children: ReactNode;
};

interface PostValue {
  postType: PostType|null;
  postModel?: PostModel;
  userPublic?: UserPublic;
  passportModels: Array<PassportModel>;
  groupModels: Array<GroupModel>;
}

const PostContext = React.createContext<PostValue | null>(null);

export function usePost(): PostValue {
  const state = useContext(PostContext);

  if (!state) {
    throw new Error('usePost must be used within PostProvider');
  }

  return state;
}

export function PostProvider({ children }: Props) {
  const search = useLocation().search;
  const postType = new URLSearchParams(search).get('postType') as PostType|null;

  const { postId } = useParams<any>();

  const { getPost } = useLocalbridgePostApi();
  const { localbridgeUser } = useLocalbridgeUserApi();
  const { searchPassports } = useLocalbridgePassportApi();
  const { searchGroups } = useLocalbridgeGroupApi();

  const [ postModel, setPostModel ] = useState<PostModel>();
  const [ userPublic, setUserPublic ] = useState<UserPublic>();
  const [ passportModels, setPassportModels ] = useState<Array<PassportModel>>([]);
  const [ groupModels, setGroupModels] = useState<Array<GroupModel>>([]);

  const initPost = useCallback(async () => {
    if (postModel?.id === postId) {
      return;
    }

    const postModelTmp = await getPost({postId});
    setPostModel(postModelTmp?.postModel);

    const userId = postModelTmp?.postModel.userId;
    if (userId) {
      const userModelTmp = await localbridgeUser({
        env: { LOCALBRIDGE_PATH, FUNCTION_V }, params: { userId },
      });
      setUserPublic(userModelTmp.userPublic);

      const passportResponsesWithPager = await searchPassports({ size: 100, userId });
      const passportsTmp = passportResponsesWithPager[0]?.data || [];
      setPassportModels(passportsTmp);
      const groupIds = [...new Set(passportsTmp.map((passport) => passport.groupId))];

      const groupResponsesWithPager = await searchGroups({ size: 100, groupIds: groupIds.slice(0, 50) });
      setGroupModels(groupResponsesWithPager[0]?.data || []);
    }
  }, [
    postId,
    postModel,
    getPost,
    localbridgeUser,
    searchPassports,
    searchGroups,
    setPostModel,
    setUserPublic,
    setPassportModels,
    setGroupModels,
  ]);

  useEffect(() => {
    initPost();
  }, [initPost]);

  const providerValue = {
    postType,
    postModel,
    userPublic,
    passportModels,
    groupModels,
  };

  return (
    <PostContext.Provider value={providerValue}>
      {children}
    </PostContext.Provider>
  );
}
