import React from 'react';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { UserLeftSidebar } from '../../components/leftSidebar';

type Props = {};

const UserPostEditRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <div className='d-flex flex-row'>
        <UserLeftSidebar />
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default UserPostEditRenderer;
