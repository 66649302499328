import axios from 'axios';
import React, { useContext, ReactNode, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { AssociationModel, AssociationRequestsSearch, AssociationResponsesWithPager, AssociationType } from '~/openapi/typescript-axios/version';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeAssociationApiValue {
  cities: Array<AssociationModel>;
  searchAssociations: (
    associationRequestsList: AssociationRequestsSearch
  ) => Promise<Array<AssociationResponsesWithPager>>;
}

const LocalbridgeAssociationApiContext = React.createContext<LocalbridgeAssociationApiValue | null>(null);

export function useLocalbridgeAssociationApi(): LocalbridgeAssociationApiValue {
  const state = useContext(LocalbridgeAssociationApiContext);

  if (!state) {
    throw new Error('useLocalbridgeAssociationApi must be used within LocalbridgeAssociationApiProvider');
  }

  return state;
}

export function LocalbridgeAssociationApiProvider({ children }: Props) {
  const searchAssociations = useCallback(async (associationRequestsList: AssociationRequestsSearch) => {
    const { data: associations } = (await axios('/associations/v20230401/index.json', {
      method: 'GET'
    }) || {data: []});

    const associationsFiltered = (associations as Array<AssociationModel>).filter((association) => {
      if (association.id === '000000') {
        return true;
      }

      let isSameCityName = true;
      if (associationRequestsList.cityName) {
        isSameCityName = association.cityName === associationRequestsList.cityName;
      }

      let areOnlyCities = true;
      if (associationRequestsList.areOnlyCities) {
        areOnlyCities = association.type === AssociationType.City;
      }
      
      let areOnlyTowns = true;
      if (associationRequestsList.areOnlyTowns) {
        areOnlyTowns = association.type === AssociationType.Town;
      }

      let isIncludeAssociationIds = true;
      if (associationRequestsList.associationIds?.length) {
        isIncludeAssociationIds = associationRequestsList.associationIds.includes(association.id);
      }

      return isSameCityName && areOnlyCities && areOnlyTowns && isIncludeAssociationIds;
    });

    const totalCount = associationsFiltered.length;
    let pageTmp = 1;
    const size = associationRequestsList.size || 1000;

    const associationResponsesWithPager: Array<AssociationResponsesWithPager> = [];
    while (associationsFiltered.length) {
      associationResponsesWithPager.push({
        data: associationsFiltered.splice(0, size),
        pager: { page: pageTmp, size, totalCount },
      });

      pageTmp++;
    }

    if (associationRequestsList.page) {
      return [associationResponsesWithPager[associationRequestsList.page - 1]];
    }

    return associationResponsesWithPager;
  }, []);

  (window as any).searchAssociations = searchAssociations;

  const initCities = useCallback(async () => {
    const citiesTmpWithPager = await searchAssociations({areOnlyCities: true});
    return citiesTmpWithPager.map((cityTmp) => cityTmp.data).flat();
  }, [searchAssociations]);

  const initCitiesResponse = useQuery([
    'initCities',
    searchAssociations,
  ], initCities);

  const cities = useMemo(() => {
    return initCitiesResponse?.data || [];
  }, [initCitiesResponse?.data]);

  const providerValue = {
    cities,
    searchAssociations,
  };

  return (
    <LocalbridgeAssociationApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeAssociationApiContext.Provider>
  );
}
