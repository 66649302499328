import React from 'react';
import { GroupWorkshopBookingsProvider } from './Provider';
import GroupWorkshopBookingsRenderer from './Renderer';

type Props = {};

export const GroupWorkshopBookings: React.FC<Props> = () => {
  return (
    <GroupWorkshopBookingsProvider>
      <GroupWorkshopBookingsRenderer />
    </GroupWorkshopBookingsProvider>
  );
};
