import { SignUpProvider } from './Provider';
import SignUpRenderer from './Renderer';

export const SignUp = () => {
  return (
    <SignUpProvider>
      <SignUpRenderer />
    </SignUpProvider>
  );
};
