
import React, { useContext, ReactNode, useState, useCallback, useEffect } from 'react';
import { BannerModel, BannerRequestsSearch, GroupModel, NoticeModel, NoticeRequestsSearch, PostModel, PostRequestsSearch, PostResponsesWithPager, PostType, UserPublic, WorkshopModel, WorkshopRequestsSearch } from '~/openapi/typescript-axios/version';
import { useLocalbridgeBannerApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBannerApiProvider';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';
import { BannerMaximum, AttractivePostMaximum, ExperiencePostMaximum, NoticeMaximum, WorkshopMaximum } from './constants';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';

type Props = {
  children: ReactNode;
};

interface HomeValue {
  attractivePosts: Array<PostModel>;
  experiencePosts: Array<PostModel>;
  experiencePostUsers: Array<UserPublic>;
  experiencePostGroups: Array<GroupModel>;
  notices: Array<NoticeModel>;
  workshops: Array<WorkshopModel>;
  banners: Array<BannerModel>;
}

const HomeContext = React.createContext<HomeValue | null>(null);

export function useHome(): HomeValue {
  const state = useContext(HomeContext);

  if (!state) {
    throw new Error('useHome must be used within HomeProvider');
  }

  return state;
}

export function HomeProvider({ children }: Props) {
  const { searchPosts } = useLocalbridgePostApi();
  const { searchGroups } = useLocalbridgeGroupApi();
  const { localbridgeUsers } = useLocalbridgeUserApi();
  const { searchNotices } = useLocalbridgeNoticeApi();
  const { searchWorkshops } = useLocalbridgeWorkshopApi();
  const { searchBanners } = useLocalbridgeBannerApi();

  const [ attractivePosts, setAttractivePosts ] = useState<Array<PostModel>>([]);
  const [ experiencePosts, setExperiencePosts ] = useState<Array<PostModel>>([]);
  const [ experiencePostUsers, setExperiencePostUsers ] = useState<Array<UserPublic>>([]);
  const [ experiencePostGroups, setExperiencePostGroups ] = useState<Array<GroupModel>>([]);
  const [ notices, setNotices ] = useState<Array<NoticeModel>>([]);
  const [ workshops, setWorkshops ] = useState<Array<WorkshopModel>>([]);
  const [ banners, setBanners ] = useState<Array<BannerModel>>([]);

  const initPage = useCallback(async () => {
    const firstAttractivePostRequestsSearch: PostRequestsSearch = {
      size: AttractivePostMaximum,
      postType: PostType.Attractive,
      shouldShowNotHidden: true,
      shouldShowApproved: true,
    };
  
    const firstExperiencePostRequestsSearch: PostRequestsSearch = {
      size: ExperiencePostMaximum,
      postType: PostType.Experience,
      shouldShowNotHidden: true,
      shouldShowApproved: true,
    };

    const firstNoticeRequestsSearch: NoticeRequestsSearch = {
      size: NoticeMaximum,
      shouldShowNotHidden: true,
    };

    const firstWorkshopRequestsSearch: WorkshopRequestsSearch = {
      size: WorkshopMaximum,
      shouldShowNotHidden: true,
      shouldShowApproved: true,
    };

    const firstBannerRequestsSearch: BannerRequestsSearch = {
      size: BannerMaximum,
      shouldShowNotHidden: true,
    };

    try {
      const noticeListTmp = await searchNotices(firstNoticeRequestsSearch);
      setNotices(noticeListTmp[0]?.data || []);
    } catch (error) {
      console.log(error);
    }

    try {
      const workshopListTmp = await searchWorkshops(firstWorkshopRequestsSearch);
      setWorkshops(workshopListTmp[0]?.data || []);
    } catch (error) {
      console.log(error);
    }

    try {
      const bannerListTmp = await searchBanners(firstBannerRequestsSearch);
      setBanners(bannerListTmp[0]?.data || []);
    } catch (error) {
      console.log(error);
    }

    try {
      const attractivePostsListTmp = await searchPosts(firstAttractivePostRequestsSearch);
      setAttractivePosts(attractivePostsListTmp[0]?.data || []);
    } catch (error) {
      console.log(error);
    }

    let experiencePostsListTmp: Array<PostResponsesWithPager> = [];
    try {
      experiencePostsListTmp = await searchPosts(firstExperiencePostRequestsSearch);
      setExperiencePosts(experiencePostsListTmp[0]?.data || []);
    } catch (error) {
      console.log(error);
    }

    try {
      const experiencePostsTmp = experiencePostsListTmp[0]?.data || [];
      const groupIds = [...new Set(experiencePostsTmp.map((post) => post.groupId))];
      const userIds = [...new Set(experiencePostsTmp.map((post) => post.userId))];
      if (groupIds.length) {
        const groupResponsesWithPagersTmp = await searchGroups({size: 100, groupIds});
        setExperiencePostGroups(groupResponsesWithPagersTmp[0]?.data || []);
      }

      if (userIds.length) {
        const groupResponsesWithPagersTmp = await localbridgeUsers({
          env: { LOCALBRIDGE_PATH, FUNCTION_V }, params: { userIds }
        });
        console.log(groupResponsesWithPagersTmp);
        setExperiencePostUsers(groupResponsesWithPagersTmp.userPublics || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    searchPosts,
    searchGroups,
    localbridgeUsers,
    searchNotices,
    searchWorkshops,
    searchBanners,
    setAttractivePosts,
    setExperiencePostUsers,
    setExperiencePostGroups,
    setExperiencePosts,
  ]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  const providerValue = {
    attractivePosts,
    experiencePostUsers,
    experiencePostGroups,
    experiencePosts,
    notices,
    workshops,
    banners,
  };

  return (
    <HomeContext.Provider value={providerValue}>
      {children}
    </HomeContext.Provider>
  );
}
