import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { GroupLeftSidebar } from './components/leftSidebar';

type Props = {};

const GroupRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />
      
      <GroupLeftSidebar />
    </>
  );
};

export default GroupRenderer;
