import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { AboutNavbar } from '../components/navbar';

type Props = {};

const AboutContributionRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title='貢献度バッジとは？' enTitle='About' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <AboutNavbar />
        <div className={'about_contribution'}>
          <div className={'about_contribution__inner'}>
            <p className={'about_contribution__text'}>
              貢献度バッジは、あなたがどんな分野の活動に参加したかを表す特別なデジタルバッジです。１つのふるさとパスポートに「地方創生」の活動分野を表す8種類の貢献度バッジが紐づいており、イベント参加や記事執筆によって取得できます。この貢献バッジを一定枚数以上集めると、それに応じてふるさとパスポートが進化していきます。
              <br />
              進化のカタチはランクとして表現され、ランクアップにより、より難易度の高いイベント・ワークショップへの参加の招待や、あなたの力を必要とする人からオファーを受けられたりします。
            </p>
            <section className={'about_contribution_content'}>
              <div className={'about_contribution_content__texts'}>
                <h2 className={'about_contribution_content__title'}>貢献度バッジの種類</h2>
                <p className={'about_contribution_content__text'}>貢献度バッジは、右の図の通り、デジタル田園都市国家構想(※)の4つの課題から、さらに2つに分類した８種類のカテゴリがあります。</p>
                <p className={'about_contribution_content__text'}>
                  イベントに参加すると、各イベントの開催目的に対応した貢献度バッジが手に入れられます。自分がどの貢献度バッジを何枚手に入れているかはマイページで確認することができるだけでなく、Local Bridgeのユーザーや「ふるさとパスポート」を発行する自治体の方も取得情報を見ることができます。
                  <br />
                  獲得している分野、興味・関心が近しい人と交流たり、あるいは自身の活動に不足している分野の貢献バッジを持っている人とタッグを組むことでさらに発展的な活動をすることもできます。
                </p>
                <p className={'about_contribution_content__note'}>
                  ※内閣官房が発表した「デジタル実装を通じて地方が抱える課題を解決し、誰一人取り残されずすべての人がデジタル化のメリットを享受できる心豊かな暮らしを実現する」という構想のこと。
                  <br />
                  URL：https://www.cas.go.jp/jp/seisaku/digitaldenen/about/social-issues.html
                </p>
              </div>
              <div className={'about_contribution_content__img'}>
                <img src='/assets/img/about/contribute/pic_img01.jpg' />
              </div>
            </section>
            <section className={'about_contribution_content about_contribution_content--reverse'}>
              <div className={'about_contribution_content__texts'}>
                <h2 className={'about_contribution_content__title'}>獲得枚数とふるさとパスポートのランク</h2>
                <p className={'about_contribution_content__text'}>
                  責献度バッジを何枚獲得するとふるさとパスポートがランクアップするかを、左の図にまとめました。
                  <br />
                  その地域のふるさとパスポートを進化させたい場合は、その地域の貢献度バッジを左の図の枚数だけ集めてください。獲得した貢献度バッジを他のふるさとパスポートの進化に使うことはできません。
                  <br />
                  ふるさとパスポートの進化に関しては、貢献度バッジの種類は関係なく、その地域の貢献度バッジを何枚持っているかで決まります。もしあなたが今貢献度バッジを1枚しか持っていない地域があれば、新たな活動に参加して、ふるさとパスポートのランクをブロンズに進化させてみましょう。
                </p>
              </div>
              <div className={'about_contribution_content__img'}>
                <img src='/assets/img/about/contribute/pic_img02.jpg' />
              </div>
            </section>
            <div className={'about_contribution__btn'}>
              <a href='#'>貢献度バッジの獲得状況を見る</a>
            </div>
          </div>
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default AboutContributionRenderer;
