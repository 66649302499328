import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { UserRequestsSearch, UserResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminUsersValue {
  usersList: Array<UserResponsesWithPager>;
  usersPageCurrent: number;
  usersPageLast: number;
  setUserRequestsSearch: (userRequestsSearch: UserRequestsSearch) => void;
  setUsersPageCurrent: (usersPageCurrent: number) => void;
}

const AdminUsersContext = React.createContext<AdminUsersValue | null>(null);

export function useAdminUsers(): AdminUsersValue {
  const state = useContext(AdminUsersContext);

  if (!state) {
    throw new Error('useAdminUsers must be used within AdminUsersProvider');
  }

  return state;
}

export function AdminUsersProvider({ children }: Props) {
  const { searchUsers } = useLocalbridgeUserApi();

  const firstUserRequestsSearch: UserRequestsSearch = {
    size: 10
  };
  const [ userRequestsSearch, setUserRequestsSearch ] = useState<UserRequestsSearch>(firstUserRequestsSearch);
  const [ usersList, setAdminUsersList ] = useState<Array<UserResponsesWithPager>>([]);
  const [ usersPageCurrent, setUsersPageCurrent ] = useState<number>(0);
  const [ usersPageLast, setAdminUsersPageLast ] = useState<number>(0);

  const searchUsersList = useCallback(async () => {
    if (!userRequestsSearch) {
      return;
    }

    const usersListTmp = await searchUsers(userRequestsSearch);
    const [usersWithPager] = usersListTmp;

    if (usersWithPager?.pager) {
      const { totalCount, size } = usersWithPager.pager;
      setAdminUsersPageLast(Math.floor((totalCount - 1) / size) + 1);
    }

    setAdminUsersList(usersListTmp);
  }, [userRequestsSearch, searchUsers, setAdminUsersList]);

  useEffect(() => {
    searchUsersList();
  }, [searchUsersList]);

  const providerValue = {
    usersList,
    usersPageCurrent,
    usersPageLast,
    setUserRequestsSearch,
    setUsersPageCurrent,
  };

  return (
    <AdminUsersContext.Provider value={providerValue}>
      {children}
    </AdminUsersContext.Provider>
  );
}
