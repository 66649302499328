import React from 'react';
import { GroupEditWithUserProvider } from './Provider';
import GroupEditWithUserRenderer from './Renderer';

export const GroupEditWithUser = () => {
  return (
    <GroupEditWithUserProvider>
      <GroupEditWithUserRenderer />
    </GroupEditWithUserProvider>
  );
};
