import React, { useCallback, useMemo } from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import SearchBoxPost, { SearchBoxPostOption } from '~/components/searchBoxPost';
import { PostModel, PostType } from '~/openapi/typescript-axios/version';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import { PaginationBox } from '~/components/paginationBox';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import PostCard from '~/components/postCard';
import { usePosts } from './Provider';
import './index.scss';

type Props = {};

const PostsRenderer: React.FC<Props> = () => {
  const {
    postType,
    postsList,
    postsPageCurrent,
    postsPageLast,
    setPostRequestsSearch,
    setPostsPageCurrent,
  } = usePosts();

  const posts: Array<PostModel> = useMemo(() => {
    return postsList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === postsPageCurrent;
    })?.data || [];
  }, [postsPageCurrent, postsList]);

  const onSubmit = useCallback((searchOption: SearchBoxPostOption) => {
    setPostRequestsSearch(searchOption);
  }, [setPostRequestsSearch]);

  return postType === PostType.Attractive ? (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title={`${postType}一覧`} enTitle={'Attractive discovery posts'} />
      <LocalbridgeBody bgColor={'#fbf8c2'}>
        <SearchBoxPost title={`${postType}検索`} onSubmit={onSubmit} />

        <div className={'attractive_list'}>
          {posts.map((post) => (
            <PostCard key={post.id} postModel={post} />
          ))}
        </div>

        <div className={'attractive_list__pagination'}>
          <PaginationBox
            forcePage={postsPageCurrent}
            pageCount={postsPageLast}
            onPageChange={setPostsPageCurrent}
          />
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  ) : (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title={`${postType}一覧`} enTitle={'Event experience posts'} />
      <LocalbridgeBody bgColor={'#ffecda'}>
        <SearchBoxPost title={`${postType}検索`} onSubmit={onSubmit} />

        <div className={'experience_list'}>
          {posts.map((post) => (
            <PostCard key={post.id} postModel={post} />
          ))}
        </div>

        <div className={'experience_list__pagination'}>
          {postsList.length ? (
            <PaginationBox
              forcePage={postsPageCurrent}
              pageCount={postsPageLast}
              onPageChange={setPostsPageCurrent}
            />
          ):(<></>)}
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default PostsRenderer;
