
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AboutCommunityValue {

}

const AboutCommunityContext = React.createContext<AboutCommunityValue | null>(null);

export function useAboutCommunity(): AboutCommunityValue {
  const state = useContext(AboutCommunityContext);

  if (!state) {
    throw new Error('useAboutCommunity must be used within AboutCommunityProvider');
  }

  return state;
}

export function AboutCommunityProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AboutCommunityContext.Provider value={providerValue}>
      {children}
    </AboutCommunityContext.Provider>
  );
}
