import { ROUTES } from '~/constants/routes';
import './index.scss';
import { useHistory } from 'react-router-dom';

type Props = {};
export const SignInHeader: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <div className={'header_block'}>
      <h1 className={'header_block__logo'}>
        <a onClick={() => history.push(ROUTES.HOME)}>
          <picture>
            <source media='(max-width: 769px)' srcSet='/assets/img/top/logo.png' />
            <img src='/assets/img/top/logo.png' alt='' />
          </picture>
        </a>
      </h1>
    </div>
  );
};

export default SignInHeader;