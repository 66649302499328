import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { UserLeftSidebar } from '../components/leftSidebar';
import UserHeader from '../components/header';
import { UserContainer } from '../components/continer';
import './index.scss';
import { useUserBooking } from './Provider';

type Props = {};

const UserBookingRenderer: React.FC<Props> = () => {
  const { bookingModel, workshopModel, town } = useUserBooking();

  return (
    <>
      <LocalbridgeNavbar />

      <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
        <UserLeftSidebar />

        <UserContainer>
          <UserHeader title='予約済みイベント - 一覧' titleEn='Reserved events' />
          <div className={'user_booking'}>
            <div className={'user_booking__contents'}>
              <div className={'user_booking__main'}>
                <div className={'user_booking_swiper'}>
                  <div className='swiper-container'>
                    <div className='swiper-wrapper'>
                      {workshopModel?.contentPhotos.map((contentPhoto) => (
                        <div key={`photo:${contentPhoto.id}`} className='swiper-slide'>
                          <img src={contentPhoto.url} alt='' />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='swiper-button-next'></div>
                  <div className='swiper-button-prev'></div>
                  <div className={`swiper-pagination ${'user_booking__pagination'}`}></div>
                </div>
                <p className={'user_booking__city'}><img src='/assets/img/event/pic_icon01.jpg' />ODK県ODK市</p>
                <section className={'user_booking_section'}>
                  <h2 className={'user_booking_section__title'}><img src='/assets/img/event/ico_flag01.svg' />イベント概要</h2>
                  <p className={'user_booking_section__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです</p>
                </section>
                <section className={'user_booking_section'}>
                  <h2 className={'user_booking_section__title'}><img src='/assets/img/event/ico_flag02.svg' />イベント詳細</h2>
                  <p className={'user_booking_section__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  <section className={'user_booking_topic'}>
                    <h3 className={'user_booking_topic__title'}>【プログラム①】地域おこしに関する講座を受講</h3>
                    <picture>
                      <source media='(max-width: 769px)' srcSet='/assets/img/event/pic_img01.jpg' />
                      <img className={'user_booking_topic__img'} src='/assets/img/event/pic_img01.jpg' alt='' />
                    </picture>
                    <p className={'user_booking_topic__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                    <p className={'user_booking_topic__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  </section>
                  <section className={'user_booking_topic'}>
                    <h3 className={'user_booking_topic__title'}>【プログラム①】地域おこしに関する講座を受講</h3>
                    <picture>
                      <source media='(max-width: 769px)' srcSet='/assets/img/event/pic_img02.jpg' />
                      <img className={'user_booking_topic__img'} src='/assets/img/event/pic_img02.jpg' alt='' />
                    </picture>
                    <p className={'user_booking_topic__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                    <p className={'user_booking_topic__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  </section>
                </section>
              </div>
              <div className={'user_booking_side'}>
                <section className={'user_booking_side__section'}>
                  <h2 className={'user_booking_side__title'}>イベント情報</h2>
                  <div className={'user_booking_side__info'}>
                    <p className={'user_booking_side__sub_title'}>開催日</p>
                    <p className={'user_booking_side__text'}>2023/07/16（日）～<br />
                      2023/07/16（日）14:00~15:00</p>
                  </div>
                  <div className={'user_booking_side__info'}>
                    <p className={'user_booking_side__sub_title'}>定員</p>
                    <p className={'user_booking_side__text'}>10人</p>
                  </div>
                  <div className={'user_booking_side__info'}>
                    <p className={'user_booking_side__sub_title'}>申込期間</p>
                    <p className={'user_booking_side__text'}>2023/6/01（木）☓☓:00 ~<br />
                      2023/7/15（土）☓☓:00</p>
                  </div>
                  <div className={'user_booking_side__info'}>
                    <p className={'user_booking_side__sub_title'}>参加費</p>
                    <p className={'user_booking_side__text'}>¥4,000-（税込）</p>
                  </div>
                  <div className={'user_booking_notes'}>
                    <p className={'user_booking_notes__title'}>注意事項</p>
                    <p className={'user_booking_notes__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。の文章はダミーです文字の大きさや書体のサンプルです。の文章はダミーです文字の大きさや書体のサンプルです。</p>
                  </div>
                </section>
                <section className={'user_booking_side__section'}>
                  <h2 className={'user_booking_side__title'}>予約をキャンセル</h2>
                  <div className={'user_booking_side__btns'}>
                    <a href='#' className={'user_booking_side__btn user_booking_side__btn--cancel'}>キャンセルする</a>
                    <a href='#' className={'user_booking_side__btn user_booking_side__btn--back'}>予約済みイベント一覧へ戻る</a>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </UserContainer>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default UserBookingRenderer;
