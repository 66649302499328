import React from 'react';
import './index.scss';

type Props = {
  children: React.ReactNode
};

export const AdminContainer: React.FC<Props> = ({children}) => {
  return (
    <div className={'admin_container__inner'}>
      <div className={'admin_container__content'}>{children}</div>
    </div>
  );
};
