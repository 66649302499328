
import React, { useContext, ReactNode, useEffect } from 'react';
import { useAuth } from '~/providers/AuthProvider';

type Props = {
  children: ReactNode;
};

interface SignOutValue {

}

const SignOutContext = React.createContext<SignOutValue | null>(null);

export function useSignOut(): SignOutValue {
  const state = useContext(SignOutContext);

  if (!state) {
    throw new Error('useSignOut must be used within SignOutProvider');
  }

  return state;
}

export function SignOutProvider({ children }: Props) {
  const { doSignOut } = useAuth();

  const providerValue = {

  };

  useEffect(() => {
    doSignOut();
  }, [doSignOut]);

  return (
    <SignOutContext.Provider value={providerValue}>
      {children}
    </SignOutContext.Provider>
  );
}
