export default function getSearchTokenMap(searchString?: string|null) {
  const searchTexts = (searchString || '').split(/[\.\n、。,を ]/).filter(Boolean);

  const uniGram: {[token: string]: true} = {};
  const biGram: {[token: string]: true} = {};
  const triGram: {[token: string]: true} = {};
  for (let searchText of searchTexts || []) {
    searchText = searchText.replace(/\./g, '');

    if (searchText.length >= 3) {
      for (let cursor = 0; cursor < searchText.length - 2; cursor++) {
        triGram[searchText.substring(cursor, cursor + 3)] = true;
      }
    }

    if (searchText.length >= 2) {
      for (let cursor = 0; cursor < searchText.length - 1; cursor++) {
        biGram[searchText.substring(cursor, cursor + 2)] = true;
      }
    }

    if (searchText.length >= 1) {
      for (let cursor = 0; cursor < searchText.length; cursor++) {
        uniGram[searchText.substring(cursor, cursor + 1)] = true;
      }
    }
  }

  return { uniGram, biGram, triGram };
}
