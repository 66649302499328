import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaginationBox } from '~/components/paginationBox';
import { GroupModel } from '~/openapi/typescript-axios/version';
import { ROUTES } from '~/constants/routes';
import { useAdminGroups } from './Provider';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { StyledAdminTable } from '../components/StyledAdminTable';

type Props = {};

const AdminGroupsRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    groupsList,
    groupsPageCurrent,
    groupsPageLast,
    setGroupsPageCurrent,
  } = useAdminGroups();

  const groups: Array<GroupModel> = useMemo(() => {
    return groupsList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === groupsPageCurrent;
    })?.data || [];
  }, [groupsPageCurrent, groupsList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <div className='d-flex flex-row'>
          <span style={{fontSize: 24}}>組織・団体一覧</span>
          <button className='btn btn-secondary ms-auto'>新規組織・団体登録</button>
        </div>

        <span
          className='m-auto fw-bolder p-2'
          hidden={groups.length > 0}
        >
          登録された現場がありません
        </span>

        {groups.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* 組織・団体名 */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  組織・団体名
                </th>

                {/* 住所 */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  住所
                </th>
              </tr>
              <tr className='border'>
                {/* 代表者名  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  代表者名 
                </th>

                {/* 都道府県 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  都道府県
                </th>

                {/* 市区町村 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  市区町村
                </th>

                {/* 郵便番号 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  郵便番号
                </th>

                {/* ホームページ */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  ホームページ
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 代表者名（ふりがな） */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  代表者名（ふりがな）
                </th>

                {/* 担当者名 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  担当者名
                </th>

                {/* 担当者の電話番号 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  担当者の電話番号
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {groups.map((group: GroupModel, index: number) => {

                return (
                  <tbody
                    key={group.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* 組織・団体名 */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {group.name}
                      </th>

                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {`${group.detailAddress1} ${group.detailAddress2}`}
                      </th>
                    </tr>

                    <tr>
                      {/* 代表者名  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {group.delegateName}
                      </td>

                      {/* 都道府県 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >{group.cityId}</td>

                      {/* 市区町村 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >{group.townId}</td>

                      {/* 郵便番号 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {group.postNumber}
                      </td>

                      {/* ホームページ */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {group.homepage}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_GROUP_EDIT_WITH_USER, {
                          groupId: group.id,
                          userId: group.userIdsManagerRole[0],
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 代表者名（ふりがな） */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {group.delegateNameFurigana}
                      </td>

                      {/* 担当者名 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {group.managerName}
                      </td>

                      {/* 担当者の電話番号 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {group.managerPhoneNumber}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        {groupsList.length ? (
          <PaginationBox
            paginationBoxClassNamePrefix='pagination_box_block'
            forcePage={groupsPageCurrent}
            pageCount={groupsPageLast}
            onPageChange={setGroupsPageCurrent}
          />
        ):(<></>)}
      </div>
    </div>
  );
};

export default AdminGroupsRenderer;
