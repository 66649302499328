import React, { useCallback } from 'react';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import './index.scss';
import { useWorkshopBookingsCreate } from '../../Provider';
import { WorkshopCreateBookingState } from '../../enums';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';

type Props = {};

const WorkshopBookingsCreateConfirm: React.FC<Props> = () => {
  const { createBookings } = useLocalbridgeBookingApi();
  const { bookingModelWithEmailConfirm, setWorkshopCreateBookingState } = useWorkshopBookingsCreate();

  const goToSubmittedPage = useCallback(async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.preventDefault();

    try {
      const newBookingModelWithEmailConfirm = Object.assign({}, bookingModelWithEmailConfirm);
      delete newBookingModelWithEmailConfirm.emailConfirm;

      await createBookings([{bookingModel: newBookingModelWithEmailConfirm}]);

      setWorkshopCreateBookingState(WorkshopCreateBookingState.WorkshopBookingSubmitted);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }
    }
  }, [bookingModelWithEmailConfirm, createBookings, setWorkshopCreateBookingState]);

  return (
    <>
      <LocalbridgeSubHeader title="イベント申し込み" enTitle="Application" />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'workshop_booking_confirm'}>
          <div className={'workshop_booking_confirm_contents'}>
            <div className={'workshop_booking_confirm_contents__inner'}>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>イベント名</p>
                <p className={'workshop_booking_confirm_contents__value'}>イベントのタイトルがこちらに入ります</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>イベント開催日</p>
                <p className={'workshop_booking_confirm_contents__value'}>2023/7/16（日）14:00</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>名前</p>
                <p className={'workshop_booking_confirm_contents__value'}>山田　太郎</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>ふりがな</p>
                <p className={'workshop_booking_confirm_contents__value'}>やまだ　たろう</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>ニックネーム</p>
                <p className={'workshop_booking_confirm_contents__value'}>ローカルブリッジ</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>メールアドレス</p>
                <p className={'workshop_booking_confirm_contents__value'}>abc@sample.com</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>メールアドレス確認</p>
                <p className={'workshop_booking_confirm_contents__value'}>abc@sample.com</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>電話番号</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>郵便番号</p>
                <p className={'workshop_booking_confirm_contents__value'}>〒 123-4567</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>都道府県</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>市区町村</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>番地</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>建物名・部屋番号</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>生年月日</p>
                <p className={'workshop_booking_confirm_contents__value'}>000-1234-5678</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>性別</p>
                <p className={'workshop_booking_confirm_contents__value'}>男性</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>職業</p>
                <p className={'workshop_booking_confirm_contents__value'}>ダミーテキストです</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>学校名</p>
                <p className={'workshop_booking_confirm_contents__value'}>〇〇大学</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>学部名</p>
                <p className={'workshop_booking_confirm_contents__value'}>〇〇大学</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>学年</p>
                <p className={'workshop_booking_confirm_contents__value'}>1学年</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>その他、事務局への連絡事項</p>
                <p className={'workshop_booking_confirm_contents__value'}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>このイベントを何で知りましたか？</p>
                <p className={'workshop_booking_confirm_contents__value'}>ダミーテキストです</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>このイベントの楽しみ・目的は？</p>
                <p className={'workshop_booking_confirm_contents__value'}>地域の応援になる</p>
              </div>
              <div className={'workshop_booking_confirm_contents__item'}>
                <p className={'workshop_booking_confirm_contents__label'}>参加費</p>
                <p className={'workshop_booking_confirm_contents__value'}>¥1,000-（税込）</p>
              </div>
            </div>
          </div>
          <div className={'workshop_booking_confirm_contents__btns'}>
            <input
              className={`${'workshop_booking_confirm_contents__btn'} ${'workshop_booking_confirm_contents__btn--submit'}`}
              type='submit'
              onClick={goToSubmittedPage}
              onChange={() => {}}
              value='この内容で確定する'
            />
            <a
              onClick={() => setWorkshopCreateBookingState(WorkshopCreateBookingState.WorkshopBookingForm)}
              className={`${'workshop_booking_confirm_contents__btn'} ${'workshop_booking_confirm_contents__btn--back'}`}
            >戻る</a>
          </div>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default WorkshopBookingsCreateConfirm;
