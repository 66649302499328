import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  PassportRequestsDelete,
  PassportRequestCreate,
  PassportRequestUpdate,
  PassportRequestsValidate,
  PassportResponsesWithPager,
  PassportModel,
  PassportRequestRead,
  PassportResponseRead,
  PassportRequestsSearch,
  testStringMax80,
  testEmptyInput,
  testStringId,
  testDateReversedStartAndEnd,
} from '~/openapi/typescript-axios/version';
import {
  LOCALBRIDGE_PATH,
  firestore,
} from '~/constants/firebase';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';

type Props = {
  children?: ReactNode;
};

interface LocalbridgePassportApiValue {
  validatePassports: (passportRequestsValidate: PassportRequestsValidate) => Promise<MessageResponse>;
  createPassports: (passportRequestCreates: Array<PassportRequestCreate>) => Promise<void>;
  getPassport: (passportRequestRead: PassportRequestRead) => Promise<PassportResponseRead|null>;
  updatePassport: (passportRequestUpdate: PassportRequestUpdate) => Promise<void>;
  deletePassports: (deletePassportsRequest: PassportRequestsDelete) => Promise<void>;
  searchPassports: (
    passportRequestsSearch: PassportRequestsSearch
  ) => Promise<Array<PassportResponsesWithPager>>;
}

const LocalbridgePassportApiContext = React.createContext<LocalbridgePassportApiValue | null>(null);

export function useLocalbridgePassportApi(): LocalbridgePassportApiValue {
  const state = useContext(LocalbridgePassportApiContext);

  if (!state) {
    throw new Error('useLocalbridgePassportApi must be used within LocalbridgePassportApiProvider');
  }

  return state;
}

export function LocalbridgePassportApiProvider({ children }: Props) {
  const validatePassports = useCallback(async (passportRequestsValidate: PassportRequestsValidate) => {
    const passportModels = passportRequestsValidate.passportRequests.map((passportRequest) => {
      return passportRequest.passportModel;
    });

    const passportValidator = new Validator<PassportModel>('PassportModel', {
      id: [
        async (passport) => testEmptyInput(passport.id),
        async (passport) => testStringMax80(passport.id),
        async (passport) => testStringId(passport.id),
      ],
      photos: [
      ],
      createdAt: [
        async (passport) => testEmptyInput(passport.createdAt),
        async (passport) => testDateReversedStartAndEnd(passport.createdAt, passport.updatedAt),
      ],
      updatedAt: [
        async (passport) => testEmptyInput(passport.updatedAt),
      ],
      groupCityId: [
        async (passport) => testEmptyInput(passport.groupCityId),
        async (passport) => testStringMax80(passport.groupCityId),
        async (passport) => testStringId(passport.groupCityId),
      ],
      groupTownId: [
        async (passport) => testEmptyInput(passport.groupTownId),
        async (passport) => testStringMax80(passport.groupTownId),
        async (passport) => testStringId(passport.groupTownId),
      ],
      userId: [
        async (passport) => testEmptyInput(passport.userId),
        async (passport) => testStringMax80(passport.userId),
        async (passport) => testStringId(passport.userId),
      ],
      userIdToken: [
        async (passport) => testEmptyInput(passport.userIdToken),
      ],
      groupId: [
        async (passport) => testEmptyInput(passport.groupId),
        async (passport) => testStringMax80(passport.groupId),
        async (passport) => testStringId(passport.groupId),
      ],
      icon: [
        async (passport) => testEmptyInput(passport.icon),
      ],
    });

    return passportValidator.validate(passportModels);
  }, []);

  const createPassports = useCallback(async (passportRequestCreates: Array<PassportRequestCreate>) => {
    if (!passportRequestCreates.length) {
      return;
    }

    const messageResponses = await validatePassports({
      isOnSubmit: true,
      passportRequests: passportRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const passportRequestCreate of passportRequestCreates) {
      const { id } = passportRequestCreate.passportModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/passports/${id}`), {
        ...Object.assign({}, passportRequestCreate.passportModel)
      });
    }

    await batch.commit();
  }, [validatePassports]);

  const updatePassport = useCallback(async (passportRequestUpdate: PassportRequestUpdate) => {
    const messageResponses = await validatePassports({
      isOnSubmit: true,
      passportRequests: [passportRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = passportRequestUpdate.passportModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/passports/${id}`), {...Object.assign({}, passportRequestUpdate)});
  }, [validatePassports]);

  const getPassport = useCallback(async (passportRequestRead: PassportRequestRead) => {
    const { passportId } = passportRequestRead;
    const passportModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/passports/${passportId}`)))
      .data() as PassportModel|null;
    return passportModel ? {passportModel} : null;
  }, []);

  const deletePassports = useCallback(async (deletePassportsRequest: PassportRequestsDelete) => {
    const passportIds = deletePassportsRequest?.passportIds || [];

    if (!passportIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const passportId of passportIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/passports/${passportId}`));
    }

    await batch.commit();
  }, []);

  const searchPassports = useCallback(async (passportRequestsSearch: PassportRequestsSearch) => {
    const {
      size,
      page,
      userId,
      groupId,
      groupCityId,
      groupTownId,
      createdFrom,
      createdTo,
      searchString,
    } = passportRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/passports`),
      and(
        ...[
          userId ? where('userId', '==', userId) : null,
          groupId ? where('groupId', '==', groupId) : null,
          groupCityId ? where('groupCityId', '==', groupCityId) : null,
          groupTownId ? where('groupTownId', '==', groupTownId) : null,
          searchString ? where('searchString', '==', searchString) : null,
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
      ),
      !searchString ? orderBy('createdAt', 'desc') : limit(1000),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<PassportModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<PassportResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const providerValue = {
    validatePassports,
    createPassports,
    getPassport,
    updatePassport,
    deletePassports,
    searchPassports,
  };

  return (
    <LocalbridgePassportApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgePassportApiContext.Provider>
  );
}
