import React from 'react';
import { QnaProvider } from './Provider';
import QnaRenderer from './Renderer';

export const Qna = () => {
  return (
    <QnaProvider>
      <QnaRenderer />
    </QnaProvider>
  );
};
