import React from 'react';
import { SignUpEmailVarificationProvider } from './Provider';
import SignUpEmailVarificationRenderer from './Renderer';

export const SignUpEmailVarification = () => {
  return (
    <SignUpEmailVarificationProvider>
      <SignUpEmailVarificationRenderer />
    </SignUpEmailVarificationProvider>
  );
};
