import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import './index.scss';
import { useWorkshopBookingsCreate } from './Provider';
import { WorkshopCreateBookingState } from './enums';
import WorkshopBookingForm from './components/bookinForm';
import WorkshopBookingConfirm from './components/bookinConfirm';
import WorkshopBookingSubmitted from './components/bookinSubmitted';

type Props = {};

const WorkshopBookingsCreateRenderer: React.FC<Props> = () => {
  const { workshopCreateBookingState } = useWorkshopBookingsCreate();

  return (
    <>
      <LocalbridgeNavbar />

      {workshopCreateBookingState === WorkshopCreateBookingState.WorkshopBookingForm
        ? (<WorkshopBookingForm />):(<></>)
      }
      {workshopCreateBookingState === WorkshopCreateBookingState.WorkshopBookingConfirm
        ? (<WorkshopBookingConfirm />):(<></>)
      }
      {workshopCreateBookingState === WorkshopCreateBookingState.WorkshopBookingSubmitted
        ? (<WorkshopBookingSubmitted />):(<></>)
      }

      <LocalbridgeFooter />
    </>
  );
};

export default WorkshopBookingsCreateRenderer;
