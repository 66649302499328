import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import firebaseJson from '~/environments/firebase.json';
const { emulators } = firebaseJson;

import {
  getAuth,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  getStorage,
  connectStorageEmulator,
  ref as storagePath,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  getFirestore,
  connectFirestoreEmulator,
  arrayUnion,
  arrayRemove,
  collection,
  collectionGroup,
  FieldValue,
  getDoc,
  getDocs,
  doc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  setDoc,
  where,
  writeBatch,
  and,
  or,
  query as firestoreQuery,
  endAt as firestoreEndAt,
  startAt as firestoreStartAt,
  orderBy,
  limit,
} from 'firebase/firestore';
import {
  getDatabase,
  connectDatabaseEmulator,
  ref,
  get,
  set,
  increment,
  off,
  onValue,
  onChildAdded,
  remove,
  update,
  query as databaseQuery,
  onDisconnect,
  orderByChild,
  orderByKey,
  equalTo,
  startAt as databaseStartAt,
  endAt as databaseEndAt,
  startAfter,
  endBefore,
} from 'firebase/database';
import {
  getFunctions,
  connectFunctionsEmulator,
} from 'firebase/functions';
import {
  getRemoteConfig,
  getValue,
  getString,
  getBoolean,
  getNumber,
  getAll,
  activate,
  fetchAndActivate,
} from 'firebase/remote-config';
import {
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import environments from '~/environments';

export const FirestoreForInResultLimit = 10;

export const REACT_APP_LOCALBRIDGE_ENV = process.env?.REACT_APP_LOCALBRIDGE_ENV || 'local';
export const {
  firebaseRoot: {
    LOCALBRIDGE_PATH,
    FIREBASE_FUNCTIONS_REGION,
  },
  firebaseConfig,
  FUNCTION_V,
} = (environments as any)[REACT_APP_LOCALBRIDGE_ENV];

// process.env.FIREBASE_AUTH_EMULATOR_HOST=`https://${AUTH_DOMAIN}`;

const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);
export const functions = getFunctions(firebaseApp, FIREBASE_FUNCTIONS_REGION);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

(window as any).firestore = firestore;
(window as any).database = database;
(window as any).functions = functions;
(window as any).storage = storage;
(window as any).auth = auth;
(window as any).remoteConfig = remoteConfig;
(window as any).analytics = analytics;

// Storage
(window as any).uploadBytes = uploadBytes;
(window as any).getDownloadURL = getDownloadURL;
(window as any).storagePath = storagePath;
(window as any).uploadBytesResumable = uploadBytesResumable;

// Database
(window as any).ref = ref;
(window as any).get = get;
(window as any).off = off;
(window as any).onValue = onValue;
(window as any).onDisconnect = onDisconnect;
(window as any).onChildAdded = onChildAdded;
(window as any).orderByChild = orderByChild;
(window as any).orderByKey = orderByKey;
(window as any).equalTo = equalTo;
(window as any).databaseStartAt = databaseStartAt;
(window as any).databaseEndAt = databaseEndAt;
(window as any).startAfter = startAfter;
(window as any).endBefore = endBefore;
(window as any).update = update;
(window as any).remove = remove;
(window as any).set = set;
(window as any).increment = increment;
(window as any).databaseQuery = databaseQuery;

// Firestore
(window as any).getDoc = getDoc;
(window as any).getDocs = getDocs;
(window as any).doc = doc;
(window as any).collection = collection;
(window as any).collectionGroup = collectionGroup;
(window as any).onSnapshot = onSnapshot;
(window as any).updateDoc = updateDoc;
(window as any).deleteDoc = deleteDoc;
(window as any).setDoc = setDoc;
(window as any).where = where;
(window as any).writeBatch = writeBatch;
(window as any).and = and;
(window as any).or = or;
(window as any).firestoreStartAt = firestoreStartAt;
(window as any).firestoreEndAt = firestoreEndAt;
(window as any).arrayUnion = arrayUnion;
(window as any).arrayRemove = arrayRemove;
(window as any).FieldValue = FieldValue;
(window as any).firestoreQuery = firestoreQuery;
(window as any).orderBy = orderBy;
(window as any).limit = limit;

// RemoteConfig
(window as any).getValue = getValue;
(window as any).getString = getString;
(window as any).getBoolean = getBoolean;
(window as any).getNumber = getNumber;
(window as any).getAll = getAll;
(window as any).activate = activate;
(window as any).fetchAndActivate = fetchAndActivate;

// Analytics
(window as any).logEvent = logEvent;

if (REACT_APP_LOCALBRIDGE_ENV === 'local') {
  if (window.location.hostname === 'localhost') {
    connectFirestoreEmulator(firestore, 'localhost', emulators.firestore.port);
    connectDatabaseEmulator(database, 'localhost', emulators.database.port);
    connectFunctionsEmulator(functions, 'localhost', emulators.functions.port);
    connectStorageEmulator(storage, 'localhost', emulators.storage.port);
    connectAuthEmulator(auth, `http://localhost:${emulators.auth.port}`);
  }
}
