import React from 'react';
import { LocalbridgeFooterProvider } from './Provider';
import LocalbridgeFooterRenderer from './Renderer';

export const LocalbridgeFooter = () => {
  return (
    <LocalbridgeFooterProvider>
      <LocalbridgeFooterRenderer />
    </LocalbridgeFooterProvider>
  );
};
