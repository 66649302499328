import React from 'react';
import { AdminNoticeEditProvider } from './Provider';
import AdminNoticeEditRenderer from './Renderer';

export const AdminNoticeEdit = () => {
  return (
    <AdminNoticeEditProvider>
      <AdminNoticeEditRenderer />
    </AdminNoticeEditProvider>
  );
};
