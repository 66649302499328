import React from 'react';
import { SignOutProvider } from './Provider';
import SignOutRenderer from './Renderer';

export const SignOut = () => {
  return (
    <SignOutProvider>
      <SignOutRenderer />
    </SignOutProvider>
  );
};
