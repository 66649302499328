import React from 'react';
import { GroupProvider } from './Provider';
import GroupRenderer from './Renderer';

export const Group = () => {
  return (
    <GroupProvider>
      <GroupRenderer />
    </GroupProvider>
  );
};
