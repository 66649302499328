import styled from 'styled-components';

type Props = {
  textAlign: string;
  isHiddenCarret: boolean;
  isHiddenButton: boolean;
  dropDownMenuWidth?: string;
}
export const StyledToasterFormDropDownButtonContext = styled.div<Props>`
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;

  .dropdown-menu {
    text-width: ${({dropDownMenuWidth}) => (dropDownMenuWidth || '100%')};
  }

  .dropdown-item {
    text-align: ${({textAlign}) => (textAlign || 'center')};
  }

  .dropdown-menu.show {
    max-height: 70vh;
    overflow-y: auto;
  }

  .dropdown-toggle {
    width: 100%;

    :first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    ${({isHiddenButton}) => (isHiddenButton ? 'padding: 0; border: 0;' : '')}
    :after {
      ${({isHiddenCarret}) => (isHiddenCarret ? 'display: none;' : '')}
    }
  }
`;
