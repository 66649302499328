
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AdminLeftSidebarValue {

}

const AdminLeftSidebarContext = React.createContext<AdminLeftSidebarValue | null>(null);

export function useAdminLeftSidebar(): AdminLeftSidebarValue {
  const state = useContext(AdminLeftSidebarContext);

  if (!state) {
    throw new Error('useAdminLeftSidebar must be used within AdminLeftSidebarProvider');
  }

  return state;
}

export function AdminLeftSidebarProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AdminLeftSidebarContext.Provider value={providerValue}>
      {children}
    </AdminLeftSidebarContext.Provider>
  );
}
