import React from 'react';
import { WorkshopBookingsCreateProvider } from './Provider';
import WorkshopBookingsCreateRenderer from './Renderer';

export const WorkshopBookingsCreate = () => {
  return (
    <WorkshopBookingsCreateProvider>
      <WorkshopBookingsCreateRenderer />
    </WorkshopBookingsCreateProvider>
  );
};
