import React from 'react';
import { PrivacyPolicyProvider } from './Provider';
import PrivacyPolicyRenderer from './Renderer';

export const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyProvider>
      <PrivacyPolicyRenderer />
    </PrivacyPolicyProvider>
  );
};
