import React, { useContext, ReactNode, useCallback } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signOut as doSignOut,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  UserRequestsDelete,
  LocalbridgeSignUpEmailVarificationSendMailRequest,
  LocalbridgeUserUpdatePasswordResetSendMailRequest,
  SignInRequest,
  UserRequestCreate,
  UserRequestUpdatePassword,
  UserRequestUpdateProfile,
  UserRequestsValidate,
  UserResponsesWithPager,
  UserModel,
  LocalbridgeUserUpdateEmailVarificationSendMailRequest,
  LocalbridgeUserSyncEmailRequest,
  UserRequestsSearch,
  testStringMax80,
  testEmptyInput,
  testStringMin8Max30,
  testStringUnMatchedPasswordConfirm,
  StringUnMatchedPasswordMessage,
  StringUnMatchedPassword5TimesMessage,
  LocalbridgeUserGetUserIdByEmailRequest,
  UserRequestRead,
  UserResponseRead,
  SignInValidateRequest,
  testStringWrongEmailFormat,
  LocalbridgeUserRequest,
  LocalbridgeUserResponse,
  LocalbridgeSignInTestSignInTypeRequest,
  GroupModel,
  SignInType,
  testStringMax40000,
  StringNotExistUserMessage,
  testDateReversedStartAndEnd,
  testStringMax200,
  testStrongPassword,
  UserModelWithPassword,
  LocalbridgeUsersCreateUserByServiceManagerRequest,
  LocalbridgeUsersCreateUserByServiceManagerResponse,
  LocalbridgeUserGetUserIdByEmailResponse,
  StringExistUserEmailMessage,
  LocalbridgeUsersRequest,
  LocalbridgeUsersResponse,
} from '~/openapi/typescript-axios/version';
import {
  FUNCTION_V,
  auth,
  functions,
} from '~/constants/firebase';
import { LOCALBRIDGE_PATH, firestore } from '~/constants/firebase';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import checkUserPassword from '~/helpers/checkUserPassword';
import { functionsRequestPost } from './helpers/functionsRequestPost';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { getSearchStringQuery } from './helpers/getSearchStringQuery';
import showError from '~/helpers/toaster/message/showError';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeUserApiValue {
  localbridgeSignInTestSignInType: (
    signInTestSignInTypeRequest: LocalbridgeSignInTestSignInTypeRequest
  ) => Promise<string>;
  localbridgeUsersCreateUserByServiceManager: (
    localbridgeUsersCreateUserByServiceManagerRequest: LocalbridgeUsersCreateUserByServiceManagerRequest
  ) => Promise<LocalbridgeUsersCreateUserByServiceManagerResponse>;
  localbridgeUser: (
    localbridgeUserRequest: LocalbridgeUserRequest
  ) => Promise<LocalbridgeUserResponse>;
  getUserIdByEmail: (
    userValidateEmailRequest: LocalbridgeUserGetUserIdByEmailRequest
  ) => Promise<LocalbridgeUserGetUserIdByEmailResponse>;
  validateUsers: (userRequestsValidate: UserRequestsValidate) => Promise<MessageResponse>;
  createUsers: (userRequestCreates: Array<UserRequestCreate>) => Promise<void>;
  getUserModel: (userRequestRead: UserRequestRead) => Promise<UserResponseRead|null>;
  updateUserPasswordSelf: (userRequestUpdatePassword: UserRequestUpdatePassword) => Promise<void>;
  updateUserProfiles: (userRequestUpdateProfiles: Array<UserRequestUpdateProfile>) => Promise<void>;
  deleteUsers: (deleteUsersRequest: UserRequestsDelete) => Promise<void>;
  signOut: () => Promise<void>;
  signInValidate: (signInValidateRequest: SignInValidateRequest) => Promise<MessageResponse>;
  signIn: (signInRequest: SignInRequest) => Promise<{
    localbridge: any;
    groupModel: GroupModel|undefined;
    userModel: UserModel|undefined;
  }>;
  searchUsers: (
    userRequestsSearch: UserRequestsSearch
  ) => Promise<Array<UserResponsesWithPager>>;
  localbridgeUsers: (
    localbridgeUsersRequest: LocalbridgeUsersRequest
  ) => Promise<LocalbridgeUsersResponse>;
  localbridgeUserSyncEmail: (
    localbridgeUserSyncEmailRequest: LocalbridgeUserSyncEmailRequest
  ) => Promise<void>;
  localbridgeUserUpdatePasswordResetSendMail: (
    localbridgeUserUpdatePasswordResetSendMailRequest: LocalbridgeUserUpdatePasswordResetSendMailRequest
  ) => Promise<void>;
  localbridgeUserUpdateEmailVarificationSendMail: (
    localbridgeUserUpdateEmailVarificationSendMailRequest: LocalbridgeUserUpdateEmailVarificationSendMailRequest
  ) => Promise<void>;
  localbridgeSignUpEmailVarificationSendMail: (
    localbridgeSignUpEmailVarificationSendMailRequest: LocalbridgeSignUpEmailVarificationSendMailRequest
  ) => Promise<void>;
}

const LocalbridgeUserApiContext = React.createContext<LocalbridgeUserApiValue | null>(null);

export function useLocalbridgeUserApi(): LocalbridgeUserApiValue {
  const state = useContext(LocalbridgeUserApiContext);

  if (!state) {
    throw new Error('useLocalbridgeUserApi must be used within LocalbridgeUserApiProvider');
  }

  return state;
}

export function LocalbridgeUserApiProvider({ children }: Props) {
  const history = useHistory();

  const localbridgeSignInTestSignInType = useCallback(async (
    signInTestSignInTypeRequest: LocalbridgeSignInTestSignInTypeRequest
  ) => {
    try {
      await httpsCallable(functions, 'localbridgeSignInTestSignInType')(
        signInTestSignInTypeRequest
      );
      return '';
    } catch (error) {
      return (error as Error).message || '';
    }
  }, []);

  const localbridgeUsersCreateUserByServiceManager = useCallback(async (
    localbridgeUsersCreateUserByServiceManagerRequest: LocalbridgeUsersCreateUserByServiceManagerRequest
  ) => {
    const result =await httpsCallable<
      LocalbridgeUsersCreateUserByServiceManagerRequest, LocalbridgeUsersCreateUserByServiceManagerResponse
    >(functions, 'localbridgeUsersCreateUserByServiceManager')(
      localbridgeUsersCreateUserByServiceManagerRequest
    );

    return result.data;
  }, []);

  const localbridgeUser = useCallback(async (localbridgeUserRequest: LocalbridgeUserRequest) => {
    return functionsRequestPost(
      'localbridgeUser', localbridgeUserRequest,
    ) as Promise<LocalbridgeUserResponse>;
  }, []);

  const getUserIdByEmail = useCallback(async (
    userValidateEmailRequest: LocalbridgeUserGetUserIdByEmailRequest
  ) => {
    const result = await httpsCallable<
      LocalbridgeUserGetUserIdByEmailRequest, LocalbridgeUserGetUserIdByEmailResponse
    >(functions, 'localbridgeUserGetUserIdByEmail')(
      userValidateEmailRequest
    );

    return result.data;
  }, []);

  const validateUsers = useCallback(async (userRequestsValidate: UserRequestsValidate) => {
    const userModelWithPasswords = userRequestsValidate.userRequests.map((userRequest) => {
      return {
        ...userRequest.userModel,
        password: userRequest.password,
        newPassword: userRequest.newPassword,
        passwordConfirm: userRequest.passwordConfirm,
      };
    });

    const usersValidator = new Validator<UserModelWithPassword>('UserModelWithPassword', {
      id: [
        async (user) => testEmptyInput(user.id),
        async (user) => testStringMax80(user.id),
      ],
      password: [
        async (user) => testEmptyInput(user.password),
        async (user) => {
          if (!userRequestsValidate?.isOnSubmit) {
            return '' ;
          }

          if (!user.email || !user.password) {
            return '';
          }

          let res: any = {};
          try {
            res = await checkUserPassword(user.email, user.password);
          } catch (err) {
            const error: any = err;
            const errorMessage = error?.error?.error?.message;

            if (errorMessage.includes('INVALID_PASSWORD')) {
              return StringUnMatchedPasswordMessage;
            } else if (errorMessage.includes('TOO_MANY_ATTEMPTS_TRY_LATER')) {
              return StringUnMatchedPassword5TimesMessage;
            } else {
              throw Error(error);
            }
          }

          return res?.localId ? '' : StringUnMatchedPasswordMessage;
        },
      ],
      newPassword: [
        async (user) => testEmptyInput(user.newPassword),
        async (user) => testStringMin8Max30(user.newPassword),
        async (user) => testStrongPassword(user.newPassword),
      ],
      passwordConfirm: [
        async (user) => testEmptyInput(user.passwordConfirm),
        async (user) => testStringMin8Max30(user.passwordConfirm),
        async (user) => userRequestsValidate?.isOnSubmit
          ? testStringUnMatchedPasswordConfirm(user.newPassword, user.passwordConfirm) : '',
      ],
      photos: [
      ],
      createdAt: [
        async (user) => testEmptyInput(user.createdAt),
        async (user) => testDateReversedStartAndEnd(user.createdAt, user.updatedAt),
      ],
      updatedAt: [
        async (user) => testEmptyInput(user.updatedAt),
      ],
      cityId: [
        async (user) => testStringMax80(user.cityId),
      ],
      townId: [
        async (user) => testStringMax80(user.townId),
      ],
      detailAddress1: [
        async (booking) => testStringMax200(booking.detailAddress1),
      ],
      detailAddress2: [
        async (booking) => testStringMax200(booking.detailAddress2),
      ],
      job: [
        async (user) => testEmptyInput(user.job),
        async (user) => testStringMax80(user.job),
      ],
      name: [
        async (user) => testEmptyInput(user.name),
        async (user) => testStringMax80(user.name),
      ],
      nameFurigana: [
        async (user) => testEmptyInput(user.nameFurigana),
        async (user) => testStringMax80(user.nameFurigana),
      ],
      nickName: [
        async (user) => testEmptyInput(user.nickName),
        async (user) => testStringMax80(user.nickName),
      ],
      email: [
        async (user) => testEmptyInput(user.email),
        async (user) => testStringWrongEmailFormat(user.email),
        async (user) => {
          if (!user.id || !user.email) {
            return '';
          }

          const { userId } = await getUserIdByEmail({
            env: {LOCALBRIDGE_PATH, FUNCTION_V},
            params: {userEmail: user.email},
          });
          if (!userId) {
            return '';
          }

          if (userRequestsValidate.isOnCreate && userId) {
            let userModel: UserModel|undefined = undefined;
            try {
              userModel = (
                await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/users/${userId}`))
              ).data() as UserModel|undefined;
            } catch (error) {
              console.log(error);
            }

            return userModel?.id === userId ? StringExistUserEmailMessage : '';
          }

          return (userId !== user.id) ? StringExistUserEmailMessage : '';
        },
      ],
      emailToken: [
        async (user) => testEmptyInput(user.emailToken),
      ],
      gender: [
        async (user) => testEmptyInput(user.gender),
        async (user) => testStringMax80(user.gender),
      ],
      interests: [
        async (user) => testEmptyInput(user.interests),
      ],
      localCategorys: [
      ],
      postNumber: [
        async (user) => testStringMax80(user.postNumber),
      ],
      description: [
        async (user) => testStringMax40000(user.description),
      ]
    });

    return usersValidator.validate(userModelWithPasswords);
  }, [getUserIdByEmail]);

  const createUsers = useCallback(async (userRequestCreates: Array<UserRequestCreate>) => {
    if (!userRequestCreates.length) {
      return;
    }

    for (const userRequestCreate of userRequestCreates) {
      if (!userRequestCreate.newPassword || !userRequestCreate.userModel.email) {
        continue;
      }

      let userId = '';
      try {
        if (userRequestCreate.isByAdmin) {
          const result = await localbridgeUsersCreateUserByServiceManager({
            env: {LOCALBRIDGE_PATH, FUNCTION_V},
            params: {
              email: userRequestCreate.userModel.email,
              password: userRequestCreate.newPassword,
              displayName: userRequestCreate.userModel.name,
            },
          });
          userId = result.userId;
        } else {
          const { userId: userIdFromServer } = await getUserIdByEmail({
            env: {LOCALBRIDGE_PATH, FUNCTION_V},
            params: {userEmail: userRequestCreate.userModel.email},
          });

          userId = userIdFromServer;
          if (!userId) {
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              userRequestCreate.userModel.email,
              userRequestCreate.newPassword,
            );
            userId = userCredential.user.uid;
          }
        }
      } catch (error) {
        console.error(error);
      }

      userRequestCreate.userModel.id = userId;
    }

    const messageResponses = await validateUsers({
      isOnSubmit: true,
      isOnCreate: true,
      userRequests: userRequestCreates,
    });

    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const userRequestCreate of userRequestCreates) {
      const { id } = userRequestCreate.userModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/users/${id}`), {
        ...Object.assign({}, userRequestCreate.userModel)
      });
      await batch.commit();
    }
  }, [validateUsers, localbridgeUsersCreateUserByServiceManager, getUserIdByEmail]);

  const getUserModel = useCallback(async (userRequestRead: UserRequestRead) => {
    const { userId } = userRequestRead;
    const userModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/users/${userId}`)))
      .data() as UserModel|null;
    return userModel ? {userModel} : null;
  }, []);



  const updateUserPasswordSelf = useCallback(async (userRequestUpdatePassword: UserRequestUpdatePassword) => {
    if (!auth.currentUser) {
      return;
    }

    // Todo
    await updatePassword(auth.currentUser, userRequestUpdatePassword.newPassword);
  }, []);

  const updateUserProfiles = useCallback(async (userRequestUpdateProfiles: Array<UserRequestUpdateProfile>) => {
    if (!userRequestUpdateProfiles.length) {
      return;
    }

    const messageResponses = await validateUsers({
      isOnSubmit: true,
      userRequests: userRequestUpdateProfiles,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const userRequestUpdateProfile of userRequestUpdateProfiles) {
      const { id } = userRequestUpdateProfile.userModel;
      batch.update(doc(firestore, `${LOCALBRIDGE_PATH}/users/${id}`), {
        ...Object.assign({}, userRequestUpdateProfile.userModel)
      });
    }

    await batch.commit();
  }, [validateUsers]);

  const deleteUsers = useCallback(async (deleteUsersRequest: UserRequestsDelete) => {
    const userIds = deleteUsersRequest?.userIds || [];

    if (!userIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const userId of userIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/users/${userId}`));
    }

    await batch.commit();
  }, []);

  const signOut = useCallback(async () => {
    await doSignOut(auth);
  }, []);

  const signInValidate = useCallback(async (signInValidateRequest: SignInValidateRequest) => {
    let emailErrorMessage = '';
    let passwordErrorMessage = '';
    let res: any = {};
    try {
      if (signInValidateRequest.email && signInValidateRequest.password) {
        res = await checkUserPassword(signInValidateRequest.email, signInValidateRequest.password);
        passwordErrorMessage = res?.localId ? '' : StringUnMatchedPasswordMessage;
      }
    } catch (err) {
      const errorMessage = (err as any)?.response?.data?.error?.message;
      if (errorMessage === 'INVALID_EMAIL') {
        passwordErrorMessage = '';
      } else if (errorMessage.includes('EMAIL_NOT_FOUND')) {
        emailErrorMessage = StringNotExistUserMessage;
      } else if (errorMessage.includes('INVALID_PASSWORD')) {
        passwordErrorMessage = StringUnMatchedPasswordMessage;
      } else if (errorMessage.includes('TOO_MANY_ATTEMPTS_TRY_LATER')) {
        passwordErrorMessage = StringUnMatchedPassword5TimesMessage;
      } else {
        throw err;
      }
    }

    const signInValidator = new Validator<SignInRequest>('SignInRequest', {
      email: [
        async () => emailErrorMessage,
        async (user) => testEmptyInput(user.email),
        async (user) => testStringWrongEmailFormat(user.email),
        // async (user) => {
        //   if (!user.email || !user.password || !user.signInType) {
        //     return '';
        //   }

        //   try {
        //     await localbridgeSignInTestSignInType({
        //       env: {LOCALBRIDGE_PATH, FUNCTION_V},
        //       params: {email: user.email, signInType: user.signInType},
        //     });
        //     return '';
        //   } catch (error) {
        //     return (error as Error).message || '';
        //   }
        // },
      ],
      password: [
        async (user) => testEmptyInput(user.password),
        async () => passwordErrorMessage,
      ],
      signInType: [
        async (user) => testEmptyInput(user.signInType),
      ]
    });

    return signInValidator.validate([signInValidateRequest]);
  }, []);

  /**
   * @description signInType === SignInType.GroupManager will return GroupModel
   */
  const signIn = useCallback(async (signInRequest: SignInRequest) => {
    let localbridge: any;
    let groupModel: GroupModel|undefined = undefined;
    let userModel: UserModel|undefined = undefined;
    const messageResponses = await signInValidate(signInRequest);
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth, signInRequest.email, signInRequest.password
      );

      const userRef = `${LOCALBRIDGE_PATH}/users/${userCredential.user.uid}`;
      userModel = (await getDoc(doc(firestore, userRef))).data() as UserModel|undefined;

      if (signInRequest.signInType === SignInType.GroupManager) {
        try {
          [groupModel] = (
            (await getDocs(collection(firestore, `${LOCALBRIDGE_PATH}/groups`))))
              .docs.map(doc => doc.data()
          ) as Array<GroupModel>;
        } catch (error) {
          console.log(error);
          showError({
            title: 'ログイン',
            text: '権限がありません',
          });
          history.push(ROUTES.SIGN_OUT);
        }
      }

      if (signInRequest.signInType === SignInType.Admin) {
        try {
          localbridge = (await getDoc(doc(firestore, LOCALBRIDGE_PATH))).data() as any;
        } catch (error) {
          console.log(error);
          showError({
            title: 'ログイン',
            text: '権限がありません',
          });
          history.push(ROUTES.SIGN_OUT);
        }
      }
    } catch (error) {
      throw error;
    }

    return {localbridge, groupModel, userModel};
  }, [history, signInValidate]);

  const searchUsers = useCallback(async (userRequestsSearch: UserRequestsSearch) => {
    const {
      size,
      page,
      cityId,
      townId,
      createdFrom,
      createdTo,
      searchString,
    } = userRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/users`),
      and(
        ...[
          cityId ? where('cityId', '==', cityId) : null,
          townId ? where('townId', '==', townId) : null,
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
          searchString ? getSearchStringQuery(searchString, ['emailToken']) : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>
      ),
      !searchString ? orderBy('email', 'asc') : limit(1000),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<UserModel>;
    resultsTmp.sort((resultA, resultB) => {
      return resultA.email.localeCompare((resultB.email),'ja');
    });

    const totalCount = resultsTmp.length;
    const resultsList: Array<UserResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const localbridgeUsers = useCallback(async (localbridgeUsersRequest: LocalbridgeUsersRequest) => {
    return functionsRequestPost(
      'localbridgeUsers', localbridgeUsersRequest,
    ) as Promise<LocalbridgeUsersResponse>;
  }, []);

  const localbridgeUserUpdatePasswordResetSendMail = useCallback(async (
    localbridgeUserUpdatePasswordResetSendMailRequest: LocalbridgeUserUpdatePasswordResetSendMailRequest
  ) => {
    if (window.location.hostname === 'localhost') {
      await sendPasswordResetEmail(auth, localbridgeUserUpdatePasswordResetSendMailRequest.params.email);
    } else {
      await httpsCallable(functions, 'localbridgeUserUpdatePasswordResetSendMail')(
        localbridgeUserUpdatePasswordResetSendMailRequest
      );
    }
  }, []);

  const localbridgeUserUpdateEmailVarificationSendMail = useCallback(async (
    localbridgeUserUpdateEmailVarificationSendMailRequest: LocalbridgeUserUpdateEmailVarificationSendMailRequest
  ) => {
    if (window.location.hostname === 'localhost' && auth.currentUser) {
      await sendEmailVerification(auth.currentUser);
    } else {
      await httpsCallable(functions, 'localbridgeUserUpdateEmailVarificationSendMail')(
        localbridgeUserUpdateEmailVarificationSendMailRequest
      );
    }
  }, []);

  const localbridgeSignUpEmailVarificationSendMail = useCallback(async (
    localbridgeSignUpEmailVarificationSendMailRequest: LocalbridgeSignUpEmailVarificationSendMailRequest
  ) => {
    if (window.location.hostname === 'localhost' && auth.currentUser) {
      await sendEmailVerification(auth.currentUser);
    } else {
      await httpsCallable(functions, 'localbridgeSignUpEmailVarificationSendMail')(
        localbridgeSignUpEmailVarificationSendMailRequest
      );
    }
  }, []);

  const localbridgeUserSyncEmail = useCallback(async (
    localbridgeUserSyncEmailRequest: LocalbridgeUserSyncEmailRequest
  ) => {
    await httpsCallable(functions, 'localbridgeUserSyncEmail')(
      localbridgeUserSyncEmailRequest
    );
  }, []);

  const providerValue = {
    localbridgeSignInTestSignInType,
    localbridgeUsersCreateUserByServiceManager,
    localbridgeUser,
    getUserIdByEmail,
    validateUsers,
    createUsers,
    getUserModel,
    updateUserPasswordSelf,
    updateUserProfiles,
    deleteUsers,
    signOut,
    signInValidate,
    signIn,
    searchUsers,
    localbridgeUsers,
    localbridgeUserSyncEmail,
    localbridgeUserUpdatePasswordResetSendMail,
    localbridgeUserUpdateEmailVarificationSendMail,
    localbridgeSignUpEmailVarificationSendMail,
  };

  return (
    <LocalbridgeUserApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeUserApiContext.Provider>
  );
}
