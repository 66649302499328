import React from 'react';
import { PostModel } from '~/openapi/typescript-axios/version';
import { StyledPostViewer } from './Styled';

type Props = {
  postModel: PostModel;
};

const PostViewer: React.FC<Props> = ({postModel}) => {
  return (
    <StyledPostViewer>
      <div dangerouslySetInnerHTML={{__html: postModel.contentData || ''}} />
    </StyledPostViewer>
  );
};

export default PostViewer;

