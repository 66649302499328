import React from 'react';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { NoticeModel } from '~/openapi/typescript-axios/version';

type Props = {
  noticeModel: NoticeModel;
};

const NoticesNewsItemRenderer: React.FC<Props> = ({noticeModel}) => {
  return (
    <>
      <div className={'news_item'}>
        <a href={generatePath(ROUTES.NOTICE, {noticeId: noticeModel.id})} className={'news_item__link'}>
          <div className={'news_item__head'}>
            <p className={'news_item__date'}>{moment(noticeModel.createdAt).format('YYYY.MM.DD')}</p>
            <div className={`${'news_item_tag'} ${'news_item_tag__is_sp'}`}>
              <img className={'news_item_tag__ico'} src="/assets/img/common/ico_tag.svg" alt="" />
              {noticeModel.tags?.map((tag, index) => {
                return (
                  <p key={index} className={'news_item_tag__text'}>
                    {tag}
                  </p>
                );
              })}
            </div>
          </div>
          <div className={'news_item__contents'}>
            <div className={`${'news_item_tag'} ${'news_item_tag__is_pc'}`}>
              <img className={'news_item_tag__ico'} src="/assets/img/common/ico_tag.svg" alt="" />
              {noticeModel.tags?.map((tag, index) => {
                return (
                  <p key={index} className={'news_item_tag__text'}>
                    {tag}
                  </p>
                );
              })}
            </div>
            <div className={'news_item__title'}>{noticeModel.title}</div>
          </div>
        </a>
      </div>
    </>
  );
};

export default NoticesNewsItemRenderer;
