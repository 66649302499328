import React from 'react';
import './index.scss';

const LocalbridgeSubHeader = ({ title, enTitle }) => {
  return (
    <div className={'local_bridge_sub_header'}>
      <h1 className={'local_bridge_sub_header__title'}>{title}</h1>
      <p className={'local_bridge_sub_header__en_title'}>{enTitle}</p>
    </div>
  );
};

export default LocalbridgeSubHeader;
