import React from 'react';
import { UserBookingProvider } from './Provider';
import UserBookingRenderer from './Renderer';

export const UserBooking = () => {
  return (
    <UserBookingProvider>
      <UserBookingRenderer />
    </UserBookingProvider>
  );
};
