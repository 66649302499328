import React, { useCallback, useState } from 'react';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { ROUTES } from '~/constants/routes';
import { useHistory } from 'react-router-dom';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { SignInRequest, SignInType } from '~/openapi/typescript-axios/version';
import { useAuth } from '~/providers/AuthProvider';
import { Button, Form } from 'react-bootstrap';
import './index.scss';
import SignInHeader from '~/components/signInHeader';
import InputWithValidation from '~/components/inputWithValidation';
import showSuccess from '~/helpers/toaster/message/showSuccess';

type Props = {};

const AdminSignInRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { signIn } = useLocalbridgeUserApi();
  const { setSignInType } = useAuth();

  const [ signInRequest, setSignInRequest ] = useState<SignInRequest>({
    email: '', password: '', signInType: SignInType.Admin,
  });

  const handleAdminSignInWithEmailAndPassword = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSignInType(signInRequest.signInType);

    try {
      const { userModel } = await signIn(signInRequest);

      if (!userModel) {
        history.push(ROUTES.SIGN_UP);
        return;
      }

      showSuccess({
        title: '管理者ログイン',
        text: 'こんにちは!',
        timer: 1000,
      });
      history.push(ROUTES.ADMIN_GROUPS);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showSuccess({
          title: '管理者ログイン',
          text: (error as Error).message,
          timer: 1000,
        });
        throw error;
      }
    }
  }, [signInRequest, history, signIn, setSignInType]);

  const onChangeSignInRequestInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof SignInRequest,
  ) => {
    if (!signInRequest) {
      return;
    }

    signInRequest[key as string] = event.currentTarget.value;
    setSignInRequest(Object.assign({}, signInRequest));
  }, [signInRequest, setSignInRequest]);

  return (    
    <div className={'index_page'}>
      <SignInHeader />
      <section className={'index_block'}>
        <div className={`${'index_block__inner'} shadow-sm rounded`}>
          <h2 className={`${'index_block__title'}`}>菅理者ログイン</h2>
          <Form
            noValidate
            className={'index_block__form'}
            onSubmit={handleAdminSignInWithEmailAndPassword}
          >
            <Form.Group className='mt-5' controlId='formBasicEmail'>
              <Form.Label>メールアドレス</Form.Label>
              <InputWithValidation<SignInRequest>
                size='lg'
                data={signInRequest}
                type='email'
                validationKey={'email'}
                validationClassName='SignInRequest'
                onChange={(event) => {
                  onChangeSignInRequestInput(event, 'email');
                }}
              />
            </Form.Group>

            <Form.Group className='mt-5' controlId='formBasicPassword'>
              <Form.Label>パスワード</Form.Label>
              <InputWithValidation<SignInRequest>
                size='lg'
                data={signInRequest}
                type='password'
                validationKey={'password'}
                validationClassName='SignInRequest'
                onChange={(event) => {
                  onChangeSignInRequestInput(event, 'password');
                }}
              />
            </Form.Group>
            <div className='d-grid gap-2 mt-5'>
              <Button variant='primary' type='submit' size='lg' className={'index_block__btn'}>
                ログイン
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
};

export default AdminSignInRenderer;
