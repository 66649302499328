import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { useQna } from './Provider';
import QnaForm from './components/qnaForm';
import { QnaState } from './enums';
import QnaConfirm from './components/qnaConfirm';
import QnaSubmitted from './components/qnaSubmitted';

type Props = {};

const QnaRenderer: React.FC<Props> = () => {
  const { qnaState } = useQna();

  return (
    <>
      <LocalbridgeNavbar />

      {qnaState === QnaState.QnaForm ? (<QnaForm />):(<></>)}
      {qnaState === QnaState.QnaConfirm ? (<QnaConfirm />):(<></>)}
      {qnaState === QnaState.QnaSubmitted ? (<QnaSubmitted />):(<></>)}

      <LocalbridgeFooter />
    </>
  );
};

export default QnaRenderer;
