import { BookingModelV1, MessageValidationMessageV1 } from './api';

export type {
  LocalbridgeQnaSendMailRequestV1 as LocalbridgeQnaSendMailRequest,
  LocalbridgeSignInTestSignInTypeRequestV1 as LocalbridgeSignInTestSignInTypeRequest,
  LocalbridgeSignUpEmailVarificationSendMailRequestV1 as LocalbridgeSignUpEmailVarificationSendMailRequest,
  LocalbridgeUserUpdateEmailVarificationSendMailRequestV1 as LocalbridgeUserUpdateEmailVarificationSendMailRequest,
  LocalbridgeUserRequestV1 as LocalbridgeUserRequest,
  LocalbridgeUserResponseV1 as LocalbridgeUserResponse,
  LocalbridgeUserUpdatePasswordResetSendMailRequestV1 as LocalbridgeUserUpdatePasswordResetSendMailRequest,
  LocalbridgeWorkshopUpdateUserReactionRequestV1 as LocalbridgeWorkshopUpdateUserReactionRequest,
  LocalbridgePostUpdateUserReactionRequestV1 as LocalbridgePostUpdateUserReactionRequest,
  LocalbridgeUserGetUserIdByEmailRequestV1 as LocalbridgeUserGetUserIdByEmailRequest,
  LocalbridgeUserGetUserIdByEmailResponseV1 as LocalbridgeUserGetUserIdByEmailResponse,
  LocalbridgeUsersRequestV1 as LocalbridgeUsersRequest,
  LocalbridgeUsersResponseV1 as LocalbridgeUsersResponse,
  LocalbridgeUsersCreateUserByServiceManagerRequestV1 as LocalbridgeUsersCreateUserByServiceManagerRequest,
  LocalbridgeUsersCreateUserByServiceManagerResponseV1 as LocalbridgeUsersCreateUserByServiceManagerResponse,
  LocalbridgeQnaSendMailParamsV1 as LocalbridgeQnaSendMailParams,
  QnaMailV1 as QnaMail,
  SignInRequestV1 as SignInRequest,
  SignInValidateRequestV1 as SignInValidateRequest,
  UserRequestCreateV1 as UserRequestCreate,
  UserRequestReadV1 as UserRequestRead,
  UserResponseReadV1 as UserResponseRead,
  UserRequestsDeleteV1 as UserRequestsDelete,
  UserRequestUpdatePasswordV1 as UserRequestUpdatePassword,
  UserRequestUpdateProfileV1 as UserRequestUpdateProfile,
  UserRequestsSearchV1 as UserRequestsSearch,
  UserRequestValidateV1 as UserRequestValidate,
  UserRequestsValidateV1 as UserRequestsValidate,
  UserResponsesWithPagerV1 as UserResponsesWithPager,
  LocalbridgeUserSyncEmailRequestV1 as LocalbridgeUserSyncEmailRequest,
  GroupRequestCreateV1 as GroupRequestCreate,
  GroupRequestReadV1 as GroupRequestRead,
  GroupResponseReadV1 as GroupResponseRead,
  GroupRequestsDeleteV1 as GroupRequestsDelete,
  GroupRequestUpdateV1 as GroupRequestUpdate,
  GroupRequestsSearchV1 as GroupRequestsSearch,
  GroupRequestsValidateV1 as GroupRequestsValidate,
  GroupResponsesWithPagerV1 as GroupResponsesWithPager,
  WorkshopRequestCreateV1 as WorkshopRequestCreate,
  WorkshopRequestReadV1 as WorkshopRequestRead,
  WorkshopResponseReadV1 as WorkshopResponseRead,
  WorkshopRequestsDeleteV1 as WorkshopRequestsDelete,
  WorkshopRequestUpdateV1 as WorkshopRequestUpdate,
  WorkshopRequestsSearchV1 as WorkshopRequestsSearch,
  WorkshopRequestsValidateV1 as WorkshopRequestsValidate,
  WorkshopResponsesWithPagerV1 as WorkshopResponsesWithPager,
  BookingRequestCreateV1 as BookingRequestCreate,
  BookingRequestReadV1 as BookingRequestRead,
  BookingResponseReadV1 as BookingResponseRead,
  BookingRequestsDeleteV1 as BookingRequestsDelete,
  BookingRequestUpdateV1 as BookingRequestUpdate,
  BookingRequestsSearchV1 as BookingRequestsSearch,
  BookingRequestsValidateV1 as BookingRequestsValidate,
  BookingResponsesWithPagerV1 as BookingResponsesWithPager,
  NoticeRequestCreateV1 as NoticeRequestCreate,
  NoticeRequestReadV1 as NoticeRequestRead,
  NoticeResponseReadV1 as NoticeResponseRead,
  NoticeRequestsDeleteV1 as NoticeRequestsDelete,
  NoticeRequestUpdateV1 as NoticeRequestUpdate,
  NoticeRequestsSearchV1 as NoticeRequestsSearch,
  NoticeRequestsValidateV1 as NoticeRequestsValidate,
  NoticeResponsesWithPagerV1 as NoticeResponsesWithPager,
  BannerRequestCreateV1 as BannerRequestCreate,
  BannerRequestReadV1 as BannerRequestRead,
  BannerResponseReadV1 as BannerResponseRead,
  BannerRequestsDeleteV1 as BannerRequestsDelete,
  BannerRequestUpdateV1 as BannerRequestUpdate,
  BannerRequestsSearchV1 as BannerRequestsSearch,
  BannerRequestsValidateV1 as BannerRequestsValidate,
  BannerResponsesWithPagerV1 as BannerResponsesWithPager,
  PassportRequestCreateV1 as PassportRequestCreate,
  PassportRequestReadV1 as PassportRequestRead,
  PassportResponseReadV1 as PassportResponseRead,
  PassportRequestsDeleteV1 as PassportRequestsDelete,
  PassportRequestUpdateV1 as PassportRequestUpdate,
  PassportRequestsSearchV1 as PassportRequestsSearch,
  PassportRequestsValidateV1 as PassportRequestsValidate,
  PassportResponsesWithPagerV1 as PassportResponsesWithPager,
  PostRequestCreateV1 as PostRequestCreate,
  PostRequestReadV1 as PostRequestRead,
  PostResponseReadV1 as PostResponseRead,
  PostRequestsDeleteV1 as PostRequestsDelete,
  PostRequestUpdateV1 as PostRequestUpdate,
  PostRequestsSearchV1 as PostRequestsSearch,
  PostRequestsValidateV1 as PostRequestsValidate,
  PostResponsesWithPagerV1 as PostResponsesWithPager,
  AssociationRequestsSearchV1 as AssociationRequestsSearch,
  AssociationResponsesWithPagerV1 as AssociationResponsesWithPager,
  UserModelV1 as UserModel,
  UserPublicV1 as UserPublic,
  PassportModelV1 as PassportModel,
  PostModelV1 as PostModel,
  GroupModelV1 as GroupModel,
  WorkshopModelV1 as WorkshopModel,
  BookingModelV1 as BookingModel,
  NoticeModelV1 as NoticeModel,
  BannerModelV1 as BannerModel,
  AssociationModelV1 as AssociationModel,
  PhotoMetaV1 as PhotoMeta,
  MessageResponseV1 as MessageResponse,
  UserJobV1 as UserJob,
  UserReactionNullableV1 as UserReactionNullable,
} from './api';

// enums
export {
  MessageModalV1 as MessageModal,
  GroupTypeV1 as GroupType,
  PostTypeV1 as PostType,
  SignInTypeV1 as SignInType,
  UserJobDropdownV1 as UserJobDropdown,
  UserGenderV1 as UserGender,
  QnaMailQuestionTypeV1 as QnaMailQuestionType,
  HighSchoolStudentGradeV1 as HighSchoolStudentGrade,
  UniversityCollegeStudentGradeV1 as UniversityCollegeStudentGrade,
  AssociationTypeV1 as AssociationType,
  LocalbridgePostUpdateUserReactionOperationV1 as LocalbridgePostUpdateUserReactionOperation,
  BookingSourceV1 as BookingSource,
} from './api';

import { UserModelV1 } from './api';
export interface UserModelWithPassword extends UserModelV1 {
  password?: string;
  newPassword?: string;
  passwordConfirm?: string;
}

export interface BookingModelWithEmailConfirm extends BookingModelV1 {
  emailConfirm?: string;
}

// Validation Message
export const {
  EmptyInputMessage,
  ExistGroupNameMessage,
  StringIdMessage,
  StringMin8Max16Message,
  StrongPasswordMessage,
  StringMax80Message,
  StringMax200Message,
  StringMax1000Message,
  StringMax40000Message,
  StringWrongEmailFormatMessage,
  StringUnMatchedEmailMessage,
  StringNotExistUserMessage,
  StringExistUserEmailMessage,
  StringExistGroupNameMessage,
  StringUnMatchedPasswordMessage,
  StringUnMatchedPasswordConfirmMessage,
  StringUnMatchedPassword5TimesMessage,
  StringNoSpecialCharacterMessage,
  DateStartAndEndMessage,
  EmptyArrayWithString,
  SignInTypeMessage,
} = MessageValidationMessageV1;

export const testEmptyInput = async (value?: string|number|null|object|Array<string>) => {
  const isArray = typeof value === 'object' && typeof (value as any)?.length === 'number';

  if (typeof value === 'string' || isArray) {
    return (value as any).length === 0 ? EmptyInputMessage : '';
  } else if (value === null || value === undefined || typeof value === 'number') {
    return '';
  } else {
    return '';
  }
};

export const testStringId = async (
  value?: string|null|Array<string>
) => {
  if (typeof value === 'string') {
    return (!/^[0-9A-Za-z\-_]*$/g.test(value) ? StringIdMessage : '');
  } else if (typeof value?.length === 'number') { // Array
    for (const valuInner of value) {
      if (!/^[0-9A-Za-z\-_]*$/g.test(valuInner)) {
        return StringIdMessage;
      }
    }

    return '';
  } else {
    return '';
  }
};

export const testStringMin8Max30 = async (value?: string|null) => (
  value && !/^[\s\S]{8,30}$/g.test(value)
) ? StringMin8Max16Message : '';

export const testStrongPassword = async (value?: string|null) => (
  value && !/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).+$/g.test(value)
) ? StrongPasswordMessage : '';

export const testStringMax80 = async (value?: string|null) => (
  value && !/^[\s\S]{1,80}$/g.test(value)
) ? StringMax80Message : '';

export const testStringMax200 = async (value?: string|null) => (
  value && !/^[\s\S]{1,200}$/g.test(value)
) ? StringMax80Message : '';

export const testStringMax1000 = async (value?: string|null) => (
  value && !/^[\s\S]{1,1000}$/g.test(value)
) ? StringMax1000Message : '';

export const testStringMax40000 = async (value?: string|null) => (
  value && !/^[\s\S]{1,40000}$/g.test(value)
) ? StringMax40000Message : '';

export const testStringWrongEmailFormat = async (value?: string|null) => (
  value && !/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/g.test(value)
) ? StringWrongEmailFormatMessage : '';

export const testUnMatchedEmail = async (email?: string|null, emailConfirm?: string|null) => (
  email && emailConfirm && email !== emailConfirm
) ? StringUnMatchedEmailMessage : '';

export const testStringUnMatchedPasswordConfirm = async (
  password?: string|null, passwordConfirm?: string|null
) => (
  password && passwordConfirm && password !== passwordConfirm
) ? StringUnMatchedPasswordConfirmMessage : '';

export const testStringNoSpecialCharacter = async (value?: string|null) => (
  value && !/^[^\\\/:*?"<>|]*$/g.test(value)
) ? StringNoSpecialCharacterMessage : '';

export const testDateReversedStartAndEnd = async (
  startAt?: number|null, endAt?: number|null
) => (
  startAt && endAt && startAt > endAt
) ? DateStartAndEndMessage : '';
