import React from 'react';
import { AdminSignOutProvider } from './Provider';
import AdminSignOutRenderer from './Renderer';

export const AdminSignOut = () => {
  return (
    <AdminSignOutProvider>
      <AdminSignOutRenderer />
    </AdminSignOutProvider>
  );
};
