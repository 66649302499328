
import React, { useContext, ReactNode, useEffect, useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AssociationModel, GroupModel, UserModel } from '~/openapi/typescript-axios/version';
import { useAppState } from '~/providers/AppStateProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { useParentGroup } from '~/providers/ParentGroupProvider';
import { useParentUser } from '~/providers/ParentUserProvider';

type Props = {
  children: ReactNode;
};

interface AdminGroupEditWithUserValue {
  group?: GroupModel;
  user?: UserModel;
  cities: Array<AssociationModel>;
  towns: Array<AssociationModel>;
  selectedCity?: AssociationModel;
  selectedTown?: AssociationModel;
  isEditingGroup: boolean;
  setGroup: (group?: GroupModel) => void;
  setUser: (user?: UserModel) => void;
  setIsEditingGroup: (isEditingGroup: boolean) => void;
}

const AdminGroupEditWithUserContext = React.createContext<AdminGroupEditWithUserValue | null>(null);

export function useAdminGroupEditWithUser(): AdminGroupEditWithUserValue {
  const state = useContext(AdminGroupEditWithUserContext);

  if (!state) {
    throw new Error('useAdminGroupEditWithUser must be used within AdminGroupEditWithUserProvider');
  }

  return state;
}

export function AdminGroupEditWithUserProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { userId, groupId } = useParams<any>();

  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const { groupModel } = useParentGroup();
  const { userModelOrPublic } = useParentUser();
  const [ group, setGroup ] = useState<GroupModel>();
  const [ user, setUser ] = useState<UserModel>();
  const [ isEditingGroup, setIsEditingGroup ] = useState<boolean>(false);

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === group?.cityId);
  }, [cities, group?.cityId]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    group?.cityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === group?.townId);
  }, [towns, group?.townId]);

  useEffect(() => {
    if (group) {
      return;
    }

    setGroup(groupModel);
  }, [group, groupModel]);

  useEffect(() => {
    if (user) {
      return;
    }

    setUser(userModelOrPublic as UserModel|undefined);
  }, [user, userModelOrPublic]);

  useEffect(() => {
    if (params.userId !== userId || params.groupId !== groupId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { userId, groupId }));
    }
  }, [userId, groupId, params, setParams]);

  const providerValue = {
    group,
    user,
    cities,
    towns,
    selectedCity,
    selectedTown,
    isEditingGroup,
    setGroup,
    setUser,
    setIsEditingGroup,
  };

  return (
    <AdminGroupEditWithUserContext.Provider value={providerValue}>
      {children}
    </AdminGroupEditWithUserContext.Provider>
  );
}
