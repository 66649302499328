import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { NoticeRequestsSearch, NoticeResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';

type Props = {
  children: ReactNode;
};

interface NoticesValue {
  noticesList: Array<NoticeResponsesWithPager>;
  noticesPageCurrent: number;
  noticesPageLast: number;
  setNoticeRequestsSearch: (noticeRequestsSearch: NoticeRequestsSearch) => void;
  setNoticesPageCurrent: (noticesPageCurrent: number) => void;
}

const NoticesContext = React.createContext<NoticesValue | null>(null);

export function useNotices(): NoticesValue {
  const state = useContext(NoticesContext);

  if (!state) {
    throw new Error('useNotices must be used within NoticesProvider');
  }

  return state;
}

export function NoticesProvider({ children }: Props) {
  const { searchNotices } = useLocalbridgeNoticeApi();

  const firstNoticeRequestsSearch: NoticeRequestsSearch = {
    size: 10,
    shouldShowNotHidden: true,
  };

  const [ noticeRequestsSearch, setNoticeRequestsSearch ] = useState<NoticeRequestsSearch>(firstNoticeRequestsSearch);
  const [ noticeRequestsSearchTmp, setNoticeRequestsSearchTmp ] = useState<NoticeRequestsSearch>(firstNoticeRequestsSearch);
  const [ noticesList, setNoticesList ] = useState<Array<NoticeResponsesWithPager>>([]);
  const [ noticesPageCurrent, setNoticesPageCurrent ] = useState<number>(0);
  const [ noticesPageLast, setNoticesPageLast ] = useState<number>(0);

  const searchNoticesList = useCallback(async () => {
    if (!noticeRequestsSearch) {
      return;
    }

    try {
      const noticesListTmp = await searchNotices(noticeRequestsSearch);
      const [noticesWithPager] = noticesListTmp;

      if (noticesWithPager?.pager) {
        const { totalCount, size } = noticesWithPager.pager;
        setNoticesPageLast(Math.floor((totalCount - 1) / size) + 1);
      }
      setNoticesList(noticesListTmp);
    } catch (error) {
      console.log(error);
    }
  }, [noticeRequestsSearch, searchNotices, setNoticesList]);

  useEffect(() => {
    searchNoticesList();
  }, [searchNoticesList]);

  const providerValue = {    
    noticesList,
    noticeRequestsSearchTmp,
    noticesPageCurrent,
    noticesPageLast,
    setNoticeRequestsSearch,
    setNoticeRequestsSearchTmp,
    setNoticesPageCurrent,
  };

  return (
    <NoticesContext.Provider value={providerValue}>
      {children}
    </NoticesContext.Provider>
  );
}
