import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { AboutNavbar } from '../components/navbar';

type Props = {};

const AboutPassportRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title='ふるさとパスポートとは？' enTitle='About' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <AboutNavbar />
        <section className={'passport'}>
          <div className={'passport__inner'}>
            <h2 className={'passport__title'}>
              ふるさとパスポートは、あなたがその地域に繋がりを持ち、
              <br className='is-pc' />
              「地方創生」に関わる活動へ参加したことを証明するデジタルパスポートです
            </h2>
            <p className={'passport__lead'}>
              Local Bridge内のイベントへの参加や体験した内容や街の魅力を記事として執筆することで、その地域の「ふるさとパスポート」を取得することができます。
              <br />
              また、ふるさとパスポートに紐づく貢献度バッジを集めることで、ふるさとパスポートが進化し、「交流人口」から「関係人口」へと自治体からも関係性の深い人であると認識され、特別なイベントに参加できるようになったり、あなたの力を必要とする人からオファーが届くなど、地域とのつながりを深めていくことができます。 あなたが日本各地で取り組んだ地域活性化の活動への貢献度がひと目でわかる勲章として、生涯に渡って証明し続けられる。これが「ふるさとパスポート」です。
            </p>
            <div className={'passport__img'}>
              <picture>
                <source media='(max-width: 769px)' srcSet='/assets/img/about/passport/pic_img01_sp.jpg' />
                <img src='/assets/img/about/passport/pic_img01.png' alt='' />
              </picture>
            </div>
            <section className={'passport_badge'}>
              <h3 className={'passport_badge__title'}>貢献度バッジの種類</h3>
              <div className={'passport_badge__contents'}>
                <div className={'passport_badge__texts'}>
                  <p className={'passport_badge__text'}>手に入れたふるさとパスポートは、あなたのLocal Bridgeサイトでのアイコンとなります。このアイコンは、他の人があなたのマイページを訪れた時に表示されたり、あなたが執筆した記事にも表示されます。</p>
                  <p className={'passport_badge__text'}>ふるさとパスポートは複数の地域のものを持つことができます。もし複数の地域のふるさとパスポートを持っている場合、どの地域のものをアイコンにするか、自由に選ぶ事ができます。ふるさとパスポートのデザインは地域によって異なるので、各地域の特徴を楽しむことができます。</p>
                </div>
                <div className={'passport_badge__img'}>
                  <img src='/assets/img/about/passport/pic_img02.png' alt='' />
                </div>
              </div>
            </section>
            <div className={'passport_group'}>
              <p className={'passport_group__text'}>自分のお気に入りの地域のふるさとパスポートを手に入れ進化させて、仲間に自慢しましょう！</p>
              <div className={'passport_group__btn'}>
                <a href='#'>ふるさとパスポートを取得する</a>
              </div>
            </div>
          </div>
        </section>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default AboutPassportRenderer;
