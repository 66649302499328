/* tslint:disable */
/* eslint-disable */
/**
 * Localbridge API Document
 * # はじめに - このドキュメントはアルファ版です。 - 今後APIの仕様が変更される場合があります。 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * ### 機関 
 * @export
 * @interface AssociationModelV1
 */
export interface AssociationModelV1 {
    /**
     * ### 機関コード 
     * @type {string}
     * @memberof AssociationModelV1
     */
    'id': string;
    /**
     * ### 都道府県名 
     * @type {string}
     * @memberof AssociationModelV1
     */
    'cityName': string;
    /**
     * ### 機関名 
     * @type {string}
     * @memberof AssociationModelV1
     */
    'associationName': string;
    /**
     * ### 機関のふりがな 
     * @type {string}
     * @memberof AssociationModelV1
     */
    'cityFurigana': string;
    /**
     * ### 機関のふりがな 
     * @type {string}
     * @memberof AssociationModelV1
     */
    'associationFurigana': string;
    /**
     * 
     * @type {AssociationTypeV1}
     * @memberof AssociationModelV1
     */
    'type': AssociationTypeV1;
}


/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface AssociationModelV1AllOf
 */
export interface AssociationModelV1AllOf {
    /**
     * ### 機関コード 
     * @type {string}
     * @memberof AssociationModelV1AllOf
     */
    'id'?: string;
    /**
     * ### 都道府県名 
     * @type {string}
     * @memberof AssociationModelV1AllOf
     */
    'cityName'?: string;
    /**
     * ### 機関名 
     * @type {string}
     * @memberof AssociationModelV1AllOf
     */
    'associationName'?: string;
    /**
     * ### 機関のふりがな 
     * @type {string}
     * @memberof AssociationModelV1AllOf
     */
    'cityFurigana'?: string;
    /**
     * ### 機関のふりがな 
     * @type {string}
     * @memberof AssociationModelV1AllOf
     */
    'associationFurigana'?: string;
    /**
     * 
     * @type {AssociationTypeV1}
     * @memberof AssociationModelV1AllOf
     */
    'type'?: AssociationTypeV1;
}


/**
 * 
 * @export
 * @interface AssociationRequestsSearchV1
 */
export interface AssociationRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof AssociationRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof AssociationRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof AssociationRequestsSearchV1
     */
    'cityName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationRequestsSearchV1
     */
    'associationIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AssociationRequestsSearchV1
     */
    'areOnlyTowns'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssociationRequestsSearchV1
     */
    'areOnlyCities'?: boolean;
}
/**
 * 
 * @export
 * @interface AssociationResponsesWithPagerV1
 */
export interface AssociationResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof AssociationResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<AssociationModelV1>}
     * @memberof AssociationResponsesWithPagerV1
     */
    'data': Array<AssociationModelV1>;
}
/**
 * ### 機関のタイプ 
 * @export
 * @enum {string}
 */

export const AssociationTypeV1 = {
    City: 'City',
    Town: 'Town',
    Others: 'Others'
} as const;

export type AssociationTypeV1 = typeof AssociationTypeV1[keyof typeof AssociationTypeV1];


/**
 * ### 機関 
 * @export
 * @interface BannerModelV1
 */
export interface BannerModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BannerModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof BannerModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BannerModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof BannerModelV1
     */
    'updatedAt': number;
    /**
     * ### 広告主名 
     * @type {string}
     * @memberof BannerModelV1
     */
    'ownerName': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof BannerModelV1
     */
    'mainPhoto': PhotoMetaV1;
    /**
     * ### バナーのリンク先 
     * @type {string}
     * @memberof BannerModelV1
     */
    'link'?: string;
    /**
     * ### お知らせを隠す - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof BannerModelV1
     */
    'isHidden': boolean | null;
}
/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface BannerModelV1AllOf
 */
export interface BannerModelV1AllOf {
    /**
     * ### 広告主名 
     * @type {string}
     * @memberof BannerModelV1AllOf
     */
    'ownerName'?: string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof BannerModelV1AllOf
     */
    'mainPhoto'?: PhotoMetaV1;
    /**
     * ### バナーのリンク先 
     * @type {string}
     * @memberof BannerModelV1AllOf
     */
    'link'?: string;
    /**
     * ### お知らせを隠す - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof BannerModelV1AllOf
     */
    'isHidden'?: boolean | null;
}
/**
 * 
 * @export
 * @interface BannerRequestCreateV1
 */
export interface BannerRequestCreateV1 {
    /**
     * 
     * @type {BannerModelV1}
     * @memberof BannerRequestCreateV1
     */
    'bannerModel': BannerModelV1;
}
/**
 * ### バナーのリクエスト 
 * @export
 * @interface BannerRequestReadV1
 */
export interface BannerRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BannerRequestReadV1
     */
    'bannerId': string;
}
/**
 * 
 * @export
 * @interface BannerRequestUpdateV1
 */
export interface BannerRequestUpdateV1 {
    /**
     * 
     * @type {BannerModelV1}
     * @memberof BannerRequestUpdateV1
     */
    'bannerModel': BannerModelV1;
}
/**
 * ### バナーのリクエスト 
 * @export
 * @interface BannerRequestValidateV1
 */
export interface BannerRequestValidateV1 {
    /**
     * 
     * @type {BannerModelV1}
     * @memberof BannerRequestValidateV1
     */
    'bannerModel': BannerModelV1;
}
/**
 * ### バナー作成リクエスト 
 * @export
 * @interface BannerRequestsCreateV1
 */
export interface BannerRequestsCreateV1 {
    /**
     * 
     * @type {Array<BannerRequestCreateV1>}
     * @memberof BannerRequestsCreateV1
     */
    'bannerRequestCreates'?: Array<BannerRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface BannerRequestsDeleteV1
 */
export interface BannerRequestsDeleteV1 {
    /**
     * ### 探すバナーのID 
     * @type {Array<string>}
     * @memberof BannerRequestsDeleteV1
     */
    'bannerIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface BannerRequestsSearchV1
 */
export interface BannerRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof BannerRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof BannerRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BannerRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BannerRequestsSearchV1
     */
    'createdTo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BannerRequestsSearchV1
     */
    'shouldShowNotHidden'?: boolean;
}
/**
 * ### バナー更新リクエスト 
 * @export
 * @interface BannerRequestsUpdateV1
 */
export interface BannerRequestsUpdateV1 {
    /**
     * 
     * @type {Array<BannerRequestUpdateV1>}
     * @memberof BannerRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<BannerRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface BannerRequestsValidateV1
 */
export interface BannerRequestsValidateV1 {
    /**
     * 
     * @type {Array<BannerRequestValidateV1>}
     * @memberof BannerRequestsValidateV1
     */
    'bannerRequests': Array<BannerRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof BannerRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### バナーのレスポンス 
 * @export
 * @interface BannerResponseReadV1
 */
export interface BannerResponseReadV1 {
    /**
     * 
     * @type {BannerModelV1}
     * @memberof BannerResponseReadV1
     */
    'bannerModel': BannerModelV1;
}
/**
 * 
 * @export
 * @interface BannerResponsesWithPagerV1
 */
export interface BannerResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof BannerResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<BannerModelV1>}
     * @memberof BannerResponsesWithPagerV1
     */
    'data': Array<BannerModelV1>;
}
/**
 * ### ワークショップ予約 
 * @export
 * @interface BookingModelV1
 */
export interface BookingModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof BookingModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BookingModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof BookingModelV1
     */
    'updatedAt': number;
    /**
     * ### ユーザーニックネーム名 
     * @type {string}
     * @memberof BookingModelV1
     */
    'nickName': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof BookingModelV1
     */
    'icon'?: PhotoMetaV1;
    /**
     * 
     * @type {UserPublicV1StudentGrade}
     * @memberof BookingModelV1
     */
    'studentGrade'?: UserPublicV1StudentGrade;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingModelV1
     */
    'interests'?: Array<string>;
    /**
     * ### ユーザーの説明 
     * @type {string}
     * @memberof BookingModelV1
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingModelV1
     */
    'localAssociationIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserLocalCategoryV1>}
     * @memberof BookingModelV1
     */
    'localCategorys'?: Array<UserLocalCategoryV1>;
    /**
     * 
     * @type {UserJobV1}
     * @memberof BookingModelV1
     */
    'job': UserJobV1;
    /**
     * ### ユーザーの教育機関名 
     * @type {string}
     * @memberof BookingModelV1
     */
    'educationalInstitutionName'?: string;
    /**
     * ### ユーザーの教育機関の学部 
     * @type {string}
     * @memberof BookingModelV1
     */
    'department'?: string;
    /**
     * ### 学生ユーザーの入学年月 
     * @type {Array<any>}
     * @memberof BookingModelV1
     */
    'studentStartAt'?: Array<any>;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof BookingModelV1
     */
    'name': string;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof BookingModelV1
     */
    'nameFurigana': string;
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof BookingModelV1
     */
    'email': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof BookingModelV1
     */
    'emailToken': SearchTokenV1 | null;
    /**
     * 
     * @type {UserGenderV1}
     * @memberof BookingModelV1
     */
    'gender': UserGenderV1;
    /**
     * ### ユーザーの電話番号 
     * @type {string}
     * @memberof BookingModelV1
     */
    'phoneNumber'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'townId'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof BookingModelV1
     */
    'detailAddress1'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof BookingModelV1
     */
    'detailAddress2'?: string;
    /**
     * ### ユーザーの郵便番号 
     * @type {string}
     * @memberof BookingModelV1
     */
    'postNumber'?: string;
    /**
     * ### ユーザーの生年月日 
     * @type {Array<number>}
     * @memberof BookingModelV1
     */
    'birthDay'?: Array<number>;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'userId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'workshopId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1
     */
    'groupId': string;
    /**
     * ### ワークショップで知りたいこと 
     * @type {string}
     * @memberof BookingModelV1
     */
    'keyPoint'?: string;
    /**
     * 
     * @type {BookingSourceV1}
     * @memberof BookingModelV1
     */
    'source': BookingSourceV1;
    /**
     * ### その他、事務局への連絡事項 
     * @type {string}
     * @memberof BookingModelV1
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingModelV1
     */
    'purposes'?: Array<string>;
}


/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface BookingModelV1AllOf
 */
export interface BookingModelV1AllOf {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1AllOf
     */
    'userId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1AllOf
     */
    'workshopId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingModelV1AllOf
     */
    'groupId'?: string;
    /**
     * ### ワークショップで知りたいこと 
     * @type {string}
     * @memberof BookingModelV1AllOf
     */
    'keyPoint'?: string;
    /**
     * 
     * @type {BookingSourceV1}
     * @memberof BookingModelV1AllOf
     */
    'source'?: BookingSourceV1;
    /**
     * ### その他、事務局への連絡事項 
     * @type {string}
     * @memberof BookingModelV1AllOf
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingModelV1AllOf
     */
    'purposes'?: Array<string>;
}


/**
 * 
 * @export
 * @interface BookingRequestCreateV1
 */
export interface BookingRequestCreateV1 {
    /**
     * 
     * @type {BookingModelV1}
     * @memberof BookingRequestCreateV1
     */
    'bookingModel': BookingModelV1;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestCreateV1
     */
    'emailConfirm'?: string;
}
/**
 * ### 予約のリクエスト 
 * @export
 * @interface BookingRequestReadV1
 */
export interface BookingRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingRequestReadV1
     */
    'bookingId': string;
}
/**
 * 
 * @export
 * @interface BookingRequestUpdateV1
 */
export interface BookingRequestUpdateV1 {
    /**
     * 
     * @type {BookingModelV1}
     * @memberof BookingRequestUpdateV1
     */
    'bookingModel': BookingModelV1;
}
/**
 * ### 予約のリクエスト 
 * @export
 * @interface BookingRequestValidateV1
 */
export interface BookingRequestValidateV1 {
    /**
     * 
     * @type {BookingModelV1}
     * @memberof BookingRequestValidateV1
     */
    'bookingModel': BookingModelV1;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestValidateV1
     */
    'emailConfirm'?: string;
}
/**
 * ### 予約作成リクエスト 
 * @export
 * @interface BookingRequestsCreateV1
 */
export interface BookingRequestsCreateV1 {
    /**
     * 
     * @type {Array<BookingRequestCreateV1>}
     * @memberof BookingRequestsCreateV1
     */
    'bookingRequestCreates'?: Array<BookingRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface BookingRequestsDeleteV1
 */
export interface BookingRequestsDeleteV1 {
    /**
     * ### 探す予約のID 
     * @type {Array<string>}
     * @memberof BookingRequestsDeleteV1
     */
    'bookingIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface BookingRequestsSearchV1
 */
export interface BookingRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof BookingRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof BookingRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof BookingRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 探すユーザーのID 
     * @type {string}
     * @memberof BookingRequestsSearchV1
     */
    'userId'?: string | null;
    /**
     * ### 探すワークショップのID 
     * @type {string}
     * @memberof BookingRequestsSearchV1
     */
    'workshopId'?: string | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingRequestsSearchV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof BookingRequestsSearchV1
     */
    'townId'?: string;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BookingRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof BookingRequestsSearchV1
     */
    'createdTo'?: number;
}
/**
 * ### 予約更新リクエスト 
 * @export
 * @interface BookingRequestsUpdateV1
 */
export interface BookingRequestsUpdateV1 {
    /**
     * 
     * @type {Array<BookingRequestUpdateV1>}
     * @memberof BookingRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<BookingRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface BookingRequestsValidateV1
 */
export interface BookingRequestsValidateV1 {
    /**
     * 
     * @type {Array<BookingRequestValidateV1>}
     * @memberof BookingRequestsValidateV1
     */
    'bookingRequests': Array<BookingRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof BookingRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### 予約のレスポンス 
 * @export
 * @interface BookingResponseReadV1
 */
export interface BookingResponseReadV1 {
    /**
     * 
     * @type {BookingModelV1}
     * @memberof BookingResponseReadV1
     */
    'bookingModel': BookingModelV1;
}
/**
 * 
 * @export
 * @interface BookingResponsesWithPagerV1
 */
export interface BookingResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof BookingResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<BookingModelV1>}
     * @memberof BookingResponsesWithPagerV1
     */
    'data': Array<BookingModelV1>;
}
/**
 * ### イベント申し込み：このイベントを何で知りましたか？のドロップダウンリスト 
 * @export
 * @enum {string}
 */

export const BookingSourceV1 = {
    Other: 'その他'
} as const;

export type BookingSourceV1 = typeof BookingSourceV1[keyof typeof BookingSourceV1];


/**
 * ### 基本データー 
 * @export
 * @interface CommonV1
 */
export interface CommonV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof CommonV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof CommonV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof CommonV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof CommonV1
     */
    'updatedAt': number;
}
/**
 * ### プロフィール 
 * @export
 * @interface DateV1
 */
export interface DateV1 {
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof DateV1
     */
    'createdAt'?: number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof DateV1
     */
    'updatedAt'?: number;
}
/**
 * ### 団体 
 * @export
 * @interface GroupModelV1
 */
export interface GroupModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof GroupModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof GroupModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof GroupModelV1
     */
    'updatedAt': number;
    /**
     * ### 団体名 
     * @type {string}
     * @memberof GroupModelV1
     */
    'name'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof GroupModelV1
     */
    'nameToken'?: SearchTokenV1 | null;
    /**
     * ### 団体の代表者のメールアドレス 
     * @type {string}
     * @memberof GroupModelV1
     */
    'groupEmail': string;
    /**
     * ### 団体ーの電話番号 
     * @type {string}
     * @memberof GroupModelV1
     */
    'groupPhoneNumber'?: string;
    /**
     * ### 団体の代表者名 
     * @type {string}
     * @memberof GroupModelV1
     */
    'delegateName': string;
    /**
     * ### 団体の担当者名(ふりがな)  
     * @type {string}
     * @memberof GroupModelV1
     */
    'delegateNameFurigana': string;
    /**
     * ### 団体の担当者のメールアドレス 
     * @type {string}
     * @memberof GroupModelV1
     */
    'managerEmail'?: string;
    /**
     * ### 団体の担当者名 
     * @type {string}
     * @memberof GroupModelV1
     */
    'managerName'?: string;
    /**
     * ### 団体の担当者の電話番号 
     * @type {string}
     * @memberof GroupModelV1
     */
    'managerPhoneNumber'?: string;
    /**
     * 
     * @type {GroupTypeV1}
     * @memberof GroupModelV1
     */
    'type'?: GroupTypeV1;
    /**
     * ### 団体ホームページ 
     * @type {string}
     * @memberof GroupModelV1
     */
    'homepage'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupModelV1
     */
    'cityId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupModelV1
     */
    'townId': string;
    /**
     * ### 団体の細部住所 
     * @type {string}
     * @memberof GroupModelV1
     */
    'detailAddress1': string;
    /**
     * ### 団体の細部住所 
     * @type {string}
     * @memberof GroupModelV1
     */
    'detailAddress2': string;
    /**
     * ### 団体の郵便番号 
     * @type {string}
     * @memberof GroupModelV1
     */
    'postNumber'?: string;
    /**
     * ### 書き込み権限を持っている団体のユーザーIDリスト 
     * @type {Array<string>}
     * @memberof GroupModelV1
     */
    'userIdsManagerRole': Array<string>;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1
     */
    'passportIcon'?: PhotoMetaV1;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1
     */
    'mainPhoto': PhotoMetaV1;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1
     */
    'icon': PhotoMetaV1;
    /**
     * ### 団体の説明 
     * @type {string}
     * @memberof GroupModelV1
     */
    'description': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1
     */
    'descriptionPhoto'?: PhotoMetaV1;
}


/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface GroupModelV1AllOf
 */
export interface GroupModelV1AllOf {
    /**
     * ### 団体名 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'name'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof GroupModelV1AllOf
     */
    'nameToken'?: SearchTokenV1 | null;
    /**
     * ### 団体の代表者のメールアドレス 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'groupEmail'?: string;
    /**
     * ### 団体ーの電話番号 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'groupPhoneNumber'?: string;
    /**
     * ### 団体の代表者名 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'delegateName'?: string;
    /**
     * ### 団体の担当者名(ふりがな)  
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'delegateNameFurigana'?: string;
    /**
     * ### 団体の担当者のメールアドレス 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'managerEmail'?: string;
    /**
     * ### 団体の担当者名 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'managerName'?: string;
    /**
     * ### 団体の担当者の電話番号 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'managerPhoneNumber'?: string;
    /**
     * 
     * @type {GroupTypeV1}
     * @memberof GroupModelV1AllOf
     */
    'type'?: GroupTypeV1;
    /**
     * ### 団体ホームページ 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'homepage'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'townId'?: string;
    /**
     * ### 団体の細部住所 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'detailAddress1'?: string;
    /**
     * ### 団体の細部住所 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'detailAddress2'?: string;
    /**
     * ### 団体の郵便番号 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'postNumber'?: string;
    /**
     * ### 書き込み権限を持っている団体のユーザーIDリスト 
     * @type {Array<string>}
     * @memberof GroupModelV1AllOf
     */
    'userIdsManagerRole'?: Array<string>;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1AllOf
     */
    'passportIcon'?: PhotoMetaV1;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1AllOf
     */
    'mainPhoto'?: PhotoMetaV1;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1AllOf
     */
    'icon'?: PhotoMetaV1;
    /**
     * ### 団体の説明 
     * @type {string}
     * @memberof GroupModelV1AllOf
     */
    'description'?: string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof GroupModelV1AllOf
     */
    'descriptionPhoto'?: PhotoMetaV1;
}


/**
 * 
 * @export
 * @interface GroupRequestCreateV1
 */
export interface GroupRequestCreateV1 {
    /**
     * 
     * @type {GroupModelV1}
     * @memberof GroupRequestCreateV1
     */
    'groupModel': GroupModelV1;
}
/**
 * ### 団体取得のリクエスト 
 * @export
 * @interface GroupRequestReadV1
 */
export interface GroupRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupRequestReadV1
     */
    'groupId': string;
}
/**
 * 
 * @export
 * @interface GroupRequestUpdateV1
 */
export interface GroupRequestUpdateV1 {
    /**
     * 
     * @type {GroupModelV1}
     * @memberof GroupRequestUpdateV1
     */
    'groupModel': GroupModelV1;
}
/**
 * ### 団体のリクエスト 
 * @export
 * @interface GroupRequestValidateV1
 */
export interface GroupRequestValidateV1 {
    /**
     * 
     * @type {GroupModelV1}
     * @memberof GroupRequestValidateV1
     */
    'groupModel': GroupModelV1;
}
/**
 * ### 団体作成リクエスト 
 * @export
 * @interface GroupRequestsCreateV1
 */
export interface GroupRequestsCreateV1 {
    /**
     * 
     * @type {Array<GroupRequestCreateV1>}
     * @memberof GroupRequestsCreateV1
     */
    'groupRequestCreates'?: Array<GroupRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface GroupRequestsDeleteV1
 */
export interface GroupRequestsDeleteV1 {
    /**
     * ### 探す団体のID 
     * @type {Array<string>}
     * @memberof GroupRequestsDeleteV1
     */
    'groupIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface GroupRequestsSearchV1
 */
export interface GroupRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof GroupRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof GroupRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupRequestsSearchV1
     */
    'groupIds'?: Array<string>;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupRequestsSearchV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof GroupRequestsSearchV1
     */
    'townId'?: string;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof GroupRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof GroupRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof GroupRequestsSearchV1
     */
    'createdTo'?: number;
}
/**
 * ### 団体更新リクエスト 
 * @export
 * @interface GroupRequestsUpdateV1
 */
export interface GroupRequestsUpdateV1 {
    /**
     * 
     * @type {Array<GroupRequestUpdateV1>}
     * @memberof GroupRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<GroupRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface GroupRequestsValidateV1
 */
export interface GroupRequestsValidateV1 {
    /**
     * 
     * @type {Array<GroupRequestValidateV1>}
     * @memberof GroupRequestsValidateV1
     */
    'groupRequests': Array<GroupRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof GroupRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### 団体取得のレスポンス 
 * @export
 * @interface GroupResponseReadV1
 */
export interface GroupResponseReadV1 {
    /**
     * 
     * @type {GroupModelV1}
     * @memberof GroupResponseReadV1
     */
    'groupModel': GroupModelV1;
}
/**
 * 
 * @export
 * @interface GroupResponsesWithPagerV1
 */
export interface GroupResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof GroupResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<GroupModelV1>}
     * @memberof GroupResponsesWithPagerV1
     */
    'data': Array<GroupModelV1>;
}
/**
 * ### 団体の区分 
 * @export
 * @enum {string}
 */

export const GroupTypeV1 = {
    Association: '自治体',
    Company: '企業',
    University: '大学',
    HighSchool: '高等学校',
    SchoolCircle: '学生団体',
    Other: 'その他'
} as const;

export type GroupTypeV1 = typeof GroupTypeV1[keyof typeof GroupTypeV1];


/**
 * ### 高等学校の学人 
 * @export
 * @enum {string}
 */

export const HighSchoolStudentGradeV1 = {
    HighScrool1: '高校1年生',
    HighScrool2: '高校2年生',
    HighScrool3: '高校3年生'
} as const;

export type HighSchoolStudentGradeV1 = typeof HighSchoolStudentGradeV1[keyof typeof HighSchoolStudentGradeV1];


/**
 * 
 * @export
 * @interface LocalbridgePostUpdateUserReactionEnvV1
 */
export interface LocalbridgePostUpdateUserReactionEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgePostUpdateUserReactionEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgePostUpdateUserReactionEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LocalbridgePostUpdateUserReactionOperationV1 = {
    ArrayUnion: 'arrayUnion',
    ArrayRemove: 'arrayRemove'
} as const;

export type LocalbridgePostUpdateUserReactionOperationV1 = typeof LocalbridgePostUpdateUserReactionOperationV1[keyof typeof LocalbridgePostUpdateUserReactionOperationV1];


/**
 * 
 * @export
 * @interface LocalbridgePostUpdateUserReactionParamsV1
 */
export interface LocalbridgePostUpdateUserReactionParamsV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof LocalbridgePostUpdateUserReactionParamsV1
     */
    'postId': string;
    /**
     * 
     * @type {LocalbridgePostUpdateUserReactionOperationV1}
     * @memberof LocalbridgePostUpdateUserReactionParamsV1
     */
    'operation': LocalbridgePostUpdateUserReactionOperationV1;
    /**
     * 
     * @type {UserReactionNullableV1}
     * @memberof LocalbridgePostUpdateUserReactionParamsV1
     */
    'userReaction': UserReactionNullableV1 | null;
}


/**
 * ### パスワード再設定リクエスト 
 * @export
 * @interface LocalbridgePostUpdateUserReactionRequestV1
 */
export interface LocalbridgePostUpdateUserReactionRequestV1 {
    /**
     * 
     * @type {LocalbridgePostUpdateUserReactionEnvV1}
     * @memberof LocalbridgePostUpdateUserReactionRequestV1
     */
    'env': LocalbridgePostUpdateUserReactionEnvV1;
    /**
     * 
     * @type {LocalbridgePostUpdateUserReactionParamsV1}
     * @memberof LocalbridgePostUpdateUserReactionRequestV1
     */
    'params': LocalbridgePostUpdateUserReactionParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeQnaSendMailEnvV1
 */
export interface LocalbridgeQnaSendMailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeQnaSendMailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeQnaSendMailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeQnaSendMailParamsV1
 */
export interface LocalbridgeQnaSendMailParamsV1 {
    /**
     * 
     * @type {QnaMailV1}
     * @memberof LocalbridgeQnaSendMailParamsV1
     */
    'qnaMail': QnaMailV1;
    /**
     * 
     * @type {boolean}
     * @memberof LocalbridgeQnaSendMailParamsV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### メール認証リクエスト 
 * @export
 * @interface LocalbridgeQnaSendMailRequestV1
 */
export interface LocalbridgeQnaSendMailRequestV1 {
    /**
     * 
     * @type {LocalbridgeQnaSendMailEnvV1}
     * @memberof LocalbridgeQnaSendMailRequestV1
     */
    'env': LocalbridgeQnaSendMailEnvV1;
    /**
     * 
     * @type {LocalbridgeQnaSendMailParamsV1}
     * @memberof LocalbridgeQnaSendMailRequestV1
     */
    'params': LocalbridgeQnaSendMailParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeSignInTestSignInTypeEnvV1
 */
export interface LocalbridgeSignInTestSignInTypeEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeSignInTestSignInTypeEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeSignInTestSignInTypeEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeSignInTestSignInTypeParamsV1
 */
export interface LocalbridgeSignInTestSignInTypeParamsV1 {
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof LocalbridgeSignInTestSignInTypeParamsV1
     */
    'email': string;
    /**
     * 
     * @type {SignInTypeV1}
     * @memberof LocalbridgeSignInTestSignInTypeParamsV1
     */
    'signInType': SignInTypeV1;
}


/**
 * ### メール認証リクエスト 
 * @export
 * @interface LocalbridgeSignInTestSignInTypeRequestV1
 */
export interface LocalbridgeSignInTestSignInTypeRequestV1 {
    /**
     * 
     * @type {LocalbridgeSignInTestSignInTypeEnvV1}
     * @memberof LocalbridgeSignInTestSignInTypeRequestV1
     */
    'env': LocalbridgeSignInTestSignInTypeEnvV1;
    /**
     * 
     * @type {LocalbridgeSignInTestSignInTypeParamsV1}
     * @memberof LocalbridgeSignInTestSignInTypeRequestV1
     */
    'params': LocalbridgeSignInTestSignInTypeParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeSignUpEmailVarificationSendMailEnvV1
 */
export interface LocalbridgeSignUpEmailVarificationSendMailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeSignUpEmailVarificationSendMailParamsV1
 */
export interface LocalbridgeSignUpEmailVarificationSendMailParamsV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailParamsV1
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailParamsV1
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailParamsV1
     */
    'route': string;
}
/**
 * ### メール認証リクエスト 
 * @export
 * @interface LocalbridgeSignUpEmailVarificationSendMailRequestV1
 */
export interface LocalbridgeSignUpEmailVarificationSendMailRequestV1 {
    /**
     * 
     * @type {LocalbridgeSignUpEmailVarificationSendMailEnvV1}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailRequestV1
     */
    'env': LocalbridgeSignUpEmailVarificationSendMailEnvV1;
    /**
     * 
     * @type {LocalbridgeSignUpEmailVarificationSendMailParamsV1}
     * @memberof LocalbridgeSignUpEmailVarificationSendMailRequestV1
     */
    'params': LocalbridgeSignUpEmailVarificationSendMailParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserEnvV1
 */
export interface LocalbridgeUserEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUserEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserGetUserIdByEmailEnvV1
 */
export interface LocalbridgeUserGetUserIdByEmailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserGetUserIdByEmailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUserGetUserIdByEmailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserGetUserIdByEmailParamsV1
 */
export interface LocalbridgeUserGetUserIdByEmailParamsV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserGetUserIdByEmailParamsV1
     */
    'userEmail': string;
}
/**
 * ### 既に登録されたメールアドレスか確認 
 * @export
 * @interface LocalbridgeUserGetUserIdByEmailRequestV1
 */
export interface LocalbridgeUserGetUserIdByEmailRequestV1 {
    /**
     * 
     * @type {LocalbridgeUserGetUserIdByEmailEnvV1}
     * @memberof LocalbridgeUserGetUserIdByEmailRequestV1
     */
    'env': LocalbridgeUserGetUserIdByEmailEnvV1;
    /**
     * 
     * @type {LocalbridgeUserGetUserIdByEmailParamsV1}
     * @memberof LocalbridgeUserGetUserIdByEmailRequestV1
     */
    'params': LocalbridgeUserGetUserIdByEmailParamsV1;
}
/**
 * ### ユーザーID取得のレスポンス 
 * @export
 * @interface LocalbridgeUserGetUserIdByEmailResponseV1
 */
export interface LocalbridgeUserGetUserIdByEmailResponseV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof LocalbridgeUserGetUserIdByEmailResponseV1
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserParamsV1
 */
export interface LocalbridgeUserParamsV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof LocalbridgeUserParamsV1
     */
    'userId': string;
}
/**
 * ### ユーザー取得のリクエスト 
 * @export
 * @interface LocalbridgeUserRequestV1
 */
export interface LocalbridgeUserRequestV1 {
    /**
     * 
     * @type {LocalbridgeUserEnvV1}
     * @memberof LocalbridgeUserRequestV1
     */
    'env': LocalbridgeUserEnvV1;
    /**
     * 
     * @type {LocalbridgeUserParamsV1}
     * @memberof LocalbridgeUserRequestV1
     */
    'params': LocalbridgeUserParamsV1;
}
/**
 * ### ユーザー取得のレスポンス 
 * @export
 * @interface LocalbridgeUserResponseV1
 */
export interface LocalbridgeUserResponseV1 {
    /**
     * 
     * @type {UserPublicV1}
     * @memberof LocalbridgeUserResponseV1
     */
    'userPublic': UserPublicV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserSyncEmailEnvV1
 */
export interface LocalbridgeUserSyncEmailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserSyncEmailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUserSyncEmailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserSyncEmailParamsV1
 */
export interface LocalbridgeUserSyncEmailParamsV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserSyncEmailParamsV1
     */
    'userId': string;
}
/**
 * ### ユーザーデーターをシンクリクエスト(Auth -> Firestore) 
 * @export
 * @interface LocalbridgeUserSyncEmailRequestV1
 */
export interface LocalbridgeUserSyncEmailRequestV1 {
    /**
     * 
     * @type {LocalbridgeUserSyncEmailEnvV1}
     * @memberof LocalbridgeUserSyncEmailRequestV1
     */
    'env': LocalbridgeUserSyncEmailEnvV1;
    /**
     * 
     * @type {LocalbridgeUserSyncEmailParamsV1}
     * @memberof LocalbridgeUserSyncEmailRequestV1
     */
    'params': LocalbridgeUserSyncEmailParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserUpdateEmailVarificationSendMailEnvV1
 */
export interface LocalbridgeUserUpdateEmailVarificationSendMailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserUpdateEmailVarificationSendMailParamsV1
 */
export interface LocalbridgeUserUpdateEmailVarificationSendMailParamsV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailParamsV1
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailParamsV1
     */
    'newEmail': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailParamsV1
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailParamsV1
     */
    'route': string;
}
/**
 * ### メール認証リクエスト 
 * @export
 * @interface LocalbridgeUserUpdateEmailVarificationSendMailRequestV1
 */
export interface LocalbridgeUserUpdateEmailVarificationSendMailRequestV1 {
    /**
     * 
     * @type {LocalbridgeUserUpdateEmailVarificationSendMailEnvV1}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailRequestV1
     */
    'env': LocalbridgeUserUpdateEmailVarificationSendMailEnvV1;
    /**
     * 
     * @type {LocalbridgeUserUpdateEmailVarificationSendMailParamsV1}
     * @memberof LocalbridgeUserUpdateEmailVarificationSendMailRequestV1
     */
    'params': LocalbridgeUserUpdateEmailVarificationSendMailParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserUpdatePasswordResetSendMailEnvV1
 */
export interface LocalbridgeUserUpdatePasswordResetSendMailEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUserUpdatePasswordResetSendMailParamsV1
 */
export interface LocalbridgeUserUpdatePasswordResetSendMailParamsV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailParamsV1
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailParamsV1
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailParamsV1
     */
    'route': string;
}
/**
 * ### パスワード再設定リクエスト 
 * @export
 * @interface LocalbridgeUserUpdatePasswordResetSendMailRequestV1
 */
export interface LocalbridgeUserUpdatePasswordResetSendMailRequestV1 {
    /**
     * 
     * @type {LocalbridgeUserUpdatePasswordResetSendMailEnvV1}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailRequestV1
     */
    'env': LocalbridgeUserUpdatePasswordResetSendMailEnvV1;
    /**
     * 
     * @type {LocalbridgeUserUpdatePasswordResetSendMailParamsV1}
     * @memberof LocalbridgeUserUpdatePasswordResetSendMailRequestV1
     */
    'params': LocalbridgeUserUpdatePasswordResetSendMailParamsV1;
}
/**
 * 
 * @export
 * @interface LocalbridgeUsersCreateUserByServiceManagerEnvV1
 */
export interface LocalbridgeUsersCreateUserByServiceManagerEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUsersCreateUserByServiceManagerParamsV1
 */
export interface LocalbridgeUsersCreateUserByServiceManagerParamsV1 {
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerParamsV1
     */
    'email': string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerParamsV1
     */
    'password': string;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerParamsV1
     */
    'displayName': string;
}
/**
 * ### パスワード再設定リクエスト 
 * @export
 * @interface LocalbridgeUsersCreateUserByServiceManagerRequestV1
 */
export interface LocalbridgeUsersCreateUserByServiceManagerRequestV1 {
    /**
     * 
     * @type {LocalbridgeUsersCreateUserByServiceManagerEnvV1}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerRequestV1
     */
    'env': LocalbridgeUsersCreateUserByServiceManagerEnvV1;
    /**
     * 
     * @type {LocalbridgeUsersCreateUserByServiceManagerParamsV1}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerRequestV1
     */
    'params': LocalbridgeUsersCreateUserByServiceManagerParamsV1;
}
/**
 * ### ユーザー取得のレスポンス 
 * @export
 * @interface LocalbridgeUsersCreateUserByServiceManagerResponseV1
 */
export interface LocalbridgeUsersCreateUserByServiceManagerResponseV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof LocalbridgeUsersCreateUserByServiceManagerResponseV1
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface LocalbridgeUsersEnvV1
 */
export interface LocalbridgeUsersEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeUsersEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeUsersEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @interface LocalbridgeUsersParamsV1
 */
export interface LocalbridgeUsersParamsV1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof LocalbridgeUsersParamsV1
     */
    'userIds': Array<string>;
}
/**
 * ### ユーザー一覧のリクエスト 
 * @export
 * @interface LocalbridgeUsersRequestV1
 */
export interface LocalbridgeUsersRequestV1 {
    /**
     * 
     * @type {LocalbridgeUsersEnvV1}
     * @memberof LocalbridgeUsersRequestV1
     */
    'env': LocalbridgeUsersEnvV1;
    /**
     * 
     * @type {LocalbridgeUsersParamsV1}
     * @memberof LocalbridgeUsersRequestV1
     */
    'params': LocalbridgeUsersParamsV1;
}
/**
 * ### ユーザー一覧のレスポンス 
 * @export
 * @interface LocalbridgeUsersResponseV1
 */
export interface LocalbridgeUsersResponseV1 {
    /**
     * 
     * @type {Array<UserPublicV1>}
     * @memberof LocalbridgeUsersResponseV1
     */
    'userPublics': Array<UserPublicV1>;
}
/**
 * 
 * @export
 * @interface LocalbridgeWorkshopUpdateUserReactionEnvV1
 */
export interface LocalbridgeWorkshopUpdateUserReactionEnvV1 {
    /**
     * 
     * @type {string}
     * @memberof LocalbridgeWorkshopUpdateUserReactionEnvV1
     */
    'LOCALBRIDGE_PATH': string;
    /**
     * 
     * @type {object}
     * @memberof LocalbridgeWorkshopUpdateUserReactionEnvV1
     */
    'FUNCTION_V': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LocalbridgeWorkshopUpdateUserReactionOperationV1 = {
    ArrayUnion: 'arrayUnion',
    ArrayRemove: 'arrayRemove'
} as const;

export type LocalbridgeWorkshopUpdateUserReactionOperationV1 = typeof LocalbridgeWorkshopUpdateUserReactionOperationV1[keyof typeof LocalbridgeWorkshopUpdateUserReactionOperationV1];


/**
 * 
 * @export
 * @interface LocalbridgeWorkshopUpdateUserReactionParamsV1
 */
export interface LocalbridgeWorkshopUpdateUserReactionParamsV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof LocalbridgeWorkshopUpdateUserReactionParamsV1
     */
    'workshopId': string;
    /**
     * 
     * @type {LocalbridgeWorkshopUpdateUserReactionOperationV1}
     * @memberof LocalbridgeWorkshopUpdateUserReactionParamsV1
     */
    'operation': LocalbridgeWorkshopUpdateUserReactionOperationV1;
    /**
     * 
     * @type {UserReactionNullableV1}
     * @memberof LocalbridgeWorkshopUpdateUserReactionParamsV1
     */
    'userReaction': UserReactionNullableV1 | null;
}


/**
 * ### パスワード再設定リクエスト 
 * @export
 * @interface LocalbridgeWorkshopUpdateUserReactionRequestV1
 */
export interface LocalbridgeWorkshopUpdateUserReactionRequestV1 {
    /**
     * 
     * @type {LocalbridgeWorkshopUpdateUserReactionEnvV1}
     * @memberof LocalbridgeWorkshopUpdateUserReactionRequestV1
     */
    'env': LocalbridgeWorkshopUpdateUserReactionEnvV1;
    /**
     * 
     * @type {LocalbridgeWorkshopUpdateUserReactionParamsV1}
     * @memberof LocalbridgeWorkshopUpdateUserReactionRequestV1
     */
    'params': LocalbridgeWorkshopUpdateUserReactionParamsV1;
}
/**
 * ### FE側でモダルでエラーメッセージを出したい場合 
 * @export
 * @enum {string}
 */

export const MessageModalV1 = {
    None: 'none',
    Info: 'info',
    Danger: 'danger',
    Warning: 'warning',
    Success: 'success'
} as const;

export type MessageModalV1 = typeof MessageModalV1[keyof typeof MessageModalV1];


/**
 * 
 * @export
 * @interface MessageResponseV1
 */
export interface MessageResponseV1 {
    /**
     * ### エラータイトル 
     * @type {string}
     * @memberof MessageResponseV1
     */
    'title': string;
    /**
     * ### エラーメッセージ 
     * @type {string}
     * @memberof MessageResponseV1
     */
    'message': string;
    /**
     * ### API の action 名 
     * @type {string}
     * @memberof MessageResponseV1
     */
    'operationId': string;
    /**
     * 
     * @type {MessageModalV1}
     * @memberof MessageResponseV1
     */
    'modal': MessageModalV1;
    /**
     * ### バリデーションエラーのメッセージ 
     * @type {Array<MessageValidationV1>}
     * @memberof MessageResponseV1
     */
    'validations'?: Array<MessageValidationV1>;
}


/**
 * ### Validation Message 
 * @export
 * @enum {string}
 */

export const MessageValidationMessageV1 = {
    EmptyInputMessage: '入力してください',
    ExistGroupNameMessage: 'すでに使用中の団体名です',
    StringIdMessage: '英数字又は(-),(_)だけ入力可能です',
    StringMin8Max16Message: '8 ~ 16文字まで入力可能です',
    StrongPasswordMessage: '半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください',
    StringMax80Message: '80文字まで入力可能です',
    StringMax200Message: '200文字まで入力可能です',
    StringMax1000Message: '1000文字まで入力可能です',
    StringMax40000Message: '40000文字まで入力可能です',
    StringWrongEmailFormatMessage: '正しいメールアドレスではありません',
    StringUnMatchedEmailMessage: '確認用のメールアドレスが一致しません',
    StringNotExistUserMessage: '存在しないユーザーです',
    StringExistUserEmailMessage: '既に登録されたメールアドレスです',
    StringExistGroupNameMessage: 'すでに存在する団体名です',
    StringUnMatchedPasswordMessage: 'パスワードが一致しません',
    StringUnMatchedPasswordConfirmMessage: 'パスワード確認が一致しません',
    StringUnMatchedPassword5TimesMessage: '連続して誤ったログインパスワードや暗証番号を入力されました。５分後に再入力してください。',
    StringNoSpecialCharacterMessage: '以下の文字は入力できません<br> \\ / : * ? \" < > |',
    DateStartAndEndMessage: '開始日は終了日より前を選択してくだたし',
    EmptyArrayWithString: '入力してください',
    SignInTypeMessage: '権限がありません'
} as const;

export type MessageValidationMessageV1 = typeof MessageValidationMessageV1[keyof typeof MessageValidationMessageV1];


/**
 * 
 * @export
 * @interface MessageValidationV1
 */
export interface MessageValidationV1 {
    /**
     * ### Id of Item 
     * @type {string}
     * @memberof MessageValidationV1
     */
    'id': string;
    /**
     * ### Class of Item(DB Table) 
     * @type {string}
     * @memberof MessageValidationV1
     */
    'className': string;
    /**
     * ### Key of Item 
     * @type {string}
     * @memberof MessageValidationV1
     */
    'key': string;
    /**
     * ### Validation Fail MessageResponse 
     * @type {Array<MessageValidationV1MessagesInner>}
     * @memberof MessageValidationV1
     */
    'messages'?: Array<MessageValidationV1MessagesInner>;
}
/**
 * @type MessageValidationV1MessagesInner
 * @export
 */
export type MessageValidationV1MessagesInner = MessageValidationMessageV1 | string;

/**
 * ### 機関 
 * @export
 * @interface NoticeModelV1
 */
export interface NoticeModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof NoticeModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof NoticeModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof NoticeModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof NoticeModelV1
     */
    'updatedAt': number;
    /**
     * ### お知らせのタイトル 
     * @type {string}
     * @memberof NoticeModelV1
     */
    'title': string;
    /**
     * ### お知らせのコンテンツ（文字列のみ） 
     * @type {string}
     * @memberof NoticeModelV1
     */
    'content': string;
    /**
     * ### お知らせを隠す - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof NoticeModelV1
     */
    'isHidden': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeModelV1
     */
    'tags'?: Array<string>;
}
/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface NoticeModelV1AllOf
 */
export interface NoticeModelV1AllOf {
    /**
     * ### お知らせのタイトル 
     * @type {string}
     * @memberof NoticeModelV1AllOf
     */
    'title'?: string;
    /**
     * ### お知らせのコンテンツ（文字列のみ） 
     * @type {string}
     * @memberof NoticeModelV1AllOf
     */
    'content'?: string;
    /**
     * ### お知らせを隠す - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof NoticeModelV1AllOf
     */
    'isHidden'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeModelV1AllOf
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NoticeRequestCreateV1
 */
export interface NoticeRequestCreateV1 {
    /**
     * 
     * @type {NoticeModelV1}
     * @memberof NoticeRequestCreateV1
     */
    'noticeModel': NoticeModelV1;
}
/**
 * ### お知らせのリクエスト 
 * @export
 * @interface NoticeRequestReadV1
 */
export interface NoticeRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof NoticeRequestReadV1
     */
    'noticeId': string;
}
/**
 * 
 * @export
 * @interface NoticeRequestUpdateV1
 */
export interface NoticeRequestUpdateV1 {
    /**
     * 
     * @type {NoticeModelV1}
     * @memberof NoticeRequestUpdateV1
     */
    'noticeModel': NoticeModelV1;
}
/**
 * ### お知らせのリクエスト 
 * @export
 * @interface NoticeRequestValidateV1
 */
export interface NoticeRequestValidateV1 {
    /**
     * 
     * @type {NoticeModelV1}
     * @memberof NoticeRequestValidateV1
     */
    'noticeModel': NoticeModelV1;
}
/**
 * ### お知らせ作成リクエスト 
 * @export
 * @interface NoticeRequestsCreateV1
 */
export interface NoticeRequestsCreateV1 {
    /**
     * 
     * @type {Array<NoticeRequestCreateV1>}
     * @memberof NoticeRequestsCreateV1
     */
    'noticeRequestCreates'?: Array<NoticeRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface NoticeRequestsDeleteV1
 */
export interface NoticeRequestsDeleteV1 {
    /**
     * ### 探すお知らせのID 
     * @type {Array<string>}
     * @memberof NoticeRequestsDeleteV1
     */
    'noticeIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface NoticeRequestsSearchV1
 */
export interface NoticeRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof NoticeRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof NoticeRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof NoticeRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof NoticeRequestsSearchV1
     */
    'createdTo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeRequestsSearchV1
     */
    'shouldShowNotHidden'?: boolean;
}
/**
 * ### お知らせ更新リクエスト 
 * @export
 * @interface NoticeRequestsUpdateV1
 */
export interface NoticeRequestsUpdateV1 {
    /**
     * 
     * @type {Array<NoticeRequestUpdateV1>}
     * @memberof NoticeRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<NoticeRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface NoticeRequestsValidateV1
 */
export interface NoticeRequestsValidateV1 {
    /**
     * 
     * @type {Array<NoticeRequestValidateV1>}
     * @memberof NoticeRequestsValidateV1
     */
    'noticeRequests': Array<NoticeRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### お知らせのレスポンス 
 * @export
 * @interface NoticeResponseReadV1
 */
export interface NoticeResponseReadV1 {
    /**
     * 
     * @type {NoticeModelV1}
     * @memberof NoticeResponseReadV1
     */
    'noticeModel': NoticeModelV1;
}
/**
 * 
 * @export
 * @interface NoticeResponsesWithPagerV1
 */
export interface NoticeResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof NoticeResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<NoticeModelV1>}
     * @memberof NoticeResponsesWithPagerV1
     */
    'data': Array<NoticeModelV1>;
}
/**
 * ### レスポンスのページネーション情報 - ページに含まれる対象の数は以下で求められる 
 * @export
 * @interface Pager
 */
export interface Pager {
    /**
     * ### ページ番号 
     * @type {number}
     * @memberof Pager
     */
    'page': number;
    /**
     * ### 1ページあたりの最大取得件数 
     * @type {number}
     * @memberof Pager
     */
    'size': number;
    /**
     * ### ページに含まれているものも含まれていないものも合わせた対象の全数 
     * @type {number}
     * @memberof Pager
     */
    'totalCount': number;
}
/**
 * ### パスポート 
 * @export
 * @interface PassportModelV1
 */
export interface PassportModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof PassportModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PassportModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof PassportModelV1
     */
    'updatedAt': number;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1
     */
    'userId': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PassportModelV1
     */
    'userIdToken': SearchTokenV1 | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1
     */
    'groupId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1
     */
    'groupCityId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1
     */
    'groupTownId': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof PassportModelV1
     */
    'icon': PhotoMetaV1;
}
/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface PassportModelV1AllOf
 */
export interface PassportModelV1AllOf {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1AllOf
     */
    'userId'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PassportModelV1AllOf
     */
    'userIdToken'?: SearchTokenV1 | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1AllOf
     */
    'groupId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1AllOf
     */
    'groupCityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportModelV1AllOf
     */
    'groupTownId'?: string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof PassportModelV1AllOf
     */
    'icon'?: PhotoMetaV1;
}
/**
 * 
 * @export
 * @interface PassportRequestCreateV1
 */
export interface PassportRequestCreateV1 {
    /**
     * 
     * @type {PassportModelV1}
     * @memberof PassportRequestCreateV1
     */
    'passportModel': PassportModelV1;
}
/**
 * ### パスポート取得のリクエスト 
 * @export
 * @interface PassportRequestReadV1
 */
export interface PassportRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportRequestReadV1
     */
    'passportId': string;
}
/**
 * 
 * @export
 * @interface PassportRequestUpdateV1
 */
export interface PassportRequestUpdateV1 {
    /**
     * 
     * @type {PassportModelV1}
     * @memberof PassportRequestUpdateV1
     */
    'passportModel': PassportModelV1;
}
/**
 * ### パスポートのリクエスト 
 * @export
 * @interface PassportRequestValidateV1
 */
export interface PassportRequestValidateV1 {
    /**
     * 
     * @type {PassportModelV1}
     * @memberof PassportRequestValidateV1
     */
    'passportModel': PassportModelV1;
}
/**
 * ### パスポート作成リクエスト 
 * @export
 * @interface PassportRequestsCreateV1
 */
export interface PassportRequestsCreateV1 {
    /**
     * 
     * @type {Array<PassportRequestCreateV1>}
     * @memberof PassportRequestsCreateV1
     */
    'passportRequestCreates'?: Array<PassportRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface PassportRequestsDeleteV1
 */
export interface PassportRequestsDeleteV1 {
    /**
     * ### 探すパスポートのID 
     * @type {Array<string>}
     * @memberof PassportRequestsDeleteV1
     */
    'passportIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface PassportRequestsSearchV1
 */
export interface PassportRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof PassportRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof PassportRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof PassportRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 探すユーザーのID 
     * @type {string}
     * @memberof PassportRequestsSearchV1
     */
    'userId'?: string | null;
    /**
     * ### 探す団体のID 
     * @type {string}
     * @memberof PassportRequestsSearchV1
     */
    'groupId'?: string | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportRequestsSearchV1
     */
    'groupCityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PassportRequestsSearchV1
     */
    'groupTownId'?: string;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PassportRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PassportRequestsSearchV1
     */
    'createdTo'?: number;
}
/**
 * ### パスポート更新リクエスト 
 * @export
 * @interface PassportRequestsUpdateV1
 */
export interface PassportRequestsUpdateV1 {
    /**
     * 
     * @type {Array<PassportRequestUpdateV1>}
     * @memberof PassportRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<PassportRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface PassportRequestsValidateV1
 */
export interface PassportRequestsValidateV1 {
    /**
     * 
     * @type {Array<PassportRequestValidateV1>}
     * @memberof PassportRequestsValidateV1
     */
    'passportRequests': Array<PassportRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof PassportRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### パスポート取得のレスポンス 
 * @export
 * @interface PassportResponseReadV1
 */
export interface PassportResponseReadV1 {
    /**
     * 
     * @type {PassportModelV1}
     * @memberof PassportResponseReadV1
     */
    'passportModel': PassportModelV1;
}
/**
 * 
 * @export
 * @interface PassportResponsesWithPagerV1
 */
export interface PassportResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof PassportResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<PassportModelV1>}
     * @memberof PassportResponsesWithPagerV1
     */
    'data': Array<PassportModelV1>;
}
/**
 * ### 画像のメタデーター 
 * @export
 * @interface PhotoMetaV1
 */
export interface PhotoMetaV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PhotoMetaV1
     */
    'id': string;
    /**
     * ### 画像ファイルのURL 
     * @type {string}
     * @memberof PhotoMetaV1
     */
    'url': string;
    /**
     * ### 画像ファイルの名 
     * @type {string}
     * @memberof PhotoMetaV1
     */
    'name': string;
    /**
     * ### 画像ファイルの容量 
     * @type {number}
     * @memberof PhotoMetaV1
     */
    'size': number;
    /**
     * ### 画像の横幅px 
     * @type {number}
     * @memberof PhotoMetaV1
     */
    'width': number;
    /**
     * ### 画像の縦幅px 
     * @type {number}
     * @memberof PhotoMetaV1
     */
    'height'?: number;
}
/**
 * ### 記事 
 * @export
 * @interface PostModelV1
 */
export interface PostModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof PostModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1
     */
    'updatedAt': number;
    /**
     * ### 記事のタイトル 
     * @type {string}
     * @memberof PostModelV1
     */
    'title': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PostModelV1
     */
    'titleToken': SearchTokenV1 | null;
    /**
     * ### 記事のコンテンツ（文字列のみ） 
     * @type {string}
     * @memberof PostModelV1
     */
    'content': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PostModelV1
     */
    'contentToken': SearchTokenV1 | null;
    /**
     * ### 記事のコンテンツ（レンダリング） 
     * @type {string}
     * @memberof PostModelV1
     */
    'contentData': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'cityId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'townId': string;
    /**
     * 
     * @type {PostTypeV1}
     * @memberof PostModelV1
     */
    'postType': PostTypeV1;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'groupId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'passportId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'userId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1
     */
    'workshopId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostModelV1
     */
    'tags': Array<string>;
    /**
     * ### 記事を隠す条件2 - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof PostModelV1
     */
    'isHidden': boolean | null;
    /**
     * ### 記事の承認を待っている 
     * @type {boolean}
     * @memberof PostModelV1
     */
    'isWaitingApproved': boolean;
    /**
     * ### 記事が承認された（記事を隠す条件2） - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof PostModelV1
     */
    'isApproved': boolean;
    /**
     * ### 記事を上端の高いイメージのリストに載せる（注目の記事） 
     * @type {boolean}
     * @memberof PostModelV1
     */
    'isInTopCarouselList': boolean;
    /**
     * ### 記事を一旦保存する - まだ作成中の。承認待ちではない。 
     * @type {boolean}
     * @memberof PostModelV1
     */
    'isFinishedWriting': boolean;
    /**
     * 
     * @type {UserReactionNullableV1}
     * @memberof PostModelV1
     */
    'userReaction'?: UserReactionNullableV1 | null;
    /**
     * ### ワークショップ開始日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1
     */
    'workshopStartAt': number;
    /**
     * ### ワークショップ終了日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1
     */
    'workshopEndAt': number;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof PostModelV1
     */
    'mainPhoto': PhotoMetaV1;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof PostModelV1
     */
    'contentPhotos': Array<PhotoMetaV1>;
}


/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface PostModelV1AllOf
 */
export interface PostModelV1AllOf {
    /**
     * ### 記事のタイトル 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'title'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PostModelV1AllOf
     */
    'titleToken'?: SearchTokenV1 | null;
    /**
     * ### 記事のコンテンツ（文字列のみ） 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'content'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof PostModelV1AllOf
     */
    'contentToken'?: SearchTokenV1 | null;
    /**
     * ### 記事のコンテンツ（レンダリング） 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'contentData'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'townId'?: string;
    /**
     * 
     * @type {PostTypeV1}
     * @memberof PostModelV1AllOf
     */
    'postType'?: PostTypeV1;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'groupId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'passportId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'userId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostModelV1AllOf
     */
    'workshopId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostModelV1AllOf
     */
    'tags'?: Array<string>;
    /**
     * ### 記事を隠す条件2 - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof PostModelV1AllOf
     */
    'isHidden'?: boolean | null;
    /**
     * ### 記事の承認を待っている 
     * @type {boolean}
     * @memberof PostModelV1AllOf
     */
    'isWaitingApproved'?: boolean;
    /**
     * ### 記事が承認された（記事を隠す条件2） - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof PostModelV1AllOf
     */
    'isApproved'?: boolean;
    /**
     * ### 記事を上端の高いイメージのリストに載せる（注目の記事） 
     * @type {boolean}
     * @memberof PostModelV1AllOf
     */
    'isInTopCarouselList'?: boolean;
    /**
     * ### 記事を一旦保存する - まだ作成中の。承認待ちではない。 
     * @type {boolean}
     * @memberof PostModelV1AllOf
     */
    'isFinishedWriting'?: boolean;
    /**
     * 
     * @type {UserReactionNullableV1}
     * @memberof PostModelV1AllOf
     */
    'userReaction'?: UserReactionNullableV1 | null;
    /**
     * ### ワークショップ開始日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1AllOf
     */
    'workshopStartAt'?: number;
    /**
     * ### ワークショップ終了日時（ミリ秒） 
     * @type {number}
     * @memberof PostModelV1AllOf
     */
    'workshopEndAt'?: number;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof PostModelV1AllOf
     */
    'mainPhoto'?: PhotoMetaV1;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof PostModelV1AllOf
     */
    'contentPhotos'?: Array<PhotoMetaV1>;
}


/**
 * 
 * @export
 * @interface PostRequestCreateV1
 */
export interface PostRequestCreateV1 {
    /**
     * 
     * @type {PostModelV1}
     * @memberof PostRequestCreateV1
     */
    'postModel': PostModelV1;
}
/**
 * ### 団体取得のリクエスト 
 * @export
 * @interface PostRequestReadV1
 */
export interface PostRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostRequestReadV1
     */
    'postId': string;
}
/**
 * 
 * @export
 * @interface PostRequestUpdateV1
 */
export interface PostRequestUpdateV1 {
    /**
     * 
     * @type {PostModelV1}
     * @memberof PostRequestUpdateV1
     */
    'postModel': PostModelV1;
}
/**
 * ### 記事のリクエスト 
 * @export
 * @interface PostRequestValidateV1
 */
export interface PostRequestValidateV1 {
    /**
     * 
     * @type {PostModelV1}
     * @memberof PostRequestValidateV1
     */
    'postModel': PostModelV1;
}
/**
 * ### 記事作成リクエスト 
 * @export
 * @interface PostRequestsCreateV1
 */
export interface PostRequestsCreateV1 {
    /**
     * 
     * @type {Array<PostRequestCreateV1>}
     * @memberof PostRequestsCreateV1
     */
    'postRequestCreates'?: Array<PostRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface PostRequestsDeleteV1
 */
export interface PostRequestsDeleteV1 {
    /**
     * ### 探す記事のID 
     * @type {Array<string>}
     * @memberof PostRequestsDeleteV1
     */
    'postIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface PostRequestsSearchV1
 */
export interface PostRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof PostRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof PostRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * 
     * @type {PostTypeV1}
     * @memberof PostRequestsSearchV1
     */
    'postType': PostTypeV1;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'cityId'?: string;
    /**
     * ### 探すユーザーのID 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'userId'?: string | null;
    /**
     * ### 探すパスポートのID 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'passportId'?: string | null;
    /**
     * ### 探す団体のID 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'groupId'?: string | null;
    /**
     * ### 探すワークショップのID 
     * @type {string}
     * @memberof PostRequestsSearchV1
     */
    'workshopId'?: string | null;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PostRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof PostRequestsSearchV1
     */
    'createdTo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostRequestsSearchV1
     */
    'shouldShowNotHidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostRequestsSearchV1
     */
    'shouldShowApproved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostRequestsSearchV1
     */
    'shouldShowTopCarouselList'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostRequestsSearchV1
     */
    'shouldShowFinishedWriting'?: boolean;
}


/**
 * ### 記事更新リクエスト 
 * @export
 * @interface PostRequestsUpdateV1
 */
export interface PostRequestsUpdateV1 {
    /**
     * 
     * @type {Array<PostRequestUpdateV1>}
     * @memberof PostRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<PostRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface PostRequestsValidateV1
 */
export interface PostRequestsValidateV1 {
    /**
     * 
     * @type {Array<PostRequestValidateV1>}
     * @memberof PostRequestsValidateV1
     */
    'postRequests': Array<PostRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof PostRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### 団体取得のレスポンス 
 * @export
 * @interface PostResponseReadV1
 */
export interface PostResponseReadV1 {
    /**
     * 
     * @type {PostModelV1}
     * @memberof PostResponseReadV1
     */
    'postModel': PostModelV1;
}
/**
 * 
 * @export
 * @interface PostResponsesWithPagerV1
 */
export interface PostResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof PostResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<PostModelV1>}
     * @memberof PostResponsesWithPagerV1
     */
    'data': Array<PostModelV1>;
}
/**
 * ### 記事の種類 
 * @export
 * @enum {string}
 */

export const PostTypeV1 = {
    Attractive: '魅力発見記事',
    Experience: 'イベント体験記事'
} as const;

export type PostTypeV1 = typeof PostTypeV1[keyof typeof PostTypeV1];


/**
 * ### プロフィール 
 * @export
 * @interface ProfileV1
 */
export interface ProfileV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof ProfileV1
     */
    'id'?: string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof ProfileV1
     */
    'photos'?: Array<PhotoMetaV1>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const QnaMailQuestionTypeV1 = {
    AboutFeatureGuide: 'サービス利用について',
    AboutNewPost: '情報掲載',
    AboutEventGuide: 'イベントについて',
    Others: 'その他'
} as const;

export type QnaMailQuestionTypeV1 = typeof QnaMailQuestionTypeV1[keyof typeof QnaMailQuestionTypeV1];


/**
 * ### お問い合わせメール 
 * @export
 * @interface QnaMailV1
 */
export interface QnaMailV1 {
    /**
     * 
     * @type {QnaMailQuestionTypeV1}
     * @memberof QnaMailV1
     */
    'questionType': QnaMailQuestionTypeV1;
    /**
     * ### お問い合わせメールの送る人の名前 
     * @type {string}
     * @memberof QnaMailV1
     */
    'senderName': string;
    /**
     * ### お問い合わせメールの送る人の団体名 
     * @type {string}
     * @memberof QnaMailV1
     */
    'groupName'?: string;
    /**
     * ### お問い合わせメールの送る人のチーム名 
     * @type {string}
     * @memberof QnaMailV1
     */
    'teamName'?: string;
    /**
     * ### お問い合わせメールの送る人のメールアドレス 
     * @type {string}
     * @memberof QnaMailV1
     */
    'emailFrom': string;
    /**
     * ### お問い合わせメールの送る人のメールアドレス(確認) 
     * @type {string}
     * @memberof QnaMailV1
     */
    'emailFromConfirm': string;
    /**
     * ### お問い合わせメールの送る人の電話番号 
     * @type {string}
     * @memberof QnaMailV1
     */
    'phoneNumberFrom': string;
    /**
     * ### お問い合わせメールのタイトル 
     * @type {string}
     * @memberof QnaMailV1
     */
    'title': string;
    /**
     * ### お問い合わせメールの本文 
     * @type {string}
     * @memberof QnaMailV1
     */
    'content': string;
}


/**
 * ### 文字列検索のために必要なトークン(n-gram) 
 * @export
 * @interface SearchTokenV1
 */
export interface SearchTokenV1 {
    /**
     * ### 文字列検索のために必要な1文字トークン(uni-gram) -  
     * @type {object}
     * @memberof SearchTokenV1
     */
    'uniGram': object;
    /**
     * ### 文字列検索のために必要な2文字トークン(bi-gram) 
     * @type {object}
     * @memberof SearchTokenV1
     */
    'biGram': object;
    /**
     * ### 文字列検索のために必要な3文字トークン(tri-gram) 
     * @type {object}
     * @memberof SearchTokenV1
     */
    'triGram': object;
}
/**
 * ### ユーザーログインリクエスト 
 * @export
 * @interface SignInRequestV1
 */
export interface SignInRequestV1 {
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof SignInRequestV1
     */
    'email': string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof SignInRequestV1
     */
    'password': string;
    /**
     * 
     * @type {SignInTypeV1}
     * @memberof SignInRequestV1
     */
    'signInType': SignInTypeV1;
}


/**
 * ### ユーザーログインタイプ - 管理者ログイン - 団体ログイン - 一般ユーザーログイン 
 * @export
 * @enum {string}
 */

export const SignInTypeV1 = {
    Admin: 'Admin',
    GroupManager: 'GroupManager',
    Normal: 'Normal'
} as const;

export type SignInTypeV1 = typeof SignInTypeV1[keyof typeof SignInTypeV1];


/**
 * ### ユーザーログインリクエスト 
 * @export
 * @interface SignInValidateRequestV1
 */
export interface SignInValidateRequestV1 {
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof SignInValidateRequestV1
     */
    'email': string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof SignInValidateRequestV1
     */
    'password': string;
    /**
     * 
     * @type {SignInTypeV1}
     * @memberof SignInValidateRequestV1
     */
    'signInType': SignInTypeV1;
}


/**
 * ### 高等学校の学人 
 * @export
 * @enum {string}
 */

export const UniversityCollegeStudentGradeV1 = {
    UniversityCollege1: '大学1年生',
    UniversityCollege2: '大学2年生',
    UniversityCollege3: '大学3年生',
    UniversityCollege4: '大学4年生'
} as const;

export type UniversityCollegeStudentGradeV1 = typeof UniversityCollegeStudentGradeV1[keyof typeof UniversityCollegeStudentGradeV1];


/**
 * ### ユーザーの性別 
 * @export
 * @enum {string}
 */

export const UserGenderV1 = {
    Male: '男性',
    Female: '女性',
    Others: 'どちらでもない'
} as const;

export type UserGenderV1 = typeof UserGenderV1[keyof typeof UserGenderV1];


/**
 * ### ユーザーの種類(ドロップダウン) 
 * @export
 * @enum {string}
 */

export const UserJobDropdownV1 = {
    StudentUniversityBachelor: '大学生',
    StudentUniversityMaster: '大学院生',
    StudentCollegeProfessional: '専門学生',
    StudentCollegeJunior: '短大生',
    StudentHighScholl: '高校生',
    Employee: '会社員',
    GovernmentWorker: '公務員',
    HomeMaker: '専属主婦/専属主夫',
    Executive: '経営者',
    PartTimeWorker: '無職・フリーター',
    Others: 'その他'
} as const;

export type UserJobDropdownV1 = typeof UserJobDropdownV1[keyof typeof UserJobDropdownV1];


/**
 * @type UserJobV1
 * ### ユーザーの種類 
 * @export
 */
export type UserJobV1 = UserJobDropdownV1 | string;

/**
 * ### ユーザーの気になる地方創生カテゴリ(オプション) 
 * @export
 * @enum {string}
 */

export const UserLocalCategoryOptionsV1 = {
    Travel: '観光・伝統文化',
    Popuration: '関係人口づくり',
    Promotion: '地域の魅力発信',
    Sustainable: 'サスティナブル',
    Education: '子供の教育',
    Family: '家族・家庭',
    Community: 'まちづくり',
    Culture: '文化・芸術'
} as const;

export type UserLocalCategoryOptionsV1 = typeof UserLocalCategoryOptionsV1[keyof typeof UserLocalCategoryOptionsV1];


/**
 * @type UserLocalCategoryV1
 * ### ユーザーの気になる地方創生カテゴリ 
 * @export
 */
export type UserLocalCategoryV1 = UserLocalCategoryOptionsV1 | string;

/**
 * ### ユーザー 
 * @export
 * @interface UserModelV1
 */
export interface UserModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof UserModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof UserModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof UserModelV1
     */
    'updatedAt': number;
    /**
     * ### ユーザーニックネーム名 
     * @type {string}
     * @memberof UserModelV1
     */
    'nickName': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof UserModelV1
     */
    'icon'?: PhotoMetaV1;
    /**
     * 
     * @type {UserPublicV1StudentGrade}
     * @memberof UserModelV1
     */
    'studentGrade'?: UserPublicV1StudentGrade;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModelV1
     */
    'interests'?: Array<string>;
    /**
     * ### ユーザーの説明 
     * @type {string}
     * @memberof UserModelV1
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModelV1
     */
    'localAssociationIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserLocalCategoryV1>}
     * @memberof UserModelV1
     */
    'localCategorys'?: Array<UserLocalCategoryV1>;
    /**
     * 
     * @type {UserJobV1}
     * @memberof UserModelV1
     */
    'job': UserJobV1;
    /**
     * ### ユーザーの教育機関名 
     * @type {string}
     * @memberof UserModelV1
     */
    'educationalInstitutionName'?: string;
    /**
     * ### ユーザーの教育機関の学部 
     * @type {string}
     * @memberof UserModelV1
     */
    'department'?: string;
    /**
     * ### 学生ユーザーの入学年月 
     * @type {Array<any>}
     * @memberof UserModelV1
     */
    'studentStartAt'?: Array<any>;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof UserModelV1
     */
    'name': string;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof UserModelV1
     */
    'nameFurigana': string;
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof UserModelV1
     */
    'email': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof UserModelV1
     */
    'emailToken': SearchTokenV1 | null;
    /**
     * 
     * @type {UserGenderV1}
     * @memberof UserModelV1
     */
    'gender': UserGenderV1;
    /**
     * ### ユーザーの電話番号 
     * @type {string}
     * @memberof UserModelV1
     */
    'phoneNumber'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserModelV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserModelV1
     */
    'townId'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof UserModelV1
     */
    'detailAddress1'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof UserModelV1
     */
    'detailAddress2'?: string;
    /**
     * ### ユーザーの郵便番号 
     * @type {string}
     * @memberof UserModelV1
     */
    'postNumber'?: string;
    /**
     * ### ユーザーの生年月日 
     * @type {Array<number>}
     * @memberof UserModelV1
     */
    'birthDay'?: Array<number>;
}


/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface UserModelV1AllOf
 */
export interface UserModelV1AllOf {
    /**
     * 
     * @type {UserJobV1}
     * @memberof UserModelV1AllOf
     */
    'job'?: UserJobV1;
    /**
     * ### ユーザーの教育機関名 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'educationalInstitutionName'?: string;
    /**
     * ### ユーザーの教育機関の学部 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'department'?: string;
    /**
     * ### 学生ユーザーの入学年月 
     * @type {Array<any>}
     * @memberof UserModelV1AllOf
     */
    'studentStartAt'?: Array<any>;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'name'?: string;
    /**
     * ### ユーザー名 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'nameFurigana'?: string;
    /**
     * ### ユーザーのメール 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'email'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof UserModelV1AllOf
     */
    'emailToken'?: SearchTokenV1 | null;
    /**
     * 
     * @type {UserGenderV1}
     * @memberof UserModelV1AllOf
     */
    'gender'?: UserGenderV1;
    /**
     * ### ユーザーの電話番号 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'phoneNumber'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'townId'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'detailAddress1'?: string;
    /**
     * ### ユーザーの細部住所 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'detailAddress2'?: string;
    /**
     * ### ユーザーの郵便番号 
     * @type {string}
     * @memberof UserModelV1AllOf
     */
    'postNumber'?: string;
    /**
     * ### ユーザーの生年月日 
     * @type {Array<number>}
     * @memberof UserModelV1AllOf
     */
    'birthDay'?: Array<number>;
}


/**
 * ### ユーザーのプロフィール（公開） 
 * @export
 * @interface UserPublicV1
 */
export interface UserPublicV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserPublicV1
     */
    'id': string;
    /**
     * ### ユーザーニックネーム名 
     * @type {string}
     * @memberof UserPublicV1
     */
    'nickName': string;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof UserPublicV1
     */
    'icon'?: PhotoMetaV1;
    /**
     * 
     * @type {UserPublicV1StudentGrade}
     * @memberof UserPublicV1
     */
    'studentGrade'?: UserPublicV1StudentGrade;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPublicV1
     */
    'interests'?: Array<string>;
    /**
     * ### ユーザーの説明 
     * @type {string}
     * @memberof UserPublicV1
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPublicV1
     */
    'localAssociationIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserLocalCategoryV1>}
     * @memberof UserPublicV1
     */
    'localCategorys'?: Array<UserLocalCategoryV1>;
}
/**
 * @type UserPublicV1StudentGrade
 * @export
 */
export type UserPublicV1StudentGrade = HighSchoolStudentGradeV1 | UniversityCollegeStudentGradeV1 | any;

/**
 * ### ユーザーのリアクション 
 * @export
 * @interface UserReactionNullableV1
 */
export interface UserReactionNullableV1 {
    /**
     * ### 「いいね!」したユーザーIDリスト 
     * @type {Array<string>}
     * @memberof UserReactionNullableV1
     */
    'userIdsLike'?: Array<string> | null;
    /**
     * ### 「気になる」したユーザーIDリスト 
     * @type {Array<string>}
     * @memberof UserReactionNullableV1
     */
    'userIdsBeCurious'?: Array<string> | null;
    /**
     * ### 「詳細を知りたい」したユーザーIDリスト 
     * @type {Array<string>}
     * @memberof UserReactionNullableV1
     */
    'userIdsWantToKnow'?: Array<string> | null;
    /**
     * ### 「参加したい」したユーザーIDリスト 
     * @type {Array<string>}
     * @memberof UserReactionNullableV1
     */
    'userIdsWantToGo'?: Array<string> | null;
    /**
     * ### 「行ったことある」したユーザーIDリスト 
     * @type {Array<string>}
     * @memberof UserReactionNullableV1
     */
    'userIdsHaveGone'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserRequestCreateV1
 */
export interface UserRequestCreateV1 {
    /**
     * 
     * @type {UserModelV1}
     * @memberof UserRequestCreateV1
     */
    'userModel': UserModelV1;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestCreateV1
     */
    'newPassword'?: string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestCreateV1
     */
    'passwordConfirm'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestCreateV1
     */
    'isByAdmin'?: boolean;
}
/**
 * ### ユーザー取得のリクエスト 
 * @export
 * @interface UserRequestReadV1
 */
export interface UserRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserRequestReadV1
     */
    'userId': string;
}
/**
 * ### ユーザーのパスワード更新リクエスト 
 * @export
 * @interface UserRequestUpdatePasswordV1
 */
export interface UserRequestUpdatePasswordV1 {
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestUpdatePasswordV1
     */
    'password': string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestUpdatePasswordV1
     */
    'newPassword': string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestUpdatePasswordV1
     */
    'passwordConfirm': string;
}
/**
 * ### パスワードと以外のユーザー情報更新リクエスト - 作業の作成・修正ページで契約に所属しているユーザーの追加 - マイページでのユーザー情報修正 
 * @export
 * @interface UserRequestUpdateProfileV1
 */
export interface UserRequestUpdateProfileV1 {
    /**
     * 
     * @type {UserModelV1}
     * @memberof UserRequestUpdateProfileV1
     */
    'userModel': UserModelV1;
}
/**
 * ### ユーザーのリクエスト 
 * @export
 * @interface UserRequestValidateV1
 */
export interface UserRequestValidateV1 {
    /**
     * 
     * @type {UserModelV1}
     * @memberof UserRequestValidateV1
     */
    'userModel': UserModelV1;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestValidateV1
     */
    'password'?: string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestValidateV1
     */
    'passwordConfirm'?: string;
    /**
     * ### ログインするためのパスワード（平文） 
     * @type {string}
     * @memberof UserRequestValidateV1
     */
    'newPassword'?: string;
}
/**
 * ### ユーザー作成リクエスト 
 * @export
 * @interface UserRequestsCreateV1
 */
export interface UserRequestsCreateV1 {
    /**
     * 
     * @type {Array<UserRequestCreateV1>}
     * @memberof UserRequestsCreateV1
     */
    'userRequestCreates'?: Array<UserRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface UserRequestsDeleteV1
 */
export interface UserRequestsDeleteV1 {
    /**
     * ### 探すユーザーのID 
     * @type {Array<string>}
     * @memberof UserRequestsDeleteV1
     */
    'userIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserRequestsSearchV1
 */
export interface UserRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof UserRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof UserRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof UserRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserRequestsSearchV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof UserRequestsSearchV1
     */
    'townId'?: string;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof UserRequestsSearchV1
     */
    'createdFrom'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof UserRequestsSearchV1
     */
    'createdTo'?: number;
}
/**
 * 
 * @export
 * @interface UserRequestsValidateV1
 */
export interface UserRequestsValidateV1 {
    /**
     * 
     * @type {Array<UserRequestValidateV1>}
     * @memberof UserRequestsValidateV1
     */
    'userRequests': Array<UserRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestsValidateV1
     */
    'isOnCreate'?: boolean;
}
/**
 * ### ユーザー取得のレスポンス 
 * @export
 * @interface UserResponseReadV1
 */
export interface UserResponseReadV1 {
    /**
     * 
     * @type {UserModelV1}
     * @memberof UserResponseReadV1
     */
    'userModel': UserModelV1;
}
/**
 * 
 * @export
 * @interface UserResponsesWithPagerV1
 */
export interface UserResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof UserResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<UserModelV1>}
     * @memberof UserResponsesWithPagerV1
     */
    'data': Array<UserModelV1>;
}
/**
 * ### ワークショップ 
 * @export
 * @interface WorkshopModelV1
 */
export interface WorkshopModelV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'id': string;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof WorkshopModelV1
     */
    'photos': Array<PhotoMetaV1>;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'createdAt': number;
    /**
     * ### 更新日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'updatedAt': number;
    /**
     * ### ワークショップ名 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'title': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1
     */
    'titleToken': SearchTokenV1 | null;
    /**
     * ### ワークショップの概要 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'overview': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1
     */
    'overviewToken': SearchTokenV1 | null;
    /**
     * ### ワークショップの詳細 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'detail': string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1
     */
    'detailToken': SearchTokenV1 | null;
    /**
     * ### ワークショップの注意事項 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'notice'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'cityId': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'townId': string;
    /**
     * ### ワークショップの細部住所 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'detailAddress1': string;
    /**
     * ### ワークショップの細部住所 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'detailAddress2': string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1
     */
    'groupId': string;
    /**
     * ### ワークショップ開始日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'workshopStartAt': number;
    /**
     * ### ワークショップ終了日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'workshopEndAt': number;
    /**
     * ### ワークショップ予約開始日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'bookingStartAt': number;
    /**
     * ### ワークショップ予約終了日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'bookingEndAt': number;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof WorkshopModelV1
     */
    'mainPhoto': PhotoMetaV1;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof WorkshopModelV1
     */
    'contentPhotos': Array<PhotoMetaV1>;
    /**
     * ### ワークショップ参加費（円） 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'participationFee'?: number;
    /**
     * ### ワークショップ予約可能な人数制限 
     * @type {number}
     * @memberof WorkshopModelV1
     */
    'bookingMax'?: number;
    /**
     * ### ワークショップを隠す条件1 - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof WorkshopModelV1
     */
    'isHidden': boolean;
    /**
     * ### ワークショップの承認を待っている 
     * @type {boolean}
     * @memberof WorkshopModelV1
     */
    'isWaitingApproved': boolean;
    /**
     * ### ワークショップが承認された（ワークショップを隠す条件2） - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof WorkshopModelV1
     */
    'isApproved': boolean;
    /**
     * ### ワークショップを上端の高いイメージのリストに載せる（注目のワークショップ） 
     * @type {boolean}
     * @memberof WorkshopModelV1
     */
    'isInTopCarouselList': boolean;
    /**
     * ### ワークショップを一旦保存する - まだ作成中の。承認待ちではない。 
     * @type {boolean}
     * @memberof WorkshopModelV1
     */
    'isFinishedWriting': boolean;
}
/**
 * 使用しない。openapi allOf の副産物。
 * @export
 * @interface WorkshopModelV1AllOf
 */
export interface WorkshopModelV1AllOf {
    /**
     * ### ワークショップ名 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'title'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1AllOf
     */
    'titleToken'?: SearchTokenV1 | null;
    /**
     * ### ワークショップの概要 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'overview'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1AllOf
     */
    'overviewToken'?: SearchTokenV1 | null;
    /**
     * ### ワークショップの詳細 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'detail'?: string;
    /**
     * 
     * @type {SearchTokenV1}
     * @memberof WorkshopModelV1AllOf
     */
    'detailToken'?: SearchTokenV1 | null;
    /**
     * ### ワークショップの注意事項 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'notice'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'townId'?: string;
    /**
     * ### ワークショップの細部住所 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'detailAddress1'?: string;
    /**
     * ### ワークショップの細部住所 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'detailAddress2'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopModelV1AllOf
     */
    'groupId'?: string;
    /**
     * ### ワークショップ開始日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'workshopStartAt'?: number;
    /**
     * ### ワークショップ終了日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'workshopEndAt'?: number;
    /**
     * ### ワークショップ予約開始日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'bookingStartAt'?: number;
    /**
     * ### ワークショップ予約終了日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'bookingEndAt'?: number;
    /**
     * 
     * @type {PhotoMetaV1}
     * @memberof WorkshopModelV1AllOf
     */
    'mainPhoto'?: PhotoMetaV1;
    /**
     * 
     * @type {Array<PhotoMetaV1>}
     * @memberof WorkshopModelV1AllOf
     */
    'contentPhotos'?: Array<PhotoMetaV1>;
    /**
     * ### ワークショップ参加費（円） 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'participationFee'?: number;
    /**
     * ### ワークショップ予約可能な人数制限 
     * @type {number}
     * @memberof WorkshopModelV1AllOf
     */
    'bookingMax'?: number;
    /**
     * ### ワークショップを隠す条件1 - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof WorkshopModelV1AllOf
     */
    'isHidden'?: boolean;
    /**
     * ### ワークショップの承認を待っている 
     * @type {boolean}
     * @memberof WorkshopModelV1AllOf
     */
    'isWaitingApproved'?: boolean;
    /**
     * ### ワークショップが承認された（ワークショップを隠す条件2） - ユーザーの検索だけ 
     * @type {boolean}
     * @memberof WorkshopModelV1AllOf
     */
    'isApproved'?: boolean;
    /**
     * ### ワークショップを上端の高いイメージのリストに載せる（注目のワークショップ） 
     * @type {boolean}
     * @memberof WorkshopModelV1AllOf
     */
    'isInTopCarouselList'?: boolean;
    /**
     * ### ワークショップを一旦保存する - まだ作成中の。承認待ちではない。 
     * @type {boolean}
     * @memberof WorkshopModelV1AllOf
     */
    'isFinishedWriting'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkshopRequestCreateV1
 */
export interface WorkshopRequestCreateV1 {
    /**
     * 
     * @type {WorkshopModelV1}
     * @memberof WorkshopRequestCreateV1
     */
    'workshopModel': WorkshopModelV1;
}
/**
 * ### ワークショップ取得のリクエスト 
 * @export
 * @interface WorkshopRequestReadV1
 */
export interface WorkshopRequestReadV1 {
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopRequestReadV1
     */
    'workshopId': string;
}
/**
 * 
 * @export
 * @interface WorkshopRequestUpdateV1
 */
export interface WorkshopRequestUpdateV1 {
    /**
     * 
     * @type {WorkshopModelV1}
     * @memberof WorkshopRequestUpdateV1
     */
    'workshopModel': WorkshopModelV1;
}
/**
 * ### ワークショップのリクエスト 
 * @export
 * @interface WorkshopRequestValidateV1
 */
export interface WorkshopRequestValidateV1 {
    /**
     * 
     * @type {WorkshopModelV1}
     * @memberof WorkshopRequestValidateV1
     */
    'workshopModel': WorkshopModelV1;
}
/**
 * ### ワークショップ作成リクエスト 
 * @export
 * @interface WorkshopRequestsCreateV1
 */
export interface WorkshopRequestsCreateV1 {
    /**
     * 
     * @type {Array<WorkshopRequestCreateV1>}
     * @memberof WorkshopRequestsCreateV1
     */
    'workshopRequestCreates'?: Array<WorkshopRequestCreateV1>;
}
/**
 * 
 * @export
 * @interface WorkshopRequestsDeleteV1
 */
export interface WorkshopRequestsDeleteV1 {
    /**
     * ### 探すワークショップのID 
     * @type {Array<string>}
     * @memberof WorkshopRequestsDeleteV1
     */
    'workshopIds': Array<string> | null;
}
/**
 * 
 * @export
 * @interface WorkshopRequestsSearchV1
 */
export interface WorkshopRequestsSearchV1 {
    /**
     * ### ページ番号 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof WorkshopRequestsSearchV1
     */
    'page'?: number | null;
    /**
     * ### ページ別アイテム数 - 入力しない場合、全てのページを取得 
     * @type {number}
     * @memberof WorkshopRequestsSearchV1
     */
    'size'?: number | null;
    /**
     * ### 区切れてない検索文字列 - 無指定ならこの絞り込みはしない。 
     * @type {string}
     * @memberof WorkshopRequestsSearchV1
     */
    'searchString'?: string | null;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopRequestsSearchV1
     */
    'cityId'?: string;
    /**
     * ### 識別子 
     * @type {string}
     * @memberof WorkshopRequestsSearchV1
     */
    'townId'?: string;
    /**
     * ### 探す団体のID 
     * @type {string}
     * @memberof WorkshopRequestsSearchV1
     */
    'groupId'?: string | null;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopRequestsSearchV1
     */
    'workshopStartAt'?: number;
    /**
     * ### 作成日時（ミリ秒） 
     * @type {number}
     * @memberof WorkshopRequestsSearchV1
     */
    'workshopEndAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopRequestsSearchV1
     */
    'shouldShowNotHidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopRequestsSearchV1
     */
    'shouldShowApproved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopRequestsSearchV1
     */
    'shouldShowTopCarouselList'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopRequestsSearchV1
     */
    'shouldShowFinishedWriting'?: boolean;
}
/**
 * ### ワークショップ更新リクエスト 
 * @export
 * @interface WorkshopRequestsUpdateV1
 */
export interface WorkshopRequestsUpdateV1 {
    /**
     * 
     * @type {Array<WorkshopRequestUpdateV1>}
     * @memberof WorkshopRequestsUpdateV1
     */
    'userRequestUpdates'?: Array<WorkshopRequestUpdateV1>;
}
/**
 * 
 * @export
 * @interface WorkshopRequestsValidateV1
 */
export interface WorkshopRequestsValidateV1 {
    /**
     * 
     * @type {Array<WorkshopRequestValidateV1>}
     * @memberof WorkshopRequestsValidateV1
     */
    'workshopRequests': Array<WorkshopRequestValidateV1>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopRequestsValidateV1
     */
    'isOnSubmit'?: boolean;
}
/**
 * ### ワークショップ取得のレスポンス 
 * @export
 * @interface WorkshopResponseReadV1
 */
export interface WorkshopResponseReadV1 {
    /**
     * 
     * @type {WorkshopModelV1}
     * @memberof WorkshopResponseReadV1
     */
    'workshopModel': WorkshopModelV1;
}
/**
 * 
 * @export
 * @interface WorkshopResponsesWithPagerV1
 */
export interface WorkshopResponsesWithPagerV1 {
    /**
     * 
     * @type {Pager}
     * @memberof WorkshopResponsesWithPagerV1
     */
    'pager': Pager;
    /**
     * 
     * @type {Array<WorkshopModelV1>}
     * @memberof WorkshopResponsesWithPagerV1
     */
    'data': Array<WorkshopModelV1>;
}

/**
 * AssociationApi - axios parameter creator
 * @export
 */
export const AssociationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ### 検索優先順位 
         * @summary 機関を列挙
         * @param {AssociationRequestsSearchV1} associationRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssociations: async (associationRequestsSearchV1: AssociationRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'associationRequestsSearchV1' is not null or undefined
            assertParamExists('searchAssociations', 'associationRequestsSearchV1', associationRequestsSearchV1)
            const localVarPath = `/firestore/associations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(associationRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssociationApi - functional programming interface
 * @export
 */
export const AssociationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssociationApiAxiosParamCreator(configuration)
    return {
        /**
         * ### 検索優先順位 
         * @summary 機関を列挙
         * @param {AssociationRequestsSearchV1} associationRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAssociations(associationRequestsSearchV1: AssociationRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssociationResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAssociations(associationRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssociationApi - factory interface
 * @export
 */
export const AssociationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssociationApiFp(configuration)
    return {
        /**
         * ### 検索優先順位 
         * @summary 機関を列挙
         * @param {AssociationRequestsSearchV1} associationRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssociations(associationRequestsSearchV1: AssociationRequestsSearchV1, options?: any): AxiosPromise<Array<AssociationResponsesWithPagerV1>> {
            return localVarFp.searchAssociations(associationRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssociationApi - object-oriented interface
 * @export
 * @class AssociationApi
 * @extends {BaseAPI}
 */
export class AssociationApi extends BaseAPI {
    /**
     * ### 検索優先順位 
     * @summary 機関を列挙
     * @param {AssociationRequestsSearchV1} associationRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociationApi
     */
    public searchAssociations(associationRequestsSearchV1: AssociationRequestsSearchV1, options?: AxiosRequestConfig) {
        return AssociationApiFp(this.configuration).searchAssociations(associationRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerApi - axios parameter creator
 * @export
 */
export const BannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary バナーを作成
         * @param {BannerRequestsCreateV1} bannerRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBanners: async (bannerRequestsCreateV1: BannerRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestsCreateV1' is not null or undefined
            assertParamExists('createBanners', 'bannerRequestsCreateV1', bannerRequestsCreateV1)
            const localVarPath = `/firestore/banners/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バナーを削除
         * @param {BannerRequestsDeleteV1} bannerRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanners: async (bannerRequestsDeleteV1: BannerRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteBanners', 'bannerRequestsDeleteV1', bannerRequestsDeleteV1)
            const localVarPath = `/firestore/banners/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バナーを取得
         * @param {BannerRequestReadV1} bannerRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner: async (bannerRequestReadV1: BannerRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestReadV1' is not null or undefined
            assertParamExists('getBanner', 'bannerRequestReadV1', bannerRequestReadV1)
            const localVarPath = `/firestore/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バナーを検証
         * @param {BannerRequestsValidateV1} bannerRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBanners: async (bannerRequestsValidateV1: BannerRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestsValidateV1' is not null or undefined
            assertParamExists('validateBanners', 'bannerRequestsValidateV1', bannerRequestsValidateV1)
            const localVarPath = `/firestore/banners/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary バナーを作成
         * @param {BannerRequestsCreateV1} bannerRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBanners(bannerRequestsCreateV1: BannerRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBanners(bannerRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バナーを削除
         * @param {BannerRequestsDeleteV1} bannerRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBanners(bannerRequestsDeleteV1: BannerRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBanners(bannerRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バナーを取得
         * @param {BannerRequestReadV1} bannerRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBanner(bannerRequestReadV1: BannerRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBanner(bannerRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バナーを検証
         * @param {BannerRequestsValidateV1} bannerRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateBanners(bannerRequestsValidateV1: BannerRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateBanners(bannerRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerApiFp(configuration)
    return {
        /**
         * 
         * @summary バナーを作成
         * @param {BannerRequestsCreateV1} bannerRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBanners(bannerRequestsCreateV1: BannerRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createBanners(bannerRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バナーを削除
         * @param {BannerRequestsDeleteV1} bannerRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanners(bannerRequestsDeleteV1: BannerRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBanners(bannerRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バナーを取得
         * @param {BannerRequestReadV1} bannerRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(bannerRequestReadV1: BannerRequestReadV1, options?: any): AxiosPromise<BannerResponseReadV1> {
            return localVarFp.getBanner(bannerRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バナーを検証
         * @param {BannerRequestsValidateV1} bannerRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBanners(bannerRequestsValidateV1: BannerRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateBanners(bannerRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary バナーを作成
     * @param {BannerRequestsCreateV1} bannerRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public createBanners(bannerRequestsCreateV1: BannerRequestsCreateV1, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).createBanners(bannerRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バナーを削除
     * @param {BannerRequestsDeleteV1} bannerRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public deleteBanners(bannerRequestsDeleteV1: BannerRequestsDeleteV1, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).deleteBanners(bannerRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バナーを取得
     * @param {BannerRequestReadV1} bannerRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public getBanner(bannerRequestReadV1: BannerRequestReadV1, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).getBanner(bannerRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バナーを検証
     * @param {BannerRequestsValidateV1} bannerRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public validateBanners(bannerRequestsValidateV1: BannerRequestsValidateV1, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).validateBanners(bannerRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 予約を作成
         * @param {BookingRequestsCreateV1} bookingRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookings: async (bookingRequestsCreateV1: BookingRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestsCreateV1' is not null or undefined
            assertParamExists('createBookings', 'bookingRequestsCreateV1', bookingRequestsCreateV1)
            const localVarPath = `/firestore/bookings/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 予約を削除
         * @param {BookingRequestsDeleteV1} bookingRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookings: async (bookingRequestsDeleteV1: BookingRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteBookings', 'bookingRequestsDeleteV1', bookingRequestsDeleteV1)
            const localVarPath = `/firestore/bookings/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 予約を取得
         * @param {BookingRequestReadV1} bookingRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBooking: async (bookingRequestReadV1: BookingRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestReadV1' is not null or undefined
            assertParamExists('getBooking', 'bookingRequestReadV1', bookingRequestReadV1)
            const localVarPath = `/firestore/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 予約を検証
         * @param {BookingRequestsValidateV1} bookingRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBookings: async (bookingRequestsValidateV1: BookingRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestsValidateV1' is not null or undefined
            assertParamExists('validateBookings', 'bookingRequestsValidateV1', bookingRequestsValidateV1)
            const localVarPath = `/firestore/bookings/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 予約を作成
         * @param {BookingRequestsCreateV1} bookingRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBookings(bookingRequestsCreateV1: BookingRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBookings(bookingRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 予約を削除
         * @param {BookingRequestsDeleteV1} bookingRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookings(bookingRequestsDeleteV1: BookingRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBookings(bookingRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 予約を取得
         * @param {BookingRequestReadV1} bookingRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBooking(bookingRequestReadV1: BookingRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBooking(bookingRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 予約を検証
         * @param {BookingRequestsValidateV1} bookingRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateBookings(bookingRequestsValidateV1: BookingRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateBookings(bookingRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingApiFp(configuration)
    return {
        /**
         * 
         * @summary 予約を作成
         * @param {BookingRequestsCreateV1} bookingRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookings(bookingRequestsCreateV1: BookingRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createBookings(bookingRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 予約を削除
         * @param {BookingRequestsDeleteV1} bookingRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookings(bookingRequestsDeleteV1: BookingRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBookings(bookingRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 予約を取得
         * @param {BookingRequestReadV1} bookingRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBooking(bookingRequestReadV1: BookingRequestReadV1, options?: any): AxiosPromise<BookingResponseReadV1> {
            return localVarFp.getBooking(bookingRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 予約を検証
         * @param {BookingRequestsValidateV1} bookingRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBookings(bookingRequestsValidateV1: BookingRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateBookings(bookingRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * 
     * @summary 予約を作成
     * @param {BookingRequestsCreateV1} bookingRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public createBookings(bookingRequestsCreateV1: BookingRequestsCreateV1, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).createBookings(bookingRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 予約を削除
     * @param {BookingRequestsDeleteV1} bookingRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public deleteBookings(bookingRequestsDeleteV1: BookingRequestsDeleteV1, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).deleteBookings(bookingRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 予約を取得
     * @param {BookingRequestReadV1} bookingRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public getBooking(bookingRequestReadV1: BookingRequestReadV1, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).getBooking(bookingRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 予約を検証
     * @param {BookingRequestsValidateV1} bookingRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public validateBookings(bookingRequestsValidateV1: BookingRequestsValidateV1, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).validateBookings(bookingRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FunctionsApi - axios parameter creator
 * @export
 */
export const FunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgePostUpdateUserReactionRequestV1} localbridgePostUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgePostUpdateUserReaction: async (localbridgePostUpdateUserReactionRequestV1: LocalbridgePostUpdateUserReactionRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgePostUpdateUserReactionRequestV1' is not null or undefined
            assertParamExists('localbridgePostUpdateUserReaction', 'localbridgePostUpdateUserReactionRequestV1', localbridgePostUpdateUserReactionRequestV1)
            const localVarPath = `/functions/localbridge/post/updateUserReaction/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgePostUpdateUserReactionRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeQnaSendMailRequestV1} localbridgeQnaSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeQnaSendMail: async (localbridgeQnaSendMailRequestV1: LocalbridgeQnaSendMailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeQnaSendMailRequestV1' is not null or undefined
            assertParamExists('localbridgeQnaSendMail', 'localbridgeQnaSendMailRequestV1', localbridgeQnaSendMailRequestV1)
            const localVarPath = `/functions/localbridge/qna/sendMail/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeQnaSendMailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignInTestSignInTypeRequestV1} localbridgeSignInTestSignInTypeRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeSignInTestSignInType: async (localbridgeSignInTestSignInTypeRequestV1: LocalbridgeSignInTestSignInTypeRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeSignInTestSignInTypeRequestV1' is not null or undefined
            assertParamExists('localbridgeSignInTestSignInType', 'localbridgeSignInTestSignInTypeRequestV1', localbridgeSignInTestSignInTypeRequestV1)
            const localVarPath = `/functions/localbridge/sign/in/testSignInType/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeSignInTestSignInTypeRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignUpEmailVarificationSendMailRequestV1} localbridgeSignUpEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeSignUpEmailVarificationSendMail: async (localbridgeSignUpEmailVarificationSendMailRequestV1: LocalbridgeSignUpEmailVarificationSendMailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeSignUpEmailVarificationSendMailRequestV1' is not null or undefined
            assertParamExists('localbridgeSignUpEmailVarificationSendMail', 'localbridgeSignUpEmailVarificationSendMailRequestV1', localbridgeSignUpEmailVarificationSendMailRequestV1)
            const localVarPath = `/functions/localbridge/sign/up/emailVarification/sendMail/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeSignUpEmailVarificationSendMailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ### ユーザー(公開) 
         * @param {LocalbridgeUserRequestV1} localbridgeUserRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUser: async (localbridgeUserRequestV1: LocalbridgeUserRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUserRequestV1' is not null or undefined
            assertParamExists('localbridgeUser', 'localbridgeUserRequestV1', localbridgeUserRequestV1)
            const localVarPath = `/functions/localbridge/user/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUserRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ### メールアドレスでユーザーID取得 
         * @param {LocalbridgeUserGetUserIdByEmailRequestV1} localbridgeUserGetUserIdByEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserGetUserIdByEmail: async (localbridgeUserGetUserIdByEmailRequestV1: LocalbridgeUserGetUserIdByEmailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUserGetUserIdByEmailRequestV1' is not null or undefined
            assertParamExists('localbridgeUserGetUserIdByEmail', 'localbridgeUserGetUserIdByEmailRequestV1', localbridgeUserGetUserIdByEmailRequestV1)
            const localVarPath = `/functions/localbridge/user/getUserIdByEmail/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUserGetUserIdByEmailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ### ユーザーデーターをシンク(Auth -> Firestore) - 変更されたメールアドレスをFirestore {env}/localbridge/users/{userId} へ反映 
         * @param {LocalbridgeUserSyncEmailRequestV1} localbridgeUserSyncEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserSyncEmail: async (localbridgeUserSyncEmailRequestV1: LocalbridgeUserSyncEmailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUserSyncEmailRequestV1' is not null or undefined
            assertParamExists('localbridgeUserSyncEmail', 'localbridgeUserSyncEmailRequestV1', localbridgeUserSyncEmailRequestV1)
            const localVarPath = `/functions/localbridge/user/sync/email/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUserSyncEmailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレス再設定のメール認証
         * @param {LocalbridgeUserUpdateEmailVarificationSendMailRequestV1} localbridgeUserUpdateEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserUpdateEmailVarificationSendMail: async (localbridgeUserUpdateEmailVarificationSendMailRequestV1: LocalbridgeUserUpdateEmailVarificationSendMailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUserUpdateEmailVarificationSendMailRequestV1' is not null or undefined
            assertParamExists('localbridgeUserUpdateEmailVarificationSendMail', 'localbridgeUserUpdateEmailVarificationSendMailRequestV1', localbridgeUserUpdateEmailVarificationSendMailRequestV1)
            const localVarPath = `/functions/localbridge/user/update/emailVarification/sendMail/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUserUpdateEmailVarificationSendMailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {LocalbridgeUserUpdatePasswordResetSendMailRequestV1} localbridgeUserUpdatePasswordResetSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserUpdatePasswordResetSendMail: async (localbridgeUserUpdatePasswordResetSendMailRequestV1: LocalbridgeUserUpdatePasswordResetSendMailRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUserUpdatePasswordResetSendMailRequestV1' is not null or undefined
            assertParamExists('localbridgeUserUpdatePasswordResetSendMail', 'localbridgeUserUpdatePasswordResetSendMailRequestV1', localbridgeUserUpdatePasswordResetSendMailRequestV1)
            const localVarPath = `/functions/localbridge/user/update/passwordReset/sendMail/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUserUpdatePasswordResetSendMailRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ### ユーザー一覧(公開) 
         * @param {LocalbridgeUsersRequestV1} localbridgeUsersRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUsers: async (localbridgeUsersRequestV1: LocalbridgeUsersRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUsersRequestV1' is not null or undefined
            assertParamExists('localbridgeUsers', 'localbridgeUsersRequestV1', localbridgeUsersRequestV1)
            const localVarPath = `/functions/localbridge/users/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUsersRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeUsersCreateUserByServiceManagerRequestV1} localbridgeUsersCreateUserByServiceManagerRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUsersCreateUserByServiceManager: async (localbridgeUsersCreateUserByServiceManagerRequestV1: LocalbridgeUsersCreateUserByServiceManagerRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeUsersCreateUserByServiceManagerRequestV1' is not null or undefined
            assertParamExists('localbridgeUsersCreateUserByServiceManager', 'localbridgeUsersCreateUserByServiceManagerRequestV1', localbridgeUsersCreateUserByServiceManagerRequestV1)
            const localVarPath = `/functions/localbridge/users/createUserByServiceManager/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeUsersCreateUserByServiceManagerRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeWorkshopUpdateUserReactionRequestV1} localbridgeWorkshopUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeWorkshopUpdateUserReaction: async (localbridgeWorkshopUpdateUserReactionRequestV1: LocalbridgeWorkshopUpdateUserReactionRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localbridgeWorkshopUpdateUserReactionRequestV1' is not null or undefined
            assertParamExists('localbridgeWorkshopUpdateUserReaction', 'localbridgeWorkshopUpdateUserReactionRequestV1', localbridgeWorkshopUpdateUserReactionRequestV1)
            const localVarPath = `/functions/localbridge/workshop/updateUserReaction/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localbridgeWorkshopUpdateUserReactionRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FunctionsApi - functional programming interface
 * @export
 */
export const FunctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FunctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgePostUpdateUserReactionRequestV1} localbridgePostUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1: LocalbridgePostUpdateUserReactionRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeQnaSendMailRequestV1} localbridgeQnaSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1: LocalbridgeQnaSendMailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignInTestSignInTypeRequestV1} localbridgeSignInTestSignInTypeRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1: LocalbridgeSignInTestSignInTypeRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignUpEmailVarificationSendMailRequestV1} localbridgeSignUpEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1: LocalbridgeSignUpEmailVarificationSendMailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ### ユーザー(公開) 
         * @param {LocalbridgeUserRequestV1} localbridgeUserRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUser(localbridgeUserRequestV1: LocalbridgeUserRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalbridgeUserResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUser(localbridgeUserRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ### メールアドレスでユーザーID取得 
         * @param {LocalbridgeUserGetUserIdByEmailRequestV1} localbridgeUserGetUserIdByEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1: LocalbridgeUserGetUserIdByEmailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalbridgeUserGetUserIdByEmailResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ### ユーザーデーターをシンク(Auth -> Firestore) - 変更されたメールアドレスをFirestore {env}/localbridge/users/{userId} へ反映 
         * @param {LocalbridgeUserSyncEmailRequestV1} localbridgeUserSyncEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1: LocalbridgeUserSyncEmailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メールアドレス再設定のメール認証
         * @param {LocalbridgeUserUpdateEmailVarificationSendMailRequestV1} localbridgeUserUpdateEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1: LocalbridgeUserUpdateEmailVarificationSendMailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {LocalbridgeUserUpdatePasswordResetSendMailRequestV1} localbridgeUserUpdatePasswordResetSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1: LocalbridgeUserUpdatePasswordResetSendMailRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ### ユーザー一覧(公開) 
         * @param {LocalbridgeUsersRequestV1} localbridgeUsersRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUsers(localbridgeUsersRequestV1: LocalbridgeUsersRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalbridgeUsersResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUsers(localbridgeUsersRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeUsersCreateUserByServiceManagerRequestV1} localbridgeUsersCreateUserByServiceManagerRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1: LocalbridgeUsersCreateUserByServiceManagerRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalbridgeUsersCreateUserByServiceManagerResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeWorkshopUpdateUserReactionRequestV1} localbridgeWorkshopUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1: LocalbridgeWorkshopUpdateUserReactionRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FunctionsApi - factory interface
 * @export
 */
export const FunctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FunctionsApiFp(configuration)
    return {
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgePostUpdateUserReactionRequestV1} localbridgePostUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1: LocalbridgePostUpdateUserReactionRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeQnaSendMailRequestV1} localbridgeQnaSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1: LocalbridgeQnaSendMailRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignInTestSignInTypeRequestV1} localbridgeSignInTestSignInTypeRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1: LocalbridgeSignInTestSignInTypeRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員登録のメール認証
         * @param {LocalbridgeSignUpEmailVarificationSendMailRequestV1} localbridgeSignUpEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1: LocalbridgeSignUpEmailVarificationSendMailRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ### ユーザー(公開) 
         * @param {LocalbridgeUserRequestV1} localbridgeUserRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUser(localbridgeUserRequestV1: LocalbridgeUserRequestV1, options?: any): AxiosPromise<LocalbridgeUserResponseV1> {
            return localVarFp.localbridgeUser(localbridgeUserRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ### メールアドレスでユーザーID取得 
         * @param {LocalbridgeUserGetUserIdByEmailRequestV1} localbridgeUserGetUserIdByEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1: LocalbridgeUserGetUserIdByEmailRequestV1, options?: any): AxiosPromise<LocalbridgeUserGetUserIdByEmailResponseV1> {
            return localVarFp.localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ### ユーザーデーターをシンク(Auth -> Firestore) - 変更されたメールアドレスをFirestore {env}/localbridge/users/{userId} へ反映 
         * @param {LocalbridgeUserSyncEmailRequestV1} localbridgeUserSyncEmailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1: LocalbridgeUserSyncEmailRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレス再設定のメール認証
         * @param {LocalbridgeUserUpdateEmailVarificationSendMailRequestV1} localbridgeUserUpdateEmailVarificationSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1: LocalbridgeUserUpdateEmailVarificationSendMailRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {LocalbridgeUserUpdatePasswordResetSendMailRequestV1} localbridgeUserUpdatePasswordResetSendMailRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1: LocalbridgeUserUpdatePasswordResetSendMailRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ### ユーザー一覧(公開) 
         * @param {LocalbridgeUsersRequestV1} localbridgeUsersRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUsers(localbridgeUsersRequestV1: LocalbridgeUsersRequestV1, options?: any): AxiosPromise<LocalbridgeUsersResponseV1> {
            return localVarFp.localbridgeUsers(localbridgeUsersRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeUsersCreateUserByServiceManagerRequestV1} localbridgeUsersCreateUserByServiceManagerRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1: LocalbridgeUsersCreateUserByServiceManagerRequestV1, options?: any): AxiosPromise<LocalbridgeUsersCreateUserByServiceManagerResponseV1> {
            return localVarFp.localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップへのユーザーリアクション
         * @param {LocalbridgeWorkshopUpdateUserReactionRequestV1} localbridgeWorkshopUpdateUserReactionRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1: LocalbridgeWorkshopUpdateUserReactionRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FunctionsApi - object-oriented interface
 * @export
 * @class FunctionsApi
 * @extends {BaseAPI}
 */
export class FunctionsApi extends BaseAPI {
    /**
     * 
     * @summary ワークショップへのユーザーリアクション
     * @param {LocalbridgePostUpdateUserReactionRequestV1} localbridgePostUpdateUserReactionRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1: LocalbridgePostUpdateUserReactionRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgePostUpdateUserReaction(localbridgePostUpdateUserReactionRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員登録のメール認証
     * @param {LocalbridgeQnaSendMailRequestV1} localbridgeQnaSendMailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1: LocalbridgeQnaSendMailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeQnaSendMail(localbridgeQnaSendMailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員登録のメール認証
     * @param {LocalbridgeSignInTestSignInTypeRequestV1} localbridgeSignInTestSignInTypeRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1: LocalbridgeSignInTestSignInTypeRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeSignInTestSignInType(localbridgeSignInTestSignInTypeRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員登録のメール認証
     * @param {LocalbridgeSignUpEmailVarificationSendMailRequestV1} localbridgeSignUpEmailVarificationSendMailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1: LocalbridgeSignUpEmailVarificationSendMailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeSignUpEmailVarificationSendMail(localbridgeSignUpEmailVarificationSendMailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ### ユーザー(公開) 
     * @param {LocalbridgeUserRequestV1} localbridgeUserRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUser(localbridgeUserRequestV1: LocalbridgeUserRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUser(localbridgeUserRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ### メールアドレスでユーザーID取得 
     * @param {LocalbridgeUserGetUserIdByEmailRequestV1} localbridgeUserGetUserIdByEmailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1: LocalbridgeUserGetUserIdByEmailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUserGetUserIdByEmail(localbridgeUserGetUserIdByEmailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ### ユーザーデーターをシンク(Auth -> Firestore) - 変更されたメールアドレスをFirestore {env}/localbridge/users/{userId} へ反映 
     * @param {LocalbridgeUserSyncEmailRequestV1} localbridgeUserSyncEmailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1: LocalbridgeUserSyncEmailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUserSyncEmail(localbridgeUserSyncEmailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレス再設定のメール認証
     * @param {LocalbridgeUserUpdateEmailVarificationSendMailRequestV1} localbridgeUserUpdateEmailVarificationSendMailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1: LocalbridgeUserUpdateEmailVarificationSendMailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUserUpdateEmailVarificationSendMail(localbridgeUserUpdateEmailVarificationSendMailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワード再設定
     * @param {LocalbridgeUserUpdatePasswordResetSendMailRequestV1} localbridgeUserUpdatePasswordResetSendMailRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1: LocalbridgeUserUpdatePasswordResetSendMailRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUserUpdatePasswordResetSendMail(localbridgeUserUpdatePasswordResetSendMailRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ### ユーザー一覧(公開) 
     * @param {LocalbridgeUsersRequestV1} localbridgeUsersRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUsers(localbridgeUsersRequestV1: LocalbridgeUsersRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUsers(localbridgeUsersRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップへのユーザーリアクション
     * @param {LocalbridgeUsersCreateUserByServiceManagerRequestV1} localbridgeUsersCreateUserByServiceManagerRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1: LocalbridgeUsersCreateUserByServiceManagerRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeUsersCreateUserByServiceManager(localbridgeUsersCreateUserByServiceManagerRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップへのユーザーリアクション
     * @param {LocalbridgeWorkshopUpdateUserReactionRequestV1} localbridgeWorkshopUpdateUserReactionRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FunctionsApi
     */
    public localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1: LocalbridgeWorkshopUpdateUserReactionRequestV1, options?: AxiosRequestConfig) {
        return FunctionsApiFp(this.configuration).localbridgeWorkshopUpdateUserReaction(localbridgeWorkshopUpdateUserReactionRequestV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 団体を作成
         * @param {GroupRequestsCreateV1} groupRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroups: async (groupRequestsCreateV1: GroupRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestsCreateV1' is not null or undefined
            assertParamExists('createGroups', 'groupRequestsCreateV1', groupRequestsCreateV1)
            const localVarPath = `/firestore/groups/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 団体を削除
         * @param {GroupRequestsDeleteV1} groupRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroups: async (groupRequestsDeleteV1: GroupRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteGroups', 'groupRequestsDeleteV1', groupRequestsDeleteV1)
            const localVarPath = `/firestore/groups/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 団体を取得
         * @param {GroupRequestReadV1} groupRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (groupRequestReadV1: GroupRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestReadV1' is not null or undefined
            assertParamExists('getGroup', 'groupRequestReadV1', groupRequestReadV1)
            const localVarPath = `/firestore/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 団体を検索
         * @param {GroupRequestsSearchV1} groupRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsSearch: async (groupRequestsSearchV1: GroupRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestsSearchV1' is not null or undefined
            assertParamExists('getGroupsSearch', 'groupRequestsSearchV1', groupRequestsSearchV1)
            const localVarPath = `/firestore/groups/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 団体を更新
         * @param {GroupRequestsUpdateV1} groupRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (groupRequestsUpdateV1: GroupRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestsUpdateV1' is not null or undefined
            assertParamExists('updateGroup', 'groupRequestsUpdateV1', groupRequestsUpdateV1)
            const localVarPath = `/firestore/group/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 団体を検証
         * @param {GroupRequestsValidateV1} groupRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGroups: async (groupRequestsValidateV1: GroupRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupRequestsValidateV1' is not null or undefined
            assertParamExists('validateGroups', 'groupRequestsValidateV1', groupRequestsValidateV1)
            const localVarPath = `/firestore/groups/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 団体を作成
         * @param {GroupRequestsCreateV1} groupRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroups(groupRequestsCreateV1: GroupRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroups(groupRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 団体を削除
         * @param {GroupRequestsDeleteV1} groupRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroups(groupRequestsDeleteV1: GroupRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroups(groupRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 団体を取得
         * @param {GroupRequestReadV1} groupRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(groupRequestReadV1: GroupRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(groupRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 団体を検索
         * @param {GroupRequestsSearchV1} groupRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupsSearch(groupRequestsSearchV1: GroupRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupsSearch(groupRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 団体を更新
         * @param {GroupRequestsUpdateV1} groupRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(groupRequestsUpdateV1: GroupRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(groupRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 団体を検証
         * @param {GroupRequestsValidateV1} groupRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateGroups(groupRequestsValidateV1: GroupRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateGroups(groupRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary 団体を作成
         * @param {GroupRequestsCreateV1} groupRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroups(groupRequestsCreateV1: GroupRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createGroups(groupRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 団体を削除
         * @param {GroupRequestsDeleteV1} groupRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroups(groupRequestsDeleteV1: GroupRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroups(groupRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 団体を取得
         * @param {GroupRequestReadV1} groupRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupRequestReadV1: GroupRequestReadV1, options?: any): AxiosPromise<GroupResponseReadV1> {
            return localVarFp.getGroup(groupRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 団体を検索
         * @param {GroupRequestsSearchV1} groupRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsSearch(groupRequestsSearchV1: GroupRequestsSearchV1, options?: any): AxiosPromise<Array<GroupResponsesWithPagerV1>> {
            return localVarFp.getGroupsSearch(groupRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 団体を更新
         * @param {GroupRequestsUpdateV1} groupRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupRequestsUpdateV1: GroupRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroup(groupRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 団体を検証
         * @param {GroupRequestsValidateV1} groupRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGroups(groupRequestsValidateV1: GroupRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateGroups(groupRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary 団体を作成
     * @param {GroupRequestsCreateV1} groupRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroups(groupRequestsCreateV1: GroupRequestsCreateV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).createGroups(groupRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 団体を削除
     * @param {GroupRequestsDeleteV1} groupRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroups(groupRequestsDeleteV1: GroupRequestsDeleteV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).deleteGroups(groupRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 団体を取得
     * @param {GroupRequestReadV1} groupRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroup(groupRequestReadV1: GroupRequestReadV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).getGroup(groupRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 団体を検索
     * @param {GroupRequestsSearchV1} groupRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroupsSearch(groupRequestsSearchV1: GroupRequestsSearchV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).getGroupsSearch(groupRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 団体を更新
     * @param {GroupRequestsUpdateV1} groupRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(groupRequestsUpdateV1: GroupRequestsUpdateV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).updateGroup(groupRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 団体を検証
     * @param {GroupRequestsValidateV1} groupRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public validateGroups(groupRequestsValidateV1: GroupRequestsValidateV1, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).validateGroups(groupRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoticeApi - axios parameter creator
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary お知らせを作成
         * @param {NoticeRequestsCreateV1} noticeRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotices: async (noticeRequestsCreateV1: NoticeRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestsCreateV1' is not null or undefined
            assertParamExists('createNotices', 'noticeRequestsCreateV1', noticeRequestsCreateV1)
            const localVarPath = `/firestore/notices/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを削除
         * @param {NoticeRequestsDeleteV1} noticeRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotices: async (noticeRequestsDeleteV1: NoticeRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteNotices', 'noticeRequestsDeleteV1', noticeRequestsDeleteV1)
            const localVarPath = `/firestore/notices/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを取得
         * @param {NoticeRequestReadV1} noticeRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice: async (noticeRequestReadV1: NoticeRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestReadV1' is not null or undefined
            assertParamExists('getNotice', 'noticeRequestReadV1', noticeRequestReadV1)
            const localVarPath = `/firestore/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを検証
         * @param {NoticeRequestsValidateV1} noticeRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotices: async (noticeRequestsValidateV1: NoticeRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestsValidateV1' is not null or undefined
            assertParamExists('validateNotices', 'noticeRequestsValidateV1', noticeRequestsValidateV1)
            const localVarPath = `/firestore/notices/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary お知らせを作成
         * @param {NoticeRequestsCreateV1} noticeRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotices(noticeRequestsCreateV1: NoticeRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotices(noticeRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを削除
         * @param {NoticeRequestsDeleteV1} noticeRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotices(noticeRequestsDeleteV1: NoticeRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotices(noticeRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを取得
         * @param {NoticeRequestReadV1} noticeRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotice(noticeRequestReadV1: NoticeRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotice(noticeRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを検証
         * @param {NoticeRequestsValidateV1} noticeRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateNotices(noticeRequestsValidateV1: NoticeRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateNotices(noticeRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticeApi - factory interface
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticeApiFp(configuration)
    return {
        /**
         * 
         * @summary お知らせを作成
         * @param {NoticeRequestsCreateV1} noticeRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotices(noticeRequestsCreateV1: NoticeRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createNotices(noticeRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを削除
         * @param {NoticeRequestsDeleteV1} noticeRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotices(noticeRequestsDeleteV1: NoticeRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotices(noticeRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを取得
         * @param {NoticeRequestReadV1} noticeRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice(noticeRequestReadV1: NoticeRequestReadV1, options?: any): AxiosPromise<NoticeResponseReadV1> {
            return localVarFp.getNotice(noticeRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを検証
         * @param {NoticeRequestsValidateV1} noticeRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotices(noticeRequestsValidateV1: NoticeRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateNotices(noticeRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - object-oriented interface
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI {
    /**
     * 
     * @summary お知らせを作成
     * @param {NoticeRequestsCreateV1} noticeRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public createNotices(noticeRequestsCreateV1: NoticeRequestsCreateV1, options?: AxiosRequestConfig) {
        return NoticeApiFp(this.configuration).createNotices(noticeRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを削除
     * @param {NoticeRequestsDeleteV1} noticeRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public deleteNotices(noticeRequestsDeleteV1: NoticeRequestsDeleteV1, options?: AxiosRequestConfig) {
        return NoticeApiFp(this.configuration).deleteNotices(noticeRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを取得
     * @param {NoticeRequestReadV1} noticeRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNotice(noticeRequestReadV1: NoticeRequestReadV1, options?: AxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNotice(noticeRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを検証
     * @param {NoticeRequestsValidateV1} noticeRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public validateNotices(noticeRequestsValidateV1: NoticeRequestsValidateV1, options?: AxiosRequestConfig) {
        return NoticeApiFp(this.configuration).validateNotices(noticeRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PassportApi - axios parameter creator
 * @export
 */
export const PassportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary パスポートを作成
         * @param {PassportRequestsCreateV1} passportRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassports: async (passportRequestsCreateV1: PassportRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestsCreateV1' is not null or undefined
            assertParamExists('createPassports', 'passportRequestsCreateV1', passportRequestsCreateV1)
            const localVarPath = `/firestore/passports/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスポートを削除
         * @param {PassportRequestsDeleteV1} passportRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePassports: async (passportRequestsDeleteV1: PassportRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestsDeleteV1' is not null or undefined
            assertParamExists('deletePassports', 'passportRequestsDeleteV1', passportRequestsDeleteV1)
            const localVarPath = `/firestore/passports/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスポートを取得
         * @param {PassportRequestReadV1} passportRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassport: async (passportRequestReadV1: PassportRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestReadV1' is not null or undefined
            assertParamExists('getPassport', 'passportRequestReadV1', passportRequestReadV1)
            const localVarPath = `/firestore/passport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスポートを検索
         * @param {PassportRequestsSearchV1} passportRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassportsSearch: async (passportRequestsSearchV1: PassportRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestsSearchV1' is not null or undefined
            assertParamExists('getPassportsSearch', 'passportRequestsSearchV1', passportRequestsSearchV1)
            const localVarPath = `/firestore/passports/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスポートを更新
         * @param {PassportRequestsUpdateV1} passportRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassport: async (passportRequestsUpdateV1: PassportRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestsUpdateV1' is not null or undefined
            assertParamExists('updatePassport', 'passportRequestsUpdateV1', passportRequestsUpdateV1)
            const localVarPath = `/firestore/passport/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスポートを検証
         * @param {PassportRequestsValidateV1} passportRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassports: async (passportRequestsValidateV1: PassportRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passportRequestsValidateV1' is not null or undefined
            assertParamExists('validatePassports', 'passportRequestsValidateV1', passportRequestsValidateV1)
            const localVarPath = `/firestore/passports/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passportRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PassportApi - functional programming interface
 * @export
 */
export const PassportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PassportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary パスポートを作成
         * @param {PassportRequestsCreateV1} passportRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPassports(passportRequestsCreateV1: PassportRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPassports(passportRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスポートを削除
         * @param {PassportRequestsDeleteV1} passportRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePassports(passportRequestsDeleteV1: PassportRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePassports(passportRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスポートを取得
         * @param {PassportRequestReadV1} passportRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPassport(passportRequestReadV1: PassportRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PassportResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPassport(passportRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスポートを検索
         * @param {PassportRequestsSearchV1} passportRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPassportsSearch(passportRequestsSearchV1: PassportRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PassportResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPassportsSearch(passportRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスポートを更新
         * @param {PassportRequestsUpdateV1} passportRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassport(passportRequestsUpdateV1: PassportRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassport(passportRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスポートを検証
         * @param {PassportRequestsValidateV1} passportRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePassports(passportRequestsValidateV1: PassportRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePassports(passportRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PassportApi - factory interface
 * @export
 */
export const PassportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PassportApiFp(configuration)
    return {
        /**
         * 
         * @summary パスポートを作成
         * @param {PassportRequestsCreateV1} passportRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassports(passportRequestsCreateV1: PassportRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createPassports(passportRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスポートを削除
         * @param {PassportRequestsDeleteV1} passportRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePassports(passportRequestsDeleteV1: PassportRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deletePassports(passportRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスポートを取得
         * @param {PassportRequestReadV1} passportRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassport(passportRequestReadV1: PassportRequestReadV1, options?: any): AxiosPromise<PassportResponseReadV1> {
            return localVarFp.getPassport(passportRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスポートを検索
         * @param {PassportRequestsSearchV1} passportRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassportsSearch(passportRequestsSearchV1: PassportRequestsSearchV1, options?: any): AxiosPromise<Array<PassportResponsesWithPagerV1>> {
            return localVarFp.getPassportsSearch(passportRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスポートを更新
         * @param {PassportRequestsUpdateV1} passportRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassport(passportRequestsUpdateV1: PassportRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassport(passportRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスポートを検証
         * @param {PassportRequestsValidateV1} passportRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassports(passportRequestsValidateV1: PassportRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validatePassports(passportRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PassportApi - object-oriented interface
 * @export
 * @class PassportApi
 * @extends {BaseAPI}
 */
export class PassportApi extends BaseAPI {
    /**
     * 
     * @summary パスポートを作成
     * @param {PassportRequestsCreateV1} passportRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public createPassports(passportRequestsCreateV1: PassportRequestsCreateV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).createPassports(passportRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスポートを削除
     * @param {PassportRequestsDeleteV1} passportRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public deletePassports(passportRequestsDeleteV1: PassportRequestsDeleteV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).deletePassports(passportRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスポートを取得
     * @param {PassportRequestReadV1} passportRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public getPassport(passportRequestReadV1: PassportRequestReadV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).getPassport(passportRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスポートを検索
     * @param {PassportRequestsSearchV1} passportRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public getPassportsSearch(passportRequestsSearchV1: PassportRequestsSearchV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).getPassportsSearch(passportRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスポートを更新
     * @param {PassportRequestsUpdateV1} passportRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public updatePassport(passportRequestsUpdateV1: PassportRequestsUpdateV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).updatePassport(passportRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスポートを検証
     * @param {PassportRequestsValidateV1} passportRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassportApi
     */
    public validatePassports(passportRequestsValidateV1: PassportRequestsValidateV1, options?: AxiosRequestConfig) {
        return PassportApiFp(this.configuration).validatePassports(passportRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostApi - axios parameter creator
 * @export
 */
export const PostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 記事を作成
         * @param {PostRequestsCreateV1} postRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPosts: async (postRequestsCreateV1: PostRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestsCreateV1' is not null or undefined
            assertParamExists('createPosts', 'postRequestsCreateV1', postRequestsCreateV1)
            const localVarPath = `/firestore/posts/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を削除
         * @param {PostRequestsDeleteV1} postRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePosts: async (postRequestsDeleteV1: PostRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestsDeleteV1' is not null or undefined
            assertParamExists('deletePosts', 'postRequestsDeleteV1', postRequestsDeleteV1)
            const localVarPath = `/firestore/posts/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を取得
         * @param {PostRequestReadV1} postRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: async (postRequestReadV1: PostRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestReadV1' is not null or undefined
            assertParamExists('getPost', 'postRequestReadV1', postRequestReadV1)
            const localVarPath = `/firestore/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バナーを検索
         * @param {BannerRequestsSearchV1} bannerRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBanners: async (bannerRequestsSearchV1: BannerRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestsSearchV1' is not null or undefined
            assertParamExists('searchBanners', 'bannerRequestsSearchV1', bannerRequestsSearchV1)
            const localVarPath = `/firestore/banners/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 予約を検索
         * @param {BookingRequestsSearchV1} bookingRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBookings: async (bookingRequestsSearchV1: BookingRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestsSearchV1' is not null or undefined
            assertParamExists('searchBookings', 'bookingRequestsSearchV1', bookingRequestsSearchV1)
            const localVarPath = `/firestore/bookings/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを検索
         * @param {NoticeRequestsSearchV1} noticeRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNotices: async (noticeRequestsSearchV1: NoticeRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestsSearchV1' is not null or undefined
            assertParamExists('searchNotices', 'noticeRequestsSearchV1', noticeRequestsSearchV1)
            const localVarPath = `/firestore/notices/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を検索
         * @param {PostRequestsSearchV1} postRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPosts: async (postRequestsSearchV1: PostRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestsSearchV1' is not null or undefined
            assertParamExists('searchPosts', 'postRequestsSearchV1', postRequestsSearchV1)
            const localVarPath = `/firestore/posts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップを検索
         * @param {WorkshopRequestsSearchV1} workshopRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWorkshops: async (workshopRequestsSearchV1: WorkshopRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestsSearchV1' is not null or undefined
            assertParamExists('searchWorkshops', 'workshopRequestsSearchV1', workshopRequestsSearchV1)
            const localVarPath = `/firestore/workshops/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を更新
         * @param {PostRequestsUpdateV1} postRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: async (postRequestsUpdateV1: PostRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestsUpdateV1' is not null or undefined
            assertParamExists('updatePost', 'postRequestsUpdateV1', postRequestsUpdateV1)
            const localVarPath = `/firestore/post/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を検証
         * @param {PostRequestsValidateV1} postRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePosts: async (postRequestsValidateV1: PostRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequestsValidateV1' is not null or undefined
            assertParamExists('validatePosts', 'postRequestsValidateV1', postRequestsValidateV1)
            const localVarPath = `/firestore/posts/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostApi - functional programming interface
 * @export
 */
export const PostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 記事を作成
         * @param {PostRequestsCreateV1} postRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPosts(postRequestsCreateV1: PostRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPosts(postRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 記事を削除
         * @param {PostRequestsDeleteV1} postRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePosts(postRequestsDeleteV1: PostRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePosts(postRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 記事を取得
         * @param {PostRequestReadV1} postRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPost(postRequestReadV1: PostRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPost(postRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バナーを検索
         * @param {BannerRequestsSearchV1} bannerRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBanners(bannerRequestsSearchV1: BannerRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BannerResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBanners(bannerRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 予約を検索
         * @param {BookingRequestsSearchV1} bookingRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBookings(bookingRequestsSearchV1: BookingRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBookings(bookingRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを検索
         * @param {NoticeRequestsSearchV1} noticeRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNotices(noticeRequestsSearchV1: NoticeRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNotices(noticeRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 記事を検索
         * @param {PostRequestsSearchV1} postRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPosts(postRequestsSearchV1: PostRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPosts(postRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップを検索
         * @param {WorkshopRequestsSearchV1} workshopRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchWorkshops(workshopRequestsSearchV1: WorkshopRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkshopResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchWorkshops(workshopRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 記事を更新
         * @param {PostRequestsUpdateV1} postRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePost(postRequestsUpdateV1: PostRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePost(postRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 記事を検証
         * @param {PostRequestsValidateV1} postRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePosts(postRequestsValidateV1: PostRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePosts(postRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostApi - factory interface
 * @export
 */
export const PostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostApiFp(configuration)
    return {
        /**
         * 
         * @summary 記事を作成
         * @param {PostRequestsCreateV1} postRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPosts(postRequestsCreateV1: PostRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createPosts(postRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を削除
         * @param {PostRequestsDeleteV1} postRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePosts(postRequestsDeleteV1: PostRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deletePosts(postRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を取得
         * @param {PostRequestReadV1} postRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost(postRequestReadV1: PostRequestReadV1, options?: any): AxiosPromise<PostResponseReadV1> {
            return localVarFp.getPost(postRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バナーを検索
         * @param {BannerRequestsSearchV1} bannerRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBanners(bannerRequestsSearchV1: BannerRequestsSearchV1, options?: any): AxiosPromise<Array<BannerResponsesWithPagerV1>> {
            return localVarFp.searchBanners(bannerRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 予約を検索
         * @param {BookingRequestsSearchV1} bookingRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBookings(bookingRequestsSearchV1: BookingRequestsSearchV1, options?: any): AxiosPromise<Array<BookingResponsesWithPagerV1>> {
            return localVarFp.searchBookings(bookingRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを検索
         * @param {NoticeRequestsSearchV1} noticeRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNotices(noticeRequestsSearchV1: NoticeRequestsSearchV1, options?: any): AxiosPromise<Array<NoticeResponsesWithPagerV1>> {
            return localVarFp.searchNotices(noticeRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を検索
         * @param {PostRequestsSearchV1} postRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPosts(postRequestsSearchV1: PostRequestsSearchV1, options?: any): AxiosPromise<Array<PostResponsesWithPagerV1>> {
            return localVarFp.searchPosts(postRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップを検索
         * @param {WorkshopRequestsSearchV1} workshopRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWorkshops(workshopRequestsSearchV1: WorkshopRequestsSearchV1, options?: any): AxiosPromise<Array<WorkshopResponsesWithPagerV1>> {
            return localVarFp.searchWorkshops(workshopRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を更新
         * @param {PostRequestsUpdateV1} postRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost(postRequestsUpdateV1: PostRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updatePost(postRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を検証
         * @param {PostRequestsValidateV1} postRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePosts(postRequestsValidateV1: PostRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validatePosts(postRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
export class PostApi extends BaseAPI {
    /**
     * 
     * @summary 記事を作成
     * @param {PostRequestsCreateV1} postRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public createPosts(postRequestsCreateV1: PostRequestsCreateV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).createPosts(postRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を削除
     * @param {PostRequestsDeleteV1} postRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public deletePosts(postRequestsDeleteV1: PostRequestsDeleteV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).deletePosts(postRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を取得
     * @param {PostRequestReadV1} postRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public getPost(postRequestReadV1: PostRequestReadV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).getPost(postRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バナーを検索
     * @param {BannerRequestsSearchV1} bannerRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public searchBanners(bannerRequestsSearchV1: BannerRequestsSearchV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).searchBanners(bannerRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 予約を検索
     * @param {BookingRequestsSearchV1} bookingRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public searchBookings(bookingRequestsSearchV1: BookingRequestsSearchV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).searchBookings(bookingRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを検索
     * @param {NoticeRequestsSearchV1} noticeRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public searchNotices(noticeRequestsSearchV1: NoticeRequestsSearchV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).searchNotices(noticeRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を検索
     * @param {PostRequestsSearchV1} postRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public searchPosts(postRequestsSearchV1: PostRequestsSearchV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).searchPosts(postRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップを検索
     * @param {WorkshopRequestsSearchV1} workshopRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public searchWorkshops(workshopRequestsSearchV1: WorkshopRequestsSearchV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).searchWorkshops(workshopRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を更新
     * @param {PostRequestsUpdateV1} postRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public updatePost(postRequestsUpdateV1: PostRequestsUpdateV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).updatePost(postRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を検証
     * @param {PostRequestsValidateV1} postRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public validatePosts(postRequestsValidateV1: PostRequestsValidateV1, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).validatePosts(postRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーを作成
         * @param {UserRequestsCreateV1} userRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers: async (userRequestsCreateV1: UserRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestsCreateV1' is not null or undefined
            assertParamExists('createUsers', 'userRequestsCreateV1', userRequestsCreateV1)
            const localVarPath = `/firestore/users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを削除
         * @param {UserRequestsDeleteV1} userRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsers: async (userRequestsDeleteV1: UserRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteUsers', 'userRequestsDeleteV1', userRequestsDeleteV1)
            const localVarPath = `/firestore/users/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを取得
         * @param {UserRequestReadV1} userRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userRequestReadV1: UserRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestReadV1' is not null or undefined
            assertParamExists('getUser', 'userRequestReadV1', userRequestReadV1)
            const localVarPath = `/firestore/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを検索
         * @param {UserRequestsSearchV1} userRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersSearch: async (userRequestsSearchV1: UserRequestsSearchV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestsSearchV1' is not null or undefined
            assertParamExists('getUsersSearch', 'userRequestsSearchV1', userRequestsSearchV1)
            const localVarPath = `/firestore/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestsSearchV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン
         * @param {SignInRequestV1} signInRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (signInRequestV1: SignInRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInRequestV1' is not null or undefined
            assertParamExists('signIn', 'signInRequestV1', signInRequestV1)
            const localVarPath = `/firestore/sign/in/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン検証
         * @param {SignInValidateRequestV1} signInValidateRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInValidate: async (signInValidateRequestV1: SignInValidateRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInValidateRequestV1' is not null or undefined
            assertParamExists('signInValidate', 'signInValidateRequestV1', signInValidateRequestV1)
            const localVarPath = `/firestore/sign/in/validate/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInValidateRequestV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/firestore/sign/out/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーパスワードを更新
         * @param {UserRequestUpdatePasswordV1} userRequestUpdatePasswordV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (userRequestUpdatePasswordV1: UserRequestUpdatePasswordV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestUpdatePasswordV1' is not null or undefined
            assertParamExists('updateUserPassword', 'userRequestUpdatePasswordV1', userRequestUpdatePasswordV1)
            const localVarPath = `/firestore/user/update/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestUpdatePasswordV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー情報を更新
         * @param {UserRequestUpdateProfileV1} userRequestUpdateProfileV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (userRequestUpdateProfileV1: UserRequestUpdateProfileV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestUpdateProfileV1' is not null or undefined
            assertParamExists('updateUserProfile', 'userRequestUpdateProfileV1', userRequestUpdateProfileV1)
            const localVarPath = `/firestore/user/update/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestUpdateProfileV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを検証
         * @param {UserRequestsValidateV1} userRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUsers: async (userRequestsValidateV1: UserRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestsValidateV1' is not null or undefined
            assertParamExists('validateUsers', 'userRequestsValidateV1', userRequestsValidateV1)
            const localVarPath = `/firestore/users/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーを作成
         * @param {UserRequestsCreateV1} userRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsers(userRequestsCreateV1: UserRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsers(userRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを削除
         * @param {UserRequestsDeleteV1} userRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsers(userRequestsDeleteV1: UserRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsers(userRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを取得
         * @param {UserRequestReadV1} userRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userRequestReadV1: UserRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを検索
         * @param {UserRequestsSearchV1} userRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersSearch(userRequestsSearchV1: UserRequestsSearchV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponsesWithPagerV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersSearch(userRequestsSearchV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン
         * @param {SignInRequestV1} signInRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(signInRequestV1: SignInRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(signInRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン検証
         * @param {SignInValidateRequestV1} signInValidateRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInValidate(signInValidateRequestV1: SignInValidateRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInValidate(signInValidateRequestV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーパスワードを更新
         * @param {UserRequestUpdatePasswordV1} userRequestUpdatePasswordV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(userRequestUpdatePasswordV1: UserRequestUpdatePasswordV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword(userRequestUpdatePasswordV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー情報を更新
         * @param {UserRequestUpdateProfileV1} userRequestUpdateProfileV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(userRequestUpdateProfileV1: UserRequestUpdateProfileV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(userRequestUpdateProfileV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを検証
         * @param {UserRequestsValidateV1} userRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUsers(userRequestsValidateV1: UserRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUsers(userRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーを作成
         * @param {UserRequestsCreateV1} userRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers(userRequestsCreateV1: UserRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createUsers(userRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを削除
         * @param {UserRequestsDeleteV1} userRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsers(userRequestsDeleteV1: UserRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsers(userRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを取得
         * @param {UserRequestReadV1} userRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userRequestReadV1: UserRequestReadV1, options?: any): AxiosPromise<UserResponseReadV1> {
            return localVarFp.getUser(userRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを検索
         * @param {UserRequestsSearchV1} userRequestsSearchV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersSearch(userRequestsSearchV1: UserRequestsSearchV1, options?: any): AxiosPromise<Array<UserResponsesWithPagerV1>> {
            return localVarFp.getUsersSearch(userRequestsSearchV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン
         * @param {SignInRequestV1} signInRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(signInRequestV1: SignInRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.signIn(signInRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン検証
         * @param {SignInValidateRequestV1} signInValidateRequestV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInValidate(signInValidateRequestV1: SignInValidateRequestV1, options?: any): AxiosPromise<void> {
            return localVarFp.signInValidate(signInValidateRequestV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut(options?: any): AxiosPromise<void> {
            return localVarFp.signOut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーパスワードを更新
         * @param {UserRequestUpdatePasswordV1} userRequestUpdatePasswordV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(userRequestUpdatePasswordV1: UserRequestUpdatePasswordV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserPassword(userRequestUpdatePasswordV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー情報を更新
         * @param {UserRequestUpdateProfileV1} userRequestUpdateProfileV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(userRequestUpdateProfileV1: UserRequestUpdateProfileV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfile(userRequestUpdateProfileV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを検証
         * @param {UserRequestsValidateV1} userRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUsers(userRequestsValidateV1: UserRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateUsers(userRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーを作成
     * @param {UserRequestsCreateV1} userRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUsers(userRequestsCreateV1: UserRequestsCreateV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUsers(userRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを削除
     * @param {UserRequestsDeleteV1} userRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUsers(userRequestsDeleteV1: UserRequestsDeleteV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUsers(userRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを取得
     * @param {UserRequestReadV1} userRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userRequestReadV1: UserRequestReadV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを検索
     * @param {UserRequestsSearchV1} userRequestsSearchV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersSearch(userRequestsSearchV1: UserRequestsSearchV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsersSearch(userRequestsSearchV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン
     * @param {SignInRequestV1} signInRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signIn(signInRequestV1: SignInRequestV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).signIn(signInRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン検証
     * @param {SignInValidateRequestV1} signInValidateRequestV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signInValidate(signInValidateRequestV1: SignInValidateRequestV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).signInValidate(signInValidateRequestV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signOut(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).signOut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーパスワードを更新
     * @param {UserRequestUpdatePasswordV1} userRequestUpdatePasswordV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPassword(userRequestUpdatePasswordV1: UserRequestUpdatePasswordV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPassword(userRequestUpdatePasswordV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー情報を更新
     * @param {UserRequestUpdateProfileV1} userRequestUpdateProfileV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserProfile(userRequestUpdateProfileV1: UserRequestUpdateProfileV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserProfile(userRequestUpdateProfileV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを検証
     * @param {UserRequestsValidateV1} userRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateUsers(userRequestsValidateV1: UserRequestsValidateV1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).validateUsers(userRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkshopApi - axios parameter creator
 * @export
 */
export const WorkshopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ワークショップを作成
         * @param {WorkshopRequestsCreateV1} workshopRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkshops: async (workshopRequestsCreateV1: WorkshopRequestsCreateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestsCreateV1' is not null or undefined
            assertParamExists('createWorkshops', 'workshopRequestsCreateV1', workshopRequestsCreateV1)
            const localVarPath = `/firestore/workshops/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestsCreateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップを削除
         * @param {WorkshopRequestsDeleteV1} workshopRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkshops: async (workshopRequestsDeleteV1: WorkshopRequestsDeleteV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestsDeleteV1' is not null or undefined
            assertParamExists('deleteWorkshops', 'workshopRequestsDeleteV1', workshopRequestsDeleteV1)
            const localVarPath = `/firestore/workshops/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestsDeleteV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップを取得
         * @param {WorkshopRequestReadV1} workshopRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkshop: async (workshopRequestReadV1: WorkshopRequestReadV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestReadV1' is not null or undefined
            assertParamExists('getWorkshop', 'workshopRequestReadV1', workshopRequestReadV1)
            const localVarPath = `/firestore/workshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestReadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バナーを更新
         * @param {BannerRequestsUpdateV1} bannerRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner: async (bannerRequestsUpdateV1: BannerRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerRequestsUpdateV1' is not null or undefined
            assertParamExists('updateBanner', 'bannerRequestsUpdateV1', bannerRequestsUpdateV1)
            const localVarPath = `/firestore/banner/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 予約を更新
         * @param {BookingRequestsUpdateV1} bookingRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking: async (bookingRequestsUpdateV1: BookingRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequestsUpdateV1' is not null or undefined
            assertParamExists('updateBooking', 'bookingRequestsUpdateV1', bookingRequestsUpdateV1)
            const localVarPath = `/firestore/booking/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを更新
         * @param {NoticeRequestsUpdateV1} noticeRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotice: async (noticeRequestsUpdateV1: NoticeRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeRequestsUpdateV1' is not null or undefined
            assertParamExists('updateNotice', 'noticeRequestsUpdateV1', noticeRequestsUpdateV1)
            const localVarPath = `/firestore/notice/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップを更新
         * @param {WorkshopRequestsUpdateV1} workshopRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkshop: async (workshopRequestsUpdateV1: WorkshopRequestsUpdateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestsUpdateV1' is not null or undefined
            assertParamExists('updateWorkshop', 'workshopRequestsUpdateV1', workshopRequestsUpdateV1)
            const localVarPath = `/firestore/workshop/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestsUpdateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ワークショップを検証
         * @param {WorkshopRequestsValidateV1} workshopRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWorkshops: async (workshopRequestsValidateV1: WorkshopRequestsValidateV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopRequestsValidateV1' is not null or undefined
            assertParamExists('validateWorkshops', 'workshopRequestsValidateV1', workshopRequestsValidateV1)
            const localVarPath = `/firestore/workshops/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workshopRequestsValidateV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkshopApi - functional programming interface
 * @export
 */
export const WorkshopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkshopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ワークショップを作成
         * @param {WorkshopRequestsCreateV1} workshopRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkshops(workshopRequestsCreateV1: WorkshopRequestsCreateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkshops(workshopRequestsCreateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップを削除
         * @param {WorkshopRequestsDeleteV1} workshopRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkshops(workshopRequestsDeleteV1: WorkshopRequestsDeleteV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkshops(workshopRequestsDeleteV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップを取得
         * @param {WorkshopRequestReadV1} workshopRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkshop(workshopRequestReadV1: WorkshopRequestReadV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopResponseReadV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkshop(workshopRequestReadV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バナーを更新
         * @param {BannerRequestsUpdateV1} bannerRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBanner(bannerRequestsUpdateV1: BannerRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBanner(bannerRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 予約を更新
         * @param {BookingRequestsUpdateV1} bookingRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBooking(bookingRequestsUpdateV1: BookingRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBooking(bookingRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを更新
         * @param {NoticeRequestsUpdateV1} noticeRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotice(noticeRequestsUpdateV1: NoticeRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotice(noticeRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップを更新
         * @param {WorkshopRequestsUpdateV1} workshopRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkshop(workshopRequestsUpdateV1: WorkshopRequestsUpdateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkshop(workshopRequestsUpdateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ワークショップを検証
         * @param {WorkshopRequestsValidateV1} workshopRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWorkshops(workshopRequestsValidateV1: WorkshopRequestsValidateV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateWorkshops(workshopRequestsValidateV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkshopApi - factory interface
 * @export
 */
export const WorkshopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkshopApiFp(configuration)
    return {
        /**
         * 
         * @summary ワークショップを作成
         * @param {WorkshopRequestsCreateV1} workshopRequestsCreateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkshops(workshopRequestsCreateV1: WorkshopRequestsCreateV1, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkshops(workshopRequestsCreateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップを削除
         * @param {WorkshopRequestsDeleteV1} workshopRequestsDeleteV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkshops(workshopRequestsDeleteV1: WorkshopRequestsDeleteV1, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkshops(workshopRequestsDeleteV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップを取得
         * @param {WorkshopRequestReadV1} workshopRequestReadV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkshop(workshopRequestReadV1: WorkshopRequestReadV1, options?: any): AxiosPromise<WorkshopResponseReadV1> {
            return localVarFp.getWorkshop(workshopRequestReadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バナーを更新
         * @param {BannerRequestsUpdateV1} bannerRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner(bannerRequestsUpdateV1: BannerRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateBanner(bannerRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 予約を更新
         * @param {BookingRequestsUpdateV1} bookingRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(bookingRequestsUpdateV1: BookingRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateBooking(bookingRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを更新
         * @param {NoticeRequestsUpdateV1} noticeRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotice(noticeRequestsUpdateV1: NoticeRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateNotice(noticeRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップを更新
         * @param {WorkshopRequestsUpdateV1} workshopRequestsUpdateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkshop(workshopRequestsUpdateV1: WorkshopRequestsUpdateV1, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkshop(workshopRequestsUpdateV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ワークショップを検証
         * @param {WorkshopRequestsValidateV1} workshopRequestsValidateV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWorkshops(workshopRequestsValidateV1: WorkshopRequestsValidateV1, options?: any): AxiosPromise<void> {
            return localVarFp.validateWorkshops(workshopRequestsValidateV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkshopApi - object-oriented interface
 * @export
 * @class WorkshopApi
 * @extends {BaseAPI}
 */
export class WorkshopApi extends BaseAPI {
    /**
     * 
     * @summary ワークショップを作成
     * @param {WorkshopRequestsCreateV1} workshopRequestsCreateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public createWorkshops(workshopRequestsCreateV1: WorkshopRequestsCreateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).createWorkshops(workshopRequestsCreateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップを削除
     * @param {WorkshopRequestsDeleteV1} workshopRequestsDeleteV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public deleteWorkshops(workshopRequestsDeleteV1: WorkshopRequestsDeleteV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).deleteWorkshops(workshopRequestsDeleteV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップを取得
     * @param {WorkshopRequestReadV1} workshopRequestReadV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public getWorkshop(workshopRequestReadV1: WorkshopRequestReadV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).getWorkshop(workshopRequestReadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バナーを更新
     * @param {BannerRequestsUpdateV1} bannerRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public updateBanner(bannerRequestsUpdateV1: BannerRequestsUpdateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).updateBanner(bannerRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 予約を更新
     * @param {BookingRequestsUpdateV1} bookingRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public updateBooking(bookingRequestsUpdateV1: BookingRequestsUpdateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).updateBooking(bookingRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを更新
     * @param {NoticeRequestsUpdateV1} noticeRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public updateNotice(noticeRequestsUpdateV1: NoticeRequestsUpdateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).updateNotice(noticeRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップを更新
     * @param {WorkshopRequestsUpdateV1} workshopRequestsUpdateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public updateWorkshop(workshopRequestsUpdateV1: WorkshopRequestsUpdateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).updateWorkshop(workshopRequestsUpdateV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ワークショップを検証
     * @param {WorkshopRequestsValidateV1} workshopRequestsValidateV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopApi
     */
    public validateWorkshops(workshopRequestsValidateV1: WorkshopRequestsValidateV1, options?: AxiosRequestConfig) {
        return WorkshopApiFp(this.configuration).validateWorkshops(workshopRequestsValidateV1, options).then((request) => request(this.axios, this.basePath));
    }
}


