
import React, { useContext, ReactNode } from 'react';
import { UserModel } from '~/openapi/typescript-axios/version';

type Props = {
  children: ReactNode;
  userModel: UserModel;
};

interface UserMyProfileValue {

}

const UserMyProfileContext = React.createContext<UserMyProfileValue | null>(null);

export function useUserMyProfile(): UserMyProfileValue {
  const state = useContext(UserMyProfileContext);

  if (!state) {
    throw new Error('useUserMyProfile must be used within UserMyProfileProvider');
  }

  return state;
}

export function UserMyProfileProvider({ children, userModel }: Props) {
  const providerValue = {
    userModel
  };

  return (
    <UserMyProfileContext.Provider value={providerValue}>
      {children}
    </UserMyProfileContext.Provider>
  );
}
