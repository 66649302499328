
import { nanoid } from 'nanoid';
import React, { useContext, ReactNode, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PostModel, PostType } from '~/openapi/typescript-axios/version';
import { useAppState } from '~/providers/AppStateProvider';
import { useParentPassport } from '~/providers/ParentPassportProvider';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';

type Props = {
  children: ReactNode;
};

interface UserPostsCreateValue {
  postModel: PostModel;
  setPostModel: (postModel: PostModel) => void;
}

const UserPostsCreateContext = React.createContext<UserPostsCreateValue | null>(null);

export function useUserPostsCreate(): UserPostsCreateValue {
  const state = useContext(UserPostsCreateContext);

  if (!state) {
    throw new Error('useUserPostsCreate must be used within UserPostsCreateProvider');
  }

  return state;
}

export function UserPostsCreateProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { passportModel } = useParentPassport();
  const { workshopModel } = useParentWorkshop();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);

  const { passportId, workshopId } = useParams<any>();

  const newPostId = nanoid();
  const now = Number(new Date());
  const [ postModel, setPostModel ] = useState<PostModel>({
    id: newPostId,
    createdAt: now,
    updatedAt: now,
    photos: [],
    title: '',
    titleToken: {uniGram: [], biGram: [], triGram: []},
    content: '',
    contentToken: {uniGram: [], biGram: [], triGram: []},
    contentData: '',
    cityId: '',
    townId: '',
    postType: (queryParams.get('postType') as PostType) || PostType.Attractive,
    groupId: '',
    passportId,
    userId: '',
    workshopId,
    tags: [],
    isHidden: false,
    isWaitingApproved: false,
    isApproved: false,
    isInTopCarouselList: false,
    isFinishedWriting: false,
    // userReaction: {},
    workshopStartAt: now,
    workshopEndAt: now,
    mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    contentPhotos: [],
  });

  useEffect(() => {
    if (params.passportId !== passportId || params.workshopId !== workshopId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), {
        passportId,
        workshopId,
      }));
    }
  }, [passportId, workshopId, params, setParams]);

  useEffect(() => {
    if (passportModel?.id === passportId
      && workshopModel?.id === workshopId
      && !postModel.passportId
      && !postModel.workshopId
    ) {
      postModel.passportId = passportId;
      postModel.workshopId = workshopId;
      postModel.workshopStartAt = workshopModel?.workshopStartAt || 0;
      postModel.workshopEndAt = workshopModel?.workshopEndAt || 0;
      setPostModel(Object.assign({}, postModel));
    }
  }, [passportId, workshopId, passportModel, workshopModel, postModel]);

  const providerValue = {
    postModel,
    setPostModel,
  };

  return (
    <UserPostsCreateContext.Provider value={providerValue}>
      {children}
    </UserPostsCreateContext.Provider>
  );
}
