import React, { useCallback, useState } from 'react';
import { useSignIn } from './Provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { ROUTES } from '~/constants/routes';
import { generatePath, useHistory } from 'react-router-dom';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { SignInRequest, SignInType } from '~/openapi/typescript-axios/version';
import { useAuth } from '~/providers/AuthProvider';
import InputWithValidation from '~/components/inputWithValidation';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import showError from '~/helpers/toaster/message/showError';
import showInfo from '~/helpers/toaster/message/showInfo';
import inputForm, { InputType } from '~/helpers/toaster/form/inputForm';
import LocalbridgeBody from '~/components/localbridgeBody';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import './index.scss';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';

type Props = {};

const SignInRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { signIn } = useLocalbridgeUserApi();
  const { setSignInType } = useAuth();
  const { doSendPasswordResetEmail } = useSignIn();

  const [ isSendingEmail, setIsSendingEmail ] = useState<boolean>(false);
  const [ signInRequest, setSignInRequest ] = useState<SignInRequest>({
    email: '', password: '', signInType: SignInType.Normal,
  });

  const handleAdminSignInWithEmailAndPassword = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSignInType(signInRequest.signInType);

    try {
      const { groupModel, userModel } = await signIn(signInRequest);

      if (!userModel) {
        history.push(ROUTES.SIGN_UP);
        return;
      }

      showSuccess({
        title: 'ログイン',
        text: 'こんにちは!',
        timer: 1000,
      });

      history.push((signInRequest.signInType === SignInType.GroupManager && groupModel)
        ? generatePath(ROUTES.GROUP, {groupId: groupModel.id}) : ROUTES.HOME
      );
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: signInRequest.signInType === SignInType.GroupManager ? '団体ログイン' : 'ログイン',
          text: (error as Error).message,
        });
        throw error;
      }
    }
  }, [signInRequest, history, signIn, setSignInType]);

  const onChangeSignInRequestInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof SignInRequest,
  ) => {
    if (!signInRequest) {
      return;
    }

    signInRequest[key as string] = event.currentTarget.value;
    setSignInRequest(Object.assign({}, signInRequest));
  }, [signInRequest, setSignInRequest]);

  const handleFotgotPassword = async () => {
    const res: any = await inputForm({
      title: 'パスワードの再設定',
      confirmButtonText: '送信',
      showDenyButton: false,
    }, {
      message: {
        label: '',
        value: (
          <p className='text-center'>
            <strong>{'登録されているメールアドレスを入力してください。'}</strong><br />
            <strong>{'パスワード再設定用のメールをお送りします。'}</strong>
          </p>
        ),
        type: InputType.Label,
        column: [0, 12],
      },
      email: { label: 'メールアドレス', value: '', type: InputType.Text },
    });

    if (res?.value) {
      const {email} = res.value;
      try {
        setIsSendingEmail(true);
        await doSendPasswordResetEmail(email);
        showInfo({
          title: 'パスワードの再設定',
          text: `${email} にパスワード再設定用のメールを送りました。`,
        });
      } catch (error: any) {
        showError({
          title: 'パスワードの再設定',
          text: '登録されてないアカウントです',
        });
      } finally {
        setIsSendingEmail(false);
      }
    }
  };

  return (
    <>
      {isSendingEmail ? (
        <div
          className='position-absolute top-0 d-flex'
          style={{width: '100%', height: '100%', zIndex: 1, backgroundColor: 'rgba(0,0,0,0.3)'}}
        >
          <div className='m-auto'>
            <FontAwesomeIcon spin icon={faSync} className='fs-1'/>
          </div>
        </div>
      ):(<></>)}

      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title="ログイン" enTitle="Login" />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <form onSubmit={handleAdminSignInWithEmailAndPassword}>
          <div className={'login'}>
            <div className={'login_contents'}>
              <div className={'login_contents__inner'}>
                <InputWithValidation<SignInRequest>
                  data={signInRequest}
                  type='email'
                  validationKey={'email'}
                  validationClassName='SignInRequest'
                  className='login_contents__input'
                  placeholder='メールアドレス'
                  onChange={(event) => {
                    onChangeSignInRequestInput(event, 'email');
                  }}
                />

                <InputWithValidation<SignInRequest>
                  data={signInRequest}
                  type='password'
                  validationKey={'password'}
                  validationClassName='SignInRequest'
                  className='login_contents__input mt-4'
                  placeholder='パスワード'
                  onChange={(event) => {
                    onChangeSignInRequestInput(event, 'password');
                  }}
                />

                <button className={'login_contents__submit'} type="submit">
                  ログイン
                </button>
                <a className={'login_contents__forgot'} onClick={() => handleFotgotPassword()}>
                  パスワードを忘れた方はこちらから
                </a>

                {signInRequest.signInType === SignInType.Normal ? (
                  <div className={'login_contents__btns'}>
                    <a
                      className={'login_contents__btn'}
                      onClick={() => {
                        signInRequest.signInType = SignInType.GroupManager;
                        setSignInRequest(Object.assign({}, signInRequest));
                      }}
                    >
                      組織・団体様はこちらから
                    </a>
                    <a className={'login_contents__btn'} onClick={() => history.push(ROUTES.SIGN_UP)}>
                      未登録の方はこちらから
                    </a>
                  </div>
                ) : (
                  <div className={'login_contents__btns'}>
                    <a className={'login_contents__btn'} onClick={() => history.push(ROUTES.QNA)}>
                      組織・団体のアカウント発行を
                      <br className={'is-sp'} />
                      ご希望の方はこちら
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className={'login__link'}>
              <a href="#">お困りの方は問合せフォームへ</a>
            </div>
          </div>
        </form>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default SignInRenderer;
