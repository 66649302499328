import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  LOCALBRIDGE_PATH,
  firestore,
  functions,
} from '~/constants/firebase';
import { httpsCallable } from 'firebase/functions';
import {
  WorkshopRequestsDelete,
  WorkshopRequestCreate,
  WorkshopRequestUpdate,
  WorkshopRequestsValidate,
  WorkshopResponsesWithPager,
  LocalbridgeWorkshopUpdateUserReactionRequest,
  WorkshopRequestsSearch,
  WorkshopModel,
  WorkshopRequestRead,
  WorkshopResponseRead,
  testEmptyInput,
  testStringMax80,
  testStringMax40000,
  testDateReversedStartAndEnd,
  testStringMax200,
} from '~/openapi/typescript-axios/version';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { getSearchStringQuery } from './helpers/getSearchStringQuery';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeWorkshopApiValue {
  validateWorkshops: (workshopRequestsValidate: WorkshopRequestsValidate) => Promise<MessageResponse>;
  createWorkshops: (workshopRequestCreates: Array<WorkshopRequestCreate>) => Promise<void>;
  getWorkshop: (workshopRequestRead: WorkshopRequestRead) => Promise<WorkshopResponseRead|null>;
  updateWorkshop: (workshopRequestUpdate: WorkshopRequestUpdate) => Promise<void>;
  deleteWorkshops: (deleteWorkshopsRequest: WorkshopRequestsDelete) => Promise<void>;
  searchWorkshops: (
    workshopRequestsSearch: WorkshopRequestsSearch
  ) => Promise<Array<WorkshopResponsesWithPager>>;
  localbridgeWorkshopUpdateUserReaction: (
    localbridgeWorkshopUpdateUserReactionRequest: LocalbridgeWorkshopUpdateUserReactionRequest
  ) => Promise<void>;
}

const LocalbridgeWorkshopApiContext = React.createContext<LocalbridgeWorkshopApiValue | null>(null);

export function useLocalbridgeWorkshopApi(): LocalbridgeWorkshopApiValue {
  const state = useContext(LocalbridgeWorkshopApiContext);

  if (!state) {
    throw new Error('useLocalbridgeWorkshopApi must be used within LocalbridgeWorkshopApiProvider');
  }

  return state;
}

export function LocalbridgeWorkshopApiProvider({ children }: Props) {
  const validateWorkshops = useCallback(async (workshopRequestsValidate: WorkshopRequestsValidate) => {
    const workshopModels = workshopRequestsValidate.workshopRequests.map((workshopRequest) => {
      return workshopRequest.workshopModel;
    });

    const workshopValidator = new Validator<WorkshopModel>('WorkshopModel', {
      id: [
        async (workshop) => testEmptyInput(workshop.id),
        async (workshop) => testStringMax80(workshop.id),
      ],
      photos: [
      ],
      createdAt: [
        async (workshop) => testEmptyInput(workshop.createdAt),
        async (workshop) => testDateReversedStartAndEnd(workshop.createdAt, workshop.updatedAt),
      ],
      updatedAt: [
        async (workshop) => testEmptyInput(workshop.updatedAt),
      ],
      cityId: [
        async (workshop) => testEmptyInput(workshop.cityId),
        async (workshop) => testStringMax80(workshop.cityId),
      ],
      townId: [
        async (workshop) => testEmptyInput(workshop.townId),
        async (workshop) => testStringMax80(workshop.townId),
      ],
      detailAddress1: [
        async (booking) => testStringMax200(booking.detailAddress1),
      ],
      detailAddress2: [
        async (booking) => testStringMax200(booking.detailAddress2),
      ],
      title: [
        async (workshop) => testEmptyInput(workshop.title),
        async (workshop) => testStringMax80(workshop.title),
      ],
      titleToken: [
        async (workshop) => testEmptyInput(workshop.titleToken),
      ],
      overview: [
        async (workshop) => testEmptyInput(workshop.overview),
        async (workshop) => testStringMax40000(workshop.overview),
      ],
      overviewToken: [
        async (workshop) => testEmptyInput(workshop.overviewToken),
      ],
      detail: [
        async (workshop) => testEmptyInput(workshop.detail),
        async (workshop) => testStringMax40000(workshop.detail),
      ],
      detailToken: [
        async (workshop) => testEmptyInput(workshop.detailToken),
      ],
      groupId: [
        async (workshop) => testEmptyInput(workshop.groupId),
        async (workshop) => testStringMax80(workshop.groupId),
      ],
      workshopStartAt: [
        async (workshop) => testEmptyInput(workshop.workshopStartAt),
        async (workshop) => testDateReversedStartAndEnd(workshop.workshopStartAt, workshop.workshopEndAt),
      ],
      workshopEndAt: [
        async (workshop) => testEmptyInput(workshop.workshopEndAt),
      ],
      bookingStartAt: [
        async (workshop) => testEmptyInput(workshop.bookingStartAt),
        async (workshop) => testDateReversedStartAndEnd(workshop.bookingStartAt, workshop.bookingEndAt),
      ],
      bookingEndAt: [
        async (workshop) => testEmptyInput(workshop.bookingEndAt),
      ],
      mainPhoto: [
      ],
      contentPhotos: [
      ],
      isHidden: [],
      isWaitingApproved: [],
      isApproved: [],
      isInTopCarouselList: [],
      isFinishedWriting: [],
    });

    return workshopValidator.validate(workshopModels);
  }, []);

  const createWorkshops = useCallback(async (workshopRequestCreates: Array<WorkshopRequestCreate>) => {
    if (!workshopRequestCreates.length) {
      return;
    }

    const messageResponses = await validateWorkshops({
      isOnSubmit: true,
      workshopRequests: workshopRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const workshopRequestCreate of workshopRequestCreates) {
      const { id } = workshopRequestCreate.workshopModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/workshops/${id}`,), {
        ...Object.assign({}, workshopRequestCreate.workshopModel)
      });
    }

    await batch.commit();
  }, [validateWorkshops]);

  const getWorkshop = useCallback(async (workshopRequestRead: WorkshopRequestRead) => {
    const { workshopId } = workshopRequestRead;
    const workshopModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/workshops/${workshopId}`,)))
      .data() as WorkshopModel|null;
    return workshopModel ? {workshopModel} : null;
  }, []);

  const updateWorkshop = useCallback(async (workshopRequestUpdate: WorkshopRequestUpdate) => {
    const messageResponses = await validateWorkshops({
      isOnSubmit: true,
      workshopRequests: [workshopRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = workshopRequestUpdate.workshopModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/workshops/${id}`,), {
      ...Object.assign({}, workshopRequestUpdate)
    });
  }, [validateWorkshops]);

  const deleteWorkshops = useCallback(async (deleteWorkshopsRequest: WorkshopRequestsDelete) => {
    const workshopIds = deleteWorkshopsRequest?.workshopIds || [];

    if (!workshopIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const workshopId of workshopIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/workshops/${workshopId}`));
    }

    await batch.commit();
  }, []);

  const searchWorkshops = useCallback(async (workshopRequestsSearch: WorkshopRequestsSearch) => {
    const {
      size,
      page,
      cityId,
      groupId,
      workshopStartAt,
      workshopEndAt,
      shouldShowNotHidden,
      shouldShowApproved,
      shouldShowTopCarouselList,
      shouldShowFinishedWriting,
      searchString,
    } = workshopRequestsSearch;

    let resultsTmp: Array<WorkshopModel> = [];
    if (workshopStartAt || workshopEndAt) {
      const qryStartAt = query(
        collection(firestore, `${LOCALBRIDGE_PATH}/workshops`),
        and(
          ...[
            cityId ? where('cityId', '==', cityId) : null,
            groupId ? where('groupId', '==', groupId) : null,
            shouldShowNotHidden ? where('isHidden', '==', false) : null,
            shouldShowApproved ? where('isApproved', '==', true) : null,
            shouldShowTopCarouselList ? where('isInTopCarouselList', '==', true) : null,
            shouldShowFinishedWriting ? where('isFinishedWriting', '==', true) : null,
            workshopStartAt ? where('workshopStartAt', '>=', workshopStartAt) : null,
            workshopEndAt ? where('workshopStartAt', '<=', workshopEndAt) : null,
            searchString
              ? getSearchStringQuery(searchString, ['titleToken', 'overviewToken', 'detailToken'])
              : null
          ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
        ),
        !searchString ? orderBy('workshopStartAt', 'asc') : limit(1000),
      );

      const qryEndAt = query(
        collection(firestore, `${LOCALBRIDGE_PATH}/workshops`),
        and(
          ...[
            cityId ? where('cityId', '==', cityId) : null,
            groupId ? where('groupId', '==', groupId) : null,
            shouldShowNotHidden ? where('isHidden', '==', false) : null,
            shouldShowApproved ? where('isApproved', '==', true) : null,
            shouldShowTopCarouselList ? where('isInTopCarouselList', '==', true) : null,
            shouldShowFinishedWriting ? where('isFinishedWriting', '==', true) : null,
            workshopStartAt ? where('workshopEndAt', '>=', workshopStartAt) : null,
            workshopEndAt ? where('workshopEndAt', '<=', workshopEndAt) : null,
            searchString
              ? getSearchStringQuery(searchString, ['titleToken', 'overviewToken', 'detailToken'])
              : null
          ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
        ),
        !searchString ? orderBy('workshopEndAt', 'desc') : limit(1000),
      );

      const [ qryStartSnap, qryEndAtSnap ] = await Promise.all([getDocs(qryStartAt), getDocs(qryEndAt)]);
      const resultsQryStartAtTmp = qryStartSnap.docs.map((doc) => doc.data()) as Array<WorkshopModel>;
      const resultsQryEndAtTmp = qryEndAtSnap.docs.map((doc) => doc.data()) as Array<WorkshopModel>;      
      const resultQryStartEndMap: {[workshopId: string]: WorkshopModel} = {};

      for (const worknote of resultsQryStartAtTmp) {
        resultQryStartEndMap[worknote.id] = worknote;
      }

      for (const worknote of resultsQryEndAtTmp) {
        resultQryStartEndMap[worknote.id] = worknote;
      }

      resultsTmp = Object.values(resultQryStartEndMap);
    } else {
      const qry = query(
        collection(firestore, `${LOCALBRIDGE_PATH}/workshops`),
        and(
          ...[
            cityId ? where('cityId', '==', cityId) : null,
            groupId ? where('groupId', '==', groupId) : null,
            shouldShowNotHidden ? where('isHidden', '==', false) : null,
            shouldShowApproved ? where('isApproved', '==', true) : null,
            shouldShowTopCarouselList ? where('isInTopCarouselList', '==', true) : null,
            shouldShowFinishedWriting ? where('isFinishedWriting', '==', true) : null,
            searchString
              ? getSearchStringQuery(searchString, ['titleToken', 'overviewToken', 'detailToken'])
              : null
          ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
        ),
        !searchString ? orderBy('workshopStartAt', 'asc') : limit(1000),
      );

      resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<WorkshopModel>;
    }

    resultsTmp.sort((resultA, resultB) => resultB.workshopStartAt - resultA.workshopStartAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<WorkshopResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const localbridgeWorkshopUpdateUserReaction = useCallback(async (
    localbridgeWorkshopUpdateUserReactionRequest: LocalbridgeWorkshopUpdateUserReactionRequest
  ) => {
    await httpsCallable(functions, 'localbridgeWorkshopUpdateUserReaction')(
      localbridgeWorkshopUpdateUserReactionRequest
    );
  }, []);

  const providerValue = {
    validateWorkshops,
    createWorkshops,
    getWorkshop,
    updateWorkshop,
    deleteWorkshops,
    searchWorkshops,
    localbridgeWorkshopUpdateUserReaction,
  };

  return (
    <LocalbridgeWorkshopApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeWorkshopApiContext.Provider>
  );
}
