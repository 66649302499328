
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import React, { useContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { auth } from '~/constants/firebase';

type Props = {
  children: ReactNode;
};

interface UserUpdatePasswordResetValue {
  actionCode: string|null,
  handleResetPassword: (newPassword: string) => Promise<void>;
}

const UserUpdatePasswordResetContext = React.createContext<UserUpdatePasswordResetValue | null>(null);

export function useUserUpdatePasswordReset(): UserUpdatePasswordResetValue {
  const state = useContext(UserUpdatePasswordResetContext);

  if (!state) {
    throw new Error('useUserUpdatePasswordReset must be used within UserUpdatePasswordResetProvider');
  }

  return state;
}

export function UserUpdatePasswordResetProvider({ children }: Props) {
  const search = useLocation().search;
  const actionCode = new URLSearchParams(search).get('oobCode');
  const [ email, setEmail ] = useState<string>();
  const history = useHistory();

  const handleResetPassword = useCallback(async (newPassword: string) => {
    if (!actionCode || !email) {
      return;
    }

    await confirmPasswordReset(auth, actionCode, newPassword);
    await signInWithEmailAndPassword(auth, email, newPassword);
  }, [actionCode, email]);

  useEffect(() => {
    if (!actionCode) {
      return;
    }

    verifyPasswordResetCode(auth, actionCode).then((resEmail) => {
      setEmail(resEmail);
    }).catch(() => {
      history.push(ROUTES.HOME);
    });
  },[actionCode, history]);

  const providerValue = {
    actionCode,
    handleResetPassword,
  };

  return (
    <UserUpdatePasswordResetContext.Provider value={providerValue}>
      {children}
    </UserUpdatePasswordResetContext.Provider>
  );
}
