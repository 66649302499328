import React, { useCallback, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import './index.scss';
import { useAdminGroupEditWithUser } from './Provider';
import { AdminLeftSidebar } from '../../components/leftSidebar';
import { ROUTES } from '~/constants/routes';
import { GroupModel, GroupType, PhotoMeta, UserModel } from '~/openapi/typescript-axios/version';
import InputWithValidation from '~/components/inputWithValidation';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { DefaultValue } from '~/constants/defaultValue';
import { LOCALBRIDGE_PATH } from '~/constants/firebase';
import { uploadFileWithProgress } from '~/helpers/uploader';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { getImageWidthHeight } from '~/helpers/getImageWidthHeight';
import showError from '~/helpers/toaster/message/showError';
import PreventAutoComplete from '~/components/preventAutoComplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AdminContainer } from '../../components/container';

type Props = {};

const AdminGroupEditWithUserRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    cities,
    towns,
    selectedCity,
    selectedTown,
    group,
    user,
    isEditingGroup,
    setGroup,
    setUser,
    setIsEditingGroup,
  } = useAdminGroupEditWithUser();

  const { updateUserProfiles, deleteUsers } = useLocalbridgeUserApi();
  const { validateGroups, updateGroup, deleteGroups } = useLocalbridgeGroupApi();

  const [ fileMapWillBeCreated, setFileMapWillBeCreated ] = useState<{[fileId: string]: File}>({});

  const handleUpdateGroupWithUser = useCallback(async () => {
    if (!group?.id || !user?.id) {
      return;
    }

    setIsEditingGroup(true);

    group.nameToken = getSearchTokenMap(group.name);
    user.emailToken = getSearchTokenMap(user.email);

    const messageResponses = await validateGroups({
      isOnSubmit: true,
      groupRequests: [{groupModel: group}],
    });
    if (messageResponses.validations?.length) {
      showLocalbridgeApiValidationErrorMessage(new LocalbridgeApiError(messageResponses, 412));
    }

    try {
      await updateUserProfiles([{userModel: user}]);
    } catch (error) {
      setIsEditingGroup(false);
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: '組織・団体作成',
          text: (error as Error).message,
        });

        throw error;
      }

      return;
    }

    try {
      for (const fileId in fileMapWillBeCreated) {
        const photoRef = `${LOCALBRIDGE_PATH}/groups/${group.id}/photos/${fileId}`;
        const downloadUrl = await uploadFileWithProgress(photoRef, fileMapWillBeCreated[fileId]);
        const photoMeta = group.photos.find((photo) => photo.id === fileId);
        if (photoMeta && downloadUrl) {
          photoMeta.url = downloadUrl;
        }
      }

      await updateGroup({groupModel: group});
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: '組織・団体作成',
          text: (error as Error).message,
        });

        throw error;
      }

      return;
    } finally {
      setIsEditingGroup(false);
    }

    showSuccess({
      title: '組織・団体詳細',
      text: '公開しました',
      timer: 1000,
    });
  }, [
    user,
    group,
    fileMapWillBeCreated,
    updateUserProfiles,
    validateGroups,
    updateGroup,
    setIsEditingGroup,
  ]);

  const handleDeleteGroupWithUser = useCallback(async () => {
    if (!group?.id || !user?.id) {
      return;
    }

    await deleteGroups({groupIds: [group?.id]});
    await deleteUsers({userIds: [user?.id]});

    showSuccess({
      title: '組織・団体詳細',
      text: '削除しました',
      timer: 1000,
    });

    history.push(ROUTES.ADMIN_GROUPS);
  }, [history, group?.id, user?.id, deleteGroups, deleteUsers]);

  const onChangeGroupInput = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof GroupModel,
  ) => {
    if (!group) {
      return;
    }

    if (key === 'icon'
      || key === 'descriptionPhoto'
      || key === 'mainPhoto'
      || key === 'passportIcon'
    ) {
      const [file] = (event.target as HTMLInputElement).files || [];

      if (file) {
        const newPhotoId = nanoid();
        const { width, height } = await getImageWidthHeight(file);

        const photoMeta: PhotoMeta = {
          id: newPhotoId,
          url: '',
          name: file.name,
          size: file.size,
          width,
          height,
        };

        const photoIdBefore = group[key as string]?.id || '';

        group[key as string] = photoMeta;
        group.photos.push(photoMeta);
        group.photos = group.photos.filter((photo) => photo.id !== photoIdBefore);
        delete fileMapWillBeCreated[photoIdBefore];
        fileMapWillBeCreated[photoMeta.id] = file;
        setFileMapWillBeCreated(Object.assign({}, fileMapWillBeCreated));
      }
    } else {
      group[key as string] = event.currentTarget.value;
    }

    setGroup(Object.assign({}, group));
  }, [group, fileMapWillBeCreated, setGroup, setFileMapWillBeCreated]);

  const onChangeUserInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof UserModel,
  ) => {
    if (!user) {
      return;
    }

    user[key as string] = event.currentTarget.value;
    setUser(Object.assign({}, user));
  }, [user, setUser]);

  return (
    <>
      {isEditingGroup ? (
        <div
          className='position-absolute top-0 d-flex'
          style={{width: '100%', height: '100%', zIndex: 1, backgroundColor: 'rgba(0,0,0,0.3)'}}
        >
          <div className='m-auto'>
            <FontAwesomeIcon spin icon={faSync} className='fs-1'/>
          </div>
        </div>
      ):(<></>)}

      <div className='d-flex flex-row'>
        <AdminLeftSidebar />

        <div className={'admin_group_detail col'}>
          <AdminContainer>
            <Form className={'admin_group_detail__form'}>
              <PreventAutoComplete />

              <div className={'admin_group_detail__head'}>
                <h1 className={'admin_group_detail__title'}>組織・団体詳細</h1>
                <a onClick={() => history.push(ROUTES.ADMIN_GROUPS)} className={'admin_group_detail__link'}>
                  組織・団体一覧へ戻る
                </a>
              </div>

              {(group && user) ? (
                <div className={'admin_group_detail__contents'}>
                  <div className={'admin_group_detail__input'}>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体区分</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            data={group}
                            validationKey={'type'}
                            validationClassName='GroupModel'
                            type={'dropdown'}
                            title={group?.type || '選択してください'}
                          >
                            {Object.values(GroupType).map((groupType) => (
                              <Dropdown.Item
                                key={`group-type-${groupType}`}
                                onClick={() => {
                                  group.type = groupType;
                                  setGroup(Object.assign({}, group));
                                }}
                              >{groupType}</Dropdown.Item>
                            ))}
                          </InputWithValidation>
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体名　※</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            data={group}
                            validationKey={'name'}
                            validationClassName='GroupModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'name');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体アイコンロゴ登録</h2>
                        <InputWithValidation<GroupModel>
                          type='file'
                          tmpPhoto={fileMapWillBeCreated[group.icon?.id || '']}
                          data={group}
                          validationKey={'icon'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'icon');
                          }}
                        />
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体HPリンク</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            data={group}
                            validationKey={'homepage'}
                            validationClassName='GroupModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'homepage');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体メールアドレス　※</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            type='email'
                            data={user}
                            validationKey={'email'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'groupEmail');
                              onChangeUserInput(event, 'email');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>組織・団体電話番号</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'phoneNumber'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'groupPhoneNumber');
                              onChangeUserInput(event, 'phoneNumber');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>代表者名　※</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'name'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'delegateName');
                              onChangeUserInput(event, 'name');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>ふりがな　※</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'nameFurigana'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeUserInput(event, 'nameFurigana');
                              onChangeGroupInput(event, 'delegateNameFurigana');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>ふるさとパスポート画像登録</h2>
                        <InputWithValidation<GroupModel>
                          type='file'
                          tmpPhoto={fileMapWillBeCreated[group.passportIcon?.id || '']}
                          data={group}
                          validationKey={'passportIcon'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'passportIcon');
                          }}
                        />
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>TOP画像</h2>
                        <InputWithValidation<GroupModel>
                          type='file'
                          tmpPhoto={fileMapWillBeCreated[group.mainPhoto?.id || '']}
                          data={group}
                          validationKey={'mainPhoto'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'mainPhoto');
                          }}
                        />
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>PR記事</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            type='textarea'
                            data={user}
                            validationKey={'description'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeUserInput(event, 'description');
                              onChangeGroupInput(event, 'description');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>PR記事画像</h2>
                        <InputWithValidation<GroupModel>
                          type='file'
                          tmpPhoto={fileMapWillBeCreated[group.descriptionPhoto?.id || '']}
                          data={group}
                          validationKey={'descriptionPhoto'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'descriptionPhoto');
                          }}
                        />
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>担当者名</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            data={group}
                            defaultValue={DefaultValue.NoInput}
                            validationKey={'managerName'}
                            validationClassName='GroupModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'managerName');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>担当者メールアドレス</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            type='email'
                            data={group}
                            defaultValue={DefaultValue.NoInput}
                            validationKey={'managerEmail'}
                            validationClassName='GroupModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'managerEmail');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>担当者電話番号</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<GroupModel>
                            data={group}
                            defaultValue={DefaultValue.NoInput}
                            validationKey={'managerPhoneNumber'}
                            validationClassName='GroupModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'managerPhoneNumber');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>郵便番号</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            defaultValue={DefaultValue.NoInput}
                            validationKey={'postNumber'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'postNumber');
                              onChangeUserInput(event, 'postNumber');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>都道府県</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'cityId'}
                            validationClassName='UserModel'
                            type={'dropdown'}
                            title={selectedCity?.cityName || '選択してください'}
                          >
                            {cities.map((city) => (
                              <Dropdown.Item
                                key={`cities-${city.id}`}
                                onClick={() => {
                                  const isSameCity = group.cityId === city.id;
                                  if (!isSameCity) {
                                    group.townId = '';
                                    user.townId = '';
                                  }

                                  group.cityId = city.id;
                                  user.cityId = city.id;
                                  setGroup(Object.assign({}, group));
                                  setUser(Object.assign({}, user));
                                }}
                              >{city.cityName}</Dropdown.Item>
                            ))}
                          </InputWithValidation>
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>市区町村</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'townId'}
                            validationClassName='UserModel'
                            type={'dropdown'}
                            disabled={!selectedCity}
                            title={selectedTown?.associationName || '選択してください'}
                          >
                            {towns.map((town) => (
                              <Dropdown.Item
                                key={`towns-${town.id}`}
                                onClick={() => {
                                  group.townId = town.id;
                                  user.townId = town.id;
                                  setGroup(Object.assign({}, group));
                                  setUser(Object.assign({}, user));
                                }}
                              >{town.associationName}</Dropdown.Item>
                            ))}
                          </InputWithValidation>
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>番地</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'detailAddress1'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'detailAddress1');
                              onChangeUserInput(event, 'detailAddress1');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                    <section className={'detail_section'}>
                      <Form.Group>
                        <h2 className={'detail_section__title'}>建物名・部屋番号</h2>
                        <div className={'detail_section__input'}>
                          <InputWithValidation<UserModel>
                            data={user}
                            validationKey={'detailAddress2'}
                            validationClassName='UserModel'
                            onChange={(event) => {
                              onChangeGroupInput(event, 'detailAddress2');
                              onChangeUserInput(event, 'detailAddress2');
                            }}
                          />
                        </div>
                      </Form.Group>
                    </section>
                  </div>

                  <div className={'detail_side'}>
                    <section className={'detail_section'}>
                      <h2 className={'detail_section__title'}>公開</h2>
                      <div className={'detail_side__inputs'}>
                        <Button
                          className={'detail_side__input detail_side__input--submit'}
                          onClick={handleUpdateGroupWithUser}
                        >公開</Button>
                        <Button
                          className={'detail_side__input detail_side__input--delete'}
                          onClick={handleDeleteGroupWithUser}
                        >削除</Button>
                      </div>
                    </section>
                  </div>
                </div>
              ):(<></>)}
            </Form>
          </AdminContainer>
        </div>
      </div>
    </>
  );
};

export default AdminGroupEditWithUserRenderer;
