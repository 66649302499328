import React from 'react';
import { useAppState } from '~/providers/AppStateProvider';
import { ROUTES } from '~/constants/routes';
import { useHistory } from 'react-router-dom';
import './index.scss';

type Props = {};

const AboutNavbarRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { pathName } = useAppState();

  return (
    <>
      <div className={'about_navbar'}>
        <button className={`about_navbar__item ${pathName === ROUTES.STARTING_GUIDE ? 'active' : ''}`}>
          <a onClick={()=> history.push(ROUTES.STARTING_GUIDE)}>はじめての方へ</a>
        </button>
        <button className={`about_navbar__item ${pathName === ROUTES.ABOUT_PASSPORT ? 'active' : ''}`}>
          <a onClick={()=> history.push(ROUTES.ABOUT_PASSPORT)}>ふるさとパスポートとは？</a>
        </button>
        <button className={`about_navbar__item ${pathName === ROUTES.ABOUT_CONTRIBUTION ? 'active' : ''}`}>
          <a onClick={()=> history.push(ROUTES.ABOUT_CONTRIBUTION)}>貢献度バッジとは？</a>
        </button>
        <button className={`about_navbar__item ${pathName === ROUTES.ABOUT_POST ? 'active' : ''}`}>
          <a onClick={()=> history.push(ROUTES.ABOUT_POST)}>イベント体験記事・魅力発見記事について</a>
        </button>
        <button className={`about_navbar__item ${pathName === ROUTES.ABOUT_COMMUNITY ? 'active' : ''}`}>
          <a onClick={()=> history.push(ROUTES.ABOUT_COMMUNITY)}>コミュニテイについて</a>
        </button>
      </div>
    </>
  );
};

export default AboutNavbarRenderer;
