import React from 'react';
import './index.scss';

type Props = {
  children: React.ReactNode;
  bgColor: string;
  width?: string;
};

const LocalbridgeBody: React.FC<Props> = (({children, bgColor, width})=> {
  const bgStyle = {
    backgroundColor: bgColor,
  };
  const widthStyle = {
    maxWidth: width === 'narrow' ? '817px' : '1240px',
  };
  return (
    <div className={'localbridge_body'} style={bgStyle}>
      <div className={'localbridge_body__inner'} style={widthStyle}>{children}</div>
    </div>
  );
});

export default LocalbridgeBody;
