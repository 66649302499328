import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PostModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';

type Props = {
  children: ReactNode;
};

interface UserPostEditValue {
  postModel?: PostModel;
  setPostModel: (postModel?: PostModel) => void;
}

const UserPostEditContext = React.createContext<UserPostEditValue | null>(null);

export function useUserPostEdit(): UserPostEditValue {
  const state = useContext(UserPostEditContext);

  if (!state) {
    throw new Error('useUserPostEdit must be used within UserPostEditProvider');
  }

  return state;
}

export function UserPostEditProvider({ children }: Props) {
  const { postId } = useParams<any>();

  const { getPost } = useLocalbridgePostApi();
  const [ postModel, setPostModel ] = useState<PostModel>();

  const initUserPostEdit = useCallback(async () => {
    if (!postId) {
      return;
    }

    const postModelTmp = await getPost({postId});
    setPostModel(postModelTmp?.postModel);
  }, [postId, getPost, setPostModel]);

  useEffect(() => {
    initUserPostEdit();
  }, [initUserPostEdit]);

  const providerValue = {
    postModel,
    setPostModel,
  };

  return (
    <UserPostEditContext.Provider value={providerValue}>
      {children}
    </UserPostEditContext.Provider>
  );
}
