import React from 'react';
import './index.scss';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

type Props = {
  paginationBoxClassNamePrefix?: 'pagination_box_block'|'pagination_box';
  forcePage: number;
  pageCount: number;
  onPageChange: (nextPage: number) => void;
};

export const PaginationBox = ({
  paginationBoxClassNamePrefix = 'pagination_box',
  forcePage,
  pageCount,
  onPageChange,
}: Props) => {
  return paginationBoxClassNamePrefix === 'pagination_box' ? (
    <ReactPaginate
      previousLabel={
        <div className={'pagination_box__prev'}>前へ</div>
      }
      nextLabel={
        <div className={'pagination_box__next'}>次へ</div>
      }
      breakLabel={<span>...</span>}
      pageLabelBuilder={(page) => (
        <span
          className={`pagination_box__number ${page - 1 === forcePage ? 'is-current' : ''}`}
        >{page}</span>
      )}
      breakClassName={'break-me'}
      forcePage={forcePage}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      previousClassName='me-auto'
      nextClassName='ms-auto'
      onPageChange={(data: any) => onPageChange(data.selected)}
      containerClassName={`${paginationBoxClassNamePrefix} px-5 py-3`}
    />
  ) : (
    <ReactPaginate
      previousLabel={
        <span className='pagination_box_block__pagination_box'>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      }
      nextLabel={
        <span className='pagination_box_block__pagination_box'>
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
      }
      breakLabel={<span>...</span>}
      pageLabelBuilder={(page) => (
        <span
          className={`pagination_box_block__pagination_box ${page - 1 === forcePage ? 'active' : ''}`}
        >{page}</span>
      )}
      breakClassName={'break-me'}
      forcePage={forcePage}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      onPageChange={(data: any) => onPageChange(data.selected)}
      containerClassName={'pagination_box_block'}
    />
  );
};
