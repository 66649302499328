import React from 'react';
import { NoticesProvider } from './Provider';
import NoticesRenderer from './Renderer';

export const Notices = () => {
  return (
    <NoticesProvider>
      <NoticesRenderer />
    </NoticesProvider>
  );
};
