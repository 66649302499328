
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AboutContributionValue {

}

const AboutContributionContext = React.createContext<AboutContributionValue | null>(null);

export function useAboutContribution(): AboutContributionValue {
  const state = useContext(AboutContributionContext);

  if (!state) {
    throw new Error('useAboutContribution must be used within AboutContributionProvider');
  }

  return state;
}

export function AboutContributionProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AboutContributionContext.Provider value={providerValue}>
      {children}
    </AboutContributionContext.Provider>
  );
}
