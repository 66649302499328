
import { applyActionCode } from 'firebase/auth';
import React, { useContext, ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FUNCTION_V, LOCALBRIDGE_PATH, auth } from '~/constants/firebase';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';

type Props = {
  children: ReactNode;
};

interface UserUpdateEmailVarificationValue {
  isRegistered: boolean;
  hasError: boolean;
  handleUserUpdateEmailVarification: () => Promise<void>;
}

const UserUpdateEmailVarificationContext = React.createContext<UserUpdateEmailVarificationValue | null>(null);

export function useUserUpdateEmailVarification(): UserUpdateEmailVarificationValue {
  const state = useContext(UserUpdateEmailVarificationContext);

  if (!state) {
    throw new Error('useUserUpdateEmailVarification must be used within UserUpdateEmailVarificationProvider');
  }

  return state;
}

export function UserUpdateEmailVarificationProvider({ children }: Props) {
  const search = useLocation().search;
  const actionCode = new URLSearchParams(search).get('oobCode');
  const userId = new URLSearchParams(search).get('userId');
  const [ isRegistered, setIsRegistered ] = useState<boolean>(false);
  const [ hasError, setHasError ] = useState<boolean>(false);

  const { localbridgeUserSyncEmail } = useLocalbridgeUserApi();

  const handleUserUpdateEmailVarification = useCallback(async () => {
    if (!actionCode || !userId) {
      return;
    }

    try {
      await applyActionCode(auth, actionCode);
      await localbridgeUserSyncEmail({
        env: {
          LOCALBRIDGE_PATH,
          FUNCTION_V,
        },  
        params: {
          userId,
        },
      });

      setIsRegistered(true);
    } catch (error) {
      setHasError(true);
    }
  }, [actionCode, userId, localbridgeUserSyncEmail]);

  const providerValue = {
    isRegistered,
    hasError,
    handleUserUpdateEmailVarification,
  };

  return (
    <UserUpdateEmailVarificationContext.Provider value={providerValue}>
      {children}
    </UserUpdateEmailVarificationContext.Provider>
  );
}
