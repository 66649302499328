import React from 'react';
import { AdminProvider } from './Provider';
import AdminRenderer from './Renderer';

export const Admin = () => {
  return (
    <AdminProvider>
      <AdminRenderer />
    </AdminProvider>
  );
};
