import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import './index.scss';
import { useAdminNoticesCreate } from './Provider';
import { AdminLeftSidebar } from '../../components/leftSidebar';
import { ROUTES } from '~/constants/routes';
import { NoticeModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { useHistory } from 'react-router-dom';
import PreventAutoComplete from '~/components/preventAutoComplete';

type Props = {};

const AdminNoticesCreateRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { createNotices } = useLocalbridgeNoticeApi();
  const { notice, setNotice } = useAdminNoticesCreate();

  const handleCreateNotice = useCallback(async () => {
    try {
      await createNotices([{noticeModel: notice}]);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }
    }

    showSuccess({
      title: 'お知らせ作成',
      text: '作成しました',
      timer: 1000,
    });
  }, [notice, createNotices]);

  // eslint-disable-next-line
  const onChangeNoticeInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof NoticeModel,
  ) => {
    if (!notice) {
      return;
    }

    notice[key as string] = event.currentTarget.value;
    setNotice(Object.assign({}, notice));
  }, [notice, setNotice]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />
      <div className={'admin_notice_detail col'}>
        <div className={'main_contents__content'}>
          <Form className={'admin_notice_detail__form'}>
            <PreventAutoComplete />

            <div className={'admin_notice_detail__head'}>
              <h1 className={'admin_notice_detail__title'}>お知らせ作成</h1>
              <a onClick={() => history.push(ROUTES.ADMIN_NOTICES)} className={'admin_notice_detail__link'}>
                お知らせ一覧へ戻る
              </a>
            </div>

            {notice ? (
              <div className={'admin_notice_detail__contents'}>
                <div className={'admin_notice_detail__input'}>

                </div>
                <div className={'detail_side'}>
                  <section className={'detail_section'}>
                    <h2 className={'detail_section__title'}>作成</h2>
                    <div className={'detail_side__inputs'}>
                      <Button
                        className={'detail_side__input detail_side__input--submit'}
                        onClick={handleCreateNotice}
                      >作成</Button>
                    </div>
                  </section>
                </div>
              </div>
            ):(<></>)}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminNoticesCreateRenderer;
