import React, { useCallback } from 'react';
import LocalbridgeBody from '~/components/localbridgeBody';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import showError from '~/helpers/toaster/message/showError';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { UserModel } from '~/openapi/typescript-axios/version';
import './index.scss';
import { useSignUp } from '../../Provider';
import { SignUpState } from '../../enums';
import { uploadFileWithProgress } from '~/helpers/uploader';
import { LOCALBRIDGE_PATH } from '~/constants/firebase';

type Props = {};

const SignUpSubmitted: React.FC<Props> = () => {
  const {
    userModelWithPassword,
    selectedCity,
    selectedTown,
    fileMapWillBeCreated,
    setIsCreatingAccount,
    setSignUpState,
  } = useSignUp();
  const { createUsers } = useLocalbridgeUserApi();

  const goToSignUpSubmittedPage = useCallback(async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    setIsCreatingAccount(true);

    event.preventDefault();

    try {
      for (const fileId in fileMapWillBeCreated) {
        const photoRef = `${LOCALBRIDGE_PATH}/users/${userModelWithPassword.id}/photos/${fileId}`;
        const downloadUrl = await uploadFileWithProgress(photoRef, fileMapWillBeCreated[fileId]);
        const photoMeta = userModelWithPassword.photos.find((photo) => photo.id === fileId);
        if (photoMeta && downloadUrl) {
          photoMeta.url = downloadUrl;
        }
      }

      const newPassword = userModelWithPassword.newPassword;
      const passwordConfirm = userModelWithPassword.passwordConfirm;
      userModelWithPassword.emailToken = getSearchTokenMap(userModelWithPassword.email);

      const newUserModelWithPassword = Object.assign({}, userModelWithPassword);
      delete newUserModelWithPassword.newPassword;
      delete newUserModelWithPassword.passwordConfirm;

      await createUsers([{
        isByAdmin: true,
        userModel: newUserModelWithPassword as UserModel,
        newPassword,
        passwordConfirm,
      }]);

      // await doSendEmailVerification(email); // メール認証はなし

      showSuccess({
        title: '会員登録',
        text: '登録を完了しました',
        timer: 1000,
      });

      setSignUpState(SignUpState.SignUpSubmitted);
    } catch (error) {
      showError({
        title: '会員登録',
        text: (error as Error).message,
      });
      throw error;
    } finally {
      setIsCreatingAccount(false);
    }
  }, [
    userModelWithPassword,
    fileMapWillBeCreated,
    createUsers,
    setIsCreatingAccount,
    setSignUpState,
  ]);

  const [birthDayYear = 0, birthDayMonth = 0, birthDayDate = 0] = userModelWithPassword.birthDay || [];
  const birthDayString = `${birthDayYear}年${birthDayMonth}月${birthDayDate}日`;

  const [studentStartAtYear = 0, studentStartAtMonth = 0 ]= userModelWithPassword.studentStartAt || [];
  const studentStartAtString = `${studentStartAtYear}年${studentStartAtMonth}月`;

  return (
    <>
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'sign_up_confirm'}>
          <h1 className={'sign_up_confirm__title'}>新規会員登録</h1>
          <p className={'sign_up_confirm__title_en'}>Sign up</p>
          <p className={'sign_up_confirm__lead'}>
            ご入力内容にお間違いがないことを確認の上、
            <br className={'is-sp'} />
            「送信」してください
          </p>
          <div className={'sign_up_confirm_contents'}>
            <div className={'sign_up_confirm_contents__inner'}>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>名前</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.name}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>ふりがな</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.nameFurigana}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>
                  ニックネーム<span>この情報は公開されます</span>
                </p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.nickName}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>性別</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.gender}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>生年月日</p>
                <p className={'sign_up_confirm_contents__value'}>{birthDayString}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>郵便番号</p>
                <p className={'sign_up_confirm_contents__value'}>〒 {userModelWithPassword.postNumber}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>都道府県市区町村</p>
                <p className={'sign_up_confirm_contents__value'}>
                  {`${selectedCity?.cityName || ''} ${selectedTown?.associationName || ''}`}
                </p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>番地</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.detailAddress1}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>建物名・部屋番号</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.detailAddress2}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>職業</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.job}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>学校名</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.educationalInstitutionName}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>学部名</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.department}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>
                  学年<span>この情報は公開されます</span>
                </p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.studentGrade}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>入学年月</p>
                <p className={'sign_up_confirm_contents__value'}>{studentStartAtString}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>
                  興味・関心<span>この情報は公開されます</span>
                </p>
                <div className={'sign_up_confirm_contents__choices'}>
                  {(userModelWithPassword.interests || []).map((interest) => (
                    <p key={`interest-${interest}`} className={'sign_up_confirm_contents__choice'}>{interest}</p>
                  ))}
                </div>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>私の気になる第2のふるさと</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.localAssociationIds}</p>
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>気になる地方創生カテゴリ</p>
                {userModelWithPassword.localCategorys?.map((localCategory) => (
                  <p key={`localCategory-${localCategory}`} className={'sign_up_confirm_contents__value'}>
                    {localCategory}
                  </p>
                ))}
              </div>
              <div className={'sign_up_confirm_contents__item'}>
                <p className={'sign_up_confirm_contents__label'}>自己紹介文</p>
                <p className={'sign_up_confirm_contents__value'}>{userModelWithPassword.description}</p>
              </div>
            </div>
            <div className={'sign_up_confirm_contents__btns'}>
              <input
                className={`${'sign_up_confirm_contents__btn'} ${'sign_up_confirm_contents__btn--submit'}`}
                value='登録'
                onClick={(event) => goToSignUpSubmittedPage(event)}
                onChange={() => {}}
              />
              <a
                onClick={() => setSignUpState(SignUpState.SignUpForm)}
                className={`${'sign_up_confirm_contents__btn'} ${'sign_up_confirm_contents__btn--back'}`}
              >
                戻る
              </a>
            </div>
          </div>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default SignUpSubmitted;
