import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { WorkshopRequestsSearch, WorkshopResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminWorkshopsValue {
  workshopsList: Array<WorkshopResponsesWithPager>;
  workshopsPageCurrent: number;
  workshopsPageLast: number;
  setWorkshopRequestsSearch: (workshopRequestsSearch: WorkshopRequestsSearch) => void;
  setWorkshopsPageCurrent: (workshopsPageCurrent: number) => void;
}

const AdminWorkshopsContext = React.createContext<AdminWorkshopsValue | null>(null);

export function useAdminWorkshops(): AdminWorkshopsValue {
  const state = useContext(AdminWorkshopsContext);

  if (!state) {
    throw new Error('useAdminWorkshops must be used within AdminWorkshopsProvider');
  }

  return state;
}

export function AdminWorkshopsProvider({ children }: Props) {
  const { searchWorkshops } = useLocalbridgeWorkshopApi();

  const firstWorkshopRequestsSearch: WorkshopRequestsSearch = {
    size: 10,
  };
  const [ workshopRequestsSearch, setWorkshopRequestsSearch ] = useState<WorkshopRequestsSearch>(firstWorkshopRequestsSearch);
  const [ workshopRequestsSearchTmp, setWorkshopRequestsSearchTmp ] = useState<WorkshopRequestsSearch>(firstWorkshopRequestsSearch);
  const [ workshopsList, setAdminWorkshopsList ] = useState<Array<WorkshopResponsesWithPager>>([]);
  const [ workshopsPageCurrent, setWorkshopsPageCurrent ] = useState<number>(0);
  const [ workshopsPageLast, setAdminWorkshopsPageLast ] = useState<number>(0);

  const searchWorkshopsList = useCallback(async () => {
    if (!workshopRequestsSearch) {
      return;
    }

    const workshopsListTmp = await searchWorkshops(workshopRequestsSearch);
    const [workshopsWithPager] = workshopsListTmp;

    if (workshopsWithPager?.pager) {
      const { totalCount, size } = workshopsWithPager.pager;
      setAdminWorkshopsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }

    setAdminWorkshopsList(workshopsListTmp);
  }, [workshopRequestsSearch, searchWorkshops, setAdminWorkshopsList]);

  useEffect(() => {
    searchWorkshopsList();
  }, [searchWorkshopsList]);

  const providerValue = {
    workshopsList,
    workshopRequestsSearchTmp,
    workshopsPageCurrent,
    workshopsPageLast,
    setWorkshopRequestsSearch,
    setWorkshopRequestsSearchTmp,
    setWorkshopsPageCurrent,
  };

  return (
    <AdminWorkshopsContext.Provider value={providerValue}>
      {children}
    </AdminWorkshopsContext.Provider>
  );
}
