
import { nanoid } from 'nanoid';
import React, { useContext, ReactNode, useState, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { FUNCTION_V, LOCALBRIDGE_PATH, auth } from '~/constants/firebase';
import { ROUTES } from '~/constants/routes';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { AssociationModel, UserGender, UserModelWithPassword } from '~/openapi/typescript-axios/version';
import { useAuth } from '~/providers/AuthProvider';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { SignUpState } from './enums';

type Props = {
  children: ReactNode;
};

interface SignUpValue {
  isCreatingAccount: boolean;
  userModelWithPassword: UserModelWithPassword;
  signUpState: SignUpState;
  selectedCity?: AssociationModel;
  selectedTown?: AssociationModel;
  fileMapWillBeCreated: {[fileId: string]: File};
  doSendEmailVerification: (email: string) => Promise<void>;
  setIsCreatingAccount: (isCreatingAccount: boolean) => void;
  setUserModelWithPassword: (userModelWithPassword: UserModelWithPassword) => void;
  setSignUpState: (signUpState: SignUpState) => void;
  setSelectedCity: (selectedCity?: AssociationModel) => void;
  setSelectedTown: (selectedTown?: AssociationModel) => void;
  setFileMapWillBeCreated: (fileMapWillBeCreated: {[fileId: string]: File}) => void;
}

const SignUpContext = React.createContext<SignUpValue | null>(null);

export function useSignUp(): SignUpValue {
  const state = useContext(SignUpContext);

  if (!state) {
    throw new Error('useSignUp must be used within SignUpProvider');
  }

  return state;
}

export function SignUpProvider({ children }: Props) {
  const { thisUser } = useAuth();
  const { localbridgeSignUpEmailVarificationSendMail } = useLocalbridgeUserApi();

  const [ signUpState, setSignUpState ] = useState<SignUpState>(SignUpState.SignUpForm);
  const [ selectedCity, setSelectedCity ] = useState<AssociationModel>();
  const [ selectedTown, setSelectedTown ] = useState<AssociationModel>();
  const [ fileMapWillBeCreated, setFileMapWillBeCreated ] = useState<{[fileId: string]: File}>({});

  const now = Number(new Date());
  const [ userModelWithPassword, setUserModelWithPassword ] = useState<UserModelWithPassword>({
    id: nanoid(),
    email: '',
    newPassword: '',
    passwordConfirm: '',
    emailToken: getSearchTokenMap(''),
    job: '',
    // studentStartAt: [0, 0],
    // educationalInstitutionName: '',
    // department: '',
    name: '',
    nameFurigana: '',
    nickName: '',
    gender: '' as UserGender,
    cityId: '',
    townId: '',
    detailAddress1: '',
    detailAddress2: '',
    postNumber: '',
    photos: [],
    // birthDay: [0, 0, 0],
    interests: [],
    createdAt: now,
    updatedAt: now,
  });

  const [ isCreatingAccount, setIsCreatingAccount ] = useState<boolean>(false);

  const doSendEmailVerification = useCallback(async (email: string) => {
    await localbridgeSignUpEmailVarificationSendMail({
      env: {LOCALBRIDGE_PATH, FUNCTION_V},
      params: {
        email,
        origin: window.location.origin,
        route: ROUTES.SIGN_UP_EMAIL_CONFIRMATION,
      },
    });
  }, [localbridgeSignUpEmailVarificationSendMail]);

  useEffect(() => {
    const tid = setInterval(() => {
      const hasUserDataOnFirestore = auth.currentUser?.uid !== userModelWithPassword.id;

      if (auth.currentUser && hasUserDataOnFirestore) {
        userModelWithPassword.id = auth.currentUser.uid;
        userModelWithPassword.name = auth.currentUser.displayName || '';
        userModelWithPassword.nameFurigana = auth.currentUser.displayName || '';
        userModelWithPassword.nickName = auth.currentUser.displayName || '';
        userModelWithPassword.email = auth.currentUser.email || '';
        userModelWithPassword.phoneNumber = auth.currentUser.phoneNumber || '';
        delete userModelWithPassword.newPassword;
        delete userModelWithPassword.passwordConfirm;

        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
      }
    }, 300);

    return () => {
      clearInterval(tid);
    };
  }, [userModelWithPassword, setUserModelWithPassword]);

  const providerValue = {
    isCreatingAccount,
    userModelWithPassword,
    signUpState,
    selectedCity,
    selectedTown,
    fileMapWillBeCreated,
    doSendEmailVerification,
    setIsCreatingAccount,
    setUserModelWithPassword,
    setSignUpState,
    setSelectedCity,
    setSelectedTown,
    setFileMapWillBeCreated,
  };

  return !thisUser ? (
    <SignUpContext.Provider value={providerValue}>
      {children}
    </SignUpContext.Provider>
  ) : (<Redirect to={ROUTES.HOME}/>);
}
