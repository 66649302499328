import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AppStateProvider } from '~/providers/AppStateProvider';
import { AuthProvider } from '~/providers/AuthProvider';
import { ROUTES } from '~/constants/routes';
import { StyledApp } from '~/Styled';
import { LocalbridgeApiProvider } from './providers/LocalbridgeApiProvider';
import { User } from './views/user';
import { TestPostEditor } from './views/test/postEditor';
import { ParentGroupProvider } from './providers/ParentGroupProvider';
import { ParentUserProvider } from './providers/ParentUserProvider';
import { ParentPassportProvider } from './providers/ParentPassportProvider';
import { ParentWorkshopProvider } from './providers/ParentWorkshopProvider';
import { Group } from './views/group';
import { GroupEditWithUser } from './views/group/editWithUser';
import { GroupWorkshopEdit } from './views/group/workshop/edit';
import { UserBookings } from './views/user/bookings';
import { UserEdit } from './views/user/edit';
import { UserPosts } from './views/user/posts';
import { UserPostsCreate } from './views/user/posts/create';
import { UserPost } from './views/user/post';
import { UserPostEdit } from './views/user/post/edit';
import { UserUpdatePasswordReset } from './views/user/update/passwordReset';
import { Admin } from './views/admin';
import { AdminGroups } from './views/admin/groups';
import { AdminGroupsCreateWithUser } from './views/admin/groups/createWithUser';
import { AdminGroupEditWithUser } from './views/admin/group/editWithUser';
import { AdminPosts } from './views/admin/posts';
import { AdminUsers } from './views/admin/users';
import { Home } from './views/home';
import { SignIn } from './views/sign/in';
import { SignOut } from './views/sign/out';
import { SignUp } from './views/sign/up';
import { SignUpEmailVarification } from './views/sign/up/emailVarification';
import { Workshop } from './views/workshop';
import { Workshops } from './views/workshops';
import { Post } from './views/post';
import { Posts } from './views/posts';
import { Qna } from './views/qna';
import { UserUpdateEmailVarification } from './views/user/update/emailVarification';
import { AdminSignIn } from './views/admin/sign/in';
import { AdminSignOut } from './views/admin/sign/out';
import { TestSearching } from './views/test/searching';
import { TestValidation } from './views/test/validation';
import { AdminPostEdit } from './views/admin/post/edit';
import { AdminUserEdit } from './views/admin/user/edit';
import { AdminWorkshops } from './views/admin/workshops';
import { AdminWorkshopEdit } from './views/admin/workshop/edit';
import { AdminPassports } from './views/admin/passports';
import { AdminNotices } from './views/admin/notices';
import { AdminNoticesCreate } from './views/admin/notices/create';
import { AdminNoticeEdit } from './views/admin/notice/edit';
import { GroupWorkshops } from './views/group/workshops';
import { GroupWorkshopsCreate } from './views/group/workshops/create';
import { Notices } from './views/notices';
import { Notice } from './views/notice';
import { UserBooking } from './views/user/booking';
import { StartingGuide } from './views/startingGuide';
import { TermsOfService } from './views/termsOfService';
import { PrivacyPolicy } from './views/privacyPolicy';
import { CookiePolicy } from './views/cookiePolicy';
import { AboutCommunity } from './views/about/community';
import { AboutContribution } from './views/about/contribution';
import { AboutPassport } from './views/about/passport';
import { AboutPost } from './views/about/post';
import { WorkshopBookingsCreate } from './views/workshop/bookings/create';
// import { GroupSignUp } from './views/group/sign/up';

function App() {
  return (
    <StyledApp>
      <Router>
        <AppStateProvider>
          <LocalbridgeApiProvider>
            <AuthProvider>
              <ParentGroupProvider>
                <ParentWorkshopProvider>
                  <ParentUserProvider>
                    <ParentPassportProvider>
                      <Switch>
                        <Route exact path={ROUTES.HOME} component={Home} />
                        <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
                        <Route exact path={ROUTES.SIGN_OUT} component={SignOut} />
                        <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
                        <Route exact path={ROUTES.SIGN_UP_EMAIL_CONFIRMATION} component={SignUpEmailVarification} />
                        <Route exact path={ROUTES.WORKSHOPS} component={Workshops}/>
                        <Route exact path={ROUTES.WORKSHOP} component={Workshop}/>
                        <Route exact path={ROUTES.WORKSHOP_BOOKINGS_CREATE} component={WorkshopBookingsCreate}/>
                        <Route exact path={ROUTES.POSTS} component={Posts}/>
                        <Route exact path={ROUTES.POST} component={Post}/>
                        <Route exact path={ROUTES.NOTICES} component={Notices}/>
                        <Route exact path={ROUTES.NOTICE} component={Notice}/>
                        <Route exact path={ROUTES.QNA} component={Qna}/>
                        <Route exact path={ROUTES.STARTING_GUIDE} component={StartingGuide}/>
                        <Route exact path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService}/>
                        <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy}/>
                        <Route exact path={ROUTES.COOKIE_POLICY} component={CookiePolicy}/>
                        <Route exact path={ROUTES.ABOUT_COMMUNITY} component={AboutCommunity}/>
                        <Route exact path={ROUTES.ABOUT_CONTRIBUTION} component={AboutContribution}/>
                        <Route exact path={ROUTES.ABOUT_PASSPORT} component={AboutPassport}/>
                        <Route exact path={ROUTES.ABOUT_POST} component={AboutPost}/>

                        <Route exact path={ROUTES.GROUP} component={Group}/>
                        {/* <Route exact path={ROUTES.GROUP_SIGN_UP} component={GroupSignUp}/> */}
                        <Route exact path={ROUTES.GROUP_EDIT_WITH_USER} component={GroupEditWithUser}/>
                        <Route exact path={ROUTES.GROUP_WORKSHOPS} component={GroupWorkshops}/>
                        <Route exact path={ROUTES.GROUP_WORKSHOPS_CREATE} component={GroupWorkshopsCreate}/>
                        <Route exact path={ROUTES.GROUP_WORKSHOP_EDIT} component={GroupWorkshopEdit}/>

                        <Route exact path={ROUTES.USER} component={User}/>
                        <Route exact path={ROUTES.USER_BOOKINGS} component={UserBookings}/>
                        <Route exact path={ROUTES.USER_BOOKING} component={UserBooking}/>
                        <Route exact path={ROUTES.USER_EDIT} component={UserEdit}/>
                        <Route exact path={ROUTES.USER_POSTS} component={UserPosts}/>
                        <Route exact path={ROUTES.USER_POSTS_CREATE} component={UserPostsCreate}/>
                        <Route exact path={ROUTES.USER_POST} component={UserPost}/>
                        <Route exact path={ROUTES.USER_POST_EDIT} component={UserPostEdit}/>
                        <Route exact path={ROUTES.USER_UPDATE_EMAIL_CONFIRMATION} component={UserUpdateEmailVarification}/>
                        <Route exact path={ROUTES.USER_UPDATE_PASSWORD_RESET} component={UserUpdatePasswordReset}/>

                        <Route exact path={ROUTES.ADMIN} component={Admin}/>
                        <Route exact path={ROUTES.ADMIN_SIGN_IN} component={AdminSignIn}/>
                        <Route exact path={ROUTES.ADMIN_SIGN_OUT} component={AdminSignOut}/>
                        <Route exact path={ROUTES.ADMIN_GROUPS} component={AdminGroups}/>
                        <Route exact path={ROUTES.ADMIN_GROUPS_CREATE_WITH_USER} component={AdminGroupsCreateWithUser}/>
                        <Route exact path={ROUTES.ADMIN_GROUP_EDIT_WITH_USER} component={AdminGroupEditWithUser}/>
                        <Route exact path={ROUTES.ADMIN_NOTICES} component={AdminNotices}/>
                        <Route exact path={ROUTES.ADMIN_NOTICES_CREATE} component={AdminNoticesCreate}/>
                        <Route exact path={ROUTES.ADMIN_NOTICE_EDIT} component={AdminNoticeEdit}/>
                        <Route exact path={ROUTES.ADMIN_POSTS} component={AdminPosts}/>
                        <Route exact path={ROUTES.ADMIN_POST_EDIT} component={AdminPostEdit}/>
                        <Route exact path={ROUTES.ADMIN_USERS} component={AdminUsers}/>
                        <Route exact path={ROUTES.ADMIN_USER_EDIT} component={AdminUserEdit}/>
                        <Route exact path={ROUTES.ADMIN_PASSPORTS} component={AdminPassports}/>
                        <Route exact path={ROUTES.ADMIN_WORKSHOPS} component={AdminWorkshops}/>
                        <Route exact path={ROUTES.ADMIN_WORKSHOP_EDIT} component={AdminWorkshopEdit}/>

                        <Route exact path={ROUTES.TEST_POST_EDITOR} component={TestPostEditor}/>
                        <Route exact path={ROUTES.TEST_VALIDATION} component={TestValidation}/>
                        <Route exact path={ROUTES.TEST_SEARCHING} component={TestSearching}/>

                        <Redirect to={ROUTES.HOME} />
                      </Switch>
                    </ParentPassportProvider>
                  </ParentUserProvider>
                </ParentWorkshopProvider>
              </ParentGroupProvider>
            </AuthProvider>
          </LocalbridgeApiProvider>
        </AppStateProvider>
      </Router>
    </StyledApp>
  );
}

export default App;
