import React from 'react';
import { WorkshopsProvider } from './Provider';
import WorkshopsRenderer from './Renderer';

export const Workshops = () => {
  return (
    <WorkshopsProvider>
      <WorkshopsRenderer />
    </WorkshopsProvider>
  );
};
