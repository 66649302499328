import axios from 'axios';
import firebaseJson from '~/environments/firebase.json';
const { emulators } = firebaseJson;

import environments from '~/environments';
const { firebaseConfig } = (environments as any)[process.env?.REACT_APP_LOCALBRIDGE_ENV || 'local'];

export default async function checkUserPassword(
  email: string,
  password: string,
): Promise<{ localId: string }> {
  const isLocalEnv = window.location.hostname === 'localhost';

  const res = await axios([
    isLocalEnv ? `http://localhost:${emulators.auth.port}/` : 'https://',
    'identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?',
    `key=${firebaseConfig.apiKey}`,
  ].join(''),{
    method: 'POST',
    data: { email, password, returnSecureToken: true },
  });

  return res.data;
}
