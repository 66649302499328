import React from 'react';
import './index.scss';
import UserHeader from '../header';
import { useParentUser } from '~/providers/ParentUserProvider';
import { useUser } from '../../Provider';
import { UserContainer } from '../continer';

type Props = {};

const UserMyProfileRenderer: React.FC<Props> = () => {
  const { userModelOrPublic } = useParentUser();
  const { passports, towns } = useUser();
  const description = userModelOrPublic?.description || '';

  return (
    <UserContainer>
      <UserHeader title={'プロフィール'} titleEn={'Profile'}/>

      <div className={'my_profile'}>
        <div className={'my_profile__inner'}>
          <div className={'my_profile_info'}>
            <img className={'my_profile_info__img'} src={userModelOrPublic?.icon?.url || ''} />
            <p className={'my_profile_info__label'}>ニックネーム</p>
            <p className={'my_profile_info__name'}>{userModelOrPublic?.nickName || ''}</p>
          </div>

          <div style={{maxWidth: 700, width: '100%'}}>
            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>保有パスポート一覧</h2>

              <div className={'my_profile_passport'}>
                {passports.map((passport) => (
                  <div key={`passport:${passport.id}`} className={'my_profile_passport__item'}>
                    <img className={'my_profile_passport__img'} src={passport.icon.url} />
                    <p className={'my_profile_passport__text'}>{passport.id}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>自己紹介文</h2>
              <p className={'my_profile_section__text'}>
                {description.split('\n').map((splitedText, index) => (
                  <React.Fragment key={`userModelOrPublic:${userModelOrPublic?.id}-description:${index}`}>
                    {splitedText}
                    {(index !== description.split('\n').length - 1) ? (<br />) : (<></>)}
                  </React.Fragment>
                ))}
              </p>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>興味・関心</h2>
              <p className={'my_profile_section__text'}>
                {(userModelOrPublic?.interests || []).map((interest, index) => (
                  <React.Fragment key={`userModelOrPublic:${userModelOrPublic?.id}-interest:${index}`}>
                    {interest}
                    {(index !== (userModelOrPublic?.interests || []).length- 1) ? (<br />) : (<></>)}
                  </React.Fragment>
                ))}
              </p>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>私の気になる第2のふるさと</h2>
              <p className={'my_profile_section__text'}>
                {towns.map((town, index) => (
                  <React.Fragment key={`userModelOrPublic:${userModelOrPublic?.id}-town:${town.id}`}>
                    {town.associationName}
                    {(index !== towns.length- 1) ? (<br />) : (<></>)}
                  </React.Fragment>
                ))}
              </p>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>気になる地方創生カテゴリ</h2>
              <p className={'my_profile_section__text'}>
                {userModelOrPublic?.localCategorys?.map((localCategory, index) => (
                  <React.Fragment key={`userModelOrPublic:${userModelOrPublic?.id}-localCategory:${index}`}>
                    {localCategory}
                    {(index !== (userModelOrPublic?.localCategorys?.length || 0)- 1) ? (<br />) : (<></>)}
                  </React.Fragment>
                ))}
              </p>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>活動履歴</h2>
              <ul className={'my_profile_list'}>
                {/* <li className={'my_profile_list__item'}>
                  <a href='#'>
                    <p className={'my_profile_list__date'}>2023.11.1</p>
                    <p className={'my_profile_list__text'}>記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。</p>
                  </a>
                </li> */}
              </ul>

              <div className={'my_profile__btn'}>
                <a href='#'>さらにみる</a>
              </div>
            </section>

            <section className={'my_profile_section'}>
              <h2 className={'my_profile_section__title'}>執筆記事一覧</h2>
              <ul className={'my_profile_list'}>
                <li className={'my_profile_list__item'}>
                  <a href="#">
                    <p className={'my_profile_list__date'}>2023.11.1</p>
                    <p className={'my_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={'my_profile_list__item'}>
                  <a href="#">
                    <p className={'my_profile_list__date'}>2023.11.1</p>
                    <p className={'my_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={'my_profile_list__item'}>
                  <a href="#">
                    <p className={'my_profile_list__date'}>2023.11.1</p>
                    <p className={'my_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={'my_profile_list__item'}>
                  <a href="#">
                    <p className={'my_profile_list__date'}>2023.11.1</p>
                    <p className={'my_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
              </ul>

              <div className={'my_profile__btn'}>
                <a href="#">さらにみる</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </UserContainer>
  );
};

export default UserMyProfileRenderer;
