import React from 'react';
import { AdminGroupsCreateWithUserProvider } from './Provider';
import AdminGroupsCreateWithUserRenderer from './Renderer';

export const AdminGroupsCreateWithUser = () => {
  return (
    <AdminGroupsCreateWithUserProvider>
      <AdminGroupsCreateWithUserRenderer />
    </AdminGroupsCreateWithUserProvider>
  );
};
