import React, { useMemo } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaginationBox } from '~/components/paginationBox';
import { NoticeModel } from '~/openapi/typescript-axios/version';
import { useAdminNotices } from './Provider';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { StyledAdminTable } from '../components/StyledAdminTable';
import { ROUTES } from '~/constants/routes';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {};

const AdminNoticesRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    noticesList,
    noticesPageCurrent,
    noticesPageLast,
    setNoticesPageCurrent,
  } = useAdminNotices();

  const notices: Array<NoticeModel> = useMemo(() => {
    return noticesList.find((noticesWithPager) => {
      return noticesWithPager.pager.page === noticesPageCurrent;
    })?.data || [];
  }, [noticesPageCurrent, noticesList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <div className='d-flex flex-row'>
          <span style={{fontSize: 24}}>お知らせ一覧</span>
          <button className='btn btn-secondary ms-auto'>新規お知らせ登録</button>
        </div>

        <span
          className='m-auto fw-bolder p-2'
          hidden={notices.length > 0}
        >
          登録された現場がありません
        </span>

        {notices.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* お知らせID */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  お知らせID
                </th>

                {/* タイトル */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  タイトル
                </th>
              </tr>
              <tr className='border'>
                {/* 作成時刻  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  作成時刻 
                </th>

                {/*  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  
                </th>

                {/*  */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  
                </th>

                {/*  */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  
                </th>

                {/*  */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 修正時刻 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  修正時刻
                </th>

                {/*  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  
                </th>

                {/*  */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {notices.map((notice: NoticeModel, index: number) => {

                return (
                  <tbody
                    key={notice.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* お知らせID */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {notice.id}
                      </th>

                      {/* タイトル */}
                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {notice.title}
                      </th>
                    </tr>

                    <tr>
                      {/* 作成時刻  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(notice.createdAt).format('YYYY.MM.DD HH:mm:ss')}
                      </td>

                      {/*  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/*  */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/*  */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/*  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_NOTICE_EDIT, {
                          noticeId: notice.id,
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 修正時刻 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(notice.updatedAt).format('YYYY.MM.DD HH:mm:ss')}
                      </td>

                      {/*  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/*  */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {/* {notice.id} */}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        {noticesList.length ? (
          <PaginationBox
            paginationBoxClassNamePrefix='pagination_box_block'
            forcePage={noticesPageCurrent}
            pageCount={noticesPageLast}
            onPageChange={setNoticesPageCurrent}
          />
        ):(<></>)}
      </div>
    </div>
  );
};

export default AdminNoticesRenderer;
