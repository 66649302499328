import React from 'react';
import './index.scss';

type Props = {
  title: string;
  titleEn: string;
};

const GroupHeader: React.FC<Props> = ({title, titleEn, children}) => {
  return (
    <div className={'group_header'}>
      <h1 className={'group_header__title'}>{title}</h1>
      <p className={'group_header__title_en'}>{titleEn}</p>
      {children}
    </div>
  );
};

export default GroupHeader;