
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface NoticesNewsItemValue {

}

const NoticesNewsItemContext = React.createContext<NoticesNewsItemValue | null>(null);

export function useNoticesNewsItem(): NoticesNewsItemValue {
  const state = useContext(NoticesNewsItemContext);

  if (!state) {
    throw new Error('useNoticesNewsItem must be used within NoticesNewsItemProvider');
  }

  return state;
}

export function NoticesNewsItemProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <NoticesNewsItemContext.Provider value={providerValue}>
      {children}
    </NoticesNewsItemContext.Provider>
  );
}
