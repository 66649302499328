import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import InputWithValidation from '~/components/inputWithValidation';
import PreventAutoComplete from '~/components/preventAutoComplete';
import UserHeader from '../components/header';
import { useParentUser } from '~/providers/ParentUserProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { PhotoMeta, UserGender, UserJobDropdown, UserModel } from '~/openapi/typescript-axios/version';
import './index.scss';
import SelectTown from './components/selectTown';
import SelectCategory from './components/selectCategory';
import { UserLeftSidebar } from '../components/leftSidebar';
import { UserContainer } from '../components/continer';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import showError from '~/helpers/toaster/message/showError';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { useUserEdit } from './Provider';
import { nanoid } from 'nanoid';
import { getImageWidthHeight } from '~/helpers/getImageWidthHeight';
import { LOCALBRIDGE_PATH } from '~/constants/firebase';
import { uploadFileWithProgress } from '~/helpers/uploader';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';

type Props = {};

const UserEditRenderer: React.FC<Props> = () => {
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const { updateUserProfiles, validateUsers } = useLocalbridgeUserApi();
  const { isCreatingAccount, setIsCreatingAccount } = useUserEdit();
  const { userModelOrPublic, setUserModelOrPublic } = useParentUser();
  const userModel = userModelOrPublic as UserModel|undefined;

  const [ tmpInterest, setTmpInterest ] = useState<string>();
  const [ fileMapWillBeCreated, setFileMapWillBeCreated ] = useState<{[fileId: string]: File}>({});

  const iconUrl = useMemo(() => {
    const iconTmpFile = fileMapWillBeCreated[userModelOrPublic?.icon?.id || ''];
    return iconTmpFile ? URL.createObjectURL(iconTmpFile) : userModelOrPublic?.icon?.url;
  }, [fileMapWillBeCreated, userModelOrPublic?.icon]);

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === userModel?.cityId);
  }, [cities, userModel?.cityId]);

  const categoryNames = [
    '観光・伝統文化',
    '関係人口づくり',
    '地域の魅力発信',
    'サスティナブル',
    '子供の教育',
    '家族・家庭',
    'まちづくり',
    '文化・芸術',
  ];

  const currentYear = new Date().getFullYear();
  const yearList = new Array(130)
    .fill(0).map((_, year) => (currentYear - year));
  const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];
  const birthDayDateList = useMemo(() => {
    const [birthDayYear, birthDayMonth] = userModel?.birthDay || [];
    if (!birthDayYear || !birthDayMonth) {
      return [];
    }

    return new Array(new Date(birthDayYear, birthDayMonth + 1, 0).getDate())
      .fill(0).map((_, date) => date + 1);
  }, [userModel?.birthDay]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);


  const isStudent = (value: UserJobDropdown) => {
    return UserJobDropdown.StudentUniversityBachelor === value
      || UserJobDropdown.StudentUniversityMaster === value
      || UserJobDropdown.StudentCollegeProfessional === value
      || UserJobDropdown.StudentCollegeJunior === value
      || UserJobDropdown.StudentHighScholl === value;
  };

  const onChangeUserInput = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>
      |React.FocusEvent<HTMLInputElement, Element>,
    key: keyof UserModel,
  ) => {
    if (!userModel) {
      return;
    }

    if (key === 'interests') {
      if (event.type === 'blur' && event.currentTarget.value) {
        if (!userModel.interests) {
          userModel.interests = [];
        }

        userModel.interests.push(event.currentTarget.value);
        setTmpInterest(undefined);
        userModel.interests = [...new Set(userModel.interests)];
        setUserModelOrPublic(Object.assign({}, userModel));
      } else {
        setTmpInterest(event.currentTarget.value);
      }

      return;
    }

    if (key === 'icon') {
      const [file] = (event.target as HTMLInputElement).files || [];

      if (file) {
        const newPhotoId = nanoid();
        const { width, height } = await getImageWidthHeight(file);

        const photoMeta: PhotoMeta = {
          id: newPhotoId,
          url: '',
          name: file.name,
          size: file.size,
          width,
          height,
        };

        const photoIdBefore = userModel[key as string]?.id || '';

        userModel[key as string] = photoMeta;
        userModel.photos.push(photoMeta);
        userModel.photos = userModel.photos.filter((photo) => photo.id !== photoIdBefore);
        delete fileMapWillBeCreated[photoIdBefore];
        fileMapWillBeCreated[photoMeta.id] = file;
        setFileMapWillBeCreated(Object.assign({}, fileMapWillBeCreated));
      }
    } else {
      userModel[key as string] = event.currentTarget.value;
      if (key === 'job' && !isStudent(userModel.job as UserJobDropdown)) {
        delete userModel.studentStartAt;
        delete userModel.educationalInstitutionName;
        delete userModel.department;
      }
    }

    setUserModelOrPublic(Object.assign({}, userModel));
  }, [userModel, fileMapWillBeCreated, setUserModelOrPublic, setTmpInterest]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    userModel?.cityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === userModel?.townId);
  }, [towns, userModel?.townId]);

  const handleUploadUserProfile = useCallback(async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsCreatingAccount(true);
    event.preventDefault();

    if (!userModel) {
      return;
    }

    try {
      const messageResponses = await validateUsers({userRequests: [{userModel}]});
      if (messageResponses.validations?.length) {
        throw new LocalbridgeApiError(messageResponses, 412);
      }
    } catch (error) {
      setIsCreatingAccount(false);
      if ((error as Error).name === 'LocalbridgeApiError') {
        console.log((error as LocalbridgeApiError).messageResponse);
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }

      return;
    }

    try {
      userModel.emailToken = getSearchTokenMap(userModel.email);

      for (const fileId in fileMapWillBeCreated) {
        const photoRef = `${LOCALBRIDGE_PATH}/users/${userModel.id}/photos/${fileId}`;
        const downloadUrl = await uploadFileWithProgress(photoRef, fileMapWillBeCreated[fileId]);
        const photoMeta = userModel.photos.find((photo) => photo.id === fileId);
        if (photoMeta && downloadUrl) {
          photoMeta.url = downloadUrl;
        }
      }

      await updateUserProfiles([{userModel}]);

      showSuccess({
        title: '会員登録',
        text: '登録を完了しました',
        timer: 1000,
      });
    } catch (error) {
      showError({
        title: '会員登録',
        text: (error as Error).message,
      });
      throw error;
    } finally {
      setIsCreatingAccount(false);
    }
  }, [
    userModel,
    fileMapWillBeCreated,
    updateUserProfiles,
    validateUsers,
    setIsCreatingAccount,
  ]);

  return userModel ? (
    <>
      {isCreatingAccount ? (
        <div
          className='position-absolute top-0 d-flex'
          style={{width: '100%', height: '100%', zIndex: 1, backgroundColor: 'rgba(0,0,0,0.3)'}}
        >
          <div className='m-auto'>
            <FontAwesomeIcon spin icon={faSync} className='fs-1'/>
          </div>
        </div>
      ):(<></>)}

      <LocalbridgeNavbar />

      <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
        <UserLeftSidebar />

        <UserContainer>
          <UserHeader title={'会員情報設定'} titleEn={'Member information settings'}/>

          <div className={'user_edit'}>
            <form className={'user_edit__form'}>
              <PreventAutoComplete />

              <div className={'user_edit__contents'}>
                <p className={'user_edit__note'}>※は必須項目です</p>
                <div className={'user_edit_item user_edit_item--logo'}>

                  <img src={iconUrl || '/assets/img/sign_up/pic_image01.png'} alt='' />
                  <InputWithValidation<UserModel>
                    type='file'
                    data={userModel}
                    validationKey={'icon'}
                    validationClassName='UserModel'
                  >
                    <label className={'user_edit_logo'}>
                      <input
                        type='file'
                        name='organization_logo'
                        onChange={(event) => {
                          onChangeUserInput(event, 'icon');
                        }}
                      />
                      <p className={'user_edit_logo__text'}>画像を変更する</p>
                    </label>
                  </InputWithValidation>
                </div>
                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    名前<span className={'required'}>※</span>
                    <span className={'note'}>この情報は公開されます</span>
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'name'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'name');
                    }}
                  />
                </div>
                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    ふりがな<span className={'required'}>※</span>
                    <span className={'note'}>この情報は公開されます</span>
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'nameFurigana'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'nameFurigana');
                    }}
                  />
                </div>
                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    メールアドレス<span className={'note'}>この情報は公開されます</span>
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'email'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'email');
                    }}
                  />
                </div>

                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    ニックネーム<span className={'required'}>※</span>
                    <span className={'note'}>この情報は公開されます</span>
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'nickName'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'nickName');
                    }}
                  />
                </div>

                <div className={'user_edit_item user_edit_item--width-sm'}>
                  <label className={'user_edit_item__label'}>
                    性別<span className={'required'}>※</span>
                  </label>
                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'gender'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {userModel?.gender || '選択してください'}
                      </span>
                    }
                  >
                    {Object.values(UserGender).map((gender) => (
                      <Dropdown.Item
                        key={`gender-${gender}`}
                        onClick={() => {
                          userModel.gender = gender;
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >{gender}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>

                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    生年月日
                  </label>
                  <div className={'d-flex gap-2'}>
                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'birthDay'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {userModel?.birthDay?.[0] || '年'}
                      </span>
                    }
                  >
                    {yearList.map((year) => (
                      <Dropdown.Item
                        key={`birthDay-year-${year}`}
                        onClick={() => {
                          userModel.birthDay = [year];

                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >{year}</Dropdown.Item>
                    ))}
                  </InputWithValidation>

                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'birthDay'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    disabled={!userModel.birthDay?.[0]}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {userModel?.birthDay?.[1] || '月'}
                      </span>
                    }
                  >
                    {monthList.map((month) => (
                      <Dropdown.Item
                        key={`birthDay-month-${month}`}
                        onClick={() => {
                          if (!userModel?.birthDay) {
                            return;
                          }

                          userModel.birthDay[1] = month;
                          userModel.birthDay = [...userModel.birthDay];
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >{month}</Dropdown.Item>
                    ))}
                  </InputWithValidation>

                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'birthDay'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    disabled={!userModel.birthDay?.[1]}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {userModel?.birthDay?.[2] || '日'}
                      </span>
                    }
                  >
                    {birthDayDateList.map((date) => (
                      <Dropdown.Item
                        key={`birthDay-date-${date}`}
                        onClick={() => {
                          if (!userModel?.birthDay) {
                            return;
                          }

                          userModel.birthDay[2] = date;
                          userModel.birthDay = [...userModel.birthDay];
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >{date}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>

                </div>
                <div className={'user_edit_item user_edit_item--width-xs'}>
                  <label className={'user_edit_item__label'}>
                    郵便番号
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'postNumber'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'postNumber');
                    }}
                  />
                </div>
                <div className={'user_edit_item user_edit_item--width-xs'}>
                  <label className={'user_edit_item__label'}>
                    都道府県
                  </label>
                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'cityId'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {selectedCity?.cityName || '選択してください'}
                      </span>
                    }
                  >
                    <div className={'row m-1'}>
                      <Dropdown.Item
                        className='btn btn-sm p-1 text-center'
                        onClick={() => {
                          userModel.cityId = '';
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >選択なし</Dropdown.Item>

                      {cities.map((city) => (
                        <Dropdown.Item
                          key={`cities-${city.id}`}
                          className='btn btn-sm p-1 text-center'
                          onClick={() => {
                            const isSameCity = userModel.cityId === city.id;
                            if (!isSameCity) {
                              userModel.townId = '';
                            }

                            userModel.cityId = city.id;
                            setUserModelOrPublic(Object.assign({}, userModel));
                          }}
                        >{city.cityName}</Dropdown.Item>
                      ))}
                    </div>
                  </InputWithValidation>
                </div>
                <div className={'user_edit_item user_edit_item--width-md'}>
                  <label className={'user_edit_item__label'}>
                    市区町村
                  </label>
                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'townId'}
                    validationClassName='UserModel'
                    className='user_edit_item__input p-0'
                    type={'dropdown'}
                    disabled={!selectedCity}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {selectedTown?.associationName || '選択してください'}
                      </span>
                    }
                  >
                    <div className={'row m-1'}>
                      <Dropdown.Item
                        className='btn btn-sm p-1 text-center'
                        onClick={() => {
                          userModel.townId = '';
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >選択なし</Dropdown.Item>

                      {towns.map((town) => (
                        <Dropdown.Item
                          key={`towns-${town.id}`}
                          onClick={() => {
                            userModel.townId = town.id;
                            setUserModelOrPublic(Object.assign({}, userModel));
                          }}
                        >{town.associationName}</Dropdown.Item>
                      ))}
                    </div>
                  </InputWithValidation>
                </div>
                <div className={'user_edit_item user_edit_item--width-md'}>
                  <label className={'user_edit_item__label'}>
                    番地
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'detailAddress1'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    placeholder='123-4567'
                    onChange={(event) => {
                      onChangeUserInput(event, 'detailAddress1');
                    }}
                  />
                </div>
                <div className={'user_edit_item user_edit_item--width-md'}>
                  <label className={'user_edit_item__label'}>
                    建物名・部屋番号
                  </label>
                  <InputWithValidation<UserModel>
                    type='text'
                    data={userModel}
                    validationKey={'detailAddress2'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    placeholder='123-4567'
                    onChange={(event) => {
                      onChangeUserInput(event, 'detailAddress2');
                    }}
                  />
                </div>
                <div className={'user_edit_item user_edit_item--width-md'}>
                  <label className={'user_edit_item__label'}>
                    職業<span className={'required'}>※</span>
                  </label>
                  <InputWithValidation<UserModel>
                    data={userModel}
                    validationKey={'job'}
                    validationClassName='UserModel'
                    className='user_edit_item__input user_edit_item--width-md p-0'
                    type={'dropdown'}
                    title={
                      <span className='user_edit_item__input border-0 p-0'>
                        {userModel?.job || '選択してください'}
                      </span>
                    }
                  >
                    {Object.values(UserJobDropdown).map((job) => (
                      <Dropdown.Item
                        key={`userJobs-${job}`}
                        onClick={() => {
                          userModel.job = job;
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >{job}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>

                {isStudent(userModel.job as UserJobDropdown) ? (
                  <>
                    <div className={`${'user_edit_item'} ${'user_edit_item--width-sm'}`}>
                      <label className={'user_edit_item__label'}>
                        学校名
                      </label>
                      <InputWithValidation<UserModel>
                        type='text'
                        data={userModel}
                        validationKey={'educationalInstitutionName'}
                        validationClassName='UserModel'
                        className='user_edit_item__input'
                        placeholder='〇〇大学'
                        onChange={(event) => {
                          onChangeUserInput(event, 'educationalInstitutionName');
                        }}
                      />
                    </div>

                    {userModel.job !== UserJobDropdown.StudentHighScholl ? (
                      <div className={`${'user_edit_item'} ${'user_edit_item--width-sm'}`}>
                        <label className={'user_edit_item__label'}>
                          学部名
                        </label>
                        <InputWithValidation<UserModel>
                          type='text'
                          data={userModel}
                          validationKey={'department'}
                          validationClassName='UserModel'
                          className='user_edit_item__input'
                          placeholder='〇〇学部'
                          onChange={(event) => {
                            onChangeUserInput(event, 'department');
                          }}
                        />
                      </div>
                    ):(<></>)}

                    <div className={`${'user_edit_item'} ${'user_edit_item--width-sm'}`}>
                      <label className={'user_edit_item__label'}>
                        学年<span className={'note'}>この情報は公開されます</span>
                      </label>
                      <InputWithValidation<UserModel>
                        data={userModel}
                        validationKey={'job'}
                        validationClassName='UserModel'
                        className='user_edit_item__input user_edit_item--width-md p-0'
                        type={'dropdown'}
                        title={
                          <span className='user_edit_item__input border-0 p-0'>
                            {userModel?.job || '選択してください'}
                          </span>
                        }
                      >
                        {Object.values(UserJobDropdown).map((job) => (
                          <Dropdown.Item
                            key={`userJobs-${job}`}
                            onClick={() => {
                              userModel.job = job;
                              setUserModelOrPublic(Object.assign({}, userModel));
                            }}
                          >{job}</Dropdown.Item>
                        ))}
                      </InputWithValidation>
                    </div>

                    <div className={'user_edit_item'}>
                      <label className={'user_edit_item__label'}>
                        入学年月
                      </label>
                      <div className={'d-flex gap-2'}>
                        <InputWithValidation<UserModel>
                          data={userModel}
                          validationKey={'studentStartAt'}
                          validationClassName='UserModel'
                          className='user_edit_item__input p-0'
                          type={'dropdown'}
                          title={
                            <span className='user_edit_item__input border-0 p-0'>
                              {userModel?.studentStartAt?.[0] || '年'}
                            </span>
                          }
                        >
                          {yearList.map((year) => (
                            <Dropdown.Item
                              key={`studentStartAt-year-${year}`}
                              onClick={() => {
                                userModel.studentStartAt = [year];

                                setUserModelOrPublic(Object.assign({}, userModel));
                              }}
                            >{year}</Dropdown.Item>
                          ))}
                        </InputWithValidation>

                        <InputWithValidation<UserModel>
                          data={userModel}
                          validationKey={'studentStartAt'}
                          validationClassName='UserModel'
                          className='user_edit_item__input p-0'
                          type={'dropdown'}
                          disabled={!userModel.studentStartAt?.[0]}
                          title={
                            <span className='user_edit_item__input border-0 p-0'>
                              {userModel?.studentStartAt?.[1] || '月'}
                            </span>
                          }
                        >
                          {monthList.map((month) => (
                            <Dropdown.Item
                              key={`studentStartAt-month-${month}`}
                              onClick={() => {
                                if (!userModel?.studentStartAt) {
                                  return;
                                }

                                userModel.studentStartAt[1] = month;
                                userModel.studentStartAt = [...userModel.studentStartAt];
                                setUserModelOrPublic(Object.assign({}, userModel));
                              }}
                            >{month}</Dropdown.Item>
                          ))}
                        </InputWithValidation>
                      </div>
                    </div>
                  </>
                ):(<></>)}

                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    興味・関心<span className={'required'}>※</span>
                    <span className={'note'}>この情報は公開されます</span>
                  </label>

                  <div className='user_edit_item__input p-0 border-0'>
                    <InputWithValidation<UserModel>
                      type='textArray'
                      data={userModel}
                      tmpText={tmpInterest}
                      validationKey={'interests'}
                      validationClassName='UserModel'
                      onChange={(event) => {
                        onChangeUserInput(event, 'interests');
                      }}
                      onBlur={(event) => {
                        onChangeUserInput(event, 'interests');
                      }}
                    >
                      <button
                        className='btn btn-outline-secondary p-1'
                        onClick={(event) => {
                          event.preventDefault();
                          if (!tmpInterest) {
                            return;
                          }

                          if (!userModel.interests) {
                            userModel.interests = [];
                          }

                          userModel.interests.push(tmpInterest);
                          setTmpInterest(undefined);
                          userModel.interests = [...new Set(userModel.interests)];
                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} style={{width: 25}} />
                      </button>
                    </InputWithValidation>

                    <div className={'user_edit_item_choice flex-wrap'}>
                      {(userModel.interests || [])?.map((interest) => (
                        <p key={`interests-${interest}`} className={'user_edit_item_choice__text'}>
                          ＃ {interest}
                          <span onClick={() => {
                            userModel.interests = (userModel.interests || [])
                              .filter((interestInner) => interestInner !== interest);
                            setUserModelOrPublic(Object.assign({}, userModel));
                          }}></span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    私の気になる第2のふるさと<span className={'note'}>この情報は公開されます</span>
                  </label>

                  <div className={'user_edit_item__input d-flex flex-column gap-2 border-0 p-0'}>
                    <SelectTown
                      townId={userModel?.localAssociationIds?.[0]}
                      townIndex={0}
                      onChangeTownId={(townId) => {
                        userModel.localAssociationIds = [townId];
                        setUserModelOrPublic(Object.assign({}, userModel));
                      }}
                    />

                    <SelectTown
                      townId={userModel?.localAssociationIds?.[1]}
                      townIndex={1}
                      disabled={!userModel?.localAssociationIds?.[0]}
                      onChangeTownId={(townId) => {
                        if (!userModel?.localAssociationIds) {
                          return;
                        }

                        userModel.localAssociationIds[1] = townId;
                        userModel.localAssociationIds = [
                          ...new Set(userModel.localAssociationIds)
                        ];
                        setUserModelOrPublic(Object.assign({}, userModel));
                      }}
                    />

                    <SelectTown
                      townId={userModel?.localAssociationIds?.[2]}
                      townIndex={2}
                      disabled={!userModel?.localAssociationIds?.[1]}
                      onChangeTownId={(townId) => {
                        if (!userModel?.localAssociationIds) {
                          return;
                        }

                        userModel.localAssociationIds[2] = townId;
                        userModel.localAssociationIds = [
                          ...new Set(userModel.localAssociationIds)
                        ];
                        setUserModelOrPublic(Object.assign({}, userModel));
                      }}
                    />
                  </div>
                </div>

                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    気になる地方創生カテゴリ
                  </label>

                  <div className={'user_edit_item_checkbox'}>
                    {categoryNames.map((categoryName) => {
                      const checked = !!userModel.localCategorys?.includes(categoryName);
                      return (
                        <SelectCategory
                          key={`categoryNames-${categoryName}`}
                          categoryName={categoryName}
                          checked={checked}
                          onSelectCategory={(selectedCategoryName) => {
                            if (!userModel.localCategorys) {
                              userModel.localCategorys = [];
                            }
    
                            if (checked) {
                              userModel.localCategorys = userModel.localCategorys
                                .filter((localCategory) => localCategory !== categoryName);
                            } else {
                              userModel.localCategorys = [
                                ...new Set(userModel.localCategorys), selectedCategoryName,
                              ];
                            }
    
                            setUserModelOrPublic(Object.assign({}, userModel));
                          }}
                        />
                      );
                    })}

                    <div className={'user_edit_item user_edit_checkbox__item'}>
                      <label className={''}>
                        その他
                      </label>
                      <input
                        className={'user_edit_item__input user_edit__input--checkbox col'}
                        type='text'
                        name='other'
                        value={userModel.localCategorys?.filter((localCategory) => {
                          return !categoryNames.includes(localCategory);
                        }) || ''}
                        placeholder='入力してください'
                        onChange={(event) => {
                          userModel.localCategorys = userModel.localCategorys
                            ?.filter((localCategory) => categoryNames.includes(localCategory));

                          userModel.localCategorys = [
                            ...new Set(userModel.localCategorys), event.target.value,
                          ].filter(Boolean);

                          setUserModelOrPublic(Object.assign({}, userModel));
                        }}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className={'user_edit_item'}>
                  <label className={'user_edit_item__label'}>
                    自己紹介文<span className={'note'}>この情報は公開されます</span>
                  </label>
                  <InputWithValidation<UserModel>
                    type='textarea'
                    data={userModel}
                    validationKey={'description'}
                    validationClassName='UserModel'
                    className='user_edit_item__input'
                    placeholder=''
                    onChange={(event) => {
                      onChangeUserInput(event, 'description');
                    }}
                  />
                </div>
              </div>

              <div className={'user_edit_submit'}>
                <p className={'user_edit_submit__title'}>編集・保存</p>
                <button className={'user_edit_submit__btn'} onClick={handleUploadUserProfile}>
                  保存
                </button>
              </div>
            </form>
          </div>  
        </UserContainer>
      </div>

      <LocalbridgeFooter />
    </>
  ):(<></>);
};

export default UserEditRenderer;
