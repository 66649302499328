import React from 'react';
import { UserEditProvider } from './Provider';
import UserEditRenderer from './Renderer';

export const UserEdit = () => {
  return (
    <UserEditProvider>
      <UserEditRenderer />
    </UserEditProvider>
  );
};
