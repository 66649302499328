import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { getValidationQueries, showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import InputWithValidation from '~/components/inputWithValidation';
import { BookingModelWithEmailConfirm, BookingSource, UserGender, UserJobDropdown } from '~/openapi/typescript-axios/version';
import './index.scss';
import { WorkshopCreateBookingState } from '../../enums';
import { useWorkshopBookingsCreate } from '../../Provider';
import SelectCategory from './components/selectCategory';

type Props = {};

const WorkshopBookingsCreateForm: React.FC<Props> = () => {
  const history = useHistory();
  const { validateBookings } = useLocalbridgeBookingApi();
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const {
    bookingModelWithEmailConfirm,
    setBookingModelWithEmailConfirm,
    setWorkshopCreateBookingState,
  } = useWorkshopBookingsCreate();

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === bookingModelWithEmailConfirm?.cityId);
  }, [cities, bookingModelWithEmailConfirm?.cityId]);

  const purposes = [
    'イベント内容に興味があった',
    '既にイベント開催地にゆかりがあるため',
    'イベント開催地の地域活性化に興味があった',
    '地方創生・地域活性化の活動に参加したいと思った',
    'イベント体験記事を読んで自分も参加したいと思った',
    '魅力発見記事を読んでイベント開催地に興味を持った',
    '友人・仲間を作りたいと思った',
    '知人・知り合いの勧め',
    '就職・転職に活かせると思った',
    '将来、移住・定住を考えているため',
  ];

  const currentYear = new Date().getFullYear();
  const yearList = new Array(130)
    .fill(0).map((_, year) => (currentYear - year));
  const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];
  const birthDayDateList = useMemo(() => {
    const [birthDayYear, birthDayMonth] = bookingModelWithEmailConfirm?.birthDay || [];
    if (!birthDayYear || !birthDayMonth) {
      return [];
    }

    return new Array(new Date(birthDayYear, birthDayMonth + 1, 0).getDate())
      .fill(0).map((_, date) => date + 1);
  }, [bookingModelWithEmailConfirm?.birthDay]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const goToConfirmPage = useCallback(async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.preventDefault();

    try {
      const messageResponses = await validateBookings({
        bookingRequests: [{bookingModel: bookingModelWithEmailConfirm}]
      });
      if (messageResponses.validations?.length) {
        throw new LocalbridgeApiError(messageResponses, 412);
      }

      setWorkshopCreateBookingState(WorkshopCreateBookingState.WorkshopBookingConfirm);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }
    }
  }, [
    bookingModelWithEmailConfirm,
    setWorkshopCreateBookingState,
    validateBookings,
  ]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    bookingModelWithEmailConfirm?.cityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === bookingModelWithEmailConfirm?.townId);
  }, [towns, bookingModelWithEmailConfirm?.townId]);

  const isStudent = (value: UserJobDropdown) => {
    return UserJobDropdown.StudentUniversityBachelor === value
      || UserJobDropdown.StudentUniversityMaster === value
      || UserJobDropdown.StudentCollegeProfessional === value
      || UserJobDropdown.StudentCollegeJunior === value
      || UserJobDropdown.StudentHighScholl === value;
  };

  const onChangeBookingInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof BookingModelWithEmailConfirm,
  ) => {
    if (!bookingModelWithEmailConfirm) {
      return;
    }

    bookingModelWithEmailConfirm[key as string] = event.currentTarget.value;
    setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
  }, [bookingModelWithEmailConfirm, setBookingModelWithEmailConfirm]);

  return (
    <>
      <LocalbridgeSubHeader title='イベント申し込み' enTitle='Application' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'workshop_booking_form'}>
          <form className={'workshop_booking_form__form'}>
            <div className={'workshop_booking_form_contents'}>
              <div className={'workshop_booking_form_contents__inner'}>
                <p className={'workshop_booking_form_contents__note'}>※は必須項目です</p>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'} ${'is-error'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    名前<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'name'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'name');
                    }}
                  />
                </div>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    ふりがな<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'nameFurigana'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'nameFurigana');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    ニックネーム<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'nickName'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'nickName');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    メールアドレス<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='email'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'email'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'email');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    メールアドレス確認<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='email'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'emailConfirm'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'emailConfirm');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    電話番号<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'phoneNumber'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'phoneNumber');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-xs'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    郵便番号
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'postNumber'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'postNumber');
                    }}
                  />
                </div>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-xs'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    都道府県
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    validationKey={'cityId'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='workshop_booking_form_contents__input border-0 p-0'>
                        {selectedCity?.cityName || '選択してください'}
                      </span>
                    }
                  >
                    <div className={'row m-1'}>
                      <Dropdown.Item
                        className='btn btn-sm p-1 text-center'
                        onClick={() => {
                          bookingModelWithEmailConfirm.cityId = '';
                          setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                        }}
                      >選択なし</Dropdown.Item>

                      {cities.map((city) => (
                        <Dropdown.Item
                          key={`cities-${city.id}`}
                          className='btn btn-sm p-1 text-center'
                          onClick={() => {
                            const isSameCity = bookingModelWithEmailConfirm.cityId === city.id;
                            if (!isSameCity) {
                              bookingModelWithEmailConfirm.townId = '';
                            }

                            bookingModelWithEmailConfirm.cityId = city.id;
                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        >{city.cityName}</Dropdown.Item>
                      ))}
                    </div>
                  </InputWithValidation>
                </div>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-md'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    市区町村
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    validationKey={'townId'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input p-0'
                    type={'dropdown'}
                    disabled={!selectedCity}
                    title={
                      <span className='workshop_booking_form_contents__input border-0 p-0'>
                        {selectedTown?.associationName || '選択してください'}
                      </span>
                    }
                  >
                    <div className={'row m-1'}>
                      <Dropdown.Item
                        className='btn btn-sm p-1 text-center'
                        onClick={() => {
                          bookingModelWithEmailConfirm.townId = '';
                          setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                        }}
                      >選択なし</Dropdown.Item>

                      {towns.map((town) => (
                        <Dropdown.Item
                          key={`towns-${town.id}`}
                          onClick={() => {
                            bookingModelWithEmailConfirm.townId = town.id;
                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        >{town.associationName}</Dropdown.Item>
                      ))}
                    </div>
                  </InputWithValidation>
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-md'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    番地
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'detailAddress1'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'detailAddress1');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-md'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    建物名・部屋番号
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'detailAddress2'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'detailAddress2');
                    }}
                  />
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    生年月日
                  </label>
                  <div className={'d-flex gap-2'}>
                    <InputWithValidation<BookingModelWithEmailConfirm>
                      data={bookingModelWithEmailConfirm}
                      validationKey={'birthDay'}
                      validationClassName='BookingModelWithEmailConfirm'
                      className='workshop_booking_form_contents__input p-0'
                      type={'dropdown'}
                      title={
                        <span className='workshop_booking_form_contents__input border-0 p-0'>
                          {bookingModelWithEmailConfirm?.birthDay?.[0] || '年'}
                        </span>
                      }
                    >
                      {yearList.map((year) => (
                        <Dropdown.Item
                          key={`birthDay-year-${year}`}
                          onClick={() => {
                            bookingModelWithEmailConfirm.birthDay = [year];

                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        >{year}</Dropdown.Item>
                      ))}
                    </InputWithValidation>

                    <InputWithValidation<BookingModelWithEmailConfirm>
                      data={bookingModelWithEmailConfirm}
                      validationKey={'birthDay'}
                      validationClassName='BookingModelWithEmailConfirm'
                      className='workshop_booking_form_contents__input p-0'
                      type={'dropdown'}
                      disabled={!bookingModelWithEmailConfirm.birthDay?.[0]}
                      title={
                        <span className='workshop_booking_form_contents__input border-0 p-0'>
                          {bookingModelWithEmailConfirm?.birthDay?.[1] || '月'}
                        </span>
                      }
                    >
                      {monthList.map((month) => (
                        <Dropdown.Item
                          key={`birthDay-month-${month}`}
                          onClick={() => {
                            if (!bookingModelWithEmailConfirm?.birthDay) {
                              return;
                            }

                            bookingModelWithEmailConfirm.birthDay[1] = month;
                            bookingModelWithEmailConfirm.birthDay = [...bookingModelWithEmailConfirm.birthDay];
                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        >{month}</Dropdown.Item>
                      ))}
                    </InputWithValidation>

                    <InputWithValidation<BookingModelWithEmailConfirm>
                      data={bookingModelWithEmailConfirm}
                      validationKey={'birthDay'}
                      validationClassName='BookingModelWithEmailConfirm'
                      className='workshop_booking_form_contents__input p-0'
                      type={'dropdown'}
                      disabled={!bookingModelWithEmailConfirm.birthDay?.[1]}
                      title={
                        <span className='workshop_booking_form_contents__input border-0 p-0'>
                          {bookingModelWithEmailConfirm?.birthDay?.[2] || '日'}
                        </span>
                      }
                    >
                      {birthDayDateList.map((date) => (
                        <Dropdown.Item
                          key={`birthDay-date-${date}`}
                          onClick={() => {
                            if (!bookingModelWithEmailConfirm?.birthDay) {
                              return;
                            }

                            bookingModelWithEmailConfirm.birthDay[2] = date;
                            bookingModelWithEmailConfirm.birthDay = [...bookingModelWithEmailConfirm.birthDay];
                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        >{date}</Dropdown.Item>
                      ))}
                    </InputWithValidation>
                  </div>
                </div>

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    性別<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    validationKey={'gender'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='workshop_booking_form_contents__input border-0 p-0'>
                        {bookingModelWithEmailConfirm?.gender || '選択してください'}
                      </span>
                    }
                  >
                    {Object.values(UserGender).map((gender) => (
                      <Dropdown.Item
                        key={`gender-${gender}`}
                        onClick={() => {
                          bookingModelWithEmailConfirm.gender = gender;
                          setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                        }}
                      >{gender}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-md'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    職業<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    validationKey={'job'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input workshop_booking_form__item--width-md p-0'
                    type={'dropdown'}
                    title={
                      <span className='workshop_booking_form_contents__input border-0 p-0'>
                        {bookingModelWithEmailConfirm?.job || '選択してください'}
                      </span>
                    }
                  >
                    {Object.values(UserJobDropdown).map((job) => (
                      <Dropdown.Item
                        key={`userJobs-${job}`}
                        onClick={() => {
                          bookingModelWithEmailConfirm.job = job;
                          setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                        }}
                      >{job}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>

                {isStudent(bookingModelWithEmailConfirm.job as UserJobDropdown) ? (
                  <>
                    <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                      <label className={'workshop_booking_form_contents__label'}>
                        学校名
                      </label>
                      <InputWithValidation<BookingModelWithEmailConfirm>
                        type='text'
                        data={bookingModelWithEmailConfirm}
                        validationKey={'educationalInstitutionName'}
                        validationClassName='BookingModelWithEmailConfirm'
                        className='workshop_booking_form_contents__input'
                        placeholder='〇〇大学'
                        onChange={(event) => {
                          onChangeBookingInput(event, 'educationalInstitutionName');
                        }}
                      />
                    </div>

                    {bookingModelWithEmailConfirm.job !== UserJobDropdown.StudentHighScholl ? (
                      <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                        <label className={'workshop_booking_form_contents__label'}>
                          学部名
                        </label>
                        <InputWithValidation<BookingModelWithEmailConfirm>
                          type='text'
                          data={bookingModelWithEmailConfirm}
                          validationKey={'department'}
                          validationClassName='BookingModelWithEmailConfirm'
                          className='workshop_booking_form_contents__input'
                          placeholder='〇〇学部'
                          onChange={(event) => {
                            onChangeBookingInput(event, 'department');
                          }}
                        />
                      </div>
                    ):(<></>)}

                    <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-sm'}`}>
                      <label className={'workshop_booking_form_contents__label'}>
                        学年<span className={'note'}>この情報は公開されます</span>
                      </label>
                      <InputWithValidation<BookingModelWithEmailConfirm>
                        data={bookingModelWithEmailConfirm}
                        validationKey={'job'}
                        validationClassName='BookingModelWithEmailConfirm'
                        className='workshop_booking_form_contents__input workshop_booking_form__item--width-md p-0'
                        type={'dropdown'}
                        title={
                          <span className='workshop_booking_form_contents__input border-0 p-0'>
                            {bookingModelWithEmailConfirm?.job || '選択してください'}
                          </span>
                        }
                      >
                        {Object.values(UserJobDropdown).map((job) => (
                          <Dropdown.Item
                            key={`userJobs-${job}`}
                            onClick={() => {
                              bookingModelWithEmailConfirm.job = job;
                              setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                            }}
                          >{job}</Dropdown.Item>
                        ))}
                      </InputWithValidation>
                    </div>

                    <div className={'workshop_booking_form_contents__item'}>
                      <label className={'workshop_booking_form_contents__label'}>
                        入学年月
                      </label>
                      <div className={'d-flex gap-2'}>
                        <InputWithValidation<BookingModelWithEmailConfirm>
                          data={bookingModelWithEmailConfirm}
                          validationKey={'studentStartAt'}
                          validationClassName='BookingModelWithEmailConfirm'
                          className='workshop_booking_form_contents__input p-0'
                          type={'dropdown'}
                          title={
                            <span className='workshop_booking_form_contents__input border-0 p-0'>
                              {bookingModelWithEmailConfirm?.studentStartAt?.[0] || '年'}
                            </span>
                          }
                        >
                          {yearList.map((year) => (
                            <Dropdown.Item
                              key={`studentStartAt-year-${year}`}
                              onClick={() => {
                                bookingModelWithEmailConfirm.studentStartAt = [year];

                                setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                              }}
                            >{year}</Dropdown.Item>
                          ))}
                        </InputWithValidation>

                        <InputWithValidation<BookingModelWithEmailConfirm>
                          data={bookingModelWithEmailConfirm}
                          validationKey={'studentStartAt'}
                          validationClassName='BookingModelWithEmailConfirm'
                          className='workshop_booking_form_contents__input p-0'
                          type={'dropdown'}
                          disabled={!bookingModelWithEmailConfirm.studentStartAt?.[0]}
                          title={
                            <span className='workshop_booking_form_contents__input border-0 p-0'>
                              {bookingModelWithEmailConfirm?.studentStartAt?.[1] || '月'}
                            </span>
                          }
                        >
                          {monthList.map((month) => (
                            <Dropdown.Item
                              key={`studentStartAt-month-${month}`}
                              onClick={() => {
                                if (!bookingModelWithEmailConfirm?.studentStartAt) {
                                  return;
                                }

                                bookingModelWithEmailConfirm.studentStartAt[1] = month;
                                bookingModelWithEmailConfirm.studentStartAt = [...bookingModelWithEmailConfirm.studentStartAt];
                                setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                              }}
                            >{month}</Dropdown.Item>
                          ))}
                        </InputWithValidation>
                      </div>
                    </div>
                  </>
                ):(<></>)}

                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-md'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    その他、事務局への連絡事項
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    type='text'
                    data={bookingModelWithEmailConfirm}
                    validationKey={'keyPoint'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input'
                    onChange={(event) => {
                      onChangeBookingInput(event, 'keyPoint');
                    }}
                  />
                </div>

                <p className={'workshop_booking_form_contents__title'}>アンケート</p>
                <div className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-lg'}`}>
                  <label className={'workshop_booking_form_contents__label'}>
                    このイベントを何で知りましたか？<span>※</span>
                  </label>
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    validationKey={'source'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='workshop_booking_form_contents__input border-0 p-0'>
                        {bookingModelWithEmailConfirm?.source || '選択してください'}
                      </span>
                    }
                  >
                    {Object.values(BookingSource).map((source) => (
                      <Dropdown.Item
                        key={`source-${source}`}
                        onClick={() => {
                          bookingModelWithEmailConfirm.source = source;
                          setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                        }}
                      >{source}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>
                
                <div
                  className={`${'workshop_booking_form_contents__item'} ${'workshop_booking_form__item--width-lg'}`}
                  onClick={() => {
                    const inputElement = document.querySelector(getValidationQueries({
                      className: 'BookingModelWithEmailConfirm', id: '', key: 'purposes',
                    }).join(''));

                    inputElement?.classList.remove('is-invalid');
                  }}
                >
                  <InputWithValidation<BookingModelWithEmailConfirm>
                    data={bookingModelWithEmailConfirm}
                    type='label'
                    validationKey={'purposes'}
                    validationClassName='BookingModelWithEmailConfirm'
                    className='workshop_booking_form_contents__input p-0'
                  >
                    <label className={'workshop_booking_form_contents__label'}>
                      このイベントに参加しようと思った動機を教えてください。（複数選択可）<span>※</span>
                    </label>
                  </InputWithValidation>
                  <div className={'workshop_booking_form_checkbox'}>
                    {purposes.map((purpose) => {
                      const checked = !!bookingModelWithEmailConfirm.purposes?.includes(purpose);
                      return (
                        <SelectCategory
                          key={`purposes-${purpose}`}
                          purpose={purpose}
                          checked={checked}
                          onSelectPurpose={(selectedPurpose) => {
                            if (!bookingModelWithEmailConfirm.purposes) {
                              bookingModelWithEmailConfirm.purposes = [];
                            }
    
                            if (checked) {
                              bookingModelWithEmailConfirm.purposes = bookingModelWithEmailConfirm.purposes
                                .filter((innerPerpose) => innerPerpose !== purpose);
                            } else {
                              bookingModelWithEmailConfirm.purposes = [
                                ...new Set(bookingModelWithEmailConfirm.purposes), selectedPurpose,
                              ];
                            }
    
                            setBookingModelWithEmailConfirm(Object.assign({}, bookingModelWithEmailConfirm));
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={`${'workshop_booking_form_contents__btns'} ${'workshop_booking_form_contents__btns--is_pc'}`}>
                <input
                  className={`${'workshop_booking_form_contents__btn'} ${'workshop_booking_form_contents__btn--submit'}`}
                  type='submit'
                  onClick={goToConfirmPage}
                  value='この内容で確定する'
                />

                <a
                  className={`${'workshop_booking_form_contents__btn'} ${'workshop_booking_form_contents__btn--back'}`}
                  onClick={() => history.goBack()}
                >
                  戻る
                </a>
              </div>
            </div>

            <div className={'workshop_booking_form_side'}>
              <div className={'workshop_booking_form_side__head'}>
                <img className={'workshop_booking_form_side__img'} src='/assets/img/application/pic_dummy.jpg' />
                <div className={'workshop_booking_form_side__texts'}>
                  <p className={'workshop_booking_form_side__title'}>見出しのダミーテキストイメージです。見出しのダミーテキストイメージです。</p>
                  <p className={'workshop_booking_form_side__date'}>2023/7/16（日）14:00</p>
                </div>
              </div>
              <p className={'workshop_booking_form_side__label'}>料金</p>
              <div className={'workshop_booking_form_side_price'}>
                <p className={'workshop_booking_form_side_price__text'}>参加費</p>
                <p className={'workshop_booking_form_side_price__number'}>¥2,000</p>
              </div>
              <a className={'workshop_booking_form_side__btn'}>入力が完了していません</a>
              <div className={'workshop_booking_form_side_description'}>
                <p className={'workshop_booking_form_side_description__title'}>キャンセルについて</p>
                <p className={'workshop_booking_form_side_description__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              </div>
            </div>

            <div className={`${'workshop_booking_form_contents__btns'} ${'workshop_booking_form_contents__btns--is_sp'}`}>
              <input
                className={`${'workshop_booking_form_contents__btn'} ${'workshop_booking_form_contents__btn--submit'}`}
                type='submit'
                onClick={goToConfirmPage}
                value='この内容で確定する'
              />

              <a
                className={`${'workshop_booking_form_contents__btn'} ${'workshop_booking_form_contents__btn--back'}`}
                onClick={() => history.goBack()}>
                戻る
              </a>
            </div>
          </form>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default WorkshopBookingsCreateForm;
