import React, { useCallback } from 'react';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { useLocalbridgeApi } from '~/providers/LocalbridgeApiProvider';
import './index.scss';
import { useQna } from '../../Provider';
import { QnaState } from '../../enums';
import { LOCALBRIDGE_PATH, FUNCTION_V } from '~/constants/firebase';

type Props = {};

const QnaConfirm: React.FC<Props> = () => {
  const { localbridgeQnaSendMail } = useLocalbridgeApi();
  const { qnaMail, setQnaState } = useQna();

  const goToQnaSubmittedPage = useCallback(async () => {
    try {
      await localbridgeQnaSendMail({
        env: { LOCALBRIDGE_PATH, FUNCTION_V }, params: { qnaMail },
      });

      setQnaState(QnaState.QnaSubmitted);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        console.log((error as LocalbridgeApiError).messageResponse);
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }
    }
  }, [qnaMail, localbridgeQnaSendMail, setQnaState]);

  return (
    <>
      <LocalbridgeSubHeader title='お問い合わせ' enTitle='Contact' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'qna_confirm'}>
          <p className={'qna_confirm__lead'}>

          </p>
          <div className={'qna_confirm_contents'}>
            <div className={'qna_confirm_contents__inner'}>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>組織・団体</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.groupName}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>部署名</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.teamName}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>氏名</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.senderName}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>電話番号</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.phoneNumberFrom}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>メールアドレス</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.emailFrom}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>メールアドレス確認</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.emailFromConfirm}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>問合せカテゴリー</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.questionType}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>件名</p>
                <p className={'qna_confirm_contents__value'}>{qnaMail.title}</p>
              </div>
              <div className={'qna_confirm_contents__item'}>
                <p className={'qna_confirm_contents__label'}>お問い合わせ内容</p>
                <p className={'qna_confirm_contents__value'}>
                  {qnaMail.content.split('\n').map((splitedText, index) => (
                    <React.Fragment key={`qnaMail-content:${index}`}>
                      {splitedText}
                      {(index !== qnaMail.content.split('\n').length - 1) ? (<br />) : (<></>)}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          </div>
          <p className={'qna_confirm__note'}>
            ※いただいた内容を元に、3営業日以内に担当者からご返信いたします。
          </p>
          <p className={'qna_confirm__note'}>
            ※本お問い合わせフォームにご記入いただいた個人情報については、プライバシーポリシー（リンク）に沿って取り扱いますので、ご確認いただきますようお願い申し上げます。
          </p>
          <div className={'qna_confirm_contents__btns'}>
            <input
              className={'qna_confirm_contents__btn qna_confirm_contents__btn--submit'}
              value='送信'
              onClick={goToQnaSubmittedPage}
            />
            <a
              className={'qna_confirm_contents__btn qna_confirm_contents__btn--back'}
              onClick={() => setQnaState(QnaState.QnaForm)}
            >戻る</a>
          </div>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default QnaConfirm;
