
import React, { useContext, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '~/providers/AppStateProvider';

type Props = {
  children: ReactNode;
};

interface AdminUserEditValue {

}

const AdminUserEditContext = React.createContext<AdminUserEditValue | null>(null);

export function useAdminUserEdit(): AdminUserEditValue {
  const state = useContext(AdminUserEditContext);

  if (!state) {
    throw new Error('useAdminUserEdit must be used within AdminUserEditProvider');
  }

  return state;
}

export function AdminUserEditProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { userId } = useParams<any>();

  useEffect(() => {
    if (params.userId !== userId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { userId }));
    }
  }, [userId, params, setParams]);

  const providerValue = {

  };

  return (
    <AdminUserEditContext.Provider value={providerValue}>
      {children}
    </AdminUserEditContext.Provider>
  );
}
