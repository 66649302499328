
import React, { useContext, ReactNode, useEffect } from 'react';
import { useAuth } from '~/providers/AuthProvider';

type Props = {
  children: ReactNode;
};

interface AdminSignOutValue {

}

const AdminSignOutContext = React.createContext<AdminSignOutValue | null>(null);

export function useAdminSignOut(): AdminSignOutValue {
  const state = useContext(AdminSignOutContext);

  if (!state) {
    throw new Error('useAdminSignOut must be used within AdminSignOutProvider');
  }

  return state;
}

export function AdminSignOutProvider({ children }: Props) {
  const { doSignOut } = useAuth();

  const providerValue = {

  };

  useEffect(() => {
    doSignOut();
  }, [doSignOut]);

  return (
    <AdminSignOutContext.Provider value={providerValue}>
      {children}
    </AdminSignOutContext.Provider>
  );
}
