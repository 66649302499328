import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  LOCALBRIDGE_PATH,
  firestore,
} from '~/constants/firebase';
import {
  BookingRequestsDelete,
  BookingRequestCreate,
  BookingRequestUpdate,
  BookingRequestsValidate,
  BookingResponsesWithPager,  
  BookingRequestsSearch,
  BookingModel,
  BookingRequestRead,
  BookingResponseRead,
  testEmptyInput,
  testStringMax80,
  testStringId,
  testStringMax40000,
  testDateReversedStartAndEnd,
  testStringMax200,
  BookingModelWithEmailConfirm,
  testUnMatchedEmail,
} from '~/openapi/typescript-axios/version';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { getSearchStringQuery } from './helpers/getSearchStringQuery';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeBookingApiValue {
  validateBookings: (bookingRequestsValidate: BookingRequestsValidate) => Promise<MessageResponse>;
  createBookings: (bookingRequestCreates: Array<BookingRequestCreate>) => Promise<void>;
  getBooking: (bookingRequestRead: BookingRequestRead) => Promise<BookingResponseRead|null>;
  updateBooking: (bookingRequestUpdate: BookingRequestUpdate) => Promise<void>;
  deleteBookings: (deleteBookingsRequest: BookingRequestsDelete) => Promise<void>;
  searchBookings: (
    bookingRequestsSearch: BookingRequestsSearch
  ) => Promise<Array<BookingResponsesWithPager>>;
}

const LocalbridgeBookingApiContext = React.createContext<LocalbridgeBookingApiValue | null>(null);

export function useLocalbridgeBookingApi(): LocalbridgeBookingApiValue {
  const state = useContext(LocalbridgeBookingApiContext);

  if (!state) {
    throw new Error('useLocalbridgeBookingApi must be used within LocalbridgeBookingApiProvider');
  }

  return state;
}

export function LocalbridgeBookingApiProvider({ children }: Props) {
  const validateBookings = useCallback(async (bookingRequestsValidate: BookingRequestsValidate) => {
    const bookingModelWithEmailConfirms = bookingRequestsValidate.bookingRequests.map((bookingRequest) => {
      return {
        ...bookingRequest.bookingModel,
        emailConfirm: bookingRequest.emailConfirm,
      };
    });

    const bookingValidator = new Validator<BookingModelWithEmailConfirm>('BookingModelWithEmailConfirm', {
      id: [
        async (booking) => testEmptyInput(booking.id),
        async (booking) => testStringMax80(booking.id),
        async (booking) => testStringId(booking.id),
      ],
      name: [
        async (booking) => testEmptyInput(booking.name),
        async (booking) => testStringMax80(booking.name),
      ],
      photos: [
      ],
      createdAt: [
        async (booking) => testEmptyInput(booking.createdAt),
        async (booking) => testDateReversedStartAndEnd(booking.createdAt, booking.updatedAt),
      ],
      updatedAt: [
        async (booking) => testEmptyInput(booking.updatedAt),
      ],
      email: [
        async (booking) => testEmptyInput(booking.email),
      ],
      emailConfirm: [
        async (booking) => testEmptyInput(booking.emailConfirm),
        async (booking) => testUnMatchedEmail(booking.email, booking.emailConfirm),
      ],
      cityId: [
        async (booking) => testStringMax80(booking.cityId),
      ],
      townId: [
        async (booking) => testStringMax80(booking.townId),
      ],
      detailAddress1: [
        async (booking) => testStringMax200(booking.detailAddress1),
      ],
      detailAddress2: [
        async (booking) => testStringMax200(booking.detailAddress2),
      ],
      icon: [
        async (booking) => testEmptyInput(booking.icon),
      ],
      job: [
        async (booking) => testStringMax80(booking.job),
      ],
      nameFurigana: [
        async (booking) => testEmptyInput(booking.nameFurigana),
        async (booking) => testStringMax80(booking.nameFurigana),
      ],
      nickName: [
        async (booking) => testEmptyInput(booking.nickName),
        async (booking) => testStringMax80(booking.nickName),
      ],
      emailToken: [
        async (booking) => testEmptyInput(booking.emailToken),
      ],
      gender: [
        async (booking) => testEmptyInput(booking.gender),
        async (booking) => testStringMax80(booking.gender),
      ],
      interests: [],
      userId: [
        async (booking) => testStringMax80(booking.userId),
      ],
      workshopId: [
        async (booking) => testEmptyInput(booking.workshopId),
        async (booking) => testStringMax80(booking.workshopId),
      ],
      groupId: [
        async (booking) => testEmptyInput(booking.groupId),
        async (booking) => testStringMax80(booking.groupId),
      ],
      source: [
        async (booking) => testEmptyInput(booking.source),
        async (booking) => testStringMax80(booking.source),
      ],
      purposes: [
        async (booking) => testEmptyInput(booking.purposes),
      ],
      postNumber: [
        async (booking) => testStringMax80(booking.postNumber),
      ],
      keyPoint: [
        async (booking) => testStringMax40000(booking.keyPoint),
      ],
    });

    return bookingValidator.validate(bookingModelWithEmailConfirms);
  }, []);

  const createBookings = useCallback(async (bookingRequestCreates: Array<BookingRequestCreate>) => {
    if (!bookingRequestCreates.length) {
      return;
    }

    const messageResponses = await validateBookings({
      isOnSubmit: true,
      bookingRequests: bookingRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const bookingRequestCreate of bookingRequestCreates) {
      console.log(bookingRequestCreate.bookingModel);
      const { id } = bookingRequestCreate.bookingModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/bookings/${id}`), {
        ...Object.assign({}, bookingRequestCreate.bookingModel)
      });
    }

    await batch.commit();
  }, [validateBookings]);

  const getBooking = useCallback(async (bookingRequestRead: BookingRequestRead) => {
    const { bookingId } = bookingRequestRead;
    const bookingModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/bookings/${bookingId}`))).data() as BookingModel|null;
    return bookingModel ? {bookingModel} : null;
  }, []);

  const updateBooking = useCallback(async (bookingRequestUpdate: BookingRequestUpdate) => {
    const messageResponses = await validateBookings({
      isOnSubmit: true,
      bookingRequests: [bookingRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = bookingRequestUpdate.bookingModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/bookings/${id}`), {
      ...Object.assign({}, bookingRequestUpdate)
    });
  }, [validateBookings]);

  const deleteBookings = useCallback(async (deleteBookingsRequest: BookingRequestsDelete) => {
    const bookingIds = deleteBookingsRequest?.bookingIds || [];

    if (!bookingIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const bookingId of bookingIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/bookings/${bookingId}`));
    }

    await batch.commit();
  }, []);

  const searchBookings = useCallback(async (bookingRequestsSearch: BookingRequestsSearch) => {
    const {
      size,
      page,
      userId,
      workshopId,
      cityId,
      townId,
      createdFrom,
      createdTo,
      searchString,
    } = bookingRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/groups`),
      and(
        ...[
          userId ? where('userId', '==', userId) : null,
          workshopId ? where('workshopId', '==', workshopId) : null,
          cityId ? where('cityId', '==', cityId) : null,
          townId ? where('townId', '==', townId) : null,
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
          searchString
            ? getSearchStringQuery(searchString, ['titleToken', 'overviewToken', 'detailToken'])
            : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
      ),
      !searchString ? orderBy('createdAt', 'desc') : limit(1000),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<BookingModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<BookingResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const providerValue = {
    validateBookings,
    createBookings,
    getBooking,
    updateBooking,
    deleteBookings,
    searchBookings,
  };

  return (
    <LocalbridgeBookingApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeBookingApiContext.Provider>
  );
}
