import axios from 'axios';
import { REACT_APP_LOCALBRIDGE_ENV, firebaseConfig } from '~/constants/firebase';
import firebaseJson from '~/environments/firebase.json';

export const functionsRequestPost = async (functionName: string, requestData: any) => {
  const { emulators: { functions: { port } } } = firebaseJson;
  const functionUrl = REACT_APP_LOCALBRIDGE_ENV === 'local'
    ? `http://localhost:${port}/${firebaseConfig.projectId}/asia-northeast1/${functionName}`
    : `https://asia-northeast1-${firebaseConfig.projectId}.cloudfunctions.net/${functionName}`;

  const response = await axios(functionUrl, {
    method: 'POST',
    data: requestData,
    responseType: 'json'
  });

  return response.data;
};
