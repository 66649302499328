import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaginationBox } from '~/components/paginationBox';
import { UserModel } from '~/openapi/typescript-axios/version';
import { useAdminUsers } from './Provider';
import { StyledAdminTable } from '../components/StyledAdminTable';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';

type Props = {};

const AdminUsersRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    usersList,
    usersPageCurrent,
    usersPageLast,
    setUsersPageCurrent,
  } = useAdminUsers();

  const users: Array<UserModel> = useMemo(() => {
    return usersList.find((usersWithPager) => {
      return usersWithPager.pager.page === usersPageCurrent;
    })?.data || [];
  }, [usersPageCurrent, usersList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <span style={{fontSize: 24}}>ユーザー一覧</span>

        <span
          className='m-auto fw-bolder p-2'
          hidden={users.length > 0}
        >
          登録された現場がありません
        </span>

        {users.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* 名前/ふりがな/ニックネーム */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  名前
                  <br />
                  ふりがな
                  <br />
                  ニックネーム
                </th>

                {/* 住所 */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  住所
                </th>
              </tr>
              <tr className='border'>
                {/* 職業 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  職業
                </th>

                {/* 都道府県 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  都道府県
                </th>

                {/* 市区町村 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  市区町村
                </th>

                {/* 興味・関心 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  興味・関心
                </th>

                {/* 第二ふるさと */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  第二ふるさと
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 学校名 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  学校名
                </th>

                {/* 学部名 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  学部名
                </th>

                {/* 学年 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  学年
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {users.map((user: UserModel, index: number) => {

                return (
                  <tbody
                    key={user.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* 名前/ふりがな/ニックネーム */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {user.name}
                        <br />
                        {user.nameFurigana}
                        <br />
                        {user.nickName}
                      </th>

                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {`${user.detailAddress1} ${user.detailAddress2}`}
                      </th>
                    </tr>

                    <tr>
                      {/* 職業 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {user.job}
                      </td>

                      {/* 都道府県 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >{user.cityId}</td>

                      {/* 市区町村 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >{user.townId}</td>

                      {/* 興味・関心 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {user.interests}
                      </td>

                      {/* 第二ふるさと */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {JSON.stringify(user.localAssociationIds)}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_USER_EDIT, {
                          userId: user.id,
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 学校名 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {user.educationalInstitutionName}
                      </td>

                      {/* 学部名 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {user.department}
                      </td>

                      {/* 学年 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {user.studentGrade}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        {usersList.length ? (
          <PaginationBox
            paginationBoxClassNamePrefix='pagination_box_block'
            forcePage={usersPageCurrent}
            pageCount={usersPageLast}
            onPageChange={setUsersPageCurrent}
          />
        ):(<></>)}
      </div>
    </div>
  );
};

export default AdminUsersRenderer;
