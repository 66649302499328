import React from 'react';
import './index.scss';

type Props = {
  title: string;
  titleEn: string;
};

const UserHeader: React.FC<Props> = ({title, titleEn, children}) => {
  return (
    <div className={'user_header'}>
      <h1 className={'user_header__title'}>{title}</h1>
      <p className={'user_header__title_en'}>{titleEn}</p>
      {children}
    </div>
  );
};

export default UserHeader;