
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AdminValue {

}

const AdminContext = React.createContext<AdminValue | null>(null);

export function useAdmin(): AdminValue {
  const state = useContext(AdminContext);

  if (!state) {
    throw new Error('useAdmin must be used within AdminProvider');
  }

  return state;
}

export function AdminProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AdminContext.Provider value={providerValue}>
      {children}
    </AdminContext.Provider>
  );
}
