import React, { useCallback } from 'react';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useWorkshopBookingsCreate } from '../../Provider';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { removeCheck } from '~/helpers/toaster/doubleCheck/remoteCheck';
import showError from '~/helpers/toaster/message/showError';

type Props = {};

const WorkshopBookingsCreateSubmitted: React.FC<Props> = () => {
  const { deleteBookings } = useLocalbridgeBookingApi();
  const { bookingModelWithEmailConfirm } = useWorkshopBookingsCreate();

  const history = useHistory();

  const handleDeleteBooking = useCallback(async () => {
    if (!bookingModelWithEmailConfirm?.id) {
      return;
    }

    try {
      const canRemove = await removeCheck({
        title: 'お申し込みのキャンセル',
        message: '本当にキャンセルしますか？',
        confirmButtonText: 'キャンセル',
        checkboxMessage: 'はい、削除して問題ありません',
      });

      if (canRemove) {
        await deleteBookings({bookingIds: [bookingModelWithEmailConfirm.id]});
        showSuccess({
          title: 'お申し込みのキャンセル',
          text: 'キャンセルしました'
        });
      }
    } catch (error) {
      showError({
        title: 'お申し込みのキャンセル',
        text: (error as Error).message,
      });
    }
  }, [bookingModelWithEmailConfirm?.id, deleteBookings]);

  return (
    <>
      <LocalbridgeSubHeader title="イベント申し込み" enTitle="Application" />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <section className={'workshop_booking_submitted'}>
          <div className={'workshop_booking_submitted__inner'}>
            <h2 className={'workshop_booking_submitted__title'}>
              イベントへのお申し込みが
              <br className={'is-sp'} />
              完了しました
            </h2>
            <p className={'workshop_booking_submitted__text'}>
              お申し込みいただきありがとうございます。
              <br />
              確認メールをお送りしましたので、予約内容をご確認ください。
              <br />
              お申し込み内容を変更する場合は、一度キャンセルの上再申し込みをするか、お問い合わせ窓口までご連絡ください。
            </p>
            <a
              className={'workshop_booking_submitted__cancel'}
              onClick={() => handleDeleteBooking()}
            >
              お申し込みのキャンセルはこちら
            </a>
            <div className={'workshop_booking_submitted__back'}>
              <a onClick={() => history.push(ROUTES.HOME)}>TOPページに戻る</a>
            </div>
          </div>
        </section>
      </LocalbridgeBody>
    </>
  );
};

export default WorkshopBookingsCreateSubmitted;
