import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookingResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';

type Props = {
  children: ReactNode;
};

interface GroupWorkshopBookingsValue {
  bookingsList: Array<BookingResponsesWithPager>;
  bookingsPageCurrent: number;
  bookingsPageLast: number;
  setBookingsPageCurrent: (bookingsPageCurrent: number) => void;
}

const GroupWorkshopBookingsContext = React.createContext<GroupWorkshopBookingsValue | null>(null);

export function useGroupWorkshopBookings(): GroupWorkshopBookingsValue {
  const state = useContext(GroupWorkshopBookingsContext);

  if (!state) {
    throw new Error('useGroupWorkshopBookings must be used within GroupWorkshopBookingsProvider');
  }

  return state;
}

export function GroupWorkshopBookingsProvider({ children }: Props) {
  const { workshopId } = useParams<any>();
  const { workshopModel } = useParentWorkshop();

  const { searchBookings } = useLocalbridgeBookingApi();

  const [ bookingsList, setBookingsList ] = useState<Array<BookingResponsesWithPager>>([]);
  const [ bookingsPageCurrent, setBookingsPageCurrent ] = useState<number>(0);
  const [ bookingsPageLast, setBookingsPageLast ] = useState<number>(0);

  const getBookingsList = useCallback(async () => {
    if (!workshopModel?.id !== workshopId) {
      return;
    }

    const bookingsListTmp = await searchBookings({workshopId});
    const [bookingsWithPager] = bookingsListTmp;

    if (bookingsWithPager?.pager) {
      const { totalCount, size } = bookingsWithPager.pager;
      setBookingsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }
    setBookingsList(bookingsListTmp);
  }, [workshopModel, workshopId, searchBookings, setBookingsList]);

  useEffect(() => {
    getBookingsList();
  }, [getBookingsList]);

  const providerValue = {
    bookingsList,
    bookingsPageCurrent,
    bookingsPageLast,
    setBookingsPageCurrent,
  };

  return (
    <GroupWorkshopBookingsContext.Provider value={providerValue}>
      {children}
    </GroupWorkshopBookingsContext.Provider>
  );
}
