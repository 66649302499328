import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useQuery } from 'react-query';

import './index.scss';
import { AssociationModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';

export type SearchBoxPostOption = {
  searchString: string;
  cityId?: string;
  townId?: string;  
}

type Props = {
  title: string;
  onSubmit: (searchBoxPostOption: SearchBoxPostOption) => void;
};

const SearchBoxPost: React.FC<Props> = ({
  title,
  onSubmit,
}) => {
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const [ selectedCity, setSelectedCity ] = useState<AssociationModel>();
  const [ selectedTown, setSelectedTown ] = useState<AssociationModel>();
  const [ searchString, setSearchString ] = useState<string>('');

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const onChangeSearchBoxPostString = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    console.log(event.currentTarget.value);

    setSearchString(event.currentTarget.value);
  }, []);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  useEffect(() => {
    setSelectedTown(undefined);
  }, [selectedCity]);

  return (
    <div className={'search_box_post'}>
      <div className={'search_box_post__main'}>
        <p className={'search_box_post__title'}>{title}</p>
        <form className={'search_box_post__contents'}>
          <div className={'search_box_post__content'}>
            <div className={'search_box_post__input'}>
              <div className={'search_box_post__item'}>
                <input
                  type='text'
                  placeholder='フリーキーワード'
                  name='prefecture'
                  onChange={onChangeSearchBoxPostString}
                />
                <img src='/assets/img/common/ico_search.svg' />
              </div>
            </div>
          </div>
          <div className={'search_box_post__content'}>
            <div className={'search_box_post__input'}>
              <DropdownButton
                variant={'white'}
                className='p-0 col'
                as={ButtonGroup}
                drop={'down'}
                title={
                  <div className={'search_box_post__item d-flex'}>
                    <span>{selectedCity?.cityName || '都道府県'}</span>
                    <img src='/assets/img/common/ico_arrow.svg' />
                  </div>
                }
              >
                <div className='row m-1'>
                  <Dropdown.Item
                    className='btn btn-sm p-1 text-center'
                    onClick={() => setSelectedCity(undefined)}
                  >選択なし</Dropdown.Item>

                  {cities.filter((town) => town.id !== '000000').map((city) => (
                    <Dropdown.Item
                      key={`cities-${city.id}`}
                      className='btn btn-sm p-1 text-center'
                      onClick={() => setSelectedCity(city)}
                    >{city.cityName}</Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>

              <DropdownButton
                variant={'white'}
                className='p-0 col'
                disabled={!selectedCity}
                as={ButtonGroup}
                drop={'down'}
                title={
                  <div className={'search_box_post__item d-flex'}>
                    <span>{selectedTown?.associationName || '市区町村'}</span>
                    <img src='/assets/img/common/ico_arrow.svg' />
                  </div>
                }
              >
                <div className='row m-1'>
                  <Dropdown.Item
                    className='btn btn-sm p-1 text-center'
                    onClick={() => setSelectedTown(undefined)}
                  >選択なし</Dropdown.Item>

                  {towns.filter((town) => town.id !== '000000').map((town) => (
                    <Dropdown.Item
                      key={`towns-${town.id}`}
                      className='btn btn-sm p-1 text-center'
                      onClick={() => setSelectedTown(town)}
                    >{town.associationName}</Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>

              <div className={'search_box_post__submit col'}>
                <button onClick={(event) => {
                  event.preventDefault();

                  const searchBoxPostOption: SearchBoxPostOption = { searchString };
                  if (selectedCity) {
                    searchBoxPostOption.cityId = selectedCity.id;
                  }

                  if (selectedTown) {
                    searchBoxPostOption.townId = selectedTown.id;
                  }

                  onSubmit(searchBoxPostOption);
                }}>検索</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <p className={'search_box_post__result'}>検索結果が10件見つかりました</p> */}
    </div>
  );
};

export default SearchBoxPost;
