
import { applyActionCode } from 'firebase/auth';
import React, { useContext, ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from '~/constants/firebase';

type Props = {
  children: ReactNode;
};

interface SignUpEmailVarificationValue {
  isRegistered: boolean;
  hasError: boolean;
  handleSignUpEmailVarification: () => Promise<void>;
}

const SignUpEmailVarificationContext = React.createContext<SignUpEmailVarificationValue | null>(null);

export function useSignUpEmailVarification(): SignUpEmailVarificationValue {
  const state = useContext(SignUpEmailVarificationContext);

  if (!state) {
    throw new Error('useSignUpEmailVarification must be used within SignUpEmailVarificationProvider');
  }

  return state;
}

export function SignUpEmailVarificationProvider({ children }: Props) {
  const search = useLocation().search;
  const actionCode = new URLSearchParams(search).get('oobCode');
  const [ isRegistered, setIsRegistered ] = useState<boolean>(false);
  const [ hasError, setHasError ] = useState<boolean>(false);

  const handleSignUpEmailVarification = useCallback(async () => {
    if (!actionCode) {
      return;
    }

    try {
      await applyActionCode(auth, actionCode);
      setIsRegistered(true);
    } catch (error) {
      setHasError(true);
    }
  }, [actionCode]);

  const providerValue = {
    isRegistered,
    hasError,
    handleSignUpEmailVarification,
  };

  return (
    <SignUpEmailVarificationContext.Provider value={providerValue}>
      {children}
    </SignUpEmailVarificationContext.Provider>
  );
}
