import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  PostRequestsDelete,
  PostRequestCreate,
  PostRequestUpdate,
  PostRequestsValidate,
  PostResponsesWithPager,
  LocalbridgePostUpdateUserReactionRequest,
  PostRequestsSearch,
  testStringMax80,
  PostRequestRead,
  PostResponseRead,
  PostModel,
  testStringId,
  testEmptyInput,
  testStringMax40000,
  testDateReversedStartAndEnd,
} from '~/openapi/typescript-axios/version';
import {
  LOCALBRIDGE_PATH,
  firestore,
  functions,
} from '~/constants/firebase';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { getSearchStringQuery } from './helpers/getSearchStringQuery';

type Props = {
  children?: ReactNode;
};

interface LocalbridgePostApiValue {
  validatePosts: (postRequestsValidate: PostRequestsValidate) => Promise<MessageResponse>;
  createPosts: (postRequestCreates: Array<PostRequestCreate>) => Promise<void>;
  getPost: (postRequestRead: PostRequestRead) => Promise<PostResponseRead|null>;
  updatePost: (postRequestUpdate: PostRequestUpdate) => Promise<void>;
  deletePosts: (deletePostsRequest: PostRequestsDelete) => Promise<void>;
  searchPosts: (postRequestsSearch: PostRequestsSearch) => Promise<Array<PostResponsesWithPager>>;
  localbridgePostUpdateUserReaction: (
    localbridgePostUpdateUserReactionRequest: LocalbridgePostUpdateUserReactionRequest
  ) => Promise<void>;
}

const LocalbridgePostApiContext = React.createContext<LocalbridgePostApiValue | null>(null);

export function useLocalbridgePostApi(): LocalbridgePostApiValue {
  const state = useContext(LocalbridgePostApiContext);

  if (!state) {
    throw new Error('useLocalbridgePostApi must be used within LocalbridgePostApiProvider');
  }

  return state;
}

export function LocalbridgePostApiProvider({ children }: Props) {
  const validatePosts = useCallback(async (postRequestsValidate: PostRequestsValidate) => {
    const postModels = postRequestsValidate.postRequests.map((postRequest) => {
      return postRequest.postModel;
    });

    const postValidator = new Validator<PostModel>('PostModel', {
      id: [
        async (post) => testEmptyInput(post.id),
        async (post) => testStringMax80(post.id),
        async (post) => testStringId(post.id),
      ],
      photos: [
      ],
      createdAt: [
        async (post) => testEmptyInput(post.createdAt),
        async (post) => testDateReversedStartAndEnd(post.createdAt, post.updatedAt),
      ],
      updatedAt: [
        async (post) => testEmptyInput(post.updatedAt),
      ],
      cityId: [
        async (post) => testEmptyInput(post.cityId),
        async (post) => testStringMax80(post.cityId),
        async (post) => testStringId(post.cityId),
      ],
      townId: [
        async (post) => testEmptyInput(post.townId),
        async (post) => testStringMax80(post.townId),
        async (post) => testStringId(post.townId),
      ],
      mainPhoto: [
      ],
      title: [
        async (post) => testEmptyInput(post.title),
        async (post) => testStringMax80(post.title),
      ],
      titleToken: [
        async (post) => testEmptyInput(post.titleToken),
      ],
      content: [
        async (post) => testEmptyInput(post.content),
        async (post) => testStringMax40000(post.content),
      ],
      contentToken: [
        async (post) => testEmptyInput(post.contentToken),
      ],
      contentData: [
        async (post) => testEmptyInput(post.contentData),
      ],
      postType: [
        async (post) => testEmptyInput(post.postType),
        async (post) => testStringMax80(post.postType),
      ],
      groupId: [
        async (post) => testEmptyInput(post.groupId),
        async (post) => testStringMax80(post.groupId),
        async (post) => testStringId(post.groupId),
      ],
      passportId: [
        async (post) => testEmptyInput(post.passportId),
        async (post) => testStringMax80(post.passportId),
        async (post) => testStringId(post.passportId),
      ],
      userId: [
        async (post) => testEmptyInput(post.userId),
        async (post) => testStringMax80(post.userId),
        async (post) => testStringId(post.userId),
      ],
      workshopId: [
        async (post) => testEmptyInput(post.workshopId),
        async (post) => testStringMax80(post.workshopId),
        async (post) => testStringId(post.workshopId),
      ],
      tags: [
        async (post) => testEmptyInput(post.tags),
      ],
      workshopStartAt: [
        async (post) => testEmptyInput(post.workshopStartAt),
        async (post) => testDateReversedStartAndEnd(post.workshopStartAt, post.workshopEndAt),
      ],
      workshopEndAt: [
        async (post) => testEmptyInput(post.workshopEndAt),
      ],
      contentPhotos: [
      ],
      isHidden: [],
      isWaitingApproved: [],
      isApproved: [],
      isInTopCarouselList: [],
      isFinishedWriting: [],
    });

    return postValidator.validate(postModels);
  }, []);

  const createPosts = useCallback(async (postRequestCreates: Array<PostRequestCreate>) => {
    // userReaction should not be updated from input form
    for (const post of postRequestCreates) {
      delete post.postModel.userReaction;
    }

    if (!postRequestCreates.length) {
      return;
    }

    const messageResponses = await validatePosts({
      isOnSubmit: true,
      postRequests: postRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const postRequestCreate of postRequestCreates) {
      const { id } = postRequestCreate.postModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/posts/${id}`), {
        ...Object.assign({}, postRequestCreate.postModel)
      });
    }

    await batch.commit();
  }, [validatePosts]);

  const getPost = useCallback(async (postRequestRead: PostRequestRead) => {
    const { postId } = postRequestRead;
    const postModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/posts/${postId}`)))
      .data() as PostModel|null;
    return postModel ? {postModel} : null;
  }, []);

  const updatePost = useCallback(async (postRequestUpdate: PostRequestUpdate) => {
    // userReaction should not be updated from input form
    delete postRequestUpdate.postModel.userReaction;

    const messageResponses = await validatePosts({
      isOnSubmit: true,
      postRequests: [postRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = postRequestUpdate.postModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/posts/${id}`), {
      ...Object.assign({}, postRequestUpdate)
    });
  }, [validatePosts]);

  const deletePosts = useCallback(async (deletePostsRequest: PostRequestsDelete) => {
    const postIds = deletePostsRequest?.postIds || [];

    if (!postIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const postId of postIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/posts/${postId}`));
    }

    await batch.commit();
  }, []);

  const searchPosts = useCallback(async (postRequestsSearch: PostRequestsSearch) => {
    const {
      size,
      page,
      postType,
      cityId,
      userId,
      passportId,
      groupId,
      workshopId,
      createdFrom,
      createdTo,
      shouldShowNotHidden,
      shouldShowApproved,
      shouldShowTopCarouselList,
      shouldShowFinishedWriting,
      searchString,
    } = postRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/posts`),
      and(
        ...[
          postType ? where('postType', '==', postType) : null,
          cityId ? where('cityId', '==', cityId) : null,
          userId ? where('userId', '==', userId) : null,
          passportId ? where('passportId', '==', passportId) : null,
          groupId ? where('groupId', '==', groupId) : null,
          workshopId ? where('workshopId', '==', workshopId) : null,
          shouldShowNotHidden ? where('isHidden', '==', false) : null,
          shouldShowApproved ? where('isApproved', '==', true) : null,
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
          shouldShowTopCarouselList ? where('isInTopCarouselList', '==', true) : null,
          shouldShowFinishedWriting ? where('isFinishedWriting', '==', true) : null,
          searchString
            ? getSearchStringQuery(searchString, ['titleToken', 'contentToken'])
            : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
      ),
      !searchString ? orderBy('createdAt', 'desc') : limit(1000),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<PostModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<PostResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const localbridgePostUpdateUserReaction = useCallback(async (
    localbridgePostUpdateUserReactionRequest: LocalbridgePostUpdateUserReactionRequest
  ) => {
    await httpsCallable(functions, 'localbridgePostUpdateUserReaction')(
      localbridgePostUpdateUserReactionRequest
    );
  }, []);

  const providerValue = {
    validatePosts,
    createPosts,
    getPost,
    updatePost,
    deletePosts,
    searchPosts,
    localbridgePostUpdateUserReaction,
  };

  return (
    <LocalbridgePostApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgePostApiContext.Provider>
  );
}
