import React from 'react';
import { UserPostEditProvider } from './Provider';
import UserPostEditRenderer from './Renderer';

export const UserPostEdit = () => {
  return (
    <UserPostEditProvider>
      <UserPostEditRenderer />
    </UserPostEditProvider>
  );
};
