
import { nanoid } from 'nanoid';
import React, { useContext, ReactNode, useState } from 'react';
import { NoticeModel } from '~/openapi/typescript-axios/version';

type Props = {
  children: ReactNode;
};

interface AdminNoticesCreateValue {
  notice: NoticeModel;
  setNotice: (notice: NoticeModel) => void;
}

const AdminNoticesCreateContext = React.createContext<AdminNoticesCreateValue | null>(null);

export function useAdminNoticesCreate(): AdminNoticesCreateValue {
  const state = useContext(AdminNoticesCreateContext);

  if (!state) {
    throw new Error('useAdminNoticesCreate must be used within AdminNoticesCreateProvider');
  }

  return state;
}

export function AdminNoticesCreateProvider({ children }: Props) {
  const newNoticeId = nanoid();
  const now = Number(new Date());
  const [ notice, setNotice ] = useState<NoticeModel>({
    id: newNoticeId,
    createdAt: now,
    updatedAt: now,
    photos: [],
    tags: [],
    title: '',
    content: '',
    isHidden: false,
  });

  const providerValue = {
    notice,
    setNotice,
  };

  return (
    <AdminNoticesCreateContext.Provider value={providerValue}>
      {children}
    </AdminNoticesCreateContext.Provider>
  );
}
