
import React, { useContext, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '~/providers/AppStateProvider';

type Props = {
  children: ReactNode;
};

interface UserEditValue {
  isCreatingAccount: boolean;
  setIsCreatingAccount: (isCreatingAccount: boolean) => void;
}

const UserEditContext = React.createContext<UserEditValue | null>(null);

export function useUserEdit(): UserEditValue {
  const state = useContext(UserEditContext);

  if (!state) {
    throw new Error('useUserEdit must be used within UserEditProvider');
  }

  return state;
}

export function UserEditProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { userId } = useParams<any>();

  const [ isCreatingAccount, setIsCreatingAccount ] = useState<boolean>(false);

  useEffect(() => {
    if (params.userId !== userId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { userId }));
    }
  }, [userId, params, setParams]);

  const providerValue = {
    isCreatingAccount,
    setIsCreatingAccount,
  };

  return (
    <UserEditContext.Provider value={providerValue}>
      {children}
    </UserEditContext.Provider>
  );
}
