import React from 'react';
import { AdminLeftSidebarProvider } from './Provider';
import AdminLeftSidebarRenderer from './Renderer';

export const AdminLeftSidebar = () => {
  return (
    <AdminLeftSidebarProvider>
      <AdminLeftSidebarRenderer />
    </AdminLeftSidebarProvider>
  );
};
