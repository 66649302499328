import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { NoticeRequestsSearch, NoticeResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminNoticesValue {
  noticesList: Array<NoticeResponsesWithPager>;
  noticesPageCurrent: number;
  noticesPageLast: number;
  setNoticeRequestsSearch: (noticeRequestsSearch: NoticeRequestsSearch) => void;
  setNoticesPageCurrent: (noticesPageCurrent: number) => void;
}

const AdminNoticesContext = React.createContext<AdminNoticesValue | null>(null);

export function useAdminNotices(): AdminNoticesValue {
  const state = useContext(AdminNoticesContext);

  if (!state) {
    throw new Error('useAdminNotices must be used within AdminNoticesProvider');
  }

  return state;
}

export function AdminNoticesProvider({ children }: Props) {
  const { searchNotices } = useLocalbridgeNoticeApi();

  const firstNoticeRequestsSearch: NoticeRequestsSearch = {
    size: 10,
  };
  const [ noticeRequestsSearch, setNoticeRequestsSearch ] = useState<NoticeRequestsSearch>(firstNoticeRequestsSearch);
  const [ noticesList, setAdminNoticesList ] = useState<Array<NoticeResponsesWithPager>>([]);
  const [ noticesPageCurrent, setNoticesPageCurrent ] = useState<number>(0);
  const [ noticesPageLast, setAdminNoticesPageLast ] = useState<number>(0);

  const searchNoticesList = useCallback(async () => {
    if (!noticeRequestsSearch) {
      return;
    }

    const noticesListTmp = await searchNotices(noticeRequestsSearch);
    const [noticesWithPager] = noticesListTmp;

    if (noticesWithPager?.pager) {
      const { totalCount, size } = noticesWithPager.pager;
      setAdminNoticesPageLast(Math.floor((totalCount - 1) / size) + 1);
    }

    setAdminNoticesList(noticesListTmp);
  }, [noticeRequestsSearch, searchNotices, setAdminNoticesList]);

  useEffect(() => {
    searchNoticesList();
  }, [searchNoticesList]);

  const providerValue = {
    noticesList,
    noticesPageCurrent,
    noticesPageLast,
    setNoticeRequestsSearch,
    setNoticesPageCurrent,
  };

  return (
    <AdminNoticesContext.Provider value={providerValue}>
      {children}
    </AdminNoticesContext.Provider>
  );
}
