import React from 'react';
import { useParentUser } from '~/providers/ParentUserProvider';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { UserModel, UserPublic } from '~/openapi/typescript-axios/version';
import { UserMyProfile } from './components/myProfile';
import { UserOtherPersonProfile } from './components/otherPersonProfile';
import { UserLeftSidebar } from './components/leftSidebar';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';

type Props = {};

const UserRenderer: React.FC<Props> = () => {
  const { isMe, userModelOrPublic } = useParentUser();

  if (!userModelOrPublic) {
    return <></>;
  }

  return (
    <>
      <LocalbridgeNavbar />

      {isMe ? (
        <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
          <UserLeftSidebar />

          <UserMyProfile userModel={userModelOrPublic as UserModel} />
        </div>
      ):(
        <>
          <LocalbridgeSubHeader title={`${userModelOrPublic.nickName}さんのプロフィール`} enTitle='Profile'/>
          <div className='mx-auto' style={{backgroundColor: '#fdfaf1'}}>
            <UserOtherPersonProfile userPublic={userModelOrPublic as UserPublic} />
          </div>
        </>
      )}

      <LocalbridgeFooter />
    </>
  );
};

export default UserRenderer;
