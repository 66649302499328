import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  LOCALBRIDGE_PATH,
  firestore,
} from '~/constants/firebase';
import {
  BannerRequestsDelete,
  BannerRequestCreate,
  BannerRequestUpdate,
  BannerRequestsValidate,
  BannerResponsesWithPager,  
  BannerRequestsSearch,
  BannerModel,
  BannerRequestRead,
  BannerResponseRead,
  testEmptyInput,
  testStringMax80,
  testStringId,
  testDateReversedStartAndEnd,
  testStringMax1000,
} from '~/openapi/typescript-axios/version';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeBannerApiValue {
  validateBanners: (bannerRequestsValidate: BannerRequestsValidate) => Promise<MessageResponse>;
  createBanners: (bannerRequestCreates: Array<BannerRequestCreate>) => Promise<void>;
  getBanner: (bannerRequestRead: BannerRequestRead) => Promise<BannerResponseRead|null>;
  updateBanner: (bannerRequestUpdate: BannerRequestUpdate) => Promise<void>;
  deleteBanners: (deleteBannersRequest: BannerRequestsDelete) => Promise<void>;
  searchBanners: (
    bannerRequestsSearch: BannerRequestsSearch
  ) => Promise<Array<BannerResponsesWithPager>>;
}

const LocalbridgeBannerApiContext = React.createContext<LocalbridgeBannerApiValue | null>(null);

export function useLocalbridgeBannerApi(): LocalbridgeBannerApiValue {
  const state = useContext(LocalbridgeBannerApiContext);

  if (!state) {
    throw new Error('useLocalbridgeBannerApi must be used within LocalbridgeBannerApiProvider');
  }

  return state;
}

export function LocalbridgeBannerApiProvider({ children }: Props) {
  const validateBanners = useCallback(async (bannerRequestsValidate: BannerRequestsValidate) => {
    const bannerModels = bannerRequestsValidate.bannerRequests.map((bannerRequest) => {
      return bannerRequest.bannerModel;
    });

    const bannerValidator = new Validator<BannerModel>('BannerModel', {
      id: [
        async (banner) => testEmptyInput(banner.id),
        async (banner) => testStringMax80(banner.id),
        async (banner) => testStringId(banner.id),
      ],
      photos: [
      ],
      createdAt: [
        async (post) => testEmptyInput(post.createdAt),
        async (post) => testDateReversedStartAndEnd(post.createdAt, post.updatedAt),
      ],
      updatedAt: [
        async (post) => testEmptyInput(post.updatedAt),
      ],
      ownerName: [
        async (post) => testEmptyInput(post.ownerName),
        async (post) => testStringMax80(post.ownerName),
      ],
      link: [
        async (post) => testStringMax1000(post.link),
      ],
      mainPhoto: [],
      isHidden: [],
    });

    return bannerValidator.validate(bannerModels);
  }, []);

  const createBanners = useCallback(async (bannerRequestCreates: Array<BannerRequestCreate>) => {
    if (!bannerRequestCreates.length) {
      return;
    }

    const messageResponses = await validateBanners({
      isOnSubmit: true,
      bannerRequests: bannerRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const bannerRequestCreate of bannerRequestCreates) {
      const { id } = bannerRequestCreate.bannerModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/banners/${id}`), {
        ...Object.assign({}, bannerRequestCreate.bannerModel)
      });
    }

    await batch.commit();
  }, [validateBanners]);

  const getBanner = useCallback(async (bannerRequestRead: BannerRequestRead) => {
    const { bannerId } = bannerRequestRead;
    const bannerModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/banners/${bannerId}`))).data() as BannerModel|null;
    return bannerModel ? {bannerModel} : null;
  }, []);

  const updateBanner = useCallback(async (bannerRequestUpdate: BannerRequestUpdate) => {
    const messageResponses = await validateBanners({
      isOnSubmit: true,
      bannerRequests: [bannerRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = bannerRequestUpdate.bannerModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/banners/${id}`), {
      ...Object.assign({}, bannerRequestUpdate)
    });
  }, [validateBanners]);

  const deleteBanners = useCallback(async (deleteBannersRequest: BannerRequestsDelete) => {
    const bannerIds = deleteBannersRequest?.bannerIds || [];

    if (!bannerIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const bannerId of bannerIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/banners/${bannerId}`));
    }

    await batch.commit();
  }, []);

  const searchBanners = useCallback(async (bannerRequestsSearch: BannerRequestsSearch) => {
    const {
      size,
      page,
      createdFrom,
      createdTo,
      shouldShowNotHidden,
    } = bannerRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/banners`),
      and(
        ...[
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
          shouldShowNotHidden ? where('isHidden', '==', false) : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
      ),
      orderBy('createdAt', 'desc'),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<BannerModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<BannerResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const providerValue = {
    validateBanners,
    createBanners,
    getBanner,
    updateBanner,
    deleteBanners,
    searchBanners,
  };

  return (
    <LocalbridgeBannerApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeBannerApiContext.Provider>
  );
}
