
import React, { useContext, ReactNode, useState } from 'react';
import { QnaMail, QnaMailQuestionType } from '~/openapi/typescript-axios/version';
import { QnaState } from './enums';

type Props = {
  children: ReactNode;
};

interface QnaValue {
  qnaMail: QnaMail;
  qnaState: QnaState;
  setQnaMail: (qnaMail: QnaMail) => void;
  setQnaState: (qnaState: QnaState) => void;
}

const QnaContext = React.createContext<QnaValue | null>(null);

export function useQna(): QnaValue {
  const state = useContext(QnaContext);

  if (!state) {
    throw new Error('useQna must be used within QnaProvider');
  }

  return state;
}

export function QnaProvider({ children }: Props) {
  const [ qnaState, setQnaState ] = useState<QnaState>(QnaState.QnaForm);
  const [ qnaMail, setQnaMail ] = useState<QnaMail>({
    groupName: '',
    teamName: '',
    senderName: '',
    emailFrom: '',
    emailFromConfirm: '',
    phoneNumberFrom: '',
    questionType: '' as QnaMailQuestionType,
    title: '',
    content: '',
  });

  const providerValue = {
    qnaMail,
    qnaState,
    setQnaMail,
    setQnaState,
  };

  return (
    <QnaContext.Provider value={providerValue}>
      {children}
    </QnaContext.Provider>
  );
}
