import React from 'react';
import { AdminLeftSidebar } from '../../components/leftSidebar';

type Props = {};

const AdminPostEditRenderer: React.FC<Props> = () => {
  return (
    <>
      <AdminLeftSidebar />
    </>
  );
};

export default AdminPostEditRenderer;
