import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { WorkshopModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';
import { useAppState } from './AppStateProvider';

type Props = {
  children: ReactNode;
};

interface ParentWorkshopValue {
  workshopModel?: WorkshopModel;
}

const ParentWorkshopContext = React.createContext<ParentWorkshopValue | null>(null);

export function useParentWorkshop(): ParentWorkshopValue {
  const state = useContext(ParentWorkshopContext);

  if (!state) {
    throw new Error('useParentWorkshop must be used within ParentWorkshopProvider');
  }

  return state;
}

export function ParentWorkshopProvider({ children }: Props) {
  const { params } = useAppState();
  const { getWorkshop } = useLocalbridgeWorkshopApi();
  const [ workshopModel, setWorkshopModel ] = useState<WorkshopModel>();

  const initParentWorkshop = useCallback(async () => {
    if (!params?.groupId || !params?.workshopId) {
      return;
    }

    const workshopModelTmp = await getWorkshop({workshopId: params?.workshopId});
    setWorkshopModel(workshopModelTmp?.workshopModel);
  }, [params?.groupId, params?.workshopId, getWorkshop, setWorkshopModel]);

  useEffect(() => {
    initParentWorkshop();
  }, [initParentWorkshop]);

  const providerValue = {
    workshopModel,
  };

  return (
    <ParentWorkshopContext.Provider value={providerValue}>
      {children}
    </ParentWorkshopContext.Provider>
  );
}
