import React, { useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import { signInWithPopup, Auth, UserCredential, GoogleAuthProvider, updateEmail } from 'firebase/auth';
import { auth, database, firestore, FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { ref, set, onValue, off } from 'firebase/database';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { useAppState } from './AppStateProvider';
import platform from 'platform';
import { useLocalbridgeUserApi } from './LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { GroupModel, SignInType, UserModel } from '~/openapi/typescript-axios/version';
import {
  LocalStorageKey,
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage,
} from '~/helpers/localstorage';

export const NoServiceManager = 'NoServiceManager';

type Props = {
  children: ReactNode;
};

interface AuthValue {
  auth: Auth;
  thisUser: UserModel|null;
  thisUserGroup?: GroupModel;
  serviceManagerId?: string;
  systemMaintainer?: SystemMaintainer;
  systemMaintainers: Array<SystemMaintainer>;
  isServiceManager: boolean;
  isThisServiceManagerOnly: boolean;
  signInType?: SignInType;
  setThisUserGroup: (thisUserGroup?: GroupModel) => void;
  doSignInWithGoogle: () => Promise<UserCredential | undefined>;
  doSignOut: () => Promise<void>;
  // doUpdateUserEmail: (newEmail: string) => Promise<void>;
  setIsThisServiceManagerOnly: (isThisServiceManagerOnly: boolean) => void;
  setSignInType: (signInType?: SignInType) => void;
}

export type SystemMaintainer = {
  id: string;
  email: string;
  name: string;
  hasRandomPassword: boolean;
  password?: string;
}

const AuthContext = React.createContext<AuthValue | null>(null);

export function useAuth(): AuthValue {
  const state = useContext(AuthContext);

  if (!state) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return state;
}

export function AuthProvider({ children }: Props) {
  const {
    signOut,
    localbridgeUserUpdateEmailVarificationSendMail,
  } = useLocalbridgeUserApi();

  const [ signInType, setSignInType ] = useState<SignInType>();
  const [ thisUserGroup, setThisUserGroup ] = useState<GroupModel>();
  const [ thisUser, setThisUser ] = useState<UserModel|null>(null);
  const [ serviceManagerId, setServiceManagerId ] = useState<string>();
  const [ systemMaintainer, setSystemMaintainer ] = useState<SystemMaintainer>();
  const [ systemMaintainers, setSystemMaintainers ] = useState<Array<SystemMaintainer>>([]);
  const [ isThisServiceManagerOnly, setIsThisServiceManagerOnly ] = useState<boolean>(true);
  const { isIos } = useAppState();
  const history = useHistory();

  const isServiceManager = !!serviceManagerId && serviceManagerId !== NoServiceManager;

  const doSignInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider);
  };

  const doSignOut = useCallback(async () => {
    await signOut();
    setThisUser(null);
    setSignInType(undefined);
    removeItemLocalStorage(LocalStorageKey.SignInType);
    setThisUserGroup(undefined);
    history.push(ROUTES.SIGN_IN);
    window.location.reload();
  }, [history, signOut]);

  // eslint-disable-next-line
  const doUpdateUserEmail = useCallback(async (newEmail: string) => {
    const user = auth.currentUser;
    if (!user || !thisUser) {
      return;
    }

    try {
      await updateEmail(user, newEmail);
      await localbridgeUserUpdateEmailVarificationSendMail({
        env: {
          LOCALBRIDGE_PATH,
          FUNCTION_V,
        },
        params: {
          email: thisUser.email,
          newEmail,
          origin: window.location.origin,
          route: ROUTES.USER_UPDATE_EMAIL_CONFIRMATION,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [
    thisUser,
    localbridgeUserUpdateEmailVarificationSendMail,
  ]);

  // eslint-disable-next-line
  const setDeviceUser = useCallback(async () => {
    if (thisUser) {
      const deviceUserPath = [
        LOCALBRIDGE_PATH,
        `deviceUsers/${thisUser.id}`,
      ].join('/');

      const ipAPI = '//api.ipify.org?format=json';
      const ip = await fetch(ipAPI)
        .then(response => response.json())
        .catch((error) => console.error(error))
        .then(data => data.ip) || '';

      if (isIos) {
        platform.os = 'iOS';
      }
      await set(ref(database, deviceUserPath), {
        id: thisUser.id,
        ip,
        lastAccessAt: Number(new Date()),
        userName: thisUser?.name,
        ...JSON.parse(JSON.stringify(platform)),
      } as any);
    }
  }, [thisUser, isIos]);

  useEffect(() => {
    let unsubscribeUser = () => {};
    const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(async (user) => {
      const beforeSignInType = getItemLocalStorage<SignInType>(LocalStorageKey.SignInType);

      if (user && beforeSignInType) {
        const userPath = [
          LOCALBRIDGE_PATH,
          `users/${user.uid}`,
        ].join('/');
        const beforeSignInType = getItemLocalStorage<SignInType>(LocalStorageKey.SignInType);

        unsubscribeUser = onSnapshot(doc(firestore, userPath), async (snapshot) => {
          const userData = snapshot?.data() as any;
          setThisUser(userData);
          setSignInType(beforeSignInType);
          if (beforeSignInType === SignInType.GroupManager) {
            const [groupModel] = (
              await getDocs(collection(firestore, `${LOCALBRIDGE_PATH}/groups`))).docs.map(doc => doc.data()
            ) as Array<GroupModel>;
            setThisUserGroup(groupModel);
          }
        }, (error) => {
          console.log('AuthProvider: setThisUser snapshot error', error);
        });
      } else if (!user && beforeSignInType) {
        setThisUser(null);
        setThisUserGroup(undefined);
        setSignInType(undefined);
        removeItemLocalStorage(LocalStorageKey.SignInType);
        unsubscribeUser();
        unsubscribeOnAuthStateChanged();

        // Session Out
        if ([
          ROUTES.SIGN_UP_EMAIL_CONFIRMATION,
          ROUTES.USER_UPDATE_PASSWORD_RESET,
        ].includes(window.location.pathname)) {
          return (<></>);
        }

        history.push(ROUTES.SIGN_IN);
      }
    });
  }, [history]);

  useEffect(() => {
    if (!thisUser) {
      return;
    }

    const serviceManagerPath = [
      'global/localbridge',
      `serviceManagers/${thisUser?.id}`
    ].join('/');
    const serviceManagerDatabase = ref(database, serviceManagerPath);

    // Set ServiceManager
    onValue(serviceManagerDatabase, (serviceManagerSnap) => {
      if (serviceManagerSnap.exists()) {
        setServiceManagerId(serviceManagerSnap?.key || NoServiceManager);
      } else {
        setServiceManagerId(NoServiceManager);
      }
    }, (error) => {
      console.log(error);
      console.log('AuthProvider: No Service Manager');
    });

    return () => {
      if (serviceManagerDatabase) {
        off(serviceManagerDatabase, 'value');
      }
    };
  }, [thisUser]);

  useEffect(() => {
    if (!thisUser) {
      return;
    }

    const systemMaintainerPath = [
      'global/localbridge',
      `systemMaintainers/${thisUser?.id}`
    ].join('/');
    const systemMaintainerDatabase = ref(database, systemMaintainerPath);

    // set SystemMaintainer
    onValue(systemMaintainerDatabase, (systemMaintainerSnap) => {
      const newSystemMaintainers = Object.values<SystemMaintainer>(systemMaintainerSnap.val() || {});
      const newSystemMaintainer = newSystemMaintainers.find((maintainer) => {
        return maintainer.id === thisUser.id;
      });

      setSystemMaintainers(newSystemMaintainers);
      setSystemMaintainer(newSystemMaintainer);
    }, (error) => {
      console.log(error);
      console.log('AuthProvider: No System Maintainer');
    });

    return () => {
      if (systemMaintainerDatabase) {
        off(systemMaintainerDatabase, 'value');
      }
    };
  }, [thisUser]);

  useEffect(() => {
    const intervalDuration = 1000;
    let intervalId: NodeJS.Timeout|undefined;

    const handleVisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        if (intervalId) {
          clearTimeout(intervalId);
          intervalId = undefined;
        }
      } else {
        auth?.currentUser?.getIdToken(true);

        intervalId = setInterval(() => {
          auth?.currentUser?.getIdToken(true);
        }, intervalDuration);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilitychange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilitychange);
    };
  }, []);

  useEffect(() => {
    if (!signInType) {
      return;
    }

    setItemLocalStorage<SignInType>(LocalStorageKey.SignInType, signInType);
  }, [signInType]);

  const providerValue = {
    auth,
    thisUser,
    thisUserGroup,
    serviceManagerId,
    systemMaintainer,
    systemMaintainers,
    isServiceManager,
    isThisServiceManagerOnly,
    signInType,
    setThisUserGroup,
    doSignInWithGoogle,
    doSignOut,
    // doUpdateUserEmail,
    setIsThisServiceManagerOnly,
    setSignInType,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}
