import { get, off, onValue, ref } from 'firebase/database';
import platform from 'platform';
import React, { useContext, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { database, REACT_APP_LOCALBRIDGE_ENV } from '~/constants/firebase';

export enum BrowserName {
  Safari = 'Safari',
  Chrome = 'Chrome',
  ChromeMobile = 'Chrome Mobile',
  Firefox = 'Firefox',
  FirefoxMobild = 'Firefox Mobile',
  MicrosoftEdge = 'Microsoft Edge',
}

type Props = {
  children: ReactNode;
};

// Add Global State
interface AppStateValue {
  isMobile: boolean,
  isIos: boolean,
  osVersion: number,
  browserName?: BrowserName,
  params: any;
  pathName: string;
  setParams: (params: any) => void;
}

const AppStateContext = React.createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return state;
}

export function AppStateProvider({ children }: Props) {
  const search = useLocation().search;
  
  (window as any).reactHistory = useHistory();

  const [ lastDeployedAt, setLastDeployedAt ] = useState<number>();
  const [ pathName, setPathName ] = useState<string>('');

  const isMobile = typeof window.orientation === 'number';
  const isIos = isMobile && /iPhone|iPad|iPod|Macintosh/g.test(navigator.userAgent);  
  const osVersion = Number(platform?.version) || 0;
  const browserName = platform.name as BrowserName|undefined;
  const [ params, setParams ] = useState<any>({});

  useEffect(() => {
    const lastDeployedAtPath = `/global/maintenance/${REACT_APP_LOCALBRIDGE_ENV}/localbridge/lastDeployedAt`;
    const lastDeployedAtDatabase = ref(database, lastDeployedAtPath);

    const handleVisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        get(lastDeployedAtDatabase).then((lastDeployedAtSnap) => {
          const lastDeployedAtTmp = lastDeployedAtSnap.val();
          setLastDeployedAt(lastDeployedAtTmp);

          if (lastDeployedAt && lastDeployedAt !== lastDeployedAtTmp) {
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
      } else {
        // Do nothing;
      }
    };
    document.addEventListener('visibilitychange', handleVisibilitychange);

    onValue(lastDeployedAtDatabase, (serviceManagerSnap) => {
      if (serviceManagerSnap.exists()) {
        const lastDeployedAtTmp = serviceManagerSnap.val();
        setLastDeployedAt(lastDeployedAtTmp);

        if (lastDeployedAt && lastDeployedAt !== lastDeployedAtTmp) {
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      }
    });

    return () => {
      off(lastDeployedAtDatabase, 'value');
    };
  }, [lastDeployedAt]);


  useEffect(() => {
    const tid = setInterval(() => {
      setPathName(location.pathname);
    }, 100);

    return () => {
      clearInterval(tid);
    };
  }, [search, setPathName]);

  const providerValue = {
    isMobile,
    isIos,
    osVersion,
    browserName,
    params,
    pathName,
    setParams,
  };

  return (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  );
}