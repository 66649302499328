import React from 'react';
import { PostsProvider } from './Provider';
import PostsRenderer from './Renderer';

export const Posts = () => {
  return (
    <PostsProvider>
      <PostsRenderer />
    </PostsProvider>
  );
};
