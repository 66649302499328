import equal from 'fast-deep-equal';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import datepicker from 'js-datepicker';
import { StyledInputForm } from './Styled';
import { ToasterFormDropDownButton } from './components/toasterFormDropDownButton';

const MySwal = withReactContent(Swal);

export type SingleValidation = (value: any) => Promise<string>;
export type MultipleValidation = (valueMap: any) => Promise<string>;

export type ProgressCallback = {
  descriptionEl: HTMLElement;
  progressLabelEl: HTMLElement;
  progressBarEl: HTMLElement;
  closeInputForm: (result?: SweetAlertResult<any> | undefined) => void;
}

export enum InputType {
  Label = 'Label',
  Date = 'Date',
  Text = 'Text',
  Textarea = 'Textarea',
  File = 'File',
  Password = 'Password',
  Files = 'Files',
  Month = 'Month',
  Newtab = 'Newtab',
  Link = 'Link',
  Radio = 'Radio',
  Check = 'Check',
  Progress = 'Progress',
  Select = 'Select',
  Suggest = 'Suggest',
}

export type SelectOption = {
  value: any,
  label: string,
  disabled?: boolean,
}

export type SuggestOption = {
  value: any,
  disabled?: boolean,
}

export type FormInputData = {
  label: any,
  value: any,
  placeholder?: string,
  disabled?: boolean,
  hidden?: boolean,
  type: InputType,
  select?: Array<SelectOption>,
  suggest?: Array<SuggestOption>,
  column?: Array<number>,
  fileType?: string,
  hasLineSpacing?: boolean,
  rows?: number,
  nullable?: boolean,
  onChangeSingleValidations?: Array<SingleValidation>,
  onSubmitSingleValidations?: Array<SingleValidation>,
  callbacks?: Array<(val: any) => void>,
}

const removeErrorMessageOnChange = (event: any) => {
  (event.target as any).classList.remove('is-invalid');
  document.getElementById('swal-input-multiple')?.classList.remove('is-invalid');
};

const line: {[s: string]: (key: string, input: FormInputData) => JSX.Element} = {
  [InputType.File]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`}>
          <input
            id={`swal-input-${key}`}
            type='file'
            placeholder={input.placeholder}
            hidden={input.hidden}
            accept={input.fileType}
            className={`form-control me-auto col-${column[1]} text-start text-break`}
            aria-describedby={`swal-input-${key}-feedback`}
            required
            onChange={removeErrorMessageOnChange}
            style={input?.disabled ? {color: 'grey'} : undefined}
          />
          <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
        </div>
      </div>
    );
  },
  [InputType.Files]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`}>
          <input
            id={`swal-input-${key}`}
            type='files'
            placeholder={input.placeholder}
            hidden={input.hidden}
            accept={input.fileType}
            className={`form-control me-auto col-${column[1]} text-start text-break`}
            style={input?.disabled ? {color: 'grey'} : undefined}
            onChange={removeErrorMessageOnChange}
            multiple
          />
        </div>
      </div>
    );
  },
  [InputType.Text]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
      if (el) {
        el.value = input.value;
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`}>
          <input
            id={`swal-input-${key}`}
            type='text'
            placeholder={input.placeholder}
            hidden={input.hidden}
            className={`form-control me-auto col-${column[1]} text-start text-break`}
            aria-describedby={`swal-input-${key}-feedback`}
            required
            onChange={removeErrorMessageOnChange}
            style={input?.disabled ? {color: 'grey'} : undefined}
          />    
          <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
        </div>
      </div>
    );
  },
  [InputType.Textarea]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    const hasLineSpacing = !!input.hasLineSpacing;
    const rows = input?.rows || 5;

    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
      if (el) {
        el.value = input.value;
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`}>
          <textarea
            id={`swal-input-${key}`}
            rows={rows}
            className='form-control text-start text-break'
            aria-describedby={`swal-input-${key}-feedback`}
            placeholder={input.placeholder}
            hidden={input.hidden}
            required
            onChange={removeErrorMessageOnChange}
            onKeyDown={(event) => setTimeout(()=> {
              if (hasLineSpacing) {
                return;
              }

              (event.target as any).value = (event.target as any).value.replace('\n','');
            }, 10)}
            style={input?.disabled ? {color: 'grey'} : undefined}
          ></textarea>
          <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
        </div>
      </div>
    );
  },
  [InputType.Password]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
      if (el) {
        el.value = input.value;
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`}>
          <input
            id={`swal-input-${key}`}
            type='password'
            placeholder={input.placeholder}
            hidden={input.hidden}
            className='form-control text-start text-break'
            aria-describedby={`swal-input-${key}-feedback`}
            required
            onChange={removeErrorMessageOnChange}
            style={input?.disabled ? {color: 'grey'} : undefined}
          />
          <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
        </div>
      </div>
    );
  },
  [InputType.Label]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
      if (el) {
        el.value = input.value;
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <span
          id={`swal-input-${key}`}
          className={`my-auto me-auto col-${column[1]} text-start text-break`}
        >{input.value}</span>
      </div>
    );
  },
  [InputType.Newtab]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLAnchorElement;
      if (el) {
        el.href = input.value[0];
        el.innerHTML = input.value[1];
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        {/* eslint-disable-next-line */}
        <a
          id={`swal-input-${key}`}
          target='_blank'
          className={`my-auto me-auto col-${column[1]} text-start text-break`}
        ></a>
      </div>
    );
  },
  [InputType.Link]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    setTimeout(() => {
      const el = document.getElementById(`swal-input-${key}`) as HTMLAnchorElement;
      if (el) {
        el.href = input.value[0];
        el.innerHTML = input.value[1];
      }
    }, 50);

    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        {/* eslint-disable-next-line */}
        <a
          id={`swal-input-${key}`}
          className={`my-auto me-auto col-${column[1]} text-start text-break`}
        ></a>
      </div>
    );
  },
  [InputType.Radio]: (key: string, input: FormInputData) => {
    const radios: Array<any> = [];
    for(const radio of input?.select || []) {
      radios.push(
        <div key={`radio-select-${radio.label}`} className='form-check d-flex flex-row'>
          <input
            className='form-check-input'
            type='radio'
            name={`swal-input-${key}`}
            id={`inlineRadio${radio.value}`}
            value={`${radio.value}`}
            defaultChecked={radio.value === input.value}
            // onChange={removeErrorMessageOnChange}
            disabled={!!input?.disabled}
          />
          <label className='form-check-label me-auto ms-1' htmlFor={`inlineRadio${input.value}`}>{radio.label}</label>
        </div>
      );
    }

    const column = input.column || [4, 8];
    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div
          id={`swal-input-${key}`}
          className={`my-auto me-auto col-${column[1]} d-flex flex-column`}
        >{radios}</div>
      </div>
    );
  },
  [InputType.Check]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    return (
      <div key={`swal-input-${key}`} className='form-workArea mt-2'>
        <input
          className={`form-check-input ms-auto col-${column[0]} me-2`}
          type='checkbox'
          value=''
          id={`swal-input-${key}`}
          checked={input.value || undefined}
          disabled={!!input?.disabled}
          onChange={removeErrorMessageOnChange}
          style={input?.disabled ? {color: 'grey'} : undefined}
        />
        <label
          className={`form-check-label text-start text-break me-auto col-${column[1]}`}
          htmlFor={`swal-input-${key}`}
        >{input.label}</label>
      </div>
    );
  },
  [InputType.Date]: (key: string, input: FormInputData) => {
    let picker: any;
    setTimeout(() => {
      let beforeSelectedDate = Number(input.value);
      picker = datepicker(`#swal-input-${key}`, {
        formatter: (input: any, date: Date) => {
          input.value = moment(date).format('YYYY-MM-DD');
        },
        onSelect: (instance: any, date: Date) => {
          const dateInputEl = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
          if (date && dateInputEl) {
            dateInputEl.value = moment(date).format('YYYY-MM-DD');
            beforeSelectedDate = Number(date);
          } else {
            dateInputEl.value = moment(beforeSelectedDate).format('YYYY-MM-DD');
          }
        },
        dateSelected: new Date(beforeSelectedDate),
        position: 'tr',
        customDays: ['日', '月', '火', '水', '木', '金', '土'],
        customMonths: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        overlayButton: '確認',
        overlayPlaceholder: '4桁の数字',
      });
    }, 50);

    const column = input.column || [4, 8];
    return (
      <div        
        key={`swal-input-${key}`}
        className='form-workArea row mt-2'
      >
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`me-auto col-${column[1]}`} id={`swal-input-${key}-wraper`}>
          <input
            style={{width: '100%'}}
            id={`swal-input-${key}`}
            className='form-control bg-white'
            aria-describedby={`swal-input-${key}-feedback`}
            readOnly
            required
            onChange={removeErrorMessageOnChange}
            onClick={(event) => {
              event.stopPropagation();
              picker?.show();
            }}
          />
          <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
        </div>
      </div>
    );
  },
  [InputType.Progress]: (key: string, input: FormInputData) => {
    const [progressCallback] = input.callbacks || [];
    setTimeout(() => {
      const descriptionEl = document.getElementById(`swal-input-${key}-description`);
      const progressLabelEl = document.getElementById(`swal-input-${key}-progress-label`);
      const progressBarEl = document.getElementById(`swal-input-${key}-progress-bar`);
      const closeInputForm = MySwal.close;
      if (!descriptionEl || !progressLabelEl || !progressBarEl) {
        return;
      }
      progressCallback({descriptionEl, progressLabelEl, progressBarEl, closeInputForm});
    }, 50);

    return (
      <>
        <div key={`swal-input-${key}-description`} className='form-workArea row mt-2'>
          <span
            id={`swal-input-${key}-description`}
            className='my-auto me-auto col-12 text-break text-center'
          >{input.label}</span>
        </div>
        <div key={`swal-input-${key}-progress-bar`} className='form-workArea row mt-2'>
          <div className='progress p-0'>
            <div
              id={`swal-input-${key}-progress-bar`}
              className='progress-bar progress-bar-striped progress-bar-animated'
              role='progressbar'
              style={{width: '0%'}}
            ></div>
          </div>
        </div>
        <div key={`swal-input-${key}-progress-label`} className='form-workArea row mt-2'>
          <span
            id={`swal-input-${key}-progress-label`}
            className='my-auto me-auto col-12 text-break text-center'
          >{input.value}</span>
        </div>
      </>
    );
  },
  [InputType.Select]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    const options = input.select || [];
    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <input
          id={`swal-input-${key}`}
          value={JSON.stringify(input.value)}
          style={{ width: 0, height: 0, padding: 0, border: 'none' }}
          onChange={removeErrorMessageOnChange}
        />

        <div className={`col-${column[1]}`}>
          <ToasterFormDropDownButton
            variant='outline-secondary'
            dropDownMenuStyle={{width: '100%'}}
            dropDownButtonStyle={{width: '100%'}}
            title={
              <span className='fw-bold' id={`swal-input-dropdown-option-selected-${key}`}>
                {options?.find((option) => equal(option.value, input.value))?.label || ''}
              </span>
            }
          >
            {options?.map((option, optionIndex) => (
              <Dropdown.Item
                key={`swal-input-dropdown-option-${key}-${optionIndex}`}
                eventKey={`swal-input-dropdown-option-${key}-${optionIndex}`}
                disabled={option.disabled}
                style={{width: '100%'}}
                onClick={() => {
                  const inputEl = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
                  const titleEl = document.getElementById(`swal-input-dropdown-option-selected-${key}`);
                  if (titleEl && inputEl) {
                    inputEl.value = JSON.stringify(option.value);
                    titleEl.innerHTML = option.label;
                  }
                }}
              >
                {option.label }
              </Dropdown.Item>
            ))}
          </ToasterFormDropDownButton>
        </div>
        <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
      </div>
    );
  },
  [InputType.Suggest]: (key: string, input: FormInputData) => {
    const column = input.column || [4, 8];
    const options = input.suggest || [];
    return (
      <div key={`swal-input-${key}`} className='form-workArea row mt-2'>
        <span className={`my-auto ms-auto col-${column[0]} text-end text-break`}>{input.label}</span>
        <div className={`col-${column[1]} position-relative`}>
          <div>
            <input
              id={`swal-input-${key}`}
              defaultValue={input.value}
              type='text'
              placeholder={input.placeholder}
              hidden={input.hidden}
              className={'form-control me-auto text-start text-break'}
              aria-describedby={`swal-input-${key}-feedback`}
              required
              style={{backgroundColor: 'white', color: input?.disabled ? 'grey' : undefined}}
              onChange={(event) => {
                removeErrorMessageOnChange(event);

                const customInputOptionEl = window.document
                  .getElementById(`swal-input-dropdown-option-custom-${key}`);

                const customInputEl = window.document
                  .getElementById(`swal-input-dropdown-option-custom-${key}-value`);

                if (!customInputEl || !customInputOptionEl) {
                  return;
                }

                customInputOptionEl.hidden = !event.currentTarget.value;
                customInputEl.innerHTML = event.currentTarget.value;
              }}
              onClick={() => {
                window.document.getElementById(`swal-input-dropdown-${key}`)?.click();
              }}
            />
            <div id={`swal-input-${key}-feedback`} className='px-3 invalid-feedback'></div>
          </div>

          <div style={{height: 0, width: '100%', position: 'relative'}}>
            <div style={{height: 0, width: '100%', bottom: 15, position: 'absolute'}}>
              <ToasterFormDropDownButton
                variant='outline-secondary'
                textAlign='left'
                isHiddenCarret
                isHiddenButton
                id={`swal-input-dropdown-${key}`}
                dropDownButtonStyle={{width: '100%', height: 0, padding: 0, border: 'none'}}
                title=''
              >
                <Dropdown.Item
                  id={`swal-input-dropdown-option-custom-${key}`}
                  key={`swal-input-dropdown-option-custom-${key}`}
                  eventKey={`swal-input-dropdown-option-custom-${key}`}
                  style={{width: '100%'}}
                  hidden
                  onClick={() => {
                    const inputEl = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
                    if (inputEl) {
                      inputEl.value = window.document
                        .getElementById(`swal-input-dropdown-option-custom-${key}-value`)?.innerText || '';
                    }
                  }}
                >
                  <span id={`swal-input-dropdown-option-custom-${key}-value`}></span>
                  <span>{'  を追加'}</span>
                </Dropdown.Item>

                {options?.map((option, optionIndex) => (
                  <Dropdown.Item
                    key={`swal-input-dropdown-option-${key}-${optionIndex}`}
                    eventKey={`swal-input-dropdown-option-${key}-${optionIndex}`}
                    disabled={option.disabled}
                    style={{width: '100%'}}
                    onClick={() => {
                      const inputEl = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
                      if (inputEl) {
                        inputEl.value = option.value ?? '';
                      }
                    }}
                  >
                    {option.value}
                  </Dropdown.Item>
                ))}
              </ToasterFormDropDownButton>
            </div>
          </div>
        </div>        
      </div>
    );
  }
};

/**
 * Sweetalert2 Simple Input Form
 * @param option - Title and Submit Button
 * @param inputMap.file ```{label: string, fileType: string}```
 * @param inputMap.text ```{label: string, value: string}```
 * @param inputMap.textarea ```{label: string, value: string}```
 * @param inputMap.password ```{label: string, value: string}```
 * @param inputMap.date ```{label: string, value: string}```
 * @param inputMap.radio ```{label: string, value: string}```
 * @param inputMap.check ```{label: string, value: string, select: any}```
 * @param inputMap.link ```{label: string, value: string}```
 */
export default function inputForm(
  option: SweetAlertOptions,
  inputMap: {[key: string]: FormInputData},
  multipleValidationMap?: {
    onSubmitMultipleValidations?: Array<MultipleValidation>,
    // onChangeMultipleValidations?: Array<MultipleValidation>, // You can make it if you want
  }
): Promise<SweetAlertResult<any>> {
  const inputLines: Array<JSX.Element> = [];
  for (const key in inputMap) {
    const inputType: InputType = inputMap[key].type;
    const getLineInput = line[inputType];
    inputLines.push(getLineInput(key, inputMap[key]));

    // Validation onkeydown
    setTimeout(() => {
      if ([
        InputType.Date, InputType.Text, InputType.Textarea, InputType.File, InputType.Password
      ].includes(inputMap[key].type)) {
        const inputEl: any = document.getElementById(`swal-input-${key}`);
        const inputError = document.getElementById(`swal-input-${key}-feedback`);
        if (!inputEl || !inputError || !inputMap[key].onChangeSingleValidations) {
          return;
        }

        inputEl.onkeydown = () => {
          setTimeout(async () => {
            const inputMultiple = document.getElementById('swal-input-multiple');
            const inputMultipleError = document.getElementById('swal-input-multiple-feedback');    
            if (inputMultipleError && inputMultiple) {
              inputMultipleError.innerHTML = '';
              document.getElementById('swal-input-multiple')?.classList.remove('is-invalid');
            }        

            const errorMessages: Array<string> = [];

            if (!inputEl.value) {
              inputError.innerHTML = '';
              inputEl.classList.remove('is-invalid');
              return;
            }

            for (const onChangeSingleValidation of (inputMap[key].onChangeSingleValidations || [])) {
              const errorMessage = await onChangeSingleValidation(inputEl.value as string);
              if (errorMessage) {
                errorMessages.push(errorMessage);
              }
            }

            if (errorMessages.length > 0) {
              inputError.innerHTML = errorMessages.join('<br />');
              inputEl.classList.add('is-invalid');
            } else {
              inputError.innerHTML = '';
              inputEl.classList.remove('is-invalid');
            }
          }, 10);
        };
      }
    }, 30);
  }

  setTimeout(() => {
    const htmlContainerEls = [...document.getElementsByClassName('swal2-html-container')];
    for (const htmlContainerEl of htmlContainerEls) {
      (htmlContainerEl as HTMLElement).style.zIndex = '2';
    }
  }, 30);

  option.html = (
    <StyledInputForm className='container'>
      {inputLines}
      <input
        id='swal-input-multiple'
        aria-describedby='swal-input-multiple-feedback'
        hidden
      />
      <div id='swal-input-multiple-feedback' className='px-3 invalid-feedback'></div>
    </StyledInputForm>
  ) as any;

  option.preConfirm = () => new Promise(async (resolve) => {
    const results: any = {};
    let hasError = false;

    const nullCheckErrorMessage: any = {
      [InputType.File]: 'ファイルを選択してください',
      [InputType.Date]: '入力してください',
      [InputType.Text]: '入力してください',
      [InputType.Textarea]: '入力してください',
      [InputType.Password]: '入力してください',
      [InputType.Suggest]: '入力してください',
    };

    for (const key in inputMap) {
      const inputEl = document.getElementById(`swal-input-${key}`) as HTMLInputElement;
      const radios = [...document.getElementsByName(`swal-input-${key}`)] as Array<HTMLInputElement>;
      if (!inputEl) {
        continue;
      }

      results[key] = InputType.Month === inputMap[key].type ? Number(moment(inputEl?.value))
        : InputType.Date === inputMap[key].type ? Number(moment(inputEl?.value))
        : InputType.File === inputMap[key].type ? inputEl?.files?.[0]
        : InputType.Files === inputMap[key].type ? (inputEl?.files || [])
        : InputType.Radio === inputMap[key].type ? radios.find((radio) => radio.checked)?.value
        : InputType.Check === inputMap[key].type ? inputEl?.checked
        : InputType.Select === inputMap[key].type ? (inputEl?.value ? JSON.parse(inputEl?.value) : null)
        : inputEl?.value;

      const inputError = document.getElementById(`swal-input-${key}-feedback`);
      if (!inputError) {
        continue;
      }

      // Validation onsubmit
      if (!results[key] && !inputMap[key].nullable) {
        inputError.innerHTML = nullCheckErrorMessage[inputMap[key].type] || '';
      } else if (
        [InputType.Date, InputType.Text, InputType.Textarea, InputType.Password]
          .includes(inputMap[key].type)
      ) {
        const errorMessages: Array<string> = [];

        for (const onSubmitSingleValidation of (inputMap[key].onChangeSingleValidations || [])) {
          const errorMessage = await onSubmitSingleValidation(results[key] as string);
          if (errorMessage) {
            errorMessages.push(errorMessage);
          }
        }

        inputError.innerHTML = errorMessages.join('<br />');
      }

      if (inputError.innerHTML) {
        hasError = true;
        inputEl.classList.add('is-invalid');
      }
    }

    const multipleErrorMessages: Array<string> = [];
    const inputMultipleError = document.getElementById('swal-input-multiple-feedback');
    const inputMultiple = document.getElementById('swal-input-multiple');
    for (const onSubmitMultipleValidation of (multipleValidationMap?.onSubmitMultipleValidations || [])) {
      const multipleErrorMessage = await onSubmitMultipleValidation(results);
      if (multipleErrorMessage) {
        hasError = true;
        multipleErrorMessages.push(multipleErrorMessage);
      }
    }

    if (inputMultipleError && inputMultiple) {
      inputMultiple.classList.add('is-invalid');
      inputMultipleError.innerHTML = multipleErrorMessages.join('<br />');
    }

    if (hasError) {
      Swal.showValidationMessage('入力エラーがあります');
    }

    resolve(results);
  });

  option.showCloseButton = option.showCloseButton ?? true;
  option.showConfirmButton = option.showConfirmButton ?? true;
  option.showDenyButton = option.showDenyButton ?? true;
  option.customClass = Object.assign({
    validationMessage: 'position-absolute opacity-0',
    htmlContainer: 'overflow-visible zindex-dropdown',
  }, {
    ...(option?.customClass as any),
  });

  option.confirmButtonText = option.confirmButtonText ?? '更新';
  option.denyButtonText = option.denyButtonText ?? 'キャンセル';
  option.confirmButtonColor = option.confirmButtonColor ?? 'var(--bs-primary)';
  option.denyButtonColor = option.denyButtonColor ?? 'var(--bs-danger)';

  return MySwal.fire(option);
}
