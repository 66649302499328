import React from 'react';
import { AboutNavbarProvider } from './Provider';
import AboutNavbarRenderer from './Renderer';

export const AboutNavbar = () => {
  return (
    <AboutNavbarProvider>
      <AboutNavbarRenderer />
    </AboutNavbarProvider>
  );
};
