
import React, { useContext, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '~/providers/AppStateProvider';

type Props = {
  children: ReactNode;
};

interface AdminWorkshopEditValue {

}

const AdminWorkshopEditContext = React.createContext<AdminWorkshopEditValue | null>(null);

export function useAdminWorkshopEdit(): AdminWorkshopEditValue {
  const state = useContext(AdminWorkshopEditContext);

  if (!state) {
    throw new Error('useAdminWorkshopEdit must be used within AdminWorkshopEditProvider');
  }

  return state;
}

export function AdminWorkshopEditProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { workshopId } = useParams<any>();

  useEffect(() => {
    if (params.workshopId !== workshopId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { workshopId }));
    }
  }, [workshopId, params, setParams]);

  const providerValue = {

  };

  return (
    <AdminWorkshopEditContext.Provider value={providerValue}>
      {children}
    </AdminWorkshopEditContext.Provider>
  );
}
