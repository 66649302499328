import React from 'react';
import { AboutContributionProvider } from './Provider';
import AboutContributionRenderer from './Renderer';

export const AboutContribution = () => {
  return (
    <AboutContributionProvider>
      <AboutContributionRenderer />
    </AboutContributionProvider>
  );
};
