import React, { useState } from 'react';

type Props = {
  tags: Array<string>;
};

const NoticesNewsListTagRenderer: React.FC<Props> = ({tags}) => {
  const [activeTag, setActiveTag] = useState(null);
  const handleClick = (index) => {
    if (activeTag === index) {
      setActiveTag(null);
    } else {
      setActiveTag(index);
    }
  };
  return (
    <>
      <div className={'news_tag'}>
        <p className={'news_tag__title'}>タグ一覧</p>
        <ul className={'news_tag__list'}>
          {tags.map((tag, index) => {
            const isActive = activeTag === index;
            const textColor = isActive ? '#13824E' : '#afafaf';
            const iconSrc = isActive ? '/assets/img/common/ico_tag.svg' : '/assets/img/common/ico_tag_gray.svg';
            return (
              <li key={index} className={'news_tag__item'} onClick={() => handleClick(index)}>
                <a href="#">
                  <img className={'news_tag__ico'} src={iconSrc} alt="" />
                  <p style={{ color: textColor }} className={'news_tag__text'}>
                    {tag}
                  </p>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default NoticesNewsListTagRenderer;
