import React from 'react';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';

type Props = {};

const TermsOfServiceRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeFooter />
    </>
  );
};

export default TermsOfServiceRenderer;
