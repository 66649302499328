import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import LocalbridgeBody from '~/components/localbridgeBody';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { useUserUpdatePasswordReset } from './Provider';
import './index.scss';
import showError from '~/helpers/toaster/message/showError';
import showInfo from '~/helpers/toaster/message/showInfo';

type Props = {};

const SignUpRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { handleResetPassword } = useUserUpdatePasswordReset();
  const [value, setValue] = useState<any>({});

  const handleDoResetPassword = useCallback(async (event: any) => {
    event.preventDefault();
    const { newPassword, passwordConfirm } = value;

    if (!newPassword) {
      showError({
        title: 'パスワード再設定',
        text: 'パスワードを入力してください',
      });
      return;
    }

    if (!passwordConfirm) {
      showError({
        title: '新しいパスワード',
        text: 'パスワード確認を入力してください',
      });
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 20) {
      showError({
        title: '新しいパスワード（確認用）',
        text: '8 ~ 20 文字のパスワードを入力してください',
      });
      return;
    }

    if (newPassword !== passwordConfirm) {
      showError({
        title: 'パスワード再設定',
        text: 'パスワードが一致しません',
      });
      return;
    }

    try {
      await handleResetPassword(newPassword);
      showInfo({
        title: 'パスワード再設定',
        text: 'パスワードが再設定されました',
      });

      history.push(ROUTES.SIGN_IN);
    } catch (error: any) {
      showError({
        title: 'パスワード再設定',
        text: error?.message,
      });
    }
  }, [history, value, handleResetPassword]);

  const handleChange = useCallback((event: any) => {
    value[event.target.id] = event.target.value;
    setValue(value);
  }, [value, setValue]);

  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <section className={'pass_reset'}>
          <h1 className={'pass_reset__title'}>パスワード再設定</h1>
          <p className={'pass_reset__title_en'}>Password reset</p>
          <p className={'pass_reset__lead'}>新しいパスワードを設定します</p>

          <form
            className={'pass_reset_contents'}
            onSubmit={(event) => handleDoResetPassword(event)}
            onChange={handleChange}
          >
            {/* dummy for no autocomplete */}
            <input type='text' style={{width: 0, height: 0}}/>
            <input type='email' style={{width: 0, height: 0}}/>
            <input type='password' style={{width: 0, height: 0}}/>

            <div className={'pass_reset_contents__item'}>
              <p className={'pass_reset_contents__label'}>新しいパスワード入力してください</p>
              <input className={'pass_reset_contents__input'} type="test" name="password" id="password" />
              <p className={'pass_reset_contents__note'}>※8〜16文字で、半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください。</p>
            </div>
            <div className={'pass_reset_contents__item'}>
              <p className={'pass_reset_contents__label'}>パスワードを再入力（確認）</p>
              <input className={'pass_reset_contents__input'} type="test" name="password" id="password" />
            </div>
            <div className={'pass_reset_contents__back'}>
              <input type="submit" value="パスワードを再設定する" />
            </div>
          </form>
        </section>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default SignUpRenderer;
