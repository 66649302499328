
import { nanoid } from 'nanoid';
import React, { useContext, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkshopModel } from '~/openapi/typescript-axios/version';

type Props = {
  children: ReactNode;
};

interface GroupWorkshopsCreateValue {
  workshopModel: WorkshopModel;
  setWorkshopModel: (workshopModel: WorkshopModel) => void;
}

const GroupWorkshopsCreateContext = React.createContext<GroupWorkshopsCreateValue | null>(null);

export function useGroupWorkshopsCreate(): GroupWorkshopsCreateValue {
  const state = useContext(GroupWorkshopsCreateContext);

  if (!state) {
    throw new Error('useGroupWorkshopsCreate must be used within GroupWorkshopsCreateProvider');
  }

  return state;
}

export function GroupWorkshopsCreateProvider({ children }: Props) {
  const { groupId } = useParams<any>();

  const newWorkshopId = nanoid();
  const now = Number(new Date());
  const [ workshopModel, setWorkshopModel ] = useState<WorkshopModel>({
    id: newWorkshopId,
    createdAt: now,
    updatedAt: now,
    photos: [],
    title: '',
    titleToken: {uniGram: [], biGram: [], triGram: []},
    overview: '',
    overviewToken: {uniGram: [], biGram: [], triGram: []},
    detail: '',
    detailToken: {uniGram: [], biGram: [], triGram: []},
    notice: '',
    cityId: '',
    townId: '',
    detailAddress1: '',
    detailAddress2: '',
    groupId,
    workshopStartAt: now,
    workshopEndAt: now,
    bookingStartAt: now,
    bookingEndAt: now,
    mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    contentPhotos: [],
    participationFee: 1000,
    isHidden: false,
    isWaitingApproved: false,
    isApproved: false,
    isInTopCarouselList: false,
    isFinishedWriting: false,
  });

  const providerValue = {
    workshopModel,
    setWorkshopModel,
  };

  return (
    <GroupWorkshopsCreateContext.Provider value={providerValue}>
      {children}
    </GroupWorkshopsCreateContext.Provider>
  );
}
