import styled from 'styled-components';

export const StyledAdminTable = styled.table`
  thead {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    box-shadow: inset 1px 1px 0 black;

    th {
      position: -webkit-sticky;
      position: sticky;
      z-index: 2;
      box-shadow: inset -1px -1px 0 black;
    }
  }

  thead, tbody {
    :not(:first-child) {
      border-top: unset;
    }

    :first-child {
      border-bottom: 2px solid currentColor;
    }

    tr > th, td {
      border: 1px solid #aaaaaa !important;
    }
  }

  tbody {
    :nth-child(2n) {
      background-color: rgba(0,0,0,0.05);

      :hover {
        background-color: rgba(0,0,0,0.12);
      }  
    }
    
    :nth-child(2n+1) {
      background-color: rgba(0,0,0,0);
    
      :hover {
        background-color: rgba(0,0,0,0.075);
      }
    }
  }
`;
