import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface LocalbridgeFooterValue {

}

const LocalbridgeFooterContext = React.createContext<LocalbridgeFooterValue | null>(null);

export function useLocalbridgeFooter(): LocalbridgeFooterValue {
  const state = useContext(LocalbridgeFooterContext);

  if (!state) {
    throw new Error('useLocalbridgeFooter must be used within LocalbridgeFooterProvider');
  }

  return state;
}

export function LocalbridgeFooterProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <LocalbridgeFooterContext.Provider value={providerValue}>
      {children}
    </LocalbridgeFooterContext.Provider>
  );
}
