import React from 'react';
import { NoticesNewsItemProvider } from './Provider';
import NoticesNewsItemRenderer from './Renderer';

export const NewsItem = (props) => {
  return (
    <NoticesNewsItemProvider>
      <NoticesNewsItemRenderer noticeModel={props.noticeModel} />
    </NoticesNewsItemProvider>
  );
};
