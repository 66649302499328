import React, { ReactNode, useMemo } from 'react';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';

type InputType = 'textarea'|'text'|'textArray'|'email'|'password'|'file'|'dropdown'|'label';

type Props<T> = {
  data: T;
  tmpText?: string;
  validationKey: keyof T;
  validationClassName: string;
  className?: string;
  defaultValue?: string | number | readonly string[];
  size?: 'sm'|'lg';
  rows?: number;
  type?: InputType;
  tmpPhoto?: File;
  placeholder?: string;
  children?: ReactNode;
  title?: ReactNode;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

function InputWithValidation<T>({
  data,
  tmpText = '',
  validationKey,
  validationClassName,
  className = 'form-control',
  defaultValue,
  type = 'text',
  tmpPhoto,
  placeholder,
  size,
  rows = 10,
  children,
  title,
  disabled,
  onChange,
  onBlur,
}: Props<T>) {
  const feedbackId = `${validationClassName}-${validationKey as string}-feedback`;
  const value = data?.[validationKey as string] === undefined
    ? defaultValue
    : data?.[validationKey as string] || '';

  const photoTmpUrl = useMemo(() => {
    if (!tmpPhoto) {
      return '';
    }

    return URL.createObjectURL(tmpPhoto);
  }, [tmpPhoto]);

  const sizeClassName = (className.split(' ').includes('form-control') && size) ? `form-control-${size}` : '';

  return (
    <>
      {type === 'label' && children ? (
        <div
          aria-describedby={feedbackId}
          validation-class-name={validationClassName}
          validation-id={''}
          validation-key={validationKey}
        >{children}</div>
      ):(<></>)}

      {type === 'dropdown' ? (
        <DropdownButton
          disabled={disabled}
          variant={'white'}
          size={size}
          className={className}
          style={{width: 'fit-content', padding: 0}}
          aria-describedby={feedbackId}
          validation-class-name={validationClassName}
          validation-id={''}
          validation-key={validationKey}
          as={ButtonGroup}
          drop={'down'}
          title={title}
        >{children}</DropdownButton>
      ):(<></>)}

      {(type === 'file') ? (
        children ? (
          <div
            aria-describedby={feedbackId}
            validation-class-name={validationClassName}
            validation-id={''}
            validation-key={validationKey}
          >{children}</div>
        ):(
          <div
            className={'detail_section__upload'}
            aria-describedby={feedbackId}
            validation-class-name={validationClassName}
            validation-id={''}
            validation-key={validationKey}
            onClick={(event) => event.currentTarget.querySelector('input')?.click()}
          >
            <input
              className='form-control p-0'
              style={{width: 0, height: 0}}
              accept='image'
              onChange={onChange}
              type='file'
            />

            {(!!photoTmpUrl || !!value?.url) ? (
              <div>
                <img src={photoTmpUrl || value.url} alt='' />
              </div>
            ) : (
              <>
                <i className='bi bi-upload'></i>
                <label className={'detail_section__label'}>
                  Select Images to Upload
                </label>
              </>
            )}
          </div>
        )
      ):(<></>)}

      {type === 'textarea' ? (
        <textarea
          className={[className, sizeClassName].filter(Boolean).join(' ')}
          placeholder={placeholder}
          rows={rows}
          aria-describedby={feedbackId}
          validation-class-name={validationClassName}
          validation-id={''}
          validation-key={validationKey}
          value={value}
          onChange={onChange}
          onFocus={(event) => {
            if (event.currentTarget.value === defaultValue) {
              event.currentTarget.value = '';
            }
          }}
          onBlur={(event) => {
            if (event.currentTarget.value === '' && defaultValue !== undefined) {
              event.currentTarget.value = `${defaultValue}`;
            }
          }}
          onClick={(event) => event.currentTarget.classList.remove('is-invalid')}
          onKeyDown={(event) => event.currentTarget.classList.remove('is-invalid')}
        ></textarea>
      ):(<></>)}

      {(type === 'textArray') ? (
        <div
          className='d-flex flex-row gap-2'
          aria-describedby={feedbackId}
          validation-class-name={validationClassName}
          validation-id={''}
          validation-key={validationKey}
          onClick={(event) => event.currentTarget.classList.remove('is-invalid')}
          onKeyDown={(event) => event.currentTarget.classList.remove('is-invalid')}
        >
          <input
            className={[className, sizeClassName].filter(Boolean).join(' ')}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            value={tmpText}
            onChange={onChange}
            onFocus={(event) => {
              if (event.currentTarget.value === defaultValue) {
                event.currentTarget.value = '';
              }
            }}
            onBlur={(event) => {
              if (event.currentTarget.value === '' && defaultValue !== undefined) {
                event.currentTarget.value = `${defaultValue}`;
              }

              if (onBlur) {
                onBlur(event);
              }
            }}
          />
          {children}
        </div>
      ):(<></>)}

      {['text', 'email', 'password'].includes(type) ? (
        <input
          className={[className, sizeClassName].filter(Boolean).join(' ')}
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          aria-describedby={feedbackId}
          validation-class-name={validationClassName}
          validation-id={''}
          validation-key={validationKey}
          value={value}
          onChange={onChange}
          onFocus={(event) => {
            if (event.currentTarget.value === defaultValue) {
              event.currentTarget.value = '';
            }
          }}
          onBlur={(event) => {
            if (event.currentTarget.value === '' && defaultValue !== undefined) {
              event.currentTarget.value = `${defaultValue}`;
            }
          }}
          onClick={(event) => event.currentTarget.classList.remove('is-invalid')}
          onKeyDown={(event) => event.currentTarget.classList.remove('is-invalid')}
        />
      ):(<></>)}

      <div
        id={feedbackId}
        validation-class-name={validationClassName}
        validation-id={''}
        validation-key={validationKey}
        validation-feedback='true'
        className='invalid-feedback'
      />
    </>
  );
}

export default InputWithValidation;
