import React, { useCallback, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import './index.scss';
import { useAdminGroupsCreateWithUser } from './Provider';
import { AdminLeftSidebar } from '../../components/leftSidebar';
import { ROUTES } from '~/constants/routes';
import { GroupModel, GroupType, PhotoMeta, UserModel, UserModelWithPassword } from '~/openapi/typescript-axios/version';
import InputWithValidation from '~/components/inputWithValidation';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { DefaultValue } from '~/constants/defaultValue';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { LOCALBRIDGE_PATH } from '~/constants/firebase';
import { nanoid } from 'nanoid';
import { uploadFileWithProgress } from '~/helpers/uploader';
import { getImageWidthHeight } from '~/helpers/getImageWidthHeight';
import { useHistory } from 'react-router-dom';
import showError from '~/helpers/toaster/message/showError';
import PreventAutoComplete from '~/components/preventAutoComplete';
import { AdminContainer } from '../../components/container';

type Props = {};

const AdminGroupsCreateWithUserRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { createUsers } = useLocalbridgeUserApi();
  const { validateGroups, createGroups } = useLocalbridgeGroupApi();

  const {
    cities,
    towns,
    selectedCity,
    selectedTown,
    group,
    userModelWithPassword,
    setGroup,
    setUserModelWithPassword,
  } = useAdminGroupsCreateWithUser();

  const [ fileMapWillBeCreated, setFileMapWillBeCreated ] = useState<{[fileId: string]: File}>({});

  const handleCreateGroupWithUser = useCallback(async () => {
    group.nameToken = getSearchTokenMap(group.name);
    userModelWithPassword.emailToken = getSearchTokenMap(userModelWithPassword.email);

    const messageResponses = await validateGroups({
      isOnSubmit: true,
      groupRequests: [{groupModel: group}],
    });
    if (messageResponses.validations?.length) {
      showLocalbridgeApiValidationErrorMessage(new LocalbridgeApiError(messageResponses, 412));
    }

    let newUserId = '';
    try {
      const newPassword = userModelWithPassword.newPassword || '';
      const passwordConfirm = userModelWithPassword.passwordConfirm || '';
      userModelWithPassword.emailToken = getSearchTokenMap(userModelWithPassword.email);

      const newUserModelWithPassword = Object.assign({}, userModelWithPassword);
      delete newUserModelWithPassword.newPassword;
      delete newUserModelWithPassword.passwordConfirm;

      await createUsers([{
        isByAdmin: true,
        userModel: newUserModelWithPassword as UserModel,
        newPassword,
        passwordConfirm,
      }]);
      newUserId = newUserModelWithPassword.id;
    } catch (error) {
      console.log((error as LocalbridgeApiError).messageResponse);
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: '組織・団体作成',
          text: (error as Error).message,
        });

        throw error;
      }

      return;
    }

    try {
      for (const fileId in fileMapWillBeCreated) {
        const photoRef = `${LOCALBRIDGE_PATH}/groups/${group.id}/photos/${fileId}`;
        const downloadUrl = await uploadFileWithProgress(photoRef, fileMapWillBeCreated[fileId]);
        const photoMeta = group.photos.find((photo) => photo.id === fileId);
        if (photoMeta && downloadUrl) {
          photoMeta.url = downloadUrl;
        }
      }

      group.userIdsManagerRole.push(newUserId);
      await createGroups([{groupModel: group}]);
    } catch (error) {
      console.log((error as LocalbridgeApiError).messageResponse);
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: '組織・団体作成',
          text: (error as Error).message,
        });

        throw error;
      }

      return;
    }

    showSuccess({
      title: '組織・団体作成',
      text: '作成しました',
      timer: 1000,
    });
  }, [
    userModelWithPassword,
    group,
    fileMapWillBeCreated,
    createUsers,
    validateGroups,
    createGroups,
  ]);

  const onChangeGroupInput = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof GroupModel,
  ) => {
    if (!group) {
      return;
    }

    if (key === 'icon'
      || key === 'descriptionPhoto'
      || key === 'mainPhoto'
      || key === 'passportIcon'
    ) {
      const [file] = (event.target as HTMLInputElement).files || [];

      if (file) {
        const newPhotoId = nanoid();
        const { width, height } = await getImageWidthHeight(file);

        const photoMeta: PhotoMeta = {
          id: newPhotoId,
          url: '',
          name: file.name,
          size: file.size,
          width,
          height,
        };

        const photoIdBefore = group[key as string]?.id || '';

        group[key as string] = photoMeta;
        group.photos.push(photoMeta);
        group.photos = group.photos.filter((photo) => photo.id !== photoIdBefore);
        delete fileMapWillBeCreated[photoIdBefore];
        fileMapWillBeCreated[photoMeta.id] = file;
        setFileMapWillBeCreated(Object.assign({}, fileMapWillBeCreated));
      }
    } else {
      group[key as string] = event.currentTarget.value;
    }

    setGroup(Object.assign({}, group));
  }, [group, fileMapWillBeCreated, setGroup, setFileMapWillBeCreated]);

  const onChangeUserInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof UserModelWithPassword,
  ) => {
    if (!userModelWithPassword) {
      return;
    }
    
    userModelWithPassword[key as string] = event.currentTarget.value;
    if (key === 'townId') {
      userModelWithPassword.localAssociationIds = [event.currentTarget.value];
    }

    setUserModelWithPassword(Object.assign({}, userModelWithPassword));
  }, [userModelWithPassword, setUserModelWithPassword]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className={'admin_group_new col'}>
        <AdminContainer>
          <Form className={'admin_group_new__form'}>
            <PreventAutoComplete />

            <div className={'admin_group_new__head'}>
              <h1 className={'admin_group_new__title'}>組織・団体作成</h1>
              <a onClick={() => history.push(ROUTES.ADMIN_GROUPS)} className={'admin_group_new__link'}>
                組織・団体一覧へ戻る
              </a>
            </div>

            {(group && userModelWithPassword) ? (
              <div className={'admin_group_new__contents'}>
                <div className={'admin_group_new__input'}>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体区分</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          data={group}
                          validationKey={'type'}
                          validationClassName='GroupModel'
                          type={'dropdown'}
                          title={group?.type || '選択してください'}
                        >
                          {Object.values(GroupType).map((groupType) => (
                            <Dropdown.Item
                              key={`group-type-${groupType}`}
                              onClick={() => {
                                group.type = groupType;
                                setGroup(Object.assign({}, group));
                              }}
                            >{groupType}</Dropdown.Item>
                          ))}
                        </InputWithValidation>
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体名　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          data={group}
                          validationKey={'name'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'name');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体アイコンロゴ登録</h2>
                      <InputWithValidation<GroupModel>
                        type='file'
                        tmpPhoto={fileMapWillBeCreated[group.icon?.id || '']}
                        data={group}
                        validationKey={'icon'}
                        validationClassName='GroupModel'
                        onChange={(event) => {
                          onChangeGroupInput(event, 'icon');
                        }}
                      />
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体HPリンク</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          data={group}
                          validationKey={'homepage'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'homepage');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体メールアドレス　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          type='email'
                          data={userModelWithPassword}
                          validationKey={'email'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'groupEmail');
                            onChangeUserInput(event, 'email');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>組織・団体電話番号</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'phoneNumber'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'groupPhoneNumber');
                            onChangeUserInput(event, 'phoneNumber');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>パスワード　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          type='password'
                          data={userModelWithPassword}
                          validationKey={'newPassword'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeUserInput(event, 'newPassword');
                          }}
                        />
                      </div>
                      <Form.Text className={'detail_section__note'} muted>
                        8～16文字で、半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください。
                      </Form.Text>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>確認用パスワード　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          type='password'
                          data={userModelWithPassword}
                          validationKey={'passwordConfirm'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeUserInput(event, 'passwordConfirm');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>代表者名　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'name'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'delegateName');
                            onChangeUserInput(event, 'name');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>ふりがな　※</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'nameFurigana'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeUserInput(event, 'nameFurigana');
                            onChangeGroupInput(event, 'delegateNameFurigana');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>ふるさとパスポート画像登録</h2>
                      <InputWithValidation<GroupModel>
                        type='file'
                        tmpPhoto={fileMapWillBeCreated[group.passportIcon?.id || '']}
                        data={group}
                        validationKey={'passportIcon'}
                        validationClassName='GroupModel'
                        onChange={(event) => {
                          onChangeGroupInput(event, 'passportIcon');
                        }}
                      />
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>TOP画像</h2>
                      <InputWithValidation<GroupModel>
                        type='file'
                        tmpPhoto={fileMapWillBeCreated[group.mainPhoto?.id || '']}
                        data={group}
                        validationKey={'mainPhoto'}
                        validationClassName='GroupModel'
                        onChange={(event) => {
                          onChangeGroupInput(event, 'mainPhoto');
                        }}
                      />
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>PR記事</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          type='textarea'
                          data={userModelWithPassword}
                          validationKey={'description'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeUserInput(event, 'description');
                            onChangeGroupInput(event, 'description');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>PR記事画像</h2>
                      <InputWithValidation<GroupModel>
                        type='file'
                        tmpPhoto={fileMapWillBeCreated[group.descriptionPhoto?.id || '']}
                        data={group}
                        validationKey={'descriptionPhoto'}
                        validationClassName='GroupModel'
                        onChange={(event) => {
                          onChangeGroupInput(event, 'descriptionPhoto');
                        }}
                      />
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>担当者名</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          data={group}
                          defaultValue={DefaultValue.NoInput}
                          validationKey={'managerName'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'managerName');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>担当者メールアドレス</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          type='email'
                          data={group}
                          defaultValue={DefaultValue.NoInput}
                          validationKey={'managerEmail'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'managerEmail');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>担当者電話番号</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<GroupModel>
                          data={group}
                          defaultValue={DefaultValue.NoInput}
                          validationKey={'managerPhoneNumber'}
                          validationClassName='GroupModel'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'managerPhoneNumber');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>郵便番号</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          defaultValue={DefaultValue.NoInput}
                          validationKey={'postNumber'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'postNumber');
                            onChangeUserInput(event, 'postNumber');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>都道府県</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'cityId'}
                          validationClassName='UserModelWithPassword'
                          type={'dropdown'}
                          title={selectedCity?.cityName || '選択してください'}
                        >
                          {cities.map((city) => (
                            <Dropdown.Item
                              key={`cities-${city.id}`}
                              onClick={() => {
                                const isSameCity = group.cityId === city.id;
                                if (!isSameCity) {
                                  group.townId = '';
                                  userModelWithPassword.townId = '';
                                }

                                group.cityId = city.id;
                                userModelWithPassword.cityId = city.id;
                                setGroup(Object.assign({}, group));
                                setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                              }}
                            >{city.cityName}</Dropdown.Item>
                          ))}
                        </InputWithValidation>
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>市区町村</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'townId'}
                          validationClassName='UserModelWithPassword'
                          type={'dropdown'}
                          disabled={!selectedCity}
                          title={selectedTown?.associationName || '選択してください'}
                        >
                          {towns.map((town) => (
                            <Dropdown.Item
                              key={`towns-${town.id}`}
                              onClick={() => {
                                group.townId = town.id;
                                userModelWithPassword.townId = town.id;
                                setGroup(Object.assign({}, group));
                                setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                              }}
                            >{town.associationName}</Dropdown.Item>
                          ))}
                        </InputWithValidation>
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>番地</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'detailAddress1'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'detailAddress1');
                            onChangeUserInput(event, 'detailAddress1');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                  <section className={'detail_section'}>
                    <Form.Group>
                      <h2 className={'detail_section__title'}>建物名・部屋番号</h2>
                      <div className={'detail_section__input'}>
                        <InputWithValidation<UserModelWithPassword>
                          data={userModelWithPassword}
                          validationKey={'detailAddress2'}
                          validationClassName='UserModelWithPassword'
                          onChange={(event) => {
                            onChangeGroupInput(event, 'detailAddress2');
                            onChangeUserInput(event, 'detailAddress2');
                          }}
                        />
                      </div>
                    </Form.Group>
                  </section>
                </div>
                <div className={'detail_side'}>
                  <section className={'detail_section'}>
                    <h2 className={'detail_section__title'}>作成</h2>
                    <div className={'detail_side__inputs'}>
                      <Button
                        className={'detail_side__input detail_side__input--submit'}
                        onClick={handleCreateGroupWithUser}
                      >作成</Button>
                    </div>
                  </section>
                </div>
              </div>
            ):(<></>)}
          </Form>
        </AdminContainer>
      </div>
    </div>
  );
};

export default AdminGroupsCreateWithUserRenderer;
