import React from 'react';
import { UserLeftSidebarProvider } from './Provider';
import UserLeftSidebarRenderer from './Renderer';

export const UserLeftSidebar = () => {
  return (
    <UserLeftSidebarProvider>
      <UserLeftSidebarRenderer />
    </UserLeftSidebarProvider>
  );
};
