import React from 'react';
import { AdminNoticesProvider } from './Provider';
import AdminNoticesRenderer from './Renderer';

export const AdminNotices = () => {
  return (
    <AdminNoticesProvider>
      <AdminNoticesRenderer />
    </AdminNoticesProvider>
  );
};
