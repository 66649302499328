export enum LocalStorageKey {
  SignInType = 'SignInType'
}

export const setItemLocalStorage = <T>(key: string, value: T) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
};

export const getItemLocalStorage = <T>(key: string, initialValue?: T) => {
  try {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json);
    } else {
      return initialValue;
    }
  } catch (e) {
    return initialValue;
  }
};

export const removeItemLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
