import React, { useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AssociationModel } from '~/openapi/typescript-axios/version';
import { useAppState } from '~/providers/AppStateProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';

type Props = {
  children: ReactNode;
};

interface WorkshopValue {
  town?: AssociationModel;
}

const WorkshopContext = React.createContext<WorkshopValue | null>(null);

export function useWorkshop(): WorkshopValue {
  const state = useContext(WorkshopContext);

  if (!state) {
    throw new Error('useWorkshop must be used within WorkshopProvider');
  }

  return state;
}

export function WorkshopProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { workshopId } = useParams<any>();
  const { workshopModel } = useParentWorkshop();
  const [ town, setTown ] = useState<AssociationModel>();

  const { searchAssociations } = useLocalbridgeAssociationApi();

  const initTown = useCallback(async () => {
    if (workshopModel?.townId) {
      const associationResponsesWithPager = await searchAssociations({
        associationIds: [workshopModel?.townId],
      });
      setTown(associationResponsesWithPager?.[0]?.data?.[0]);
    }
  }, [workshopModel, searchAssociations, setTown]);

  useEffect(() => {
    if (params.workshopId !== workshopId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { workshopId }));
    }
  }, [workshopId, params, setParams]);

  useEffect(() => {
    initTown();
  }, [initTown]);

  const providerValue = {
    town,
  };

  return (
    <WorkshopContext.Provider value={providerValue}>
      {children}
    </WorkshopContext.Provider>
  );
}
