import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { useUserUpdateEmailVarification } from './Provider';
import { StyledUserUpdateEmailVarification } from './Styled';

type Props = {};

const UserUpdateEmailVarificationRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    isRegistered,
    hasError,
    handleUserUpdateEmailVarification,
  } = useUserUpdateEmailVarification();

  useEffect(() => {
    handleUserUpdateEmailVarification();
  }, [handleUserUpdateEmailVarification]);

  return (
    <StyledUserUpdateEmailVarification>
      <img className='logo mx-auto' src='/images/logo.png' alt=''/>
      <div className='mx-auto mb-5 fs-5'>
        {isRegistered ? 'メールアドレスを変更しました' : ''}
        {hasError ? 'すでに Eamil 認証されています' : ''}
      </div>

      <div className='mx-auto'>
        <input
          className='btn fw-bolder me-3'
          value='ログイン画面へ'
          onClick={() => history.push(ROUTES.SIGN_IN)}
          style={{
            backgroundColor: 'var(--localbridge-blue)',
            color: 'white',
          }}
        />
      </div>
    </StyledUserUpdateEmailVarification>
  );
};

export default UserUpdateEmailVarificationRenderer;
