import React from 'react';
import { GroupWorkshopEditProvider } from './Provider';
import GroupWorkshopEditRenderer from './Renderer';

export const GroupWorkshopEdit = () => {
  return (
    <GroupWorkshopEditProvider>
      <GroupWorkshopEditRenderer />
    </GroupWorkshopEditProvider>
  );
};
