import React from 'react';
import { AdminLeftSidebar } from '../../components/leftSidebar';

type Props = {};

const AdminUserEditRenderer: React.FC<Props> = () => {
  return (
    <>
      <AdminLeftSidebar />
    </>
  );
};

export default AdminUserEditRenderer;
