import { and, or, where } from 'firebase/firestore';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';

export const getSearchStringQuery = (searchString: string, tokenMapNames: Array<string>) => {
  const { uniGram, biGram, triGram } = getSearchTokenMap(searchString);
  const uniGrams = Object.keys(uniGram);
  const biGrams = Object.keys(biGram);
  const triGrams = Object.keys(triGram);

  console.log(
    searchString,
    tokenMapNames,
    uniGrams,
    biGrams,
    triGrams,
  );

  return (uniGrams.length + biGrams.length + triGrams.length > 0) ? or(
    ...tokenMapNames.map((tokenMapName) => and(
      ...uniGrams.map((token) => where(`${tokenMapName}.uniGram.${token}`, '==', true)),
      ...biGrams.map((token) => where(`${tokenMapName}.biGram.${token}`, '==', true)),
      ...triGrams.map((token) => where(`${tokenMapName}.triGram.${token}`, '==', true)),
    ))
  ) : null;
};
