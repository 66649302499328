
import React, { useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AssociationModel, BookingModel, PassportModel } from '~/openapi/typescript-axios/version';
import { useAppState } from '~/providers/AppStateProvider';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useLocalbridgePassportApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePassportApiProvider';
import { useParentUser } from '~/providers/ParentUserProvider';

type Props = {
  children: ReactNode;
};

interface UserValue {
  passports: Array<PassportModel>;
  bookings: Array<BookingModel>;
  towns: Array<AssociationModel>;
  resetPage: () => Promise<void>;
}

const UserContext = React.createContext<UserValue | null>(null);

export function useUser(): UserValue {
  const state = useContext(UserContext);

  if (!state) {
    throw new Error('useUser must be used within UserProvider');
  }

  return state;
}

export function UserProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { userId } = useParams<any>();
  const { userModelOrPublic } = useParentUser();

  const [ passports, setPassports ] = useState<Array<PassportModel>>([]);
  const [ bookings, setBookings ] = useState<Array<BookingModel>>([]);
  const [ towns, setTowns ] = useState<Array<AssociationModel>>([]);

  const { searchPassports } = useLocalbridgePassportApi();
  const { searchBookings } = useLocalbridgeBookingApi();
  const { searchAssociations } = useLocalbridgeAssociationApi();

  const resetPage = useCallback(async () => {
    const passportResponsesWithPagerTmp = await searchPassports({page: 1000, userId: userModelOrPublic?.id});
    setPassports(passportResponsesWithPagerTmp?.[0]?.data || []);

    const bookingResponsesWithPagerTmp = await searchBookings({page: 1000, userId: userModelOrPublic?.id});
    setBookings(bookingResponsesWithPagerTmp?.[0]?.data || []);

    if (userModelOrPublic?.localAssociationIds?.length) {
      const associationResponsesWithPagerTmp = await searchAssociations({
        page: 1000, associationIds: userModelOrPublic?.localAssociationIds,
      });
      setTowns(associationResponsesWithPagerTmp?.[0]?.data || []);
    }
  }, [
    userModelOrPublic,
    searchPassports,
    searchBookings,
    searchAssociations,
    setPassports,
    setBookings,
    setTowns,
  ]);

  useEffect(() => {
    if (params.userId !== userId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { userId }));
    }
  }, [userId, params, setParams]);

  useEffect(() => {
    resetPage();
  }, [resetPage]);

  const providerValue = {
    passports,
    bookings,
    towns,
    searchAssociations,
    resetPage,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
}
