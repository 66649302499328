import React, { useMemo } from 'react';
import moment from 'moment';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { UserLeftSidebar } from '../components/leftSidebar';
import { PaginationBox } from '~/components/paginationBox';
import { useUserBookings } from './Provider';
import { BookingModel } from '~/openapi/typescript-axios/version';
import './index.scss';
import UserHeader from '../components/header';
import { UserContainer } from '../components/continer';
import { ROUTES } from '~/constants/routes';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {};

const UserBookingsRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    bookingsList,
    bookingsPageCurrent,
    bookingsPageLast,
    setBookingsPageCurrent,
  } = useUserBookings();

  const bookings: Array<BookingModel> = useMemo(() => {
    return bookingsList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === bookingsPageCurrent;
    })?.data || [];
  }, [bookingsPageCurrent, bookingsList]);

  return (
    <>
      <LocalbridgeNavbar />

      <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
        <UserLeftSidebar />

        <UserContainer>
          <UserHeader title='予約済みイベント - 一覧' titleEn='Reserved events' />
          <div className={'user_bookings'}>
            <div className={'user_bookings__inner'}>
              <ul className={'user_bookings__contents'}>
                <li className={'user_bookings__head'}>
                  <p className={'user_bookings__text'}>＃</p>
                  <p className={'user_bookings__text'}>イベント名</p>
                  <p className={'user_bookings__text'}>イベント開催日</p>
                  <p className={'user_bookings__text'}>登録日</p>
                </li>
                {bookings.map((booking, index) => (
                  <li key={`booking:${booking.id}`} className={'user_bookings__item'}>
                    <a onClick={() => history.push(generatePath(ROUTES.USER_BOOKING, {
                      userId: booking.userId,
                      bookingId: booking.id,
                    }))}>
                      <p className={'user_bookings__text user_bookings__text--gray'}>{index + 1}</p>
                      <p className={'user_bookings__text user_bookings__text--large'}>{booking.name}</p>
                      <p className={'user_bookings__text'}>{moment(booking.createdAt).format('YYYY.MM.DD')}</p>
                      <p className={'user_bookings__text'}>{moment(booking.createdAt).format('YYYY.MM.DD')}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={'user_bookings__pagination'}>
              {bookingsList.length ? (
                <PaginationBox
                  forcePage={bookingsPageCurrent}
                  pageCount={bookingsPageLast}
                  onPageChange={setBookingsPageCurrent}
                />
              ):(<></>)}
            </div>
          </div>
        </UserContainer>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default UserBookingsRenderer;
