
import { nanoid } from 'nanoid';
import React, { useContext, ReactNode, useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { DefaultValue } from '~/constants/defaultValue';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import {
  AssociationModel,
  GroupModel,
  GroupType,
  UserGender,
  UserModelWithPassword,
} from '~/openapi/typescript-axios/version';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminGroupsCreateWithUserValue {
  group: GroupModel;
  userModelWithPassword: UserModelWithPassword;
  cities: Array<AssociationModel>;
  towns: Array<AssociationModel>;
  selectedCity?: AssociationModel;
  selectedTown?: AssociationModel;
  setGroup: (group: GroupModel) => void;
  setUserModelWithPassword: (userModelWithPassword: UserModelWithPassword) => void;
}

const AdminGroupsCreateWithUserContext = React.createContext<AdminGroupsCreateWithUserValue | null>(null);

export function useAdminGroupsCreateWithUser(): AdminGroupsCreateWithUserValue {
  const state = useContext(AdminGroupsCreateWithUserContext);

  if (!state) {
    throw new Error('useAdminGroupsCreateWithUser must be used within AdminGroupsCreateWithUserProvider');
  }

  return state;
}

export function AdminGroupsCreateWithUserProvider({ children }: Props) {
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();

  const newGroupId = nanoid();
  const now = Number(new Date());
  const [ group, setGroup ] = useState<GroupModel>({
    id: newGroupId,
    createdAt: now,
    updatedAt: now,
    photos: [],
    type: GroupType.Other,
    name: '',
    nameToken: getSearchTokenMap(''),
    delegateName: '',
    delegateNameFurigana: '',
    groupEmail: '',
    managerName: DefaultValue.NoInput,
    managerEmail: DefaultValue.NoInput,
    homepage: '',
    groupPhoneNumber: '',
    cityId: '000000',
    townId: '000000',
    detailAddress1: DefaultValue.NoInput,
    detailAddress2: DefaultValue.NoInput,
    postNumber: DefaultValue.NoInput,
    userIdsManagerRole: [],
    passportIcon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    description: '',
  });

  const [ userModelWithPassword, setUserModelWithPassword ] = useState<UserModelWithPassword>({
    id: '',
    createdAt: now,
    updatedAt: now,
    photos: [],
    // interests: [],
    icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    name: '',
    newPassword: '',
    passwordConfirm: '',
    nickName: DefaultValue.NoInput,
    nameFurigana: '',
    studentGrade: '',
    description: '',
    job: DefaultValue.NoInput,
    educationalInstitutionName: '',
    studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
    email: '',
    emailToken: getSearchTokenMap(''),
    gender: UserGender.Male,
    phoneNumber: '',
    cityId: '000000',
    townId: '000000',
    detailAddress1: DefaultValue.NoInput,
    detailAddress2: DefaultValue.NoInput,
    postNumber: DefaultValue.NoInput,
    localAssociationIds: ['000000'],
    localCategorys: [],
    birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
  });

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === group?.cityId);
  }, [cities, group?.cityId]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    group?.cityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === group?.townId);
  }, [towns, group?.townId]);

  const providerValue = {
    group,
    userModelWithPassword,
    cities,
    towns,
    selectedCity,
    selectedTown,
    setGroup,
    setUserModelWithPassword,
  };

  return (
    <AdminGroupsCreateWithUserContext.Provider value={providerValue}>
      {children}
    </AdminGroupsCreateWithUserContext.Provider>
  );
}
