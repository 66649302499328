import React from 'react';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { ROUTES } from '~/constants/routes';
import { useHistory } from 'react-router-dom';

type Props = {};

const QnaSubmitted: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <>
      <LocalbridgeSubHeader title='お問い合わせ' enTitle='Contact' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <section className={'qna_submitted'}>
          <div className={'qna_submitted__inner'}>
            <h2 className={'qna_submitted__title'}>お問い合わせが完了しました</h2>
            <p className={'qna_submitted__text'}>
              お問い合わせいただきありがとうございます。
              <br />
              3営業日以内に担当者よりご連絡いたしますので、今しばらくお待ちいただけますと幸いです。
            </p>
            <div className={'qna_submitted__back'}>
              <a onClick={() => history.push(ROUTES.HOME)}>TOPページに戻る</a>
            </div>
          </div>
        </section>
      </LocalbridgeBody>
    </>
  );
};

export default QnaSubmitted;
