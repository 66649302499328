import { MessageModal, MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './LocalbridgeApiError';

type Schema<T extends object> = {
  [elementId in keyof T]: Array<(item: T) => Promise<string>>;
};

export class Validator<T extends object> {
  validationClassName = '';
  schema = {};
  constructor(validationClassName: string, schema: Schema<T>) {
    this.validationClassName = validationClassName;
    this.schema = schema;
  }

  validate = async (items: Array<T>) => {
    const messageResponse: MessageResponse = {
      title: '', message: '', operationId: '', modal: MessageModal.None, validations: [],
    };

    for (const item of items) {
      for (const itemKey of Object.keys(item as any)) {
        if (!this.schema[itemKey]?.length) {
          continue;
        }

        const messages: Array<string> = [];
        for (const validationCallback of this.schema[itemKey]) {
          const message = await validationCallback(item);
          if (!message) {
            continue;
          }

          messages.push(message);
        }

        if (messages.length) {
          messageResponse.validations?.push({
            id: (item as any).id,
            className: this.validationClassName,
            key: itemKey,
            messages,
          });
        }
      }
    }

    return messageResponse;
  };
}

export type ValidationQuery = {
  className: string;
  id: string;
  key: string;
};

export const getValidationQueries = (validationQuery: ValidationQuery) => {
  // eslint-disable-next-line
  const { className, id = '', key } = validationQuery;
  return [
    `[validation-class-name="${className}"]`,
    '[validation-id=""]',
    `[validation-key="${key}"]`,
  ];
};

export const getValidationFeedbackQueries = (validationQuery: ValidationQuery) => {
  // eslint-disable-next-line
  const { className, id = '', key } = validationQuery;
  return [
    `[validation-class-name="${className}"]`,
    '[validation-id=""]',
    `[validation-key="${key}"]`,
    '[validation-feedback="true"]',
  ];
};

/**
 * @params inputElemnetId: string
 * @description setValidationMessage With `showLocalbridgeApiValidationErrorMessage`
*/
export const showLocalbridgeApiValidationErrorMessage = (error: LocalbridgeApiError) => {
  for (const validation of error.messageResponse?.validations || []) {
    const { className, id, key, messages } = validation;
    if (!messages?.length) {
      continue;
    }

    const inputElement = document
      .querySelector(getValidationQueries({ className, id, key }).join(''));
    const inputFeedbackElement = document
      .querySelector(getValidationFeedbackQueries({ className, id, key }).join(''));

    if (inputElement && inputFeedbackElement) {
      inputElement.classList.add('is-invalid');
      inputFeedbackElement.innerHTML = (messages || []).join('<br />');
    }
  }
};

/**
 * @params error: LocalbridgeApiError
 * @description removeValidationMessage With `resetLocalbridgeApiValidationMessage`
*/
export const resetLocalbridgeApiValidationMessage = (validationQuery: ValidationQuery) => {
  const { className, id, key } = validationQuery;

  const inputElement = document
    .querySelector(getValidationQueries({ className, id, key }).join(''));
  const inputFeedbackElement = document
    .querySelector(getValidationFeedbackQueries({ className, id, key }).join(''));

  if (inputElement && inputFeedbackElement) {
    inputElement.classList.remove('is-invalid');
    inputElement.classList.remove('is-valid');
    inputFeedbackElement.innerHTML = '';
  }
};
