import React from 'react';
import { PostProvider } from './Provider';
import PostRenderer from './Renderer';

export const Post = () => {
  return (
    <PostProvider>
      <PostRenderer />
    </PostProvider>
  );
};
