import inputForm, { InputType } from '../form/inputForm';

export const removeCheck = async ({
  title,
  message,
  confirmButtonText = '削除',
  checkboxMessage = 'はい、削除して問題ありません'
}:{
  title: string;
  confirmButtonText?: string;
  message: string;
  checkboxMessage?: string;
}) => {
  const res = await inputForm({
    title,
    confirmButtonText,
  }, {
    message: {
      label: '',
      value: (
        <p style={{color:'red'}} className='text-center'>
          {message}
        </p>
      ),
      type: InputType.Label,
      column: [0, 12],
    },
    canRemove: {
      label: checkboxMessage,
      value: false,
      type: InputType.Check
    },
  });

  return !!res?.value?.canRemove;
};