import React from 'react';
import { GroupLeftSidebarProvider } from './Provider';
import GroupLeftSidebarRenderer from './Renderer';

export const GroupLeftSidebar = () => {
  return (
    <GroupLeftSidebarProvider>
      <GroupLeftSidebarRenderer />
    </GroupLeftSidebarProvider>
  );
};
