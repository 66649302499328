import React from 'react';
import './index.scss';
import { ROUTES } from '~/constants/routes';
import { PostType } from '~/openapi/typescript-axios/version';
import { useHistory } from 'react-router-dom';

type Props = {};

const LocalbridgeFooterRenderer: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <div className={'localbridge_footer'}>
      <div className={'localbridge_footer__inner localbridge_footer__inner__is-pc'}>
        <div className={'localbridge_footer_left'}>
          <div className={'localbridge_footer_left__logo'}>
            <button onClick={() => history.push(ROUTES.HOME)}>
              <img src='/assets/img/common/logo02.png' alt='ロゴ' />
            </button>
          </div>
          <div className={'localbridge_footer_left__sns'}>
            <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
              <img src='/assets/img/common/ico_line.svg' alt='Lineアイコン' />
            </button>
            <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
              <img src='/assets/img/common/ico_threads.svg' alt='Threadsアイコン' />
            </button>
            <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
              <img src='/assets/img/common/ico_instagram.svg' alt='Instagramアイコン' />
            </button>
            <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
              <img src='/assets/img/common/ico_facebook.svg' alt='Facebookアイコン' />
            </button>
            <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
              <img src='/assets/img/common/ico_twitter.svg' alt='Twitterアイコン' />
            </button>
          </div>
          <div className={'localbridge_footer_left__copyright'}>&copy;2023 ODK Solutions Company, Ltd.</div>
        </div>
        <div className={'localbridge_footer_right'}>
          <div className={'localbridge_footer_right__menu'}>
            <button className={'localbridge_footer_right__item'}>
              <a onClick={() => history.push(ROUTES.HOME)}>ホーム</a>
            </button>
            <button className={'localbridge_footer_right__item'}>
              <a onClick={() => history.push(ROUTES.STARTING_GUIDE)}>はじめての方へ</a>
            </button>
            <button className={'localbridge_footer_right__item'}>
              <a onClick={() => history.push(ROUTES.WORKSHOPS)}>イベント情報</a>
            </button>
            <button className={'localbridge_footer_right__item'}>
              <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Experience}`)}>体験記事を探す</a>
            </button>
            <button className={'localbridge_footer_right__item'}>
              <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>まちの魅力発見</a>
            </button>
          </div>
          <div className={'localbridge_footer_right__buttons'}>
            <button onClick={() => history.push(ROUTES.SIGN_IN)} className={'localbridge_footer_right__button localbridge_footer_right__button--login'}>
              ログイン
            </button>
            <button onClick={() => history.push(ROUTES.SIGN_UP)} className={'localbridge_footer_right__button localbridge_footer_right__button--register'}>
              新規登録
            </button>
          </div>
          <div className={'localbridge_footer_right__links'}>
            <button className={'localbridge_footer_right__link'}>
              <a onClick={() => history.push(ROUTES.TERMS_OF_SERVICE)}>利用規約</a>
            </button>
            <button className={'localbridge_footer_right__link'}>
              <a onClick={() => history.push(ROUTES.PRIVACY_POLICY)}>プライバシーポリシー</a>
            </button>
            <button className={'localbridge_footer_right__link'}>
              <a onClick={() => history.push(ROUTES.COOKIE_POLICY)}>Cookieポリシー</a>
            </button>
            <button className={'localbridge_footer_right__link'}>
              <a href='https://www.odk.co.jp/'>運営会社</a>
            </button>
            <button className={'localbridge_footer_right__link'}>
              <a onClick={() => history.push(ROUTES.QNA)}>お問い合わせ</a>
            </button>
          </div>
        </div>
      </div>
      <div className={'localbridge_footer__inner localbridge_footer__inner__is-sp'}>
        <div className={'localbridge_footer_left__logo'}>
          <button onClick={() => history.push(ROUTES.HOME)}>
            <img src='/assets/img/common/logo02.png' alt='ロゴ' />
          </button>
        </div>
        <div className={'localbridge_footer_right__buttons'}>
          <button onClick={() => history.push(ROUTES.SIGN_IN)} className={'localbridge_footer_right__button localbridge_footer_right__button--login'}>
            ログイン
          </button>
          <button onClick={() => history.push(ROUTES.SIGN_UP)} className={'localbridge_footer_right__button localbridge_footer_right__button--register'}>
            新規登録
          </button>
        </div>

        <div className={'localbridge_footer_right__menu'}>
          <button className={'localbridge_footer_right__item'}>
            <a onClick={() => history.push(ROUTES.HOME)}>ホーム</a>
          </button>
          <button className={'localbridge_footer_right__item'}>
            <a onClick={() => history.push(ROUTES.STARTING_GUIDE)}>はじめての方へ</a>
          </button>
          <button className={'localbridge_footer_right__item'}>
            <a onClick={() => history.push(ROUTES.WORKSHOPS)}>イベント情報</a>
          </button>
          <button className={'localbridge_footer_right__item'}>
            <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Experience}`)}>体験記事を探す</a>
          </button>
          <button className={'localbridge_footer_right__item'}>
            <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>まちの魅力発見</a>
          </button>
        </div>
        <div className={'localbridge_footer_left__sns'}>
          <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
            <img src='/assets/img/common/ico_line.svg' alt='Lineアイコン' />
          </button>
          <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
            <img src='/assets/img/common/ico_threads.svg' alt='Threadsアイコン' />
          </button>
          <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
            <img src='/assets/img/common/ico_instagram.svg' alt='Instagramアイコン' />
          </button>
          <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
            <img src='/assets/img/common/ico_facebook.svg' alt='Facebookアイコン' />
          </button>
          <button className={'localbridge_footer_left__item'} rel='noopener noreferrer'>
            <img src='/assets/img/common/ico_twitter.svg' alt='Twitterアイコン' />
          </button>
        </div>
        <div className={'localbridge_footer_right__links'}>
          <button className={'localbridge_footer_right__link'}>
            <a onClick={() => history.push(ROUTES.TERMS_OF_SERVICE)}>利用規約</a>
          </button>
          <button className={'localbridge_footer_right__link'}>
            <a onClick={() => history.push(ROUTES.PRIVACY_POLICY)}>プライバシーポリシー</a>
          </button>
          <button className={'localbridge_footer_right__link'}>
            <a onClick={() => history.push(ROUTES.COOKIE_POLICY)}>Cookieポリシー</a>
          </button>
          <button className={'localbridge_footer_right__link'}>
            <a href='https://www.odk.co.jp/'>運営会社</a>
          </button>
          <button className={'localbridge_footer_right__link'}>
            <a onClick={() => history.push(ROUTES.QNA)}>お問い合わせ</a>
          </button>
        </div>
        <div className={'localbridge_footer_left__copyright'}>&copy;2023 ODK Solutions Company, Ltd.</div>
      </div>
    </div>
  );
};

export default LocalbridgeFooterRenderer;
