import React from 'react';
import { UserOtherPersonProfileProvider } from './Provider';
import UserOtherPersonProfileRenderer from './Renderer';
import { UserPublic } from '~/openapi/typescript-axios/version';

type Props = {
  userPublic: UserPublic,
};

export const UserOtherPersonProfile: React.FC<Props> = ({userPublic}) => {
  return (
    <UserOtherPersonProfileProvider userPublic={userPublic}>
      <UserOtherPersonProfileRenderer />
    </UserOtherPersonProfileProvider>
  );
};
