import React from 'react';
import { UserPostsProvider } from './Provider';
import UserPostsRenderer from './Renderer';

export const UserPosts = () => {
  return (
    <UserPostsProvider>
      <UserPostsRenderer />
    </UserPostsProvider>
  );
};
