import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default function showError(
  option: SweetAlertOptions,
  callback?: () => void,
): void {
  option.icon = 'error';  
  option.backdrop = 'rgba(0,0,0,0)';
  option.showConfirmButton = option.showConfirmButton ?? false;
  option.showCloseButton = option.showCloseButton ?? true;

  option.confirmButtonText = option.confirmButtonText ?? '確認';
  option.denyButtonText = option.denyButtonText ?? 'キャンセル';
  option.confirmButtonColor = option.confirmButtonColor ?? 'var(--bs-primary)';
  option.denyButtonColor = option.denyButtonColor ?? 'var(--bs-danger)';

  MySwal.fire(option).then((res) => {
    if (res.isDismissed && callback) {
      callback();
    }
  });
}
