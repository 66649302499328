import React from 'react';
import { AboutPassportProvider } from './Provider';
import AboutPassportRenderer from './Renderer';

export const AboutPassport = () => {
  return (
    <AboutPassportProvider>
      <AboutPassportRenderer />
    </AboutPassportProvider>
  );
};
