import React from 'react';
import { UserUpdateEmailVarificationProvider } from './Provider';
import UserUpdateEmailVarificationRenderer from './Renderer';

export const UserUpdateEmailVarification = () => {
  return (
    <UserUpdateEmailVarificationProvider>
      <UserUpdateEmailVarificationRenderer />
    </UserUpdateEmailVarificationProvider>
  );
};
