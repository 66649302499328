import React from 'react';
import { GroupWorkshopsCreateProvider } from './Provider';
import GroupWorkshopsCreateRenderer from './Renderer';

export const GroupWorkshopsCreate = () => {
  return (
    <GroupWorkshopsCreateProvider>
      <GroupWorkshopsCreateRenderer />
    </GroupWorkshopsCreateProvider>
  );
};
