import React from 'react';
import { AboutCommunityProvider } from './Provider';
import AboutCommunityRenderer from './Renderer';

export const AboutCommunity = () => {
  return (
    <AboutCommunityProvider>
      <AboutCommunityRenderer />
    </AboutCommunityProvider>
  );
};
