import React, { useContext, ReactNode, useEffect, useCallback, useState, useMemo } from 'react';
import { UserModel, UserPublic } from '~/openapi/typescript-axios/version';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { useAppState } from './AppStateProvider';
import { useAuth } from './AuthProvider';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';

type Props = {
  children: ReactNode;
};

interface ParentUserValue {
  userModelOrPublic?: UserModel|UserPublic;
  isMe: boolean;
  setUserModelOrPublic: (userModelOrPublic?: UserModel|UserPublic) => void;
}

const ParentUserContext = React.createContext<ParentUserValue | null>(null);

export function useParentUser(): ParentUserValue {
  const state = useContext(ParentUserContext);

  if (!state) {
    throw new Error('useParentUser must be used within ParentUserProvider');
  }

  return state;
}

export function ParentUserProvider({ children }: Props) {
  const { params } = useAppState();
  const { thisUser, isServiceManager } = useAuth();
  const { getUserModel, localbridgeUser } = useLocalbridgeUserApi();
  const [ userModelOrPublic, setUserModelOrPublic ] = useState<UserModel|UserPublic>();

  const isMe = useMemo(() => {
    return thisUser?.id === params?.userId;
  }, [thisUser?.id, params?.userId]);

  const initParentUser = useCallback(async () => {
    if (!params?.userId) {
      return;
    }

    let userTmp: UserModel|UserPublic|undefined;
    if (isMe || isServiceManager) {
      userTmp = (await getUserModel({userId: params?.userId}))?.userModel;
    } else {
      userTmp = (await localbridgeUser({
        env: { FUNCTION_V, LOCALBRIDGE_PATH },
        params: { userId: params?.userId },
      }))?.userPublic || null;
      console.log(userTmp);
    }

    setUserModelOrPublic(userTmp);
  }, [params?.userId, isMe, isServiceManager, getUserModel, setUserModelOrPublic, localbridgeUser]);

  useEffect(() => {
    initParentUser();
  }, [initParentUser]);

  const providerValue = {
    userModelOrPublic,
    isMe,
    setUserModelOrPublic,
  };

  return (
    <ParentUserContext.Provider value={providerValue}>
      {children}
    </ParentUserContext.Provider>
  );
}
