import React, { useContext, ReactNode, useCallback } from 'react';
import {
  QueryFieldFilterConstraint,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  LOCALBRIDGE_PATH,
  firestore,
} from '~/constants/firebase';
import {
  NoticeRequestsDelete,
  NoticeRequestCreate,
  NoticeRequestUpdate,
  NoticeRequestsValidate,
  NoticeResponsesWithPager,  
  NoticeRequestsSearch,
  NoticeModel,
  NoticeRequestRead,
  NoticeResponseRead,
  testEmptyInput,
  testStringMax80,
  testStringId,
  testStringMax40000,
  testDateReversedStartAndEnd,
} from '~/openapi/typescript-axios/version';
import { MessageResponse } from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeNoticeApiValue {
  validateNotices: (noticeRequestsValidate: NoticeRequestsValidate) => Promise<MessageResponse>;
  createNotices: (noticeRequestCreates: Array<NoticeRequestCreate>) => Promise<void>;
  getNotice: (noticeRequestRead: NoticeRequestRead) => Promise<NoticeResponseRead|null>;
  updateNotice: (noticeRequestUpdate: NoticeRequestUpdate) => Promise<void>;
  deleteNotices: (deleteNoticesRequest: NoticeRequestsDelete) => Promise<void>;
  searchNotices: (
    noticeRequestsSearch: NoticeRequestsSearch
  ) => Promise<Array<NoticeResponsesWithPager>>;
}

const LocalbridgeNoticeApiContext = React.createContext<LocalbridgeNoticeApiValue | null>(null);

export function useLocalbridgeNoticeApi(): LocalbridgeNoticeApiValue {
  const state = useContext(LocalbridgeNoticeApiContext);

  if (!state) {
    throw new Error('useLocalbridgeNoticeApi must be used within LocalbridgeNoticeApiProvider');
  }

  return state;
}

export function LocalbridgeNoticeApiProvider({ children }: Props) {
  const validateNotices = useCallback(async (noticeRequestsValidate: NoticeRequestsValidate) => {
    const noticeModels = noticeRequestsValidate.noticeRequests.map((noticeRequest) => {
      return noticeRequest.noticeModel;
    });

    const noticeValidator = new Validator<NoticeModel>('NoticeModel', {
      id: [
        async (notice) => testEmptyInput(notice.id),
        async (notice) => testStringMax80(notice.id),
        async (notice) => testStringId(notice.id),
      ],
      photos: [
      ],
      createdAt: [
        async (post) => testEmptyInput(post.createdAt),
        async (post) => testDateReversedStartAndEnd(post.createdAt, post.updatedAt),
      ],
      updatedAt: [
        async (post) => testEmptyInput(post.updatedAt),
      ],
      title: [
        async (post) => testEmptyInput(post.title),
        async (post) => testStringMax80(post.title),
      ],
      content: [
        async (post) => testEmptyInput(post.content),
        async (post) => testStringMax40000(post.content),
      ],
      isHidden: [],
    });

    return noticeValidator.validate(noticeModels);
  }, []);

  const createNotices = useCallback(async (noticeRequestCreates: Array<NoticeRequestCreate>) => {
    if (!noticeRequestCreates.length) {
      return;
    }

    const messageResponses = await validateNotices({
      isOnSubmit: true,
      noticeRequests: noticeRequestCreates,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const batch = writeBatch(firestore);
    for (const noticeRequestCreate of noticeRequestCreates) {
      const { id } = noticeRequestCreate.noticeModel;
      batch.set(doc(firestore, `${LOCALBRIDGE_PATH}/notices/${id}`), {
        ...Object.assign({}, noticeRequestCreate.noticeModel)
      });
    }

    await batch.commit();
  }, [validateNotices]);

  const getNotice = useCallback(async (noticeRequestRead: NoticeRequestRead) => {
    const { noticeId } = noticeRequestRead;
    const noticeModel = (await getDoc(doc(firestore, `${LOCALBRIDGE_PATH}/notices/${noticeId}`))).data() as NoticeModel|null;
    return noticeModel ? {noticeModel} : null;
  }, []);

  const updateNotice = useCallback(async (noticeRequestUpdate: NoticeRequestUpdate) => {
    const messageResponses = await validateNotices({
      isOnSubmit: true,
      noticeRequests: [noticeRequestUpdate],
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    const { id } = noticeRequestUpdate.noticeModel;
    await updateDoc(doc(firestore, `${LOCALBRIDGE_PATH}/notices/${id}`), {
      ...Object.assign({}, noticeRequestUpdate)
    });
  }, [validateNotices]);

  const deleteNotices = useCallback(async (deleteNoticesRequest: NoticeRequestsDelete) => {
    const noticeIds = deleteNoticesRequest?.noticeIds || [];

    if (!noticeIds.length) {
      return;
    }

    const batch = writeBatch(firestore);
    for (const noticeId of noticeIds) {
      batch.delete(doc(firestore, `${LOCALBRIDGE_PATH}/notices/${noticeId}`));
    }

    await batch.commit();
  }, []);

  const searchNotices = useCallback(async (noticeRequestsSearch: NoticeRequestsSearch) => {
    const {
      size,
      page,
      createdFrom,
      createdTo,
    } = noticeRequestsSearch;

    const qry = query(
      collection(firestore, `${LOCALBRIDGE_PATH}/notices`),
      and(
        ...[
          createdFrom ? where('createdAt', '>=', createdFrom) : null,
          createdTo ? where('createdAt', '<=', createdTo) : null,
        ].filter(Boolean) as Array<QueryFieldFilterConstraint>,
      ),
      orderBy('createdAt', 'desc'),
    );

    const resultsTmp = ((await getDocs(qry)).docs.map((doc) => doc.data()) || []) as Array<NoticeModel>;
    resultsTmp.sort((resultA, resultB) => resultB.createdAt - resultA.createdAt);

    const totalCount = resultsTmp.length;
    const resultsList: Array<NoticeResponsesWithPager> = [];

    const pageSize = (size || 10);
    for (let index = 0; index < resultsTmp.length; index += pageSize) {
      resultsList.push({
        data: resultsTmp.slice(index, pageSize),
        pager: { page: resultsList.length, size: pageSize, totalCount},
      });
    }

    if (page) {
      return [resultsList[page - 1]];
    } else {
      return resultsList;
    }
  }, []);

  const providerValue = {
    validateNotices,
    createNotices,
    getNotice,
    updateNotice,
    deleteNotices,
    searchNotices,
  };

  return (
    <LocalbridgeNoticeApiContext.Provider value={providerValue}>
      {children}
    </LocalbridgeNoticeApiContext.Provider>
  );
}
