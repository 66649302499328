import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import LocalbridgeBody from '~/components/localbridgeBody';
import './index.scss';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';
import { useWorkshop } from './Provider';
import { ROUTES } from '~/constants/routes';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

type Props = {};

const WorkshopRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const swiperPaginationRef = useRef<HTMLDivElement>(null);
  const { workshopModel } = useParentWorkshop();
  const { town } = useWorkshop();

  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeBody bgColor='#dbf1db'>
        <div className={'workshop'}>
          <p className={'workshop__label'}><img src='/assets/img/event/pic_icon01.jpg' />
            {`${town ? town.cityName + town.associationName : ''}`}
          </p>
          <h1 className={'workshop__title'}>{workshopModel?.title || ''}</h1>
          <div className={'workshop__contents'}>
            <div className={'workshop__main'}>
              <div className={'workshop_swiper'}>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  loop
                  centeredSlides
                  pagination={{
                    el: '.swiper-pagination', // ページネーション要素のセレクタ
                    clickable: true, // クリック可能にする
                  }}
                  navigation={{
                    nextEl: '.swiper-button-next', // 次のボタン要素のセレクタ
                    prevEl: '.swiper-button-prev', // 前のボタン要素のセレクタ
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 1,
                      centeredSlides: true,
                      spaceBetween: 20,
                    }
                  }}
                >
                  {workshopModel?.contentPhotos.map((contentPhoto) => (
                    <SwiperSlide
                      key={`workshopModel:${workshopModel?.id}-contentPhoto:${contentPhoto.id}`}
                      className='swiper-slide'
                    >
                      <img
                        src={contentPhoto.url}
                        alt='Slide'
                        style={{aspectRatio: '812 / 451'}}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className='swiper-button-next'></div>
                <div className='swiper-button-prev'></div>
                <div ref={swiperPaginationRef} className={'swiper-pagination workshop__pagination'}></div>
              </div>
              <p className={'workshop__city'}><img src='/assets/img/event/pic_icon01.jpg' />
                {`${town ? town.cityName + town.associationName : ''}`}
              </p>

              {workshopModel?.overview ? (
                <section className={'workshop_section'}>
                  <h2 className={'workshop_section__title'}>
                    <img src='/assets/img/event/ico_flag01.svg' />イベント概要
                  </h2>
                  <p className={'workshop_section__text'}>
                    {workshopModel.overview.split('\n').map((splitedText, index) => (
                      <React.Fragment key={`workshopModel:${workshopModel?.id}-overview:${index}`}>
                        {splitedText}
                        {(index !== workshopModel.overview.split('\n').length - 1) ? (<br />) : (<></>)}
                      </React.Fragment>
                    ))}
                  </p>
                </section>
              ) : (<></>)}

              {workshopModel?.detail ? (
                <section className={'workshop_section'}>
                  <h2 className={'workshop_section__title'}>
                    <img src='/assets/img/event/ico_flag02.svg' />イベント詳細
                  </h2>
                  <p className={'workshop_section__text'}>
                    {workshopModel.detail.split('\n').map((splitedText, index) => (
                      <React.Fragment key={`workshopModel:${workshopModel?.id}-detail:${index}`}>
                        {splitedText}
                        {(index !== workshopModel.detail.split('\n').length - 1) ? (<br />) : (<></>)}
                      </React.Fragment>
                    ))}
                  </p>
                </section>
              ) : (<></>)}

              <button className={'workshop__btn'}>
                <a onClick={() => history.push(ROUTES.WORKSHOPS)}>イベント・ワークショップ一覧へ</a>
              </button>
            </div>
            <section className={'workshop_side'}>
              <h2 className={'workshop_side__title'}>イベント情報</h2>
              <div className={'workshop_side__info'}>
                <p className={'workshop_side__sub_title'}>開催日</p>
                <p className={'workshop_side__text'}>
                  {workshopModel?.workshopStartAt
                    ? moment(workshopModel?.workshopStartAt).locale('ja').format('YYYY/MM/DD（ddd）～') : ''
                  }
                  <br />
                  {workshopModel?.workshopEndAt
                    ? moment(workshopModel?.workshopEndAt).locale('ja').format('YYYY/MM/DD（ddd）') : ''
                  }
                  <br />
                  14:00~15:00
                </p>
              </div>
              <div className={'workshop_side__info'}>
                <p className={'workshop_side__sub_title'}>定員</p>
                <p className={'workshop_side__text'}>{workshopModel?.bookingMax}人</p>
              </div>
              <div className={'workshop_side__info'}>
                <p className={'workshop_side__sub_title'}>申込期間</p>
                <p className={'workshop_side__text'}>
                  {workshopModel?.bookingStartAt
                    ? moment(workshopModel?.bookingStartAt).locale('ja').format('YYYY/MM/DD（ddd）HH:mm～') : ''
                  }
                  <br />
                  {workshopModel?.bookingEndAt
                    ? moment(workshopModel?.bookingEndAt).locale('ja').format('YYYY/MM/DD（ddd）HH:mm') : ''
                  }
                </p>
              </div>
              <div className={'workshop_side__info'}>
                <p className={'workshop_side__sub_title'}>参加費</p>
                <p className={'workshop_side__text'}>
                  {workshopModel?.participationFee ? `¥${workshopModel?.participationFee}-（税込）`: ''}
                </p>
              </div>
              <form className={'workshop_form'}>
                <p className={'workshop_form__text'}><a href='#'>イベント規約</a></p>
                <div className={'workshop_form__check'}>
                  <span>
                    <input type='checkbox' id='check' />
                    <label htmlFor='check'>イベントに同意する</label>
                  </span>
                </div>
                <input
                  className={'workshop_form__btn'}
                  value='申し込む'
                  onClick={(event) => {
                    event.preventDefault();
                    // history.push(ROUTES.)
                  }}
                />
              </form>
              <div className={'workshop_notes'}>
                <p className={'workshop_notes__title'}>注意事項</p>
                <p className={'workshop_notes__text'}>
                  {workshopModel?.notice?.split('\n').map((splitedText, index) => (
                    <React.Fragment key={`workshopModel:${workshopModel?.id}-notice:${index}`}>
                      {splitedText}
                      {(index !== (workshopModel.notice?.split('\n').length || 0) - 1) ? (<br />) : (<></>)}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </section>
          </div>
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default WorkshopRenderer;
