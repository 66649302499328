import React, { useMemo } from 'react';
import moment from 'moment';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationBox } from '~/components/paginationBox';
import { WorkshopModel } from '~/openapi/typescript-axios/version';
import { useAdminWorkshops } from './Provider';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { StyledAdminTable } from '../components/StyledAdminTable';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';

type Props = {};

const AdminWorkshopsRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    workshopsList,
    workshopsPageCurrent,
    workshopsPageLast,
    setWorkshopsPageCurrent,
  } = useAdminWorkshops();

  const workshops: Array<WorkshopModel> = useMemo(() => {
    return workshopsList.find((workshopsWithPager) => {
      return workshopsWithPager.pager.page === workshopsPageCurrent;
    })?.data || [];
  }, [workshopsPageCurrent, workshopsList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <span style={{fontSize: 24}}>イベント一覧</span>

        <span
          className='m-auto fw-bolder p-2'
          hidden={workshops.length > 0}
        >
          登録された現場がありません
        </span>

        {workshops.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* イベントID */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  イベントID
                </th>

                {/* タイトル */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  タイトル
                </th>
              </tr>
              <tr className='border'>
                {/* 開催開始  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  開催開始 
                </th>

                {/* 開催終了 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  開催終了
                </th>

                {/* 都道府県 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  都道府県
                </th>

                {/* 承認状況 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  承認状況
                </th>

                {/* 非公開 */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  非公開
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 申し込み開始 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  申し込み開始
                </th>

                {/* 申し込み終了 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  申し込み終了
                </th>

                {/* 承認待ち中 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  承認待ち中
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {workshops.map((workshop: WorkshopModel, index: number) => {

                return (
                  <tbody
                    key={workshop.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* イベントID */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {workshop.id}
                      </th>

                      {/* タイトル */}
                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {workshop.title}
                      </th>
                    </tr>

                    <tr>
                      {/* 開催開始  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(workshop.workshopStartAt).format('YYYY.MM.DD')}
                      </td>

                      {/* 開催終了 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >{moment(workshop.workshopEndAt).format('YYYY.MM.DD')}</td>

                      {/* 都道府県 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >{workshop.cityId}</td>

                      {/* 承認状況 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {workshop.isApproved}
                      </td>

                      {/* 非公開 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {workshop.isHidden}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_WORKSHOP_EDIT, {
                          workshopId: workshop.id,
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 申し込み開始 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(workshop.bookingStartAt).format('YYYY.MM.DD')}
                      </td>

                      {/* 申し込み終了 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(workshop.bookingEndAt).format('YYYY.MM.DD')}
                      </td>

                      {/* 承認待ち中 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {workshop.isWaitingApproved}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        <PaginationBox
          paginationBoxClassNamePrefix='pagination_box_block'
          forcePage={workshopsPageCurrent}
          pageCount={workshopsPageLast}
          onPageChange={setWorkshopsPageCurrent}
        />
      </div>
    </div>
  );
};

export default AdminWorkshopsRenderer;
