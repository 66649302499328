import React from 'react';
import { SignInProvider } from './Provider';
import SignInRenderer from './Renderer';

export const SignIn = () => {
  return (
    <SignInProvider>
      <SignInRenderer />
    </SignInProvider>
  );
};
