import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PostModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';

type Props = {
  children: ReactNode;
};

interface UserPostValue {
  postModel?: PostModel;
  setPostModel: (postModel?: PostModel) => void;
}

const UserPostContext = React.createContext<UserPostValue | null>(null);

export function useUserPost(): UserPostValue {
  const state = useContext(UserPostContext);

  if (!state) {
    throw new Error('useUserPost must be used within UserPostProvider');
  }

  return state;
}

export function UserPostProvider({ children }: Props) {
  const { postId } = useParams<any>();

  const { getPost } = useLocalbridgePostApi();

  const [ postModel, setPostModel ] = useState<PostModel>();

  const initPost = useCallback(async () => {
    if (!postId) {
      return;
    }

    const postResultTmp = await getPost({postId});
    setPostModel(postResultTmp?.postModel);
  }, [postId, getPost]);

  useEffect(() => {
    initPost();
  }, [initPost]);

  const providerValue = {
    postModel,
    setPostModel,
  };

  return (
    <UserPostContext.Provider value={providerValue}>
      {children}
    </UserPostContext.Provider>
  );
}
