type Props = {
  categoryName: string;
  checked?: boolean;
  onSelectCategory: (categoryName: string) => void;
};

const SelectCategory: React.FC<Props> = ({categoryName, checked, onSelectCategory}) => {
  return (
    <div
      className={'user_edit_item_checkbox__item'}
      onClick={() => {
        onSelectCategory(categoryName);
      }}
    >
      <input
        className={'user_edit_item_checkbox__btn'}
        type='checkbox'
        checked={checked}
        onChange={() => {}}
      />
      <label className={'user_edit_item_checkbox__text'}>
        {categoryName}
      </label>
    </div>
  );
};

export default SelectCategory;