import React from 'react';
import { StartingGuideProvider } from './Provider';
import StartingGuideRenderer from './Renderer';

export const StartingGuide = () => {
  return (
    <StartingGuideProvider>
      <StartingGuideRenderer />
    </StartingGuideProvider>
  );
};
