import React from 'react';
import { UserUpdatePasswordResetProvider } from './Provider';
import UserUpdatePasswordResetRenderer from './Renderer';

export const UserUpdatePasswordReset = () => {
  return (
    <UserUpdatePasswordResetProvider>
      <UserUpdatePasswordResetRenderer />
    </UserUpdatePasswordResetProvider>
  );
};
