import React from 'react';
import { NoticesNewsListTagProvider } from './Provider';
import NoticesNewsListTagRenderer from './Renderer';

export const NewsListTag = (props) => {
  return (
    <NoticesNewsListTagProvider>
      <NoticesNewsListTagRenderer tags={props.tags} />
    </NoticesNewsListTagProvider>
  );
};
