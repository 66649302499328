import { MessageResponse } from '~/openapi/typescript-axios/version';

export class LocalbridgeApiError extends Error {
  messageResponse: MessageResponse;
  status: number;

  constructor(messageResponse: MessageResponse, status: number, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only availabel on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LocalbridgeApiError);
    }

    this.name = 'LocalbridgeApiError';
    // Error Code
    this.status = status;
    // Custom debugging information
    this.messageResponse = messageResponse;
  }
}
