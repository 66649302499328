import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { GroupModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { useAppState } from './AppStateProvider';

type Props = {
  children: ReactNode;
};

interface ParentGroupValue {
  groupModel?: GroupModel;
}

const ParentGroupContext = React.createContext<ParentGroupValue | null>(null);

export function useParentGroup(): ParentGroupValue {
  const state = useContext(ParentGroupContext);

  if (!state) {
    throw new Error('useParentGroup must be used within ParentGroupProvider');
  }

  return state;
}

export function ParentGroupProvider({ children }: Props) {
  const { params } = useAppState();
  const { getGroup } = useLocalbridgeGroupApi();
  const [ groupModel, setGroupModel ] = useState<GroupModel>();

  const initParentGroup = useCallback(async () => {
    if (!params?.groupId) {
      return;
    }

    const groupModelTmp = await getGroup({groupId: params?.groupId});
    setGroupModel(groupModelTmp?.groupModel);
  }, [params?.groupId, getGroup, setGroupModel]);

  useEffect(() => {
    initParentGroup();
  }, [initParentGroup]);

  const providerValue = {
    groupModel,
  };

  return (
    <ParentGroupContext.Provider value={providerValue}>
      {children}
    </ParentGroupContext.Provider>
  );
}
