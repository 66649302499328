import React from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';

type Props = {};

const CookiePolicyRenderer: React.FC<Props> = () => {
  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeFooter />
    </>
  );
};

export default CookiePolicyRenderer;
