import React from 'react';
import { AdminLeftSidebar } from './components/leftSidebar';

type Props = {};

const AdminRenderer: React.FC<Props> = () => {
  return (
    <>
      <AdminLeftSidebar />
    </>
  );
};

export default AdminRenderer;
