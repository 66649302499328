import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookingResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgeBookingApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeBookingApiProvider';
import { useParentUser } from '~/providers/ParentUserProvider';

type Props = {
  children: ReactNode;
};

interface UserBookingsValue {
  bookingsList: Array<BookingResponsesWithPager>;
  bookingsPageCurrent: number;
  bookingsPageLast: number;
  setBookingsPageCurrent: (bookingsPageCurrent: number) => void;
}

const UserBookingsContext = React.createContext<UserBookingsValue | null>(null);

export function useUserBookings(): UserBookingsValue {
  const state = useContext(UserBookingsContext);

  if (!state) {
    throw new Error('useUserBookings must be used within UserBookingsProvider');
  }

  return state;
}

export function UserBookingsProvider({ children }: Props) {
  const { userId } = useParams<any>();

  const { userModelOrPublic } = useParentUser();

  const { searchBookings } = useLocalbridgeBookingApi();

  const [ bookingsList, setBookingsList ] = useState<Array<BookingResponsesWithPager>>([]);
  const [ bookingsPageCurrent, setBookingsPageCurrent ] = useState<number>(0);
  const [ bookingsPageLast, setBookingsPageLast ] = useState<number>(0);

  const getBookingsList = useCallback(async () => {
    if (userModelOrPublic?.id !== userId) {
      return;
    }

    const bookingsListTmp = await searchBookings({userId});
    const [bookingsWithPager] = bookingsListTmp;

    if (bookingsWithPager?.pager) {
      const { totalCount, size } = bookingsWithPager.pager;
      setBookingsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }
    setBookingsList(bookingsListTmp);
  }, [userModelOrPublic, userId, searchBookings, setBookingsList]);

  useEffect(() => {
    getBookingsList();
  }, [getBookingsList]);

  const providerValue = {
    bookingsList,
    bookingsPageCurrent,
    bookingsPageLast,
    setBookingsPageCurrent,
  };

  return (
    <UserBookingsContext.Provider value={providerValue}>
      {children}
    </UserBookingsContext.Provider>
  );
}
