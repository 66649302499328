import { UploadTask, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '~/constants/firebase';

export const uploadFileWithProgress = (
  storageFilePath: string,
  file: File,
  progressCallback?: (progress: number, uploadTask: UploadTask) => void,
): Promise<string|undefined> => {
  const uploadTask = uploadBytesResumable(
    ref(storage, `${storageFilePath}/${file.name}`),
    file,
    { contentType: file.type },
  );

  return new Promise<string|undefined>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        switch (snapshot.state) {
          case 'canceled':
            console.log('Upload is canceled');
            resolve(undefined);
            break;
        }

        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progressCallback) {
          progressCallback(progress, uploadTask);
        } else {
          console.log('Upload is ' + progress + '% done');
        }
      },
      (error) => reject(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};
