import React from 'react';
import { AdminUsersProvider } from './Provider';
import AdminUsersRenderer from './Renderer';

export const AdminUsers = () => {
  return (
    <AdminUsersProvider>
      <AdminUsersRenderer />
    </AdminUsersProvider>
  );
};
