import React from 'react';
import { TermsOfServiceProvider } from './Provider';
import TermsOfServiceRenderer from './Renderer';

export const TermsOfService = () => {
  return (
    <TermsOfServiceProvider>
      <TermsOfServiceRenderer />
    </TermsOfServiceProvider>
  );
};
