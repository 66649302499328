import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaginationBox } from '~/components/paginationBox';
import { PassportModel } from '~/openapi/typescript-axios/version';
import { useAdminPassports } from './Provider';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { StyledAdminTable } from '../components/StyledAdminTable';
import { ROUTES } from '~/constants/routes';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {};

const AdminPassportsRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    passportsList,
    passportsPageCurrent,
    passportsPageLast,
    setPassportsPageCurrent,
  } = useAdminPassports();

  const passports: Array<PassportModel> = useMemo(() => {
    return passportsList.find((passportsWithPager) => {
      return passportsWithPager.pager.page === passportsPageCurrent;
    })?.data || [];
  }, [passportsPageCurrent, passportsList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <span style={{fontSize: 24}}>ふるさとパスポート一覧</span>

        <span
          className='m-auto fw-bolder p-2'
          hidden={passports.length > 0}
        >
          登録された現場がありません
        </span>

        {passports.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* ふるさとパスポート名 */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  ふるさとパスポート名
                </th>

                {/* 住所 */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  住所
                </th>
              </tr>
              <tr className='border'>
                {/* 代表者名  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  代表者名 
                </th>

                {/* 都道府県 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  都道府県
                </th>

                {/* 市区町村 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  市区町村
                </th>

                {/* 郵便番号 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  郵便番号
                </th>

                {/* ホームページ */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  ホームページ
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 代表者名（ふりがな） */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  代表者名（ふりがな）
                </th>

                {/* 担当者名 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  担当者名
                </th>

                {/* 担当者の電話番号 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  担当者の電話番号
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {passports.map((passport: PassportModel, index: number) => {

                return (
                  <tbody
                    key={passport.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* ふるさとパスポート名 */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {/* {passport.name} */}
                      </th>

                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {/* {`${passport.detailAddress1} ${passport.detailAddress2}`} */}
                      </th>
                    </tr>

                    <tr>
                      {/* 代表者名  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {/* {passport.delegateName} */}
                      </td>

                      {/* 都道府県 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >{passport.id}</td>

                      {/* 市区町村 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >{passport.id}</td>

                      {/* 郵便番号 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {passport.id}
                      </td>

                      {/* ホームページ */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {passport.id}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_PASSPORT_EDIT, {
                          passportId: passport.id,
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 代表者名（ふりがな） */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {passport.id}
                      </td>

                      {/* 担当者名 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {passport.id}
                      </td>

                      {/* 担当者の電話番号 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {passport.id}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        <PaginationBox
          paginationBoxClassNamePrefix='pagination_box_block'
          forcePage={passportsPageCurrent}
          pageCount={passportsPageLast}
          onPageChange={setPassportsPageCurrent}
        />
      </div>
    </div>
  );
};

export default AdminPassportsRenderer;
