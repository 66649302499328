import React from 'react';
import { AdminSignInProvider } from './Provider';
import AdminSignInRenderer from './Renderer';

export const AdminSignIn = () => {
  return (
    <AdminSignInProvider>
      <AdminSignInRenderer />
    </AdminSignInProvider>
  );
};
