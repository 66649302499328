import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { PassportRequestsSearch, PassportResponsesWithPager } from '~/openapi/typescript-axios/version';
import { useLocalbridgePassportApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePassportApiProvider';

type Props = {
  children: ReactNode;
};

interface AdminPassportsValue {
  passportsList: Array<PassportResponsesWithPager>;
  passportsPageCurrent: number;
  passportsPageLast: number;
  setPassportRequestsSearch: (passportRequestsSearch: PassportRequestsSearch) => void;
  setPassportsPageCurrent: (passportsPageCurrent: number) => void;
}

const AdminPassportsContext = React.createContext<AdminPassportsValue | null>(null);

export function useAdminPassports(): AdminPassportsValue {
  const state = useContext(AdminPassportsContext);

  if (!state) {
    throw new Error('useAdminPassports must be used within AdminPassportsProvider');
  }

  return state;
}

export function AdminPassportsProvider({ children }: Props) {
  const { searchPassports } = useLocalbridgePassportApi();

  const firstPassportRequestsSearch: PassportRequestsSearch = {
    size: 10,
  };
  const [ passportRequestsSearch, setPassportRequestsSearch ] = useState<PassportRequestsSearch>(firstPassportRequestsSearch);
  const [ passportRequestsSearchTmp, setPassportRequestsSearchTmp ] = useState<PassportRequestsSearch>(firstPassportRequestsSearch);
  const [ passportsList, setPassportsList ] = useState<Array<PassportResponsesWithPager>>([]);
  const [ passportsPageCurrent, setPassportsPageCurrent ] = useState<number>(0);
  const [ passportsPageLast, setPassportsPageLast ] = useState<number>(0);

  const searchPassportsList = useCallback(async () => {
    if (!passportRequestsSearch) {
      return;
    }

    const passportsListTmp = await searchPassports(passportRequestsSearch);
    const [passportsWithPager] = passportsListTmp;

    if (passportsWithPager?.pager) {
      const { totalCount, size } = passportsWithPager.pager;
      setPassportsPageLast(Math.floor((totalCount - 1) / size) + 1);
    }

    setPassportsList(passportsListTmp);
  }, [passportRequestsSearch, searchPassports, setPassportsList]);

  useEffect(() => {
    searchPassportsList();
  }, [searchPassportsList]);

  const providerValue = {
    passportsList,
    passportRequestsSearchTmp,
    passportsPageCurrent,
    passportsPageLast,
    setPassportRequestsSearch,
    setPassportRequestsSearchTmp,
    setPassportsPageCurrent,
  };

  return (
    <AdminPassportsContext.Provider value={providerValue}>
      {children}
    </AdminPassportsContext.Provider>
  );
}
