import React, {
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { NoticeModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgeNoticeApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeNoticeApiProvider';

type Props = {
  children: ReactNode;
};

interface NoticeEditValue {
  noticeModel?: NoticeModel;
  setNoticeModel: (noticeModel?: NoticeModel) => void;
}

const NoticeEditContext = React.createContext<NoticeEditValue | null>(null);

export function useNoticeEdit(): NoticeEditValue {
  const state = useContext(NoticeEditContext);

  if (!state) {
    throw new Error('useNoticeEdit must be used within NoticeProvider');
  }

  return state;
}

export function NoticeProvider({ children }: Props) {
  const { noticeId } = useParams<any>();

  const { getNotice } = useLocalbridgeNoticeApi();
  const [ noticeModel, setNoticeModel ] = useState<NoticeModel>();

  const initNotice = useCallback(async () => {
    if (!noticeId) {
      return;
    }

    const noticeModelTmp = await getNotice({noticeId});
    setNoticeModel(noticeModelTmp?.noticeModel);
  }, [noticeId, getNotice, setNoticeModel]);

  useEffect(() => {
    initNotice();
  }, [initNotice]);

  const providerValue = {
    noticeModel,
    setNoticeModel,
  };

  return (
    <NoticeEditContext.Provider value={providerValue}>
      {children}
    </NoticeEditContext.Provider>
  );
}
