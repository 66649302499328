import React from 'react';
import { AdminWorkshopEditProvider } from './Provider';
import AdminWorkshopEditRenderer from './Renderer';

export const AdminWorkshopEdit = () => {
  return (
    <AdminWorkshopEditProvider>
      <AdminWorkshopEditRenderer />
    </AdminWorkshopEditProvider>
  );
};
