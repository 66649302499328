import React from 'react';
import LocalbridgeBody from '~/components/localbridgeBody';
import { useParentUser } from '~/providers/ParentUserProvider';
import './index.scss';

type Props = {};

const UserOtherPersonProfileRenderer: React.FC<Props> = () => {
  const { userModelOrPublic } = useParentUser();

  return (
    <>      
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'ohter_person_profile'}>
          <div className={'ohter_person_profile__inner'}>
            <div className={'ohter_person_profile_info'}>
              <img className={'ohter_person_profile_info__img'} src={userModelOrPublic?.icon?.url || ''} />
              <div className={'ohter_person_profile_info__badge'}>
                <p className={'ohter_person_profile_info__text'}>貢献度バッジ保有数</p>
                <p className={'ohter_person_profile_info__num'}>
                  <img src='/assets/img/student/mypage/profile/ico_badge.svg' />
                  30
                </p>
              </div>
            </div>
            <div className={'ohter_person_profile__contents'}>
              <p className={'ohter_person_profile__name'}>タロー</p>
              <p className={'ohter_person_profile__label'}>高校2年生</p>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>自己紹介文</h2>
                <p className={'ohter_person_profile_section__text'}>この文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーです</p>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>興味・関心</h2>
                <p className={'ohter_person_profile_section__text'}>この文章はダミーです</p>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>私の気になる第2のふるさと</h2>
                <p className={'ohter_person_profile_section__text'}>この文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーです</p>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>気になる地方創生カテゴリ</h2>
                <p className={'ohter_person_profile_section__text'}>地域の応援になる</p>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>保有パスポート一覧</h2>
                <div className={'ohter_person_profile_passport'}>
                  <div className={'ohter_person_profile_passport__item'}>
                    <img className={'ohter_person_profile_passport__img'} src='/assets/img/student/mypage/profile/pic_passport02.jpg' />
                    <p className={'ohter_person_profile_passport__text'}>パスポート名が入ります</p>
                  </div>
                  <div className={'ohter_person_profile_passport__item'}>
                    <img className={'ohter_person_profile_passport__img'} src='/assets/img/student/mypage/profile/pic_passport01.jpg' />
                    <p className={'ohter_person_profile_passport__text'}>パスポート名が入ります</p>
                  </div>
                  <div className={'ohter_person_profile_passport__item'}>
                    <img className={'ohter_person_profile_passport__img'} src='/assets/img/student/mypage/profile/pic_passport02.jpg' />
                    <p className={'ohter_person_profile_passport__text'}>パスポート名が入ります</p>
                  </div>
                  <div className={'ohter_person_profile_passport__item'}>
                    <img className={'ohter_person_profile_passport__img'} src='/assets/img/student/mypage/profile/pic_passport01.jpg' />
                    <p className={'ohter_person_profile_passport__text'}>パスポート名が入ります</p>
                  </div>
                </div>
                <div className={'ohter_person_profile_section__btn'}>
                  <a href='#'>さらにみる</a>
                </div>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>活動履歴</h2>
                <ul className={'ohter_person_profile_list'}>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                </ul>

                <div className={'ohter_person_profile_section__btn'}>
                  <a href='#'>さらにみる</a>
                </div>
              </section>
              <section className={'ohter_person_profile_section'}>
                <h2 className={'ohter_person_profile_section__title'}>執筆記事一覧</h2>
                <ul className={'ohter_person_profile_list'}>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={'ohter_person_profile_list__item'}>
                    <a href='#'>
                      <p className={'ohter_person_profile_list__date'}>2023.11.1</p>
                      <p className={'ohter_person_profile_list__text'}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                </ul>

                <div className={'ohter_person_profile_section__btn'}>
                  <a href='#'>さらにみる</a>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className={'ohter_person_profile__btn'}>
          <a href='#'>TOPページに戻る</a>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default UserOtherPersonProfileRenderer;
