
import React, { useContext, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '~/providers/AppStateProvider';

type Props = {
  children: ReactNode;
};

interface GroupValue {

}

const GroupContext = React.createContext<GroupValue | null>(null);

export function useGroup(): GroupValue {
  const state = useContext(GroupContext);

  if (!state) {
    throw new Error('useGroup must be used within GroupProvider');
  }

  return state;
}

export function GroupProvider({ children }: Props) {
  const { params, setParams } = useAppState();
  const { groupId } = useParams<any>();

  useEffect(() => {
    if (params.groupId !== groupId) {
      setParams(Object.assign(JSON.parse(JSON.stringify(params)), { groupId }));
    }
  }, [groupId, params, setParams]);

  const providerValue = {

  };

  return (
    <GroupContext.Provider value={providerValue}>
      {children}
    </GroupContext.Provider>
  );
}
