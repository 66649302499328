import React from 'react';
import { AdminPassportsProvider } from './Provider';
import AdminPassportsRenderer from './Renderer';

export const AdminPassports = () => {
  return (
    <AdminPassportsProvider>
      <AdminPassportsRenderer />
    </AdminPassportsProvider>
  );
};
