import React from 'react';
import { AdminGroupsProvider } from './Provider';
import AdminGroupsRenderer from './Renderer';

export const AdminGroups = () => {
  return (
    <AdminGroupsProvider>
      <AdminGroupsRenderer />
    </AdminGroupsProvider>
  );
};
