import React, { useCallback } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import LocalbridgeBody from '~/components/localbridgeBody';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { useLocalbridgeApi } from '~/providers/LocalbridgeApiProvider';
import InputWithValidation from '~/components/inputWithValidation';
import { QnaMail, QnaMailQuestionType } from '~/openapi/typescript-axios/version';
import './index.scss';
import { useQna } from '../../Provider';
import { QnaState } from '../../enums';

type Props = {};

const QnaForm: React.FC<Props> = () => {
  const { localbridgeQnaValidate } = useLocalbridgeApi();
  const { qnaMail, setQnaMail, setQnaState } = useQna();

  const onChangeQnaMailInput = useCallback((
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
    key: keyof QnaMail,
  ) => {
    if (!qnaMail) {
      return;
    }

    qnaMail[key as string] = event.currentTarget.value;
    setQnaMail(Object.assign({}, qnaMail));
  }, [qnaMail, setQnaMail]);

  const goToConfirmPage = useCallback(async () => {
    try {
      const messageResponses = await localbridgeQnaValidate({ qnaMail, isOnSubmit: true });
      if (messageResponses.validations?.length) {
        throw new LocalbridgeApiError(messageResponses, 412);
      }

      setQnaState(QnaState.QnaConfirm);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        console.log((error as LocalbridgeApiError).messageResponse);
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        throw error;
      }
    }
  }, [qnaMail, localbridgeQnaValidate, setQnaState]);

  return (
    <>
      <LocalbridgeSubHeader title='お問い合わせ' enTitle='Contact' />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'qna_form'}>
          <Form className={'qna_form__form'}>
            <div className={'qna_form__contents'}>
              <p className={'qna_form__note'}>※は必須項目です</p>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='organization'>
                  組織・団体
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='text'
                  validationKey={'groupName'}
                  placeholder='ローカルブリッジ'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'groupName');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='department'>
                  部署名
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='text'
                  validationKey={'teamName'}
                  placeholder='ローカルブリッジ'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'teamName');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='full_name'>
                  氏名<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='text'
                  validationKey={'senderName'}
                  placeholder='やまだ　たろう'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'senderName');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='phone_number'>
                  電話番号<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='text'
                  validationKey={'phoneNumberFrom'}
                  placeholder='000-1234-5678'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'phoneNumberFrom');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='email'>
                  メールアドレス<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='email'
                  validationKey={'emailFrom'}
                  placeholder='abc@sample.com'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'emailFrom');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='email_confirm'>
                  メールアドレス確認<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='email'
                  validationKey={'emailFromConfirm'}
                  placeholder='abc@sample.com'
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'emailFromConfirm');
                  }}
                />
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='inquiry_category'>
                  問合せカテゴリー<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  validationKey={'questionType'}
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  type={'dropdown'}
                  title={
                    <span className=''>
                      {qnaMail?.questionType || '選択してください'}
                    </span>
                  }
                >
                  {Object.values(QnaMailQuestionType).map((questionType) => (
                    <Dropdown.Item
                      key={`questionType-${questionType}`}
                      onClick={() => {
                        qnaMail.questionType = questionType;
                        setQnaMail(Object.assign({}, qnaMail));
                      }}
                    >{questionType}</Dropdown.Item>
                  ))}
                </InputWithValidation>
              </div>
              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='subject'>
                  件名<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='text'
                  validationKey={'title'}
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'title');
                  }}
                />
              </div>

              <div className={'qna_form__item'}>
                <label className={'qna_form__label'} htmlFor='inquiry_details'>
                  お問い合わせ内容<span>※</span>
                </label>
                <InputWithValidation<QnaMail>
                  data={qnaMail}
                  type='textarea'
                  validationKey={'content'}
                  validationClassName='QnaMail'
                  className={'qna_form__input'}
                  onChange={(event) => {
                    onChangeQnaMailInput(event, 'content');
                  }}
                />
              </div>
            </div>
            <input className={'qna_form__submit'} value='確認画面へ' onClick={goToConfirmPage} />
          </Form>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default QnaForm;
