
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface TermsOfServiceValue {

}

const TermsOfServiceContext = React.createContext<TermsOfServiceValue | null>(null);

export function useTermsOfService(): TermsOfServiceValue {
  const state = useContext(TermsOfServiceContext);

  if (!state) {
    throw new Error('useTermsOfService must be used within TermsOfServiceProvider');
  }

  return state;
}

export function TermsOfServiceProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <TermsOfServiceContext.Provider value={providerValue}>
      {children}
    </TermsOfServiceContext.Provider>
  );
}
