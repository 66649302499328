import React from 'react';

type Props = {
};

const PreventAutoComplete: React.FC<Props> = () => {
  return (
    <>
      {/* dummyInput */}
      <input className='position-absolute' type='text' style={{width: 0, height: 0}}/>
      <input className='position-absolute' type='email' style={{width: 0, height: 0}}/>
      <input className='position-absolute' type='password' style={{width: 0, height: 0}}/>
    </>
  );
};

export default PreventAutoComplete;
