import React from 'react';
import { GroupWorkshopsProvider } from './Provider';
import GroupWorkshopsRenderer from './Renderer';

export const GroupWorkshops = () => {
  return (
    <GroupWorkshopsProvider>
      <GroupWorkshopsRenderer />
    </GroupWorkshopsProvider>
  );
};
