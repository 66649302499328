import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LocalbridgeBody from '~/components/localbridgeBody';
import { ROUTES } from '~/constants/routes';
import { showLocalbridgeApiValidationErrorMessage } from '~/providers/LocalbridgeApiProvider/helpers/Validator';
import { LocalbridgeApiError } from '~/providers/LocalbridgeApiProvider/helpers/LocalbridgeApiError';
import { PhotoMeta, UserGender, UserJobDropdown, UserModel, UserModelWithPassword } from '~/openapi/typescript-axios/version';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import showError from '~/helpers/toaster/message/showError';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import InputWithValidation from '~/components/inputWithValidation';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import './index.scss';
import SelectTown from './components/selectTown';
import SelectCategory from './components/selectCategory';
import { useSignUp } from '../../Provider';
import { SignUpState } from '../../enums';
import PreventAutoComplete from '~/components/preventAutoComplete';
import { nanoid } from 'nanoid';
import { getImageWidthHeight } from '~/helpers/getImageWidthHeight';

type Props = {};

const SignUpForm: React.FC<Props> = () => {
  const history = useHistory();

  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const {
    userModelWithPassword,
    fileMapWillBeCreated,
    setUserModelWithPassword,
    doSendEmailVerification,
    setIsCreatingAccount,
    setSignUpState,
    setFileMapWillBeCreated,
  } = useSignUp();
  const { validateUsers } = useLocalbridgeUserApi();

  const [ tmpInterest, setTmpInterest ] = useState<string>();
  const [ isAgreedTermsOfService, setIsAgreedTermsOfService ] = useState<boolean>(false);

  const selectedCity = useMemo(() => {
    return cities.find((city) => city.id === userModelWithPassword?.cityId);
  }, [cities, userModelWithPassword?.cityId]);

  const categoryNames = [
    '観光・伝統文化',
    '関係人口づくり',
    '地域の魅力発信',
    'サスティナブル',
    '子供の教育',
    '家族・家庭',
    'まちづくり',
    '文化・芸術',
  ];

  const currentYear = new Date().getFullYear();
  const yearList = new Array(130)
    .fill(0).map((_, year) => (currentYear - year));
  const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];
  const birthDayDateList = useMemo(() => {
    const [birthDayYear, birthDayMonth] = userModelWithPassword?.birthDay || [];
    if (!birthDayYear || !birthDayMonth) {
      return [];
    }

    return new Array(new Date(birthDayYear, birthDayMonth + 1, 0).getDate())
      .fill(0).map((_, date) => date + 1);
  }, [userModelWithPassword?.birthDay]);

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const goToConfirmPage = useCallback(async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    setIsCreatingAccount(true);

    event.preventDefault();

    try {
      const newPassword = userModelWithPassword.newPassword;
      const passwordConfirm = userModelWithPassword.passwordConfirm;
      userModelWithPassword.emailToken = getSearchTokenMap(userModelWithPassword.email);

      const newUserModelWithPassword = Object.assign({}, userModelWithPassword);
      delete newUserModelWithPassword.newPassword;
      delete newUserModelWithPassword.passwordConfirm;

      const messageResponses = await validateUsers({userRequests: [{
        userModel: newUserModelWithPassword as UserModel,
        newPassword,
        passwordConfirm,
      }]});

      if (messageResponses.validations?.length) {
        throw new LocalbridgeApiError(messageResponses, 412);
      }

      setSignUpState(SignUpState.SignUpConfirm);
    } catch (error) {
      if ((error as Error).name === 'LocalbridgeApiError') {
        showLocalbridgeApiValidationErrorMessage(error as LocalbridgeApiError);
      } else {
        showError({
          title: '会員登録',
          text: (error as Error).message,
        });
        throw error;
      } 
    } finally {
      setIsCreatingAccount(false);
    }
    // eslint-disable-next-line
  }, [
    history,
    userModelWithPassword,
    validateUsers,
    doSendEmailVerification,
    setIsCreatingAccount,
    setSignUpState,
  ]);

  const isStudent = (value: UserJobDropdown) => {
    return UserJobDropdown.StudentUniversityBachelor === value
      || UserJobDropdown.StudentUniversityMaster === value
      || UserJobDropdown.StudentCollegeProfessional === value
      || UserJobDropdown.StudentCollegeJunior === value
      || UserJobDropdown.StudentHighScholl === value;
  };

  const onChangeUserInput = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>
      |React.FocusEvent<HTMLInputElement, Element>,
    key: keyof UserModelWithPassword,
  ) => {
    if (!userModelWithPassword) {
      return;
    }

    if (key === 'interests') {
      if (event.type === 'blur' && event.currentTarget.value) {
        if (!userModelWithPassword.interests) {
          userModelWithPassword.interests = [];
        }

        userModelWithPassword.interests.push(event.currentTarget.value);
        setTmpInterest(undefined);
        userModelWithPassword.interests = [...new Set(userModelWithPassword.interests)];
        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
      } else {
        setTmpInterest(event.currentTarget.value);
      }

      return;
    }

    if (key === 'icon') {
      const [file] = (event.target as HTMLInputElement).files || [];

      if (file) {
        const newPhotoId = nanoid();
        const { width, height } = await getImageWidthHeight(file);

        const photoMeta: PhotoMeta = {
          id: newPhotoId,
          url: '',
          name: file.name,
          size: file.size,
          width,
          height,
        };

        const photoIdBefore = userModelWithPassword[key as string]?.id || '';

        userModelWithPassword[key as string] = photoMeta;
        userModelWithPassword.photos.push(photoMeta);
        userModelWithPassword.photos = userModelWithPassword.photos.filter((photo) => photo.id !== photoIdBefore);
        delete fileMapWillBeCreated[photoIdBefore];
        fileMapWillBeCreated[photoMeta.id] = file;
        setFileMapWillBeCreated(Object.assign({}, fileMapWillBeCreated));
      }
    } else {
      userModelWithPassword[key as string] = event.currentTarget.value;
      if (key === 'job' && !isStudent(userModelWithPassword.job as UserJobDropdown)) {
        delete userModelWithPassword.studentStartAt;
        delete userModelWithPassword.educationalInstitutionName;
        delete userModelWithPassword.department;
      }
    }

    setUserModelWithPassword(Object.assign({}, userModelWithPassword));
  }, [
    userModelWithPassword,
    fileMapWillBeCreated,
    setUserModelWithPassword,
    setTmpInterest,
    setFileMapWillBeCreated,
  ]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    userModelWithPassword?.cityId,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const selectedTown = useMemo(() => {
    return towns.find((town) => town.id === userModelWithPassword?.townId);
  }, [towns, userModelWithPassword?.townId]);

  return (
    <>
      <LocalbridgeBody bgColor={'#fdfaf1'} width={'narrow'}>
        <div className={'sign_up_form'}>
          <h1 className={'sign_up_form__title'}>新規会員登録</h1>
          <p className={'sign_up_form__title_en'}>Sign up</p>
          <form className={'sign_up_form__form'}>
            <PreventAutoComplete />

            <div className={'sign_up_form__contents'}>
              <p className={'sign_up_form__note'}>※は必須項目です</p>
              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  名前<span className={'required'}>※</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'name'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  onChange={(event) => {
                    onChangeUserInput(event, 'name');
                  }}
                />
              </div>

              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  ふりがな<span className={'required'}>※</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'nameFurigana'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  onChange={(event) => {
                    onChangeUserInput(event, 'nameFurigana');
                  }}
                />
              </div>

              {typeof userModelWithPassword.newPassword === 'string' ? (
                <div className={'sign_up_form_item'}>
                  <label className={'sign_up_form_item__label'}>
                    パスワード<span className={'required'}>※</span>
                  </label>
                  <InputWithValidation<UserModelWithPassword>
                    type='password'
                    data={userModelWithPassword}
                    validationKey={'newPassword'}
                    validationClassName='UserModelWithPassword'
                    className='sign_up_form_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'newPassword');
                    }}
                  />
                  <p className={'sign_up_form_item__note'}>8〜16文字で、半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください。</p>
                </div>
              ):(<></>)}

              {typeof userModelWithPassword.passwordConfirm === 'string' ? (
                <div className={'sign_up_form_item'}>
                  <label className={'sign_up_form_item__label'}>
                    確認用パスワード<span className={'required'}>※</span>
                  </label>
                  <InputWithValidation<UserModelWithPassword>
                    type='password'
                    data={userModelWithPassword}
                    validationKey={'passwordConfirm'}
                    validationClassName='UserModelWithPassword'
                    className='sign_up_form_item__input'
                    onChange={(event) => {
                      onChangeUserInput(event, 'passwordConfirm');
                    }}
                  />
                </div>
              ):(<></>)}

              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  ニックネーム<span className={'required'}>※</span>
                  <span className={'note'}>この情報は公開されます</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'nickName'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  onChange={(event) => {
                    onChangeUserInput(event, 'nickName');
                  }}
                />
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-sm'}`}>
                <label className={'sign_up_form_item__label'} htmlFor="gender">
                  性別<span className={'required'}>※</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  data={userModelWithPassword}
                  validationKey={'gender'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input p-0'
                  type={'dropdown'}
                  title={
                    <span className='sign_up_form_item__input border-0 p-0'>
                      {userModelWithPassword?.gender || '選択してください'}
                    </span>
                  }
                >
                  {Object.values(UserGender).map((gender) => (
                    <Dropdown.Item
                      key={`gender-${gender}`}
                      onClick={() => {
                        userModelWithPassword.gender = gender;
                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    >{gender}</Dropdown.Item>
                  ))}
                </InputWithValidation>
              </div>

              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  生年月日
                </label>
                <div className={'d-flex gap-2'}>
                  <InputWithValidation<UserModelWithPassword>
                    data={userModelWithPassword}
                    validationKey={'birthDay'}
                    validationClassName='UserModelWithPassword'
                    className='sign_up_form_item__input p-0'
                    type={'dropdown'}
                    title={
                      <span className='sign_up_form_item__input border-0 p-0'>
                        {userModelWithPassword?.birthDay?.[0] || '年'}
                      </span>
                    }
                  >
                    {yearList.map((year) => (
                      <Dropdown.Item
                        key={`birthDay-year-${year}`}
                        onClick={() => {
                          userModelWithPassword.birthDay = [year];

                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      >{year}</Dropdown.Item>
                    ))}
                  </InputWithValidation>

                  <InputWithValidation<UserModelWithPassword>
                    data={userModelWithPassword}
                    validationKey={'birthDay'}
                    validationClassName='UserModelWithPassword'
                    className='sign_up_form_item__input p-0'
                    type={'dropdown'}
                    disabled={!userModelWithPassword.birthDay?.[0]}
                    title={
                      <span className='sign_up_form_item__input border-0 p-0'>
                        {userModelWithPassword?.birthDay?.[1] || '月'}
                      </span>
                    }
                  >
                    {monthList.map((month) => (
                      <Dropdown.Item
                        key={`birthDay-month-${month}`}
                        onClick={() => {
                          if (!userModelWithPassword?.birthDay) {
                            return;
                          }

                          userModelWithPassword.birthDay[1] = month;
                          userModelWithPassword.birthDay = [...userModelWithPassword.birthDay];
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      >{month}</Dropdown.Item>
                    ))}
                  </InputWithValidation>

                  <InputWithValidation<UserModelWithPassword>
                    data={userModelWithPassword}
                    validationKey={'birthDay'}
                    validationClassName='UserModelWithPassword'
                    className='sign_up_form_item__input p-0'
                    type={'dropdown'}
                    disabled={!userModelWithPassword.birthDay?.[1]}
                    title={
                      <span className='sign_up_form_item__input border-0 p-0'>
                        {userModelWithPassword?.birthDay?.[2] || '日'}
                      </span>
                    }
                  >
                    {birthDayDateList.map((date) => (
                      <Dropdown.Item
                        key={`birthDay-date-${date}`}
                        onClick={() => {
                          if (!userModelWithPassword?.birthDay) {
                            return;
                          }

                          userModelWithPassword.birthDay[2] = date;
                          userModelWithPassword.birthDay = [...userModelWithPassword.birthDay];
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      >{date}</Dropdown.Item>
                    ))}
                  </InputWithValidation>
                </div>
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-xs'}`}>
                <label className={'sign_up_form_item__label'}>
                  郵便番号
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'postNumber'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  placeholder='123-4567'
                  onChange={(event) => {
                    onChangeUserInput(event, 'postNumber');
                  }}
                />
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-xs'}`}>
                <label className={'sign_up_form_item__label'}>
                  都道府県
                </label>
                <InputWithValidation<UserModelWithPassword>
                  data={userModelWithPassword}
                  validationKey={'cityId'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input p-0'
                  type={'dropdown'}
                  title={
                    <span className='sign_up_form_item__input border-0 p-0'>
                      {selectedCity?.cityName || '選択してください'}
                    </span>
                  }
                >
                  <div className={'row m-1'}>
                    <Dropdown.Item
                      className='btn btn-sm p-1 text-center'
                      onClick={() => {
                        userModelWithPassword.cityId = '';
                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    >選択なし</Dropdown.Item>

                    {cities.map((city) => (
                      <Dropdown.Item
                        key={`cities-${city.id}`}
                        className='btn btn-sm p-1 text-center'
                        onClick={() => {
                          const isSameCity = userModelWithPassword.cityId === city.id;
                          if (!isSameCity) {
                            userModelWithPassword.townId = '';
                          }

                          userModelWithPassword.cityId = city.id;
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      >{city.cityName}</Dropdown.Item>
                    ))}
                  </div>
                </InputWithValidation>
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-md'}`}>
                <label className={'sign_up_form_item__label'}>
                  市区町村
                </label>
                <InputWithValidation<UserModelWithPassword>
                  data={userModelWithPassword}
                  validationKey={'townId'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input p-0'
                  type={'dropdown'}
                  disabled={!selectedCity}
                  title={
                    <span className='sign_up_form_item__input border-0 p-0'>
                      {selectedTown?.associationName || '選択してください'}
                    </span>
                  }
                >
                  <div className={'row m-1'}>
                    <Dropdown.Item
                      className='btn btn-sm p-1 text-center'
                      onClick={() => {
                        userModelWithPassword.townId = '';
                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    >選択なし</Dropdown.Item>

                    {towns.map((town) => (
                      <Dropdown.Item
                        key={`towns-${town.id}`}
                        onClick={() => {
                          userModelWithPassword.townId = town.id;
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      >{town.associationName}</Dropdown.Item>
                    ))}
                  </div>
                </InputWithValidation>
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-md'}`}>
                <label className={'sign_up_form_item__label'}>
                  番地
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'detailAddress1'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  placeholder='123-4567'
                  onChange={(event) => {
                    onChangeUserInput(event, 'detailAddress1');
                  }}
                />
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-md'}`}>
                <label className={'sign_up_form_item__label'}>
                  建物名・部屋番号
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='text'
                  data={userModelWithPassword}
                  validationKey={'detailAddress2'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  placeholder='123-4567'
                  onChange={(event) => {
                    onChangeUserInput(event, 'detailAddress2');
                  }}
                />
              </div>

              <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-md'}`}>
                <label className={'sign_up_form_item__label'}>
                  職業<span className={'required'}>※</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  data={userModelWithPassword}
                  validationKey={'job'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input sign_up_form_item--width-md p-0'
                  type={'dropdown'}
                  title={
                    <span className='sign_up_form_item__input border-0 p-0'>
                      {userModelWithPassword?.job || '選択してください'}
                    </span>
                  }
                >
                  {Object.values(UserJobDropdown).map((job) => (
                    <Dropdown.Item
                      key={`userJobs-${job}`}
                      onClick={() => {
                        userModelWithPassword.job = job;
                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    >{job}</Dropdown.Item>
                  ))}
                </InputWithValidation>
              </div>

              {isStudent(userModelWithPassword.job as UserJobDropdown) ? (
                <>
                  <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-sm'}`}>
                    <label className={'sign_up_form_item__label'}>
                      学校名
                    </label>
                    <InputWithValidation<UserModelWithPassword>
                      type='text'
                      data={userModelWithPassword}
                      validationKey={'educationalInstitutionName'}
                      validationClassName='UserModelWithPassword'
                      className='sign_up_form_item__input'
                      placeholder='〇〇大学'
                      onChange={(event) => {
                        onChangeUserInput(event, 'educationalInstitutionName');
                      }}
                    />
                  </div>

                  {userModelWithPassword.job !== UserJobDropdown.StudentHighScholl ? (
                    <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-sm'}`}>
                      <label className={'sign_up_form_item__label'}>
                        学部名
                      </label>
                      <InputWithValidation<UserModelWithPassword>
                        type='text'
                        data={userModelWithPassword}
                        validationKey={'department'}
                        validationClassName='UserModelWithPassword'
                        className='sign_up_form_item__input'
                        placeholder='〇〇学部'
                        onChange={(event) => {
                          onChangeUserInput(event, 'department');
                        }}
                      />
                    </div>
                  ):(<></>)}

                  <div className={`${'sign_up_form_item'} ${'sign_up_form_item--width-sm'}`}>
                    <label className={'sign_up_form_item__label'}>
                      学年<span className={'note'}>この情報は公開されます</span>
                    </label>
                    <InputWithValidation<UserModelWithPassword>
                      data={userModelWithPassword}
                      validationKey={'job'}
                      validationClassName='UserModelWithPassword'
                      className='sign_up_form_item__input sign_up_form_item--width-md p-0'
                      type={'dropdown'}
                      title={
                        <span className='sign_up_form_item__input border-0 p-0'>
                          {userModelWithPassword?.job || '選択してください'}
                        </span>
                      }
                    >
                      {Object.values(UserJobDropdown).map((job) => (
                        <Dropdown.Item
                          key={`userJobs-${job}`}
                          onClick={() => {
                            userModelWithPassword.job = job;
                            setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                          }}
                        >{job}</Dropdown.Item>
                      ))}
                    </InputWithValidation>
                  </div>

                  <div className={'sign_up_form_item'}>
                    <label className={'sign_up_form_item__label'}>
                      入学年月
                    </label>
                    <div className={'d-flex gap-2'}>
                      <InputWithValidation<UserModelWithPassword>
                        data={userModelWithPassword}
                        validationKey={'studentStartAt'}
                        validationClassName='UserModelWithPassword'
                        className='sign_up_form_item__input p-0'
                        type={'dropdown'}
                        title={
                          <span className='sign_up_form_item__input border-0 p-0'>
                            {userModelWithPassword?.studentStartAt?.[0] || '年'}
                          </span>
                        }
                      >
                        {yearList.map((year) => (
                          <Dropdown.Item
                            key={`studentStartAt-year-${year}`}
                            onClick={() => {
                              userModelWithPassword.studentStartAt = [year];

                              setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                            }}
                          >{year}</Dropdown.Item>
                        ))}
                      </InputWithValidation>

                      <InputWithValidation<UserModelWithPassword>
                        data={userModelWithPassword}
                        validationKey={'studentStartAt'}
                        validationClassName='UserModelWithPassword'
                        className='sign_up_form_item__input p-0'
                        type={'dropdown'}
                        disabled={!userModelWithPassword.studentStartAt?.[0]}
                        title={
                          <span className='sign_up_form_item__input border-0 p-0'>
                            {userModelWithPassword?.studentStartAt?.[1] || '月'}
                          </span>
                        }
                      >
                        {monthList.map((month) => (
                          <Dropdown.Item
                            key={`studentStartAt-month-${month}`}
                            onClick={() => {
                              if (!userModelWithPassword?.studentStartAt) {
                                return;
                              }

                              userModelWithPassword.studentStartAt[1] = month;
                              userModelWithPassword.studentStartAt = [...userModelWithPassword.studentStartAt];
                              setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                            }}
                          >{month}</Dropdown.Item>
                        ))}
                      </InputWithValidation>
                    </div>
                  </div>
                </>
              ):(<></>)}

              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  興味・関心<span className={'required'}>※</span>
                  <span className={'note'}>この情報は公開されます</span>
                </label>
                
                <div className='sign_up_form_item__input p-0 border-0'>
                  <InputWithValidation<UserModelWithPassword>
                    type='textArray'
                    data={userModelWithPassword}
                    tmpText={tmpInterest}
                    validationKey={'interests'}
                    validationClassName='UserModelWithPassword'
                    onChange={(event) => {
                      onChangeUserInput(event, 'interests');
                    }}
                    onBlur={(event) => {
                      onChangeUserInput(event, 'interests');
                    }}
                  >
                    <button
                      className='btn btn-outline-secondary p-1'
                      onClick={(event) => {
                        event.preventDefault();
                        if (!tmpInterest) {
                          return;
                        }

                        if (!userModelWithPassword.interests) {
                          userModelWithPassword.interests = [];
                        }

                        userModelWithPassword.interests.push(tmpInterest);
                        setTmpInterest(undefined);
                        userModelWithPassword.interests = [...new Set(userModelWithPassword.interests)];
                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} style={{width: 25}} />
                    </button>
                  </InputWithValidation>

                  <div className={'sign_up_form_choice flex-wrap'}>
                    {(userModelWithPassword.interests || []).map((interest) => (
                      <p key={`interests-${interest}`} className={'sign_up_form_choice__text'}>
                        ＃ {interest}
                        <span onClick={() => {
                          if (!userModelWithPassword.interests) {
                            userModelWithPassword.interests = [];
                          }

                          userModelWithPassword.interests = userModelWithPassword.interests
                            .filter((interestInner) => interestInner !== interest);
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}></span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  私の気になる第2のふるさと<span className={'note'}>この情報は公開されます</span>
                </label>
                <div className={'sign_up_form_item__input d-flex flex-column gap-2 border-0 p-0'}>
                  <SelectTown
                    townId={userModelWithPassword?.localAssociationIds?.[0]}
                    townIndex={0}
                    onChangeTownId={(townId) => {
                      userModelWithPassword.localAssociationIds = [townId];
                      setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                    }}
                  />

                  <SelectTown
                    townId={userModelWithPassword?.localAssociationIds?.[1]}
                    townIndex={1}
                    disabled={!userModelWithPassword?.localAssociationIds?.[0]}
                    onChangeTownId={(townId) => {
                      if (!userModelWithPassword?.localAssociationIds) {
                        return;
                      }

                      userModelWithPassword.localAssociationIds[1] = townId;
                      userModelWithPassword.localAssociationIds = [
                        ...new Set(userModelWithPassword.localAssociationIds)
                      ];
                      setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                    }}
                  />

                  <SelectTown
                    townId={userModelWithPassword?.localAssociationIds?.[2]}
                    townIndex={2}
                    disabled={!userModelWithPassword?.localAssociationIds?.[1]}
                    onChangeTownId={(townId) => {
                      if (!userModelWithPassword?.localAssociationIds) {
                        return;
                      }

                      userModelWithPassword.localAssociationIds[2] = townId;
                      userModelWithPassword.localAssociationIds = [
                        ...new Set(userModelWithPassword.localAssociationIds)
                      ];
                      setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                    }}
                  />
                </div>
              </div>

              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  気になる地方創生カテゴリ
                </label>
                <div className={'sign_up_form_checkbox'}>
                  {categoryNames.map((categoryName) => {
                    const checked = !!userModelWithPassword.localCategorys?.includes(categoryName);
                    return (
                      <SelectCategory
                        key={`categoryNames-${categoryName}`}
                        categoryName={categoryName}
                        checked={checked}
                        onSelectCategory={(selectedCategoryName) => {
                          if (!userModelWithPassword.localCategorys) {
                            userModelWithPassword.localCategorys = [];
                          }
  
                          if (checked) {
                            userModelWithPassword.localCategorys = userModelWithPassword.localCategorys
                              .filter((localCategory) => localCategory !== categoryName);
                          } else {
                            userModelWithPassword.localCategorys = [
                              ...new Set(userModelWithPassword.localCategorys), selectedCategoryName,
                            ];
                          }
  
                          setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                        }}
                      />
                    );
                  })}

                  <div className={'sign_up_form_checkbox__item sign_up_form_checkbox__item'}>
                    <label className={''}>
                      その他
                    </label>
                    <input
                      className={'sign_up_form_item__input sign_up_form__input--checkbox col'}
                      type='text'
                      name='other'
                      value={userModelWithPassword.localCategorys?.filter((localCategory) => {
                        return !categoryNames.includes(localCategory);
                      }) || ''}
                      placeholder='入力してください'
                      onChange={(event) => {
                        userModelWithPassword.localCategorys = userModelWithPassword.localCategorys
                          ?.filter((localCategory) => categoryNames.includes(localCategory));

                        userModelWithPassword.localCategorys = [
                          ...new Set(userModelWithPassword.localCategorys), event.target.value,
                        ].filter(Boolean);

                        setUserModelWithPassword(Object.assign({}, userModelWithPassword));
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className={'sign_up_form_item'}>
                <label className={'sign_up_form_item__label'}>
                  自己紹介文<span className={'note'}>この情報は公開されます</span>
                </label>
                <InputWithValidation<UserModelWithPassword>
                  type='textarea'
                  data={userModelWithPassword}
                  validationKey={'description'}
                  validationClassName='UserModelWithPassword'
                  className='sign_up_form_item__input'
                  placeholder=''
                  onChange={(event) => {
                    onChangeUserInput(event, 'description');
                  }}
                />
              </div>

              <div
                className={'sign_up_form_agree'}
                onClick={() => {
                  setIsAgreedTermsOfService(!isAgreedTermsOfService);
                }}
              >
                <input
                  className={'sign_up_form_agree__btn'}
                  type='checkbox'
                  checked={isAgreedTermsOfService}
                  onChange={() => {}}
                />
                <label className={'sign_up_form_agree__text'}>
                  <a href="" onClick={(event) => {
                    event.preventDefault();
                    window.open(ROUTES.TERMS_OF_SERVICE, '_blank');
                  }}>利用規約</a>
                  <p>に同意する</p>
                </label>
              </div>

              {isAgreedTermsOfService ? (
                <input
                  className={'sign_up_form__submit'}
                  value='確認画面へ'
                  onClick={goToConfirmPage}
                  onChange={() => {}}
                />
              ):(<></>)}
            </div>
          </form>
        </div>
      </LocalbridgeBody>
    </>
  );
};

export default SignUpForm;
