import { doc, setDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostEditor from '~/components/postEditor';
// import PostViewer from '~/components/postViewer';
import { LOCALBRIDGE_PATH, firestore } from '~/constants/firebase';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import { AssociationModel, PostModel, PostType } from '~/openapi/typescript-axios/version';
import { useLocalbridgeAssociationApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeAssociationApiProvider';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import showError from '~/helpers/toaster/message/showError';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import { useAuth } from '~/providers/AuthProvider';


export const TestPostEditor = () => {
  const { cities, searchAssociations } = useLocalbridgeAssociationApi();
  const { thisUser } = useAuth();

  const [ selectedCity, setSelectedCity ] = useState<AssociationModel>();
  const [ selectedTown, setSelectedTown ] = useState<AssociationModel>();

  const now = Number(new Date());
  const [ postModel, setPostModel ] = useState<PostModel>({
    id: nanoid(),
    createdAt: now,
    updatedAt: now,
    photos: [],
    title: '',
    titleToken: {uniGram: [], biGram: [], triGram: []},
    content: '',
    contentToken: {uniGram: [], biGram: [], triGram: []},
    contentData: '',
    cityId: '',
    townId: '',
    postType: PostType.Attractive,
    groupId: nanoid(),
    passportId: 'postEditorTestPassport01',
    userId: 'postEditorTestUser01',
    workshopId: nanoid(),
    tags: [],
    isHidden: false,
    isWaitingApproved: false,
    isApproved: true,
    isInTopCarouselList: true,
    isFinishedWriting: true,
    // userReaction: {},
    workshopStartAt: now,
    workshopEndAt: now,
    mainPhoto: {id: nanoid(), url: '', name: '', width: 0, height: 0, size: 0},
    contentPhotos: [],
  });

  const initTowns = useCallback(async () => {
    if (!selectedCity) {
      return [];
    }

    const townsTmpWithPager = await searchAssociations({
      areOnlyTowns: true, cityName: selectedCity?.cityName
    });
    return townsTmpWithPager.map((townTmp) => townTmp.data).flat();
  }, [selectedCity, searchAssociations]);

  const initTownsResponse = useQuery([
    'initTowns',
    selectedCity,
    searchAssociations,
  ], initTowns);

  const towns = useMemo(() => {
    return initTownsResponse?.data || [];
  }, [initTownsResponse?.data]);

  const handleSubmit = useCallback(async() => {
    if (!selectedCity || !selectedTown) {
      showError({
        title: '記事入力',
        text: '都道府県と市区町村を入力してください',
      });
      return;
    }

    const postRef = [
      LOCALBRIDGE_PATH,
      `posts/${postModel.id}`,
    ].join('/');

    const titleEl = document.getElementById('title') as HTMLInputElement;
    postModel.title = titleEl.value || '';

    const [ radioEl ] = document.querySelectorAll('[name="flexRadioDefault"][checked]');
    postModel.postType = (radioEl as HTMLInputElement).value as PostType;
    postModel.cityId = selectedCity.id;
    postModel.townId = selectedTown.id;
    postModel.userId = thisUser?.id || '';
    postModel.contentToken = getSearchTokenMap(postModel.content);

    await setDoc(doc(firestore, postRef), postModel);

    showSuccess({
      title: '記事入力',
      text: '登録に成功しました',
    });
  }, [thisUser, postModel, selectedCity, selectedTown]);

  const addTag = useCallback((newTag: string) => {
    if (!newTag) {
      return;
    }

    postModel.tags.push(newTag);
    postModel.tags = [...new Set(postModel.tags)];
    setPostModel(Object.assign({}, postModel));
  }, [postModel]);

  return (
    <div className='mt-2 container d-flex flex-column gap-2'>
      <div className='d-flex'>
        <span style={{width: 120}} className='my-auto'>タイトル</span>
        <input type='text' className='form-control col' id='title' />
      </div>

      <div className='d-flex flex-row gap-2'>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='radio'
            name='flexRadioDefault'
            id='flexRadioDefault1'
            value={PostType.Attractive}
            onChange={() => {}}
          />
          <label className='form-check-label' htmlFor='flexRadioDefault1'>
            {PostType.Attractive}
          </label>
        </div>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='radio'
            name='flexRadioDefault'
            id='flexRadioDefault2'
            value={PostType.Experience}
            onChange={() => {}}
            checked
          />
          <label className='form-check-label' htmlFor='flexRadioDefault2'>
            {PostType.Experience}
          </label>
        </div>
      </div>

      {/* {postModel.contentData ? <PostViewer postModel={postModel} /> : null} */}
      <PostEditor
        postModel={postModel}
        setPostModel={setPostModel}
      />

      <div className='d-flex'>
        <span className='my-auto'>アップロードされた画像</span>
      </div>

      <div className='d-flex flex-wrap'>
        {postModel.contentPhotos.map((contentPhoto) => (
          <img
            src={contentPhoto.url}
            alt=''
            style={{
              width: 100,
              height: 100,
              border: `4px solid ${postModel.mainPhoto.id = contentPhoto.id ? 'skyblue' : 'gray'}`
            }}
            key={contentPhoto.id}
            onClick={() => {
              postModel.mainPhoto = contentPhoto;
              setPostModel(Object.assign({}, postModel));
            }}
          />
        ))}
      </div>

      <div className='d-flex flex-row'>
        <span style={{width: 120}} className='my-auto'>都道府県</span>
        <DropdownButton
          id='dropdown-basic-button'
          title={selectedCity?.cityName || ''}
          size='sm'
          className='col p-0'
        >
          {cities.map((city) => (
            <Dropdown.Item
              key={city.id}
              size='sm'
              style={{fontSize: 12}}
              onClick={() => setSelectedCity(city)}
            >{city.cityName}</Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <div className='d-flex flex-row'>
        <span style={{width: 120}} className='my-auto'>市区町村</span>
        <DropdownButton
          id='dropdown-basic-button'
          title={selectedTown?.associationName || ''}
          className='col p-0'
          size='sm'
          disabled={!selectedCity}
        >
          {towns.map((town) => (
            <Dropdown.Item
              key={town.id}
              size='sm'
              style={{fontSize: 12}}
              onClick={() => setSelectedTown(town)}
            >{town.associationName}</Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <div className='d-flex'>
        <span style={{width: 120}} className='my-auto'>タグ</span>
        <input className='form-control' type='text' id='newTag' onBlur={() => {}} />
        <FontAwesomeIcon
          icon={faCirclePlus}
          className='ms-1 my-auto'
          onClick={() => {
            const newTag = (document.getElementById('newTag') as HTMLInputElement).value;
            (document.getElementById('newTag') as HTMLInputElement).value = '';
            addTag(newTag);
          }}
        />
      </div>

      <div className='d-flex flex-wrap'>
        {postModel.tags.map((tag) => (
          <div key={`tag-${tag}`}>
            <span>{tag}</span>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className='ms-1'
              onClick={() => {
                postModel.tags = postModel.tags.filter((tagInner) => tagInner !== tag);
                setPostModel(Object.assign({}, postModel));
              }}
            />
          </div>
        ))}
      </div>

      <div className='d-flex'>
        <button className='btn btn-primary mx-auto' onClick={handleSubmit}>登録</button>
      </div>
    </div>
  );
};
