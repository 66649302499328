import React, { useMemo } from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { GroupLeftSidebar } from '../components/leftSidebar';
import { GroupContainer } from '../components/continer';
import GroupHeader from '../components/header';
import moment from 'moment';
import { PaginationBox } from '~/components/paginationBox';
import { WorkshopModel } from '~/openapi/typescript-axios/version';
import { useGroupWorkshops } from './Provider';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import './index.scss';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';

type Props = {};

const GroupWorkshopsRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const {
    workshopsList,
    workshopsPageCurrent,
    workshopsPageLast,
    setWorkshopsPageCurrent,
  } = useGroupWorkshops();

  const workshops: Array<WorkshopModel> = useMemo(() => {
    return workshopsList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === workshopsPageCurrent;
    })?.data || [];
  }, [workshopsPageCurrent, workshopsList]);

  return (
    <>
      <LocalbridgeNavbar />

      <div className='d-flex flex-row' style={{backgroundColor: '#fdfaf1'}}>
        <GroupLeftSidebar />

        <GroupContainer>
          <GroupHeader title='イベント一覧' titleEn='Events list' />
          <div className={'group_workshops'}>
            <div className={'group_workshops__inner'}>
              <div className={'group_workshops__btn'}>
                <a href="#">イベント新規追加</a>
              </div>
              <ul className={'group_workshops__contents'}>
                <li className={'group_workshops__head'}>
                  <p className={'group_workshops__text'}>＃</p>
                  <p className={'group_workshops__text'}>イベント名</p>
                  <p className={'group_workshops__text'}>掲載状況</p>
                  <p className={'group_workshops__text'}>登録日</p>
                </li>
                {workshops.map((workshop, index) => (
                  <li key={`workshop:${workshop.id}`} className={'group_workshops__item'}>
                    <a onClick={() => history.push(generatePath(ROUTES.USER_BOOKING, {
                      groupId: workshop.groupId,
                      workshopId: workshop.id,
                    }))}>
                      <p className={`${'group_workshops__text'} ${'group_workshops__text--gray'}`}>{index + 1}</p>
                      <p className={`${'group_workshops__text'} ${'group_workshops__text--large'}`}>{workshop.title}</p>
                      <p className={'group_workshops__text'}>
                        {workshop.isWaitingApproved ? '承認待ち' :
                          workshop.isApproved ? '掲載中' : '非承認'
                        }
                      </p>
                      <p className={'group_workshops__text'}>
                        {moment(workshop.createdAt).format('YYYY.MM.DD')}
                      </p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={'group_workshops__pagination'}>
              {workshopsList.length ? (
                <PaginationBox
                  forcePage={workshopsPageCurrent}
                  pageCount={workshopsPageLast}
                  onPageChange={setWorkshopsPageCurrent}
                />
              ):(<></>)}
            </div>
          </div>
        </GroupContainer>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default GroupWorkshopsRenderer;
