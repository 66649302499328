import React from 'react';
import { AboutPostProvider } from './Provider';
import AboutPostRenderer from './Renderer';

export const AboutPost = () => {
  return (
    <AboutPostProvider>
      <AboutPostRenderer />
    </AboutPostProvider>
  );
};
