import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import PostCard from '~/components/postCard';
import WorkshopCard from '~/components/workshopCard';
import { useHome } from './Provider';
import { ROUTES } from '~/constants/routes';
import { PostType } from '~/openapi/typescript-axios/version';
import {
  BannerMaximum,
  AttractivePostMaximum,
  ExperiencePostMaximum,
  NoticeMaximum,
  WorkshopMaximum,
} from './constants';
import './index.scss';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {};

const HomeRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const swiperPaginationRef = useRef<HTMLDivElement>(null);

  const {
    attractivePosts,
    experiencePosts,
    experiencePostUsers,
    experiencePostGroups,
    notices,
    workshops,
    banners,
  } = useHome();

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const targetElement = document.querySelector('.index_page__contact');
    const targetPosition = targetElement ? targetElement.getBoundingClientRect().top + scrollY : 0;

    if (0 < targetPosition - window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    // スクロールイベントのリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされるときにリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <LocalbridgeNavbar />

      <div className={'index_page'}>
        <div className={`${'index_page__contact'} ${isVisible ? `${'visible'}` : ''}`}>
          <a onClick={() => history.push(ROUTES.QNA)}>
            <picture>
              <source media='(max-width: 769px)' srcSet='/assets/img/top/pic_contact_sp.svg' />
              <img src='/assets/img/top/pic_contact.svg' alt='' />
            </picture>
          </a>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1.4}
          loop
          centeredSlides
          pagination={{
            el: swiperPaginationRef.current, // ページネーション要素のセレクタ
            clickable: true, // クリック可能にする
          }}
        >
          <SwiperSlide className='swiper-slide'>
            <img src='/assets/img/top/pic_slide01.png' alt='Slide 1' style={{aspectRatio: '812 / 451'}}/>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <img src='/assets/img/top/pic_slide01.png' alt='Slide 2' style={{aspectRatio: '812 / 451'}}/>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <img src='/assets/img/top/pic_slide01.png' alt='Slide 3' style={{aspectRatio: '812 / 451'}}/>
          </SwiperSlide>
        </Swiper>
        <div className={'index_page__head'}>
          <div ref={swiperPaginationRef} className={`swiper-pagination ${'index_page__pagination'}`}></div>
          <section className={'notice'}>
            <div className={'notice__head'}>
              <h2 className={'notice__title'}>お知らせ</h2>
              <p className={'notice__title_en'}>News</p>
              <div className={'notice__btn'}>
                <a href={ROUTES.ADMIN_NOTICES}>お知らせの一覧へ</a>
              </div>
            </div>
            <ul className={'notice__list'}>
              {notices.slice(0, NoticeMaximum).map((notice) => (
                <li className={'notice__item'} key={notice.id}>
                  <a onClick={() => history.push(generatePath(ROUTES.NOTICE, {nooticeId: notice.id}))}>
                    <p className={'notice__date'}>{notice.createdAt}</p>
                    <p className={'notice__text'}>
                      {notice.content.split('\n').map((splitedText, index) => (
                        <React.Fragment key={`notice:${notice.id}-content:${index}`}>
                          {splitedText}
                          {(index !== notice.content.split('\n').length - 1) ? (<br />) : (<></>)}
                        </React.Fragment>
                      ))}
                    </p>
                  </a>
                </li>
              ))}
            </ul>
          </section>
          <section className={'about'}>
            <h2 className={'about__title'}>はじめての方へ</h2>
            <p className={'about__title_en'}>About</p>
            <p className={'about__lead'}>
              この文章はダミーです文字の大きさや書体のサンプルです。文字の大きさや書体のサンプルです。
              <br />
              この文章はダミーです文字の大きさや書体のサンプルです。文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさ や書体のサンプルです。文字の大きさや書体のサンプルです。
            </p>
            <picture>
              <source media='(max-width: 769px)' srcSet='/assets/img/top/pic_about01_sp.png' />
              <img className={'about__caption'} src='/assets/img/top/pic_about01.png' alt='' />
            </picture>
            <div className={'about__btn'}>
              <a href='#'>ローカルブリッジとは</a>
            </div>
          </section>
        </div>
        <section className={'event'}>
          <div className={'event__inner'}>
            <div className={'event__head'}>
              <img className={'event__img'} src='/assets/img/top/ico_event01.svg' alt='Event' />
              <div>
                <h2 className={'event__title'}>イベント</h2>
                <p className={'event__title_en'}>Events</p>
              </div>
              <p className={'event__lead'}>
                これはタイトルのダミーです。見出しのダミーテキストイメージです。
                <br />
                この文章はダミーです。これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。
              </p>
            </div>
            <div className={'event__list'}>
              {workshops.slice(0, WorkshopMaximum).map((workshop, index) => (
                <WorkshopCard key={index} workshopModel={workshop} />
              ))}
            </div>
            <div className={'event__btn'}>
              <a href='/event'>イベント一覧へ</a>
            </div>
          </div>
        </section>
        <div className={'post'}>
          <div className={'post__inner'}>
            <section className={'post_experience'}>
              <div className={'post_experience__head'}>
                <img className={'post_experience__img'} src='/assets/img/top/ico_experience01.svg' alt='Experience' />
                <div>
                  <h2 className={'post_experience__title'}>イベント体験記事</h2>
                  <p className={'post_experience__title_en'}>Event experience posts</p>
                </div>
                {/* <a className={'post_experience__link'}>入稿済イベント体験記事一覧を見る</a> */}
              </div>
              <div className={'post_experience__list'}>
                {experiencePosts.slice(0, ExperiencePostMaximum).map((experiencePost) => (
                  <PostCard
                    key={experiencePost.id}
                    postModel={experiencePost}
                    userPublic={experiencePostUsers.find((user) => user.id === experiencePost.userId)}
                    groupModel={experiencePostGroups.find((group) => group.id === experiencePost.groupId)}
                  />
                ))}
              </div>
              <div className={'post_experience__btn'}>
              <a
                onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Experience}`)}
              >イベント体験記事一覧へ</a>
              </div>
            </section>
            <section className={'post_attractive'}>
              <div className={'post_attractive__head'}>
                <div className={'post_attractive__box'}>
                  <img className={'post_attractive__img'} src='/assets/img/top/ico_attractive01.svg' alt='Attractive' />
                  <div>
                    <h2 className={'post_attractive__title'}>魅力発見記事</h2>
                    <p className={'post_attractive__title_en'}>Attractive discovery posts</p>
                  </div>
                </div>
                {/* <a className={'post_attractive__link'}>入稿済魅力発見記事一覧を見る</a> */}
              </div>
              <div className={'post_attractive__list'}>
                {attractivePosts.slice(0, AttractivePostMaximum).map((post, index) => (
                  <div className={'post_attractive_item'} key={index}>
                    <img className={'post_attractive_item__thumbnail'} src={post.mainPhoto.url} alt='Thumbnail' />
                    <div className={'post_attractive_item__body'}>
                      <p className={'post_attractive_item__text'}>
                        {post.content.split('\n').map((splitedText, index) => (
                          <React.Fragment key={`post:${post.id}-content:${index}`}>
                            {splitedText}
                            {(index !== post.content.split('\n').length - 1) ? (<br />) : (<></>)}
                          </React.Fragment>
                        ))}
                      </p>
                      <p className={'post_attractive_item__date'}>
                        {moment(post.updatedAt).format('YYYY.MM.DD')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={'post_attractive__btn'}>
              <a
                onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}
              >魅力発見記事一覧を見る</a>
              </div>
            </section>
          </div>
        </div>
        <section className={'index_page__community'}>
          <div className={'index_page__community__inner'}>
            <div className={'index_page__community__head'}>
              {banners.slice(0, BannerMaximum).map((banner) => (
                <div
                  key={banner.id}
                  className={'community__item'}
                  onClick={() => {
                    if (banner.link) {
                      location.href = banner.link;
                    }
                  }}
                >
                  <p className={'community__lead'}>
                    バナータイトルが入ります
                    <br />
                    バナータイトルが入ります
                  </p>
                  <img className={'community__img'} src={banner.mainPhoto.url} alt='Community' />
                </div>
              ))}
            </div>
            <h2 className={'index_page__community__title'}>Local Bridgeコミュニティ</h2>
            <p className={'index_page__community__title_en'}>Local Bridge Community</p>
            <div className={'index_page__community__btn'}>
              <a href='#'>コミュニティサイトへ行く！</a>
              <img className={'index_page__community__catch'} src='/assets/img/top/pic_community05.png' alt='Community' />
            </div>
          </div>
        </section>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default HomeRenderer;
