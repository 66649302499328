import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { PassportModel } from '~/openapi/typescript-axios/version';
import { useLocalbridgePassportApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePassportApiProvider';
import { useAppState } from './AppStateProvider';

type Props = {
  children: ReactNode;
};

interface ParentPassportValue {
  passportModel?: PassportModel;
}

const ParentPassportContext = React.createContext<ParentPassportValue | null>(null);

export function useParentPassport(): ParentPassportValue {
  const state = useContext(ParentPassportContext);

  if (!state) {
    throw new Error('useParentPassport must be used within ParentPassportProvider');
  }

  return state;
}

export function ParentPassportProvider({ children }: Props) {
  const { params } = useAppState();
  const { getPassport } = useLocalbridgePassportApi();
  const [ passportModel, setPassportModel ] = useState<PassportModel>();

  const initParentPassport = useCallback(async () => {
    if (!params?.passportId) {
      return;
    }

    const passportModelTmp = await getPassport({passportId: params?.passportId});
    setPassportModel(passportModelTmp?.passportModel);
  }, [params?.passportId, getPassport, setPassportModel]);

  useEffect(() => {
    initParentPassport();
  }, [initParentPassport]);

  const providerValue = {
    passportModel,
  };

  return (
    <ParentPassportContext.Provider value={providerValue}>
      {children}
    </ParentPassportContext.Provider>
  );
}
