import React from 'react';
import './index.scss';
import { WorkshopModel } from '~/openapi/typescript-axios/version';
import moment from 'moment';

type Props = {
  workshopModel: WorkshopModel;
};

const WorkshopCard: React.FC<Props> = (({workshopModel})=> {
  return (
    <div className={'workshop_card'}>
      <div className='image-container'>
        <img src={workshopModel.mainPhoto.url} className={'workshop_card__img'} />
      </div>

      <div className={'workshop_card__contents'}>
        <p className={'workshop_card__date'}>開催日 : {moment(workshopModel.workshopStartAt).format('YYYY.MM.DD')}</p>
        <p className={'workshop_card__label'}>{workshopModel.groupId}</p>
        <p className={'workshop_card__text'}>{workshopModel.detail}</p>
        <div className={'workshop_card__foot'}>
          <p className={'workshop_card__capacity'}>定員：{workshopModel.bookingMax}名</p>
          <p className={'workshop_card__period'}>
            申込期間：
            {moment(workshopModel.bookingStartAt).format('YYYY.MM.DD')}
            〜
            {moment(workshopModel.bookingEndAt).format('YYYY.MM.DD')}
          </p>
        </div>
      </div>
    </div>
  );
});

export default WorkshopCard;
