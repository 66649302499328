import React from 'react';
import { ROUTES } from '~/constants/routes';
import { PostType } from '~/openapi/typescript-axios/version';
import './index.scss';
import { useHistory } from 'react-router-dom';

type Props = {};

const AdminLeftSidebarRenderer: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <div className={'sidemenu_block'}>
      <a onClick={() => history.push(ROUTES.HOME)} className={'sidemenu_block__logo'}>
        <picture>
          <source media='(max-width: 769px)' srcSet='/assets/img/common/logo_subpage.png' />
          <img src='/assets/img/common/logo_subpage.png' alt='' />
        </picture>
      </a>
      <ul className={'sidemenu_block__list'}>
        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.ADMIN_GROUPS)}>
            <i className='bi bi-building'></i>組織・団体管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.ADMIN_USERS)}>
            <i className='bi bi-person-fill'></i>ユーザー管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.ADMIN_PASSPORTS)}>
            <i className='bi bi-card-heading'></i>ふるさとパスポート
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.ADMIN_WORKSHOPS)}>
            <i className='bi bi-flag'></i>イベント管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(`${ROUTES.ADMIN_POSTS}?postType=${PostType.Experience}`)}>
            <i className='bi bi-geo-alt'></i>イベント体験記事管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(`${ROUTES.ADMIN_POSTS}?postType=${PostType.Attractive}`)}>
            <i className='bi bi-bookmark-star'></i>魅力発見記事管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.ADMIN_NOTICES)}>
            <i className='bi bi-bell'></i>お知らせ管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
            <a onClick={() => history.push(ROUTES.ADMIN_BANNERS)}>
            <i className='bi bi-images'></i>広告バナー管理
          </a>
        </li>

        <li role='button' className={'sidemenu_block__item'}>
          <a onClick={() => history.push(ROUTES.SIGN_OUT)}>
            <i className="bi bi-box-arrow-left"></i>ログアウト
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AdminLeftSidebarRenderer;
