import React from 'react';
import { AdminUserEditProvider } from './Provider';
import AdminUserEditRenderer from './Renderer';

export const AdminUserEdit = () => {
  return (
    <AdminUserEditProvider>
      <AdminUserEditRenderer />
    </AdminUserEditProvider>
  );
};
