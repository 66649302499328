
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface GroupLeftSidebarValue {

}

const GroupLeftSidebarContext = React.createContext<GroupLeftSidebarValue | null>(null);

export function useGroupLeftSidebar(): GroupLeftSidebarValue {
  const state = useContext(GroupLeftSidebarContext);

  if (!state) {
    throw new Error('useGroupLeftSidebar must be used within GroupLeftSidebarProvider');
  }

  return state;
}

export function GroupLeftSidebarProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <GroupLeftSidebarContext.Provider value={providerValue}>
      {children}
    </GroupLeftSidebarContext.Provider>
  );
}
