import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useAuth } from '~/providers/AuthProvider';
import { ROUTES } from '~/constants/routes';
import { PostType } from '~/openapi/typescript-axios/version';
import { generatePath, useHistory } from 'react-router-dom';

type Props = {};

const LocalbridgeNavbarRenderer: React.FC<Props> = () => {
  const history = useHistory();
  const { thisUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleHamburger = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    if (isOpen) {
      menuRef.current.style.display = 'block';
      setTimeout(() => {
        if (!menuRef.current) {
          return;
        }

        document.body.style.overflow = 'hidden';
        menuRef.current.style.opacity = '1';
      }, 50); // 50ms delay for the browser to recognize the display change
    } else {
      document.body.style.overflow = 'auto';
      menuRef.current.style.opacity = '0';
      setTimeout(() => {
        if (!menuRef.current) {
          return;
        }

        menuRef.current.style.display = 'none';
      }, 300); // 300ms is the duration of the transition
    }
  }, [isOpen]);

  return (
    <div className={'localbridge_navbar'}>
      <button className={'localbridge_navbar__logo'}>
        <a onClick={() => history.push(ROUTES.HOME)}>
          <img src='/assets/img/common/logo.png' />
        </a>
      </button>

      <div className={'localbridge_navbar__menu'}>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header01.svg' />
          <a onClick={() => history.push(ROUTES.HOME)}>ホーム</a>
        </button>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header02.svg' />
          <a onClick={() => history.push(ROUTES.STARTING_GUIDE)}>はじめての方へ</a>
        </button>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header03.svg' />
          <a href='#'>コミュニティ</a>
        </button>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header04.svg' />
          <a onClick={() => history.push(ROUTES.WORKSHOPS)}>イベント情報</a>
        </button>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header05.svg' />
          <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Experience}`)}>体験記事を探す</a>
        </button>
        <button className={'localbridge_navbar__item'}>
          <img className={'localbridge_navbar__ico'} src='/assets/img/common/ico_header06.svg' />
          <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>まちの魅力発見</a>
        </button>
      </div>

      {thisUser ? (
        <button
          className={'localbridge_navbar__user'}
          onClick={() => history.push(generatePath(ROUTES.USER, {userId: thisUser.id}))}
        >
          <img src='/assets/img/common/ico_login.svg' />
        </button>
      ) : (
        <button className={'localbridge_navbar__buttons'}>
          <a
            onClick={() => history.push(ROUTES.SIGN_IN)}
            className={'localbridge_navbar__button localbridge_navbar__button--login'}
          >
            ログイン
          </a>
          <a
            onClick={() => history.push(ROUTES.SIGN_UP)}
            className={'localbridge_navbar__button localbridge_navbar__button--register'}
          >
            新規登録
          </a>
        </button>
      )}

      <div
        className={`localbridge_navbar__hamburger is-sp ${isOpen ? 'is-open' : ''}`}
        onClick={toggleHamburger}
      >
        <span></span>
        <span></span>
      </div>
      <div
        ref={menuRef}
        className={'localbridge_navbar_menu'}
        style={{ opacity: '0', transition: 'opacity 0.3s ease-in-out' }}
      >
        <div className={'localbridge_navbar_menu__list'}>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.HOME)}>
              <img
                className={'localbridge_navbar_menu__ico'}
                src='/assets/img/common/ico_header01.svg'
              />
              ホーム
            </a>
          </button>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.STARTING_GUIDE)}>
              <img
                className={'localbridge_navbar_menu__ico'}
                src='/assets/img/common/ico_header02.svg'
              />
              はじめての方へ
            </a>
          </button>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.ABOUT_COMMUNITY)}>
              <img className={'localbridge_navbar_menu__ico'} src='/assets/img/common/ico_header03.svg' />
              コミュニティ
            </a>
          </button>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.WORKSHOPS)}>
              <img className={'localbridge_navbar_menu__ico'} src='/assets/img/common/ico_header04.svg' />
              イベント情報
            </a>
          </button>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Experience}`)}>
              <img className={'localbridge_navbar_menu__ico'} src='/assets/img/common/ico_header05.svg' />
              体験記事を探す
            </a>
          </button>
          <button className={'localbridge_navbar_menu__item'}>
            <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>
              <img className={'localbridge_navbar_menu__ico'} src='/assets/img/common/ico_header06.svg' />
              まちの魅力発見
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocalbridgeNavbarRenderer;
