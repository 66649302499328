import React from 'react';
import { useSignUp } from './Provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { SignUpState } from './enums';
import SignUpForm from './components/signUpForm';
import SignUpConfirm from './components/signUpConfirm';
import SignUpSubmitted from './components/signUpSubmitted';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';

type Props = {};

const SignUpRenderer: React.FC<Props> = () => {
  const { signUpState, isCreatingAccount} = useSignUp();

  return (
    <>
      {isCreatingAccount ? (
        <div
          className='position-absolute top-0 d-flex'
          style={{width: '100%', height: '100%', zIndex: 1, backgroundColor: 'rgba(0,0,0,0.3)'}}
        >
          <div className='m-auto'>
            <FontAwesomeIcon spin icon={faSync} className='fs-1'/>
          </div>
        </div>
      ):(<></>)}

      <LocalbridgeNavbar />

      {signUpState === SignUpState.SignUpForm ? (<SignUpForm />):(<></>)}
      {signUpState === SignUpState.SignUpConfirm ? (<SignUpConfirm />):(<></>)}
      {signUpState === SignUpState.SignUpSubmitted ? (<SignUpSubmitted />):(<></>)}

      <LocalbridgeFooter />
    </>
  );
};

export default SignUpRenderer;
