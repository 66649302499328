import React, { useMemo } from 'react';
import './index.scss';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { PaginationBox } from '~/components/paginationBox';
import LocalbridgeBody from '~/components/localbridgeBody';
import LocalbridgeSubHeader from '~/components/localbridgeSubHeader';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import { useNotices } from './Provider';
import { NewsItem } from './components/newsItem';
import { NewsListTag } from './components/newsListTag';
import { NoticeModel } from '~/openapi/typescript-axios/version';

type Props = {};

const NoticesRenderer: React.FC<Props> = () => {
  const {
    noticesList,
    noticesPageCurrent,
    noticesPageLast,
    setNoticesPageCurrent,
  } = useNotices();

  const notices: Array<NoticeModel> = useMemo(() => {
    return noticesList.find((groupsWithPager) => {
      return groupsWithPager.pager.page === noticesPageCurrent;
    })?.data || [];
  }, [noticesPageCurrent, noticesList]);
  const uniqueTags = Array.from(new Set(notices.flatMap((notice) => notice.tags))).filter((tag) => !!tag);

  return (
    <>
      <LocalbridgeNavbar />

      <LocalbridgeSubHeader title="お知らせ一覧" enTitle="News" />
      <LocalbridgeBody bgColor={'#fdfaf1'}>
        <div className={'news_list'}>
          <div className={'news_list__contents'}>
            {notices.map((noticeModel) => {
              return <NewsItem key={noticeModel.id} noticeModel={noticeModel} />;
            })}
          </div>

          <NewsListTag tags={uniqueTags} />
        </div>
        <div className={'news_list__pagination'}>
          <PaginationBox
            forcePage={noticesPageCurrent}
            pageCount={noticesPageLast}
            onPageChange={setNoticesPageCurrent}
          />
        </div>
      </LocalbridgeBody>

      <LocalbridgeFooter />
    </>
  );
};

export default NoticesRenderer;
