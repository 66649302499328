import React, { useContext, ReactNode, useCallback } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  QnaMail,
  testEmptyInput,
  testStringWrongEmailFormat,
  testStringMax1000,
  testStringMax80,
  testUnMatchedEmail,
  LocalbridgeQnaSendMailRequest,
  LocalbridgeQnaSendMailParams,
  MessageResponse,
} from '~/openapi/typescript-axios/version';
import { LocalbridgeApiError } from './helpers/LocalbridgeApiError';
import { Validator } from './helpers/Validator';
import { LocalbridgeUserApiProvider } from './LocalbridgeUserApiProvider';
import { LocalbridgeGroupApiProvider } from './LocalbridgeGroupApiProvider';
import { LocalbridgeWorkshopApiProvider } from './LocalbridgeWorkshopApiProvider';
import { LocalbridgePassportApiProvider } from './LocalbridgePassportApiProvider';
import { LocalbridgePostApiProvider } from './LocalbridgePostApiProvider';
import { LocalbridgeBookingApiProvider } from './LocalbridgeBookingApiProvider';
import { LocalbridgeAssociationApiProvider } from './LocalbridgeAssociationApiProvider';
import { LocalbridgeNoticeApiProvider } from './LocalbridgeNoticeApiProvider';
import { LocalbridgeBannerApiProvider } from './LocalbridgeBannerApiProvider';
import { functionsRequestPost } from './helpers/functionsRequestPost';

type Props = {
  children?: ReactNode;
};

interface LocalbridgeApiValue {
  localbridgeQnaValidate: (qnaSendMailParams: LocalbridgeQnaSendMailParams) => Promise<MessageResponse>
  localbridgeQnaSendMail: (
    localbridgeQnaSendMailRequest: LocalbridgeQnaSendMailRequest
  ) => Promise<void>;
}

const LocalbridgeApiContext = React.createContext<LocalbridgeApiValue | null>(null);

export function useLocalbridgeApi(): LocalbridgeApiValue {
  const state = useContext(LocalbridgeApiContext);

  if (!state) {
    throw new Error('useLocalbridgeApi must be used within LocalbridgeApiProvider');
  }

  return state;
}

export function LocalbridgeApiProvider({ children }: Props) {
  const localbridgeQnaValidate = useCallback(async (qnaSendMailParams: LocalbridgeQnaSendMailParams) => {
    const qnaMailValidator = new Validator<QnaMail>('QnaMail', {
      questionType: [
        async (qnaMail) => testEmptyInput(qnaMail.questionType),
        async (qnaMail) => testStringMax80(qnaMail.questionType),
      ],
      groupName: [
        async (qnaMail) => testStringMax80(qnaMail.groupName),
      ],
      teamName: [
        async (qnaMail) => testStringMax80(qnaMail.teamName),
      ],
      senderName: [
        async (qnaMail) => testEmptyInput(qnaMail.senderName),
        async (qnaMail) => testStringMax80(qnaMail.senderName),
      ],
      emailFrom: [
        async (qnaMail) => testEmptyInput(qnaMail.emailFrom),
        async (qnaMail) => testStringWrongEmailFormat(qnaMail.emailFrom),
      ],
      emailFromConfirm: [
        async (qnaMail) => testEmptyInput(qnaMail.emailFromConfirm),
        async (qnaMail) => testUnMatchedEmail(qnaMail.emailFrom, qnaMail.emailFromConfirm),
      ],
      phoneNumberFrom: [
        async (qnaMail) => testEmptyInput(qnaMail.phoneNumberFrom),
        async (qnaMail) => testStringMax80(qnaMail.phoneNumberFrom),
      ],
      title: [
        async (qnaMail) => testEmptyInput(qnaMail.title),
        async (qnaMail) => testStringMax80(qnaMail.title),
      ],
      content: [
        async (qnaMail) => testEmptyInput(qnaMail.content),
        async (qnaMail) => testStringMax1000(qnaMail.content),
      ],
    });

    return qnaMailValidator.validate([qnaSendMailParams.qnaMail]);
  }, []);

  const localbridgeQnaSendMail = useCallback(async (
    localbridgeQnaSendMailRequest: LocalbridgeQnaSendMailRequest
  ) => {
    const messageResponses = await localbridgeQnaValidate({
      isOnSubmit: true,
      qnaMail: localbridgeQnaSendMailRequest.params.qnaMail,
    });
    if (messageResponses.validations?.length) {
      throw new LocalbridgeApiError(messageResponses, 412);
    }

    return functionsRequestPost(
      'localbridgeQnaSendMail', localbridgeQnaSendMailRequest,
    ) as Promise<void>;
  }, [localbridgeQnaValidate]);

  const providerValue = {
    localbridgeQnaValidate,
    localbridgeQnaSendMail,
  };

  return (
    <QueryClientProvider client={new QueryClient({
      defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false, refetchInterval: false },
      },
    })}>
      <LocalbridgeApiContext.Provider value={providerValue}>
        <LocalbridgeUserApiProvider>
          <LocalbridgeGroupApiProvider>
            <LocalbridgeWorkshopApiProvider>
              <LocalbridgePassportApiProvider>
                <LocalbridgePostApiProvider>
                  <LocalbridgeBookingApiProvider>
                    <LocalbridgeNoticeApiProvider>
                      <LocalbridgeBannerApiProvider>
                        <LocalbridgeAssociationApiProvider>
                          {children}
                        </LocalbridgeAssociationApiProvider>
                      </LocalbridgeBannerApiProvider>
                    </LocalbridgeNoticeApiProvider>
                  </LocalbridgeBookingApiProvider>
                </LocalbridgePostApiProvider>
              </LocalbridgePassportApiProvider>
            </LocalbridgeWorkshopApiProvider>
          </LocalbridgeGroupApiProvider>
        </LocalbridgeUserApiProvider>
      </LocalbridgeApiContext.Provider>
    </QueryClientProvider>
  );
}
