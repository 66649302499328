import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { generatePath, useHistory } from 'react-router-dom';
import {
  GroupModel,
  LocalbridgePostUpdateUserReactionOperation,
  PostModel,
  UserPublic,
  UserReactionNullable,
} from '~/openapi/typescript-axios/version';
import { ROUTES } from '~/constants/routes';
import { useAuth } from '~/providers/AuthProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';

type Props = {
  postModel: PostModel;
  userPublic?: UserPublic;
  groupModel?: GroupModel;
};

const PostCard: React.FC<Props> = (({postModel, userPublic, groupModel})=> {
  const history = useHistory();

  const { thisUser } = useAuth();
  const { localbridgePostUpdateUserReaction } = useLocalbridgePostApi();

  const postPath = `${generatePath(ROUTES.POST, {postId: postModel.id})}?postType=${postModel.postType}`;
  const userReaction: UserReactionNullable = postModel.userReaction || {};
  const {
    userIdsLike = [],
    userIdsBeCurious = [],
    userIdsWantToKnow = [],
    userIdsWantToGo = [],
    userIdsHaveGone = [],
  } = userReaction;

  const [isUserReactionSet, setIsUserReactionSet] = useState<boolean>(false);
  const [isThisUserLike, setIsThisUserLike] = useState<boolean>(false);
  const [isThisUserBeCurious, setIsThisUserBeCurious] = useState<boolean>(false);
  const [isThisUserWantToKnow, setIsThisUserWantToKnow] = useState<boolean>(false);
  const [isThisUserWantToGo, setIsThisUserWantToGo] = useState<boolean>(false);
  const [isThisUserHaveGone, setIsThisUserHaveGone] = useState<boolean>(false);

  type UserReactionType = 'Like'|'BeCurious'|'WantToKnow'|'WantToGo'|'HaveGone';
  const onClickUserReaction = useCallback(async (userReactionType: UserReactionType) => {
    if (!thisUser || !postModel) {
      return;
    }

    await localbridgePostUpdateUserReaction({
      env: { LOCALBRIDGE_PATH, FUNCTION_V },
      params: {
        postId: postModel.id,
        operation: LocalbridgePostUpdateUserReactionOperation.ArrayRemove,
        userReaction: {
          userIdsLike: [userReactionType === 'Like' ? thisUser.id : ''].filter(Boolean),
          userIdsBeCurious: [userReactionType === 'BeCurious' ? thisUser.id : ''].filter(Boolean),
          userIdsWantToKnow: [userReactionType === 'WantToKnow' ? thisUser.id : ''].filter(Boolean),
          userIdsWantToGo: [userReactionType === 'WantToGo' ? thisUser.id : ''].filter(Boolean),
          userIdsHaveGone: [userReactionType === 'HaveGone' ? thisUser.id : ''].filter(Boolean),
        },
      },
    });

    if (userReactionType === 'Like') {
      setIsThisUserLike(!isThisUserLike);
    }
    if (userReactionType === 'BeCurious') {
      setIsThisUserBeCurious(!isThisUserBeCurious);
    }
    if (userReactionType === 'WantToKnow') {
      setIsThisUserWantToKnow(!isThisUserWantToKnow);
    }
    if (userReactionType === 'WantToGo') {
      setIsThisUserWantToGo(!isThisUserWantToGo);
    }
    if (userReactionType === 'HaveGone') {
      setIsThisUserHaveGone(!isThisUserHaveGone);
    }
  }, [
    thisUser,
    postModel,
    isThisUserLike,
    isThisUserBeCurious,
    isThisUserWantToKnow,
    isThisUserWantToGo,
    isThisUserHaveGone,
    localbridgePostUpdateUserReaction,
    setIsThisUserLike,
    setIsThisUserBeCurious,
    setIsThisUserWantToKnow,
    setIsThisUserWantToGo,
    setIsThisUserHaveGone,
  ]);

  useEffect(() => {
    if (!thisUser || !postModel || isUserReactionSet) {
      return;
    }

    setIsThisUserLike(!!userIdsLike?.includes(thisUser?.id || ''));
    setIsThisUserBeCurious(!!userIdsBeCurious?.includes(thisUser?.id || ''));
    setIsThisUserWantToKnow(!!userIdsWantToKnow?.includes(thisUser?.id || ''));
    setIsThisUserWantToGo(!!userIdsWantToGo?.includes(thisUser?.id || ''));
    setIsThisUserHaveGone(!!userIdsHaveGone?.includes(thisUser?.id || ''));
    setIsUserReactionSet(true);
  }, [
    thisUser,
    postModel,
    isUserReactionSet,
    userIdsLike,
    userIdsBeCurious,
    userIdsWantToKnow,
    userIdsWantToGo,
    userIdsHaveGone,
    setIsUserReactionSet
  ]);

  return (
    <div className={'post_card'}>
      <a onClick={() => history.push(postPath)} className={'news_item__link'}>
        <img src={postModel.mainPhoto.url} className={'post_card__img'} alt="" />
        <div className={'post_card__contents'}>
          <p className={'post_card__info'}>
            {`掲載日: ${postModel.updatedAt} / 投稿者: ${userPublic?.nickName || ''} / ${groupModel?.name || ''}`}
          </p>
          <p className={'post_card__text'} style={{maxHeight: 150, overflow: 'auto'}}>
            {postModel.content.split('\n').map((splitedText, index) => (
              <React.Fragment key={`postModel:${postModel.id}-content:${index}`}>
                {splitedText}
                {(index !== postModel.content.split('\n').length - 1) ? (<br />) : (<></>)}
              </React.Fragment>
            ))}
          </p>

          <div className={'post_card_reaction'}>
            <div
              className={'post_card_reaction__item'}
              style={{color: isThisUserLike ? '#9a9a9a' : '#13824e'}}
              onClick={() => onClickUserReaction('Like')}
            >
              <img
                src={`/assets/img/card/ico_good${isThisUserLike ? '_off' : ''}.svg`}
                className={'post_card_reaction__icon'}
              />
              <p className={'post_card_reaction__text'}>{userIdsLike?.length || 0}</p>
            </div>
            <div
              className={'post_card_reaction__item'}
              style={{color: isThisUserBeCurious ? '#9a9a9a' : '#13824e'}}
              onClick={() => onClickUserReaction('BeCurious')}
            >
              <img
                src={`/assets/img/card/ico_smile${isThisUserBeCurious ? '_off' : ''}.svg`}
                className={'post_card_reaction__icon'}
              />
              <p className={'post_card_reaction__text'}>{userIdsBeCurious?.length || 0}</p>
            </div>
            <div
              className={'post_card_reaction__item'}
              style={{color: isThisUserWantToKnow ? '#9a9a9a' : '#13824e'}}
              onClick={() => onClickUserReaction('WantToKnow')}
            >
              <img
                src={`/assets/img/card/ico_read${isThisUserWantToKnow ? '_off' : ''}.svg`}
                className={'post_card_reaction__icon'}
              />
              <p className={'post_card_reaction__text'}>{userIdsWantToKnow?.length || 0}</p>
            </div>
            <div
              className={'post_card_reaction__item'}
              style={{color: isThisUserWantToGo ? '#9a9a9a' : '#13824e'}}
              onClick={() => onClickUserReaction('WantToGo')}
            >
              <img
                src={`/assets/img/card/ico_walk${isThisUserWantToGo ? '_off' : ''}.svg`}
                className={'post_card_reaction__icon'}
              />
              <p className={'post_card_reaction__text'}>{userIdsWantToGo?.length || 0}</p>
            </div>
            <div
              className={'post_card_reaction__item'}
              style={{color: isThisUserHaveGone ? '#9a9a9a' : '#13824e'}}
              onClick={() => onClickUserReaction('HaveGone')}
            >
              <img
                src={`/assets/img/card/ico_check${isThisUserHaveGone ? '_off' : ''}.svg`}
                className={'post_card_reaction__icon'}
              />
              <p className={'post_card_reaction__text'}>{userIdsHaveGone?.length || 0}</p>
            </div>
          </div>
          <div className={'post_card_tag'}>
            <img className={'post_card_tag__icon'} src="/assets/img/common/ico_tag.svg" />
            <p className={'post_card_tag__text flex-wrap'} style={{maxHeight: 100, overflow: 'auto'}}>
              {postModel.tags.map((tag) => <span key={`postModel:${postModel.id}-tag`}>{tag}</span>)}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
});

export default PostCard;
