import React, { useMemo } from 'react';
import './index.scss';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';
import { useParentGroup } from '~/providers/ParentGroupProvider';
import { useAppState } from '~/providers/AppStateProvider';

type Props = {};

const GroupLeftSidebarRenderer: React.FC<Props> = () => {
  const history = useHistory();

  const { groupModel } = useParentGroup();
  const { pathName } = useAppState();

  // ROUTES
  const groupId = useMemo(() => {
    return pathName.split('/')[2] || '';
  }, [pathName]);

  const isGroup = useMemo(() => {
    return groupId && pathName.split('/')[1] === 'group';
  }, [groupId, pathName]);

  const [userId] = useMemo(() => {
    if (!isGroup || (pathName.split('/')[3] !== 'user') || (pathName.split('/')[5] !== 'editWithUser')) {
      return [];
    }

    return [pathName.split('/')[4]];
  }, [isGroup, pathName]);

  const isGroupProfile = useMemo(() => {
    return isGroup && pathName.split('/').length === 3;
  }, [isGroup, pathName]);

  const isGroupEditWithUser = useMemo(() => {
    return groupId && userId && pathName.split('/').length === 6
      && pathName.split('/')[1] === 'group'
      && pathName.split('/')[3] === 'user'
      && pathName.split('/')[5] === 'editWithUser';
  }, [groupId, userId, pathName]);

  const isGroupWorkshops = useMemo(() => {
    return groupId && pathName.split('/').length === 4
      && pathName.split('/')[1] === 'group'
      && pathName.split('/')[3] === 'workshops';
  }, [groupId, pathName]);

  return (
    <div className={'group_left_sidebar'}>
      <p className={'group_left_sidebar__title'}>マイページ</p>

      <ul className={'group_left_sidebar__list'}>
        <li role='button' className={`group_left_sidebar__item ${isGroupProfile ? 'active' : ''}`}>
          <img src={groupModel?.icon.url} />
          <a onClick={() => history.push(generatePath(ROUTES.GROUP, {groupId}))}>
            <i className="bi bi-person-fill"></i>プロフィール
          </a>
        </li>
        <li role='button' className={`group_left_sidebar__item ${isGroupEditWithUser ? 'active' : ''}`}>
          <a onClick={() => history.push(generatePath(ROUTES.GROUP_EDIT_WITH_USER, {
            groupId: groupModel?.id || '',
            userId: groupModel?.userIdsManagerRole[0] || '',
          }))}>
            <i className="bi bi-calendar-check"></i>会員情報設定
          </a>
        </li>
        <li role='button' className={`group_left_sidebar__item ${isGroupWorkshops ? 'active' : ''}`}>
          <a onClick={() => history.push(ROUTES.WORKSHOPS)}>
            <i className="bi bi-gear"></i>イベント一覧
          </a>
        </li>
        <li role='button' className={'group_left_sidebar__item'}>
          <a onClick={() => history.push(ROUTES.SIGN_OUT)}>
            <i className="bi bi-box-arrow-left"></i>ログアウト
          </a>
        </li>
      </ul>
    </div>
  );
};

export default GroupLeftSidebarRenderer;
