import React, { useCallback, useEffect, useState } from 'react';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';
import LocalbridgeBody from '~/components/localbridgeBody';
import {
  LocalbridgePostUpdateUserReactionOperation,
  PostType,
  UserReactionNullable,
} from '~/openapi/typescript-axios/version';
import './index.scss';
import { ROUTES } from '~/constants/routes';
import PostViewer from '~/components/postViewer';
import { usePost } from './Provider';
import moment from 'moment';
import { generatePath, useHistory } from 'react-router-dom';
import { useAuth } from '~/providers/AuthProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { FUNCTION_V, LOCALBRIDGE_PATH } from '~/constants/firebase';

type Props = {};

const PostRenderer: React.FC<Props> = () => {  
  const history = useHistory();

  const { thisUser } = useAuth();
  const { localbridgePostUpdateUserReaction } = useLocalbridgePostApi();
  const { postModel, userPublic, passportModels, groupModels } = usePost();

  const userReaction: UserReactionNullable = postModel?.userReaction || {};
  const {
    userIdsLike = [],
    userIdsBeCurious = [],
    userIdsWantToKnow = [],
    userIdsWantToGo = [],
    userIdsHaveGone = [],
  } = userReaction;

  const [isUserReactionSet, setIsUserReactionSet] = useState<boolean>(false);
  const [isThisUserLike, setIsThisUserLike] = useState<boolean>(false);
  const [isThisUserBeCurious, setIsThisUserBeCurious] = useState<boolean>(false);
  const [isThisUserWantToKnow, setIsThisUserWantToKnow] = useState<boolean>(false);
  const [isThisUserWantToGo, setIsThisUserWantToGo] = useState<boolean>(false);
  const [isThisUserHaveGone, setIsThisUserHaveGone] = useState<boolean>(false);

  type UserReactionType = 'Like'|'BeCurious'|'WantToKnow'|'WantToGo'|'HaveGone';
  const onClickUserReaction = useCallback(async (userReactionType: UserReactionType) => {
    if (!thisUser || !postModel) {
      return;
    }

    await localbridgePostUpdateUserReaction({
      env: { LOCALBRIDGE_PATH, FUNCTION_V },
      params: {
        postId: postModel.id,
        operation: LocalbridgePostUpdateUserReactionOperation.ArrayRemove,
        userReaction: {
          userIdsLike: [userReactionType === 'Like' ? thisUser.id : ''].filter(Boolean),
          userIdsBeCurious: [userReactionType === 'BeCurious' ? thisUser.id : ''].filter(Boolean),
          userIdsWantToKnow: [userReactionType === 'WantToKnow' ? thisUser.id : ''].filter(Boolean),
          userIdsWantToGo: [userReactionType === 'WantToGo' ? thisUser.id : ''].filter(Boolean),
          userIdsHaveGone: [userReactionType === 'HaveGone' ? thisUser.id : ''].filter(Boolean),
        },
      },
    });

    if (userReactionType === 'Like') {
      setIsThisUserLike(!isThisUserLike);
    }
    if (userReactionType === 'BeCurious') {
      setIsThisUserBeCurious(!isThisUserBeCurious);
    }
    if (userReactionType === 'WantToKnow') {
      setIsThisUserWantToKnow(!isThisUserWantToKnow);
    }
    if (userReactionType === 'WantToGo') {
      setIsThisUserWantToGo(!isThisUserWantToGo);
    }
    if (userReactionType === 'HaveGone') {
      setIsThisUserHaveGone(!isThisUserHaveGone);
    }
  }, [
    thisUser,
    postModel,
    isThisUserLike,
    isThisUserBeCurious,
    isThisUserWantToKnow,
    isThisUserWantToGo,
    isThisUserHaveGone,
    localbridgePostUpdateUserReaction,
    setIsThisUserLike,
    setIsThisUserBeCurious,
    setIsThisUserWantToKnow,
    setIsThisUserWantToGo,
    setIsThisUserHaveGone,
  ]);

  useEffect(() => {
    if (!thisUser || !postModel || isUserReactionSet) {
      return;
    }

    setIsThisUserLike(!!userIdsLike?.includes(thisUser?.id || ''));
    setIsThisUserBeCurious(!!userIdsBeCurious?.includes(thisUser?.id || ''));
    setIsThisUserWantToKnow(!!userIdsWantToKnow?.includes(thisUser?.id || ''));
    setIsThisUserWantToGo(!!userIdsWantToGo?.includes(thisUser?.id || ''));
    setIsThisUserHaveGone(!!userIdsHaveGone?.includes(thisUser?.id || ''));
    setIsUserReactionSet(true);
  }, [
    thisUser,
    postModel,
    isUserReactionSet,
    userIdsLike,
    userIdsBeCurious,
    userIdsWantToKnow,
    userIdsWantToGo,
    userIdsHaveGone,
    setIsUserReactionSet,
  ]);

  if (!postModel) {
    return (<></>);
  }

  return postModel?.postType === PostType.Attractive ? (
    <>
      <LocalbridgeNavbar />

      <div>
        <LocalbridgeBody bgColor='#fbf8c2'>
          <div className={'attractive_detail'}>
            <div className={'attractive_main'}>
              <div className={'attractive_main__inner'}>
                <p className={'attractive_main__label'}>魅力発見記事</p>
                <h1 className={'attractive_main__title'}>{postModel.title}</h1>
                <div className={'attractive_main__info'}>
                  <p className={'attractive_main__date'}>{moment(postModel.createdAt).format('YYYY.MM.DD')}</p>
                  <img src='/assets/img/attractive/ico_info.jpg' className={'attractive_main__ico'} />
                  <p className={'attractive_main__city'}>ODK県ODK市</p>
                </div>

                <PostViewer postModel={postModel} />
              </div>
            </div>
            <div className={'attractive_info'}>
              <div className={'attractive_tag'}>
                {postModel.tags.map((tag) => (
                  <p key={`post:${postModel.id}-tag:${tag}`} className={'attractive_tag__item'}>
                    <img src='/assets/img/common/ico_tag.svg' />
                    {tag}
                  </p>
                ))}
              </div>

              <div className={'attractive_reactions'}>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserLike ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('Like')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsLike?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_good${isThisUserLike ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>いいね！</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserBeCurious ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('BeCurious')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsBeCurious?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_smile${isThisUserBeCurious ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>気になる</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserWantToKnow ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('WantToKnow')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsWantToKnow?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_read${isThisUserWantToKnow ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>詳細を知りたい</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserWantToGo ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('WantToGo')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsWantToGo?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_walk${isThisUserWantToGo ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>行ってみたい</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserHaveGone ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('HaveGone')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsHaveGone?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_check${isThisUserHaveGone ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>行ったことある</p>
                </div>
              </div>
            </div>

            <section className={'attractive_author'}>
              <h2 className={'attractive_author__title'}>この記事を書いた人</h2>
              <div className={'attractive_author_contents'}>
                <div className={'attractive_author_contents__profile'}>
                  <img src='/assets/img/attractive/pic_icon.jpg' className={'attractive_author_contents__ico'} />
                  <a
                    href={generatePath(ROUTES.USER, {userId: postModel.userId})}
                    className={'attractive_author_contents__btn'}
                  >マイページを見る</a>
                </div>
                <div className={'attractive_author_contents__texts'}>
                  <p className={'attractive_author_contents__name d-flex flex-nowrap'}>
                    {userPublic?.nickName}
                    {groupModels.map((groupModel) => (
                      <span key={`group:${groupModel.id}`}>{groupModel.name}</span>
                    ))}
                  </p>
                  <p className={'attractive_author_contents__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  <p className={'attractive_author_contents__passport'}>所有しているふるさとパスポート：</p>
                </div>
              </div>
            </section>

            <section className={'attractive_related'}>
              <div className={'attractive_related__btn'}>
                <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>魅力発見記事一覧へ</a>
              </div>
            </section>
          </div>
        </LocalbridgeBody>
      </div>

      <LocalbridgeFooter />
    </>
  ):(
    <>
      <LocalbridgeNavbar />

      <div>
        <LocalbridgeBody bgColor='#ffecda'>
          <div className={'attractive_detail'}>
            <div className={'attractive_main'}>
              <div className={'attractive_main__inner'}>
                <p className={'attractive_main__label'}>イベント体験記事</p>
                <h1 className={'attractive_main__title'}>{postModel.title}</h1>
                <div className={'attractive_main__info'}>
                  <p className={'attractive_main__date'}>{moment(postModel.createdAt).format('YYYY.MM.DD')}</p>
                  <img src='/assets/img/attractive/ico_info.jpg' className={'attractive_main__ico'} />
                  <p className={'attractive_main__city'}>ODK県ODK市</p>
                </div>

                <div className='mt-4'><PostViewer postModel={postModel}/></div>
              </div>
            </div>

            <div className={'attractive_info'}>
              <div className={'attractive_tag'}>
                {postModel.tags.map((tag) => (
                  <p key={`post:${postModel.id}-tag:${tag}`} className={'attractive_tag__item'}>
                    <img src='/assets/img/common/ico_tag.svg' />
                    {tag}
                  </p>
                ))}
              </div>

              <div className={'attractive_reactions'}>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserLike ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('Like')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsLike?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_good${isThisUserLike ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>いいね！</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserBeCurious ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('BeCurious')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsBeCurious?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_smile${isThisUserBeCurious ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>気になる</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserWantToKnow ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('WantToKnow')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsWantToKnow?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_read${isThisUserWantToKnow ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>詳細を知りたい</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserWantToGo ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('WantToGo')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsWantToGo?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_walk${isThisUserWantToGo ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>行ってみたい</p>
                </div>
                <div
                  className={'attractive_reactions__item'}
                  style={{color: isThisUserHaveGone ? '#9a9a9a' : '#13824e'}}
                  onClick={() => onClickUserReaction('HaveGone')}
                >
                  <p className={'attractive_reactions__count'}>{userIdsHaveGone?.length || 0}</p>
                  <img
                    src={`/assets/img/card/ico_check${isThisUserHaveGone ? '_off' : ''}.svg`}
                    className={'attractive_reactions__icon'}
                  />
                  <p className={'attractive_reactions__text'}>行ったことある</p>
                </div>
              </div>
            </div>

            <section className={'attractive_author'}>
              <h2 className={'attractive_author__title'}>この記事を書いた人</h2>
              <div className={'attractive_author_contents'}>
                <div className={'attractive_author_contents__profile'}>
                  <img src='/assets/img/attractive/pic_icon.jpg' className={'attractive_author_contents__ico'} />
                  <a
                    href={generatePath(ROUTES.USER, {userId: postModel.userId})}
                    className={'attractive_author_contents__btn'}
                  >マイページを見る</a>
                </div>
                <div className={'attractive_author_contents__texts'}>
                  <p className={'attractive_author_contents__name d-flex flex-wrap'}>
                    {userPublic?.nickName}
                    {groupModels.map((groupModel) => (
                      <span key={`group:${groupModel.id}`}>{groupModel.name}</span>
                    ))}
                  </p>
                  <p className={'attractive_author_contents__text'}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  <p className={'attractive_author_contents__passport d-flex flex-wrap gap-2'}>
                    所有しているふるさとパスポート：
                    {passportModels.map((passportModel) => (
                      <img key={`group:${passportModel.id}`} src={passportModel.icon.url}/>
                    ))}
                  </p>
                </div>
              </div>
            </section>

            <section className={'attractive_related'}>
              <div className={'attractive_related__btn'}>
                <a onClick={() => history.push(ROUTES.POSTS + `?postType=${PostType.Attractive}`)}>魅力発見記事一覧へ</a>
              </div>
            </section>
          </div>
        </LocalbridgeBody>
      </div>

      <LocalbridgeFooter />
    </>
  );
};

export default PostRenderer;
