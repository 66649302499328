export const getImageWidthHeight = async (file: File) => {
  if (!file.type.includes('image')) {
    throw new Error('No Image File');
  }

  return new Promise<{width: number, height: number}>((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({width: img.width, height: img.height});
      URL.revokeObjectURL(img.src);
    };
  });
};
