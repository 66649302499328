import React, { useMemo } from 'react';
import './index.scss';
import { useAppState } from '~/providers/AppStateProvider';
import { ROUTES } from '~/constants/routes';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { PostType } from '~/openapi/typescript-axios/version';

type Props = {};

const UserLeftSidebarRenderer: React.FC<Props> = () => {
  const search = useLocation().search;
  const history = useHistory();

  const { pathName } = useAppState();

  // ROUTES
  const userId = useMemo(() => {
    return pathName.split('/')[2] || '';
  }, [pathName]);

  const isUser = useMemo(() => {
    return userId && pathName.split('/')[1] === 'user';
  }, [userId, pathName]);

  const [passportId, workshopId] = useMemo(() => {
    if (!isUser || (pathName.split('/')[3] !== 'passport') || (pathName.split('/')[5] !== 'workshop')) {
      return [];
    }

    return [pathName.split('/')[4], pathName.split('/')[6]];
  }, [isUser, pathName]);

  const isUserProfile = useMemo(() => {
    return isUser && pathName.split('/').length === 3;
  }, [isUser, pathName]);

  const isUserEdit = useMemo(() => {
    return isUser && pathName.split('/').length === 4 && pathName.split('/')[3] === 'edit';
  }, [isUser, pathName]);

  const isUserBookings = useMemo(() => {
    return isUser && pathName.split('/').length === 4 && pathName.split('/')[3] === 'bookings';
  }, [isUser, pathName]);

  const isUserAttractivePosts = useMemo(() => {
    const postType = new URLSearchParams(search).get('postType') as PostType|null;
    return isUser && pathName.split('/').length === 4 && pathName.split('/')[3] === 'posts'
      && postType === PostType.Attractive;
  }, [isUser, pathName, search]);

  const isUserExperiencePosts = useMemo(() => {
    const postType = new URLSearchParams(search).get('postType') as PostType|null;
    return isUser && pathName.split('/').length === 4 && pathName.split('/')[3] === 'posts'
    && postType === PostType.Experience;
  }, [isUser, pathName, search]);

  const isUserPostsCreate = useMemo(() => {
    return isUser && passportId && workshopId
      && pathName.split('/')[7] === 'posts'
      && pathName.split('/')[8] === 'create';
  }, [isUser, pathName, passportId, workshopId]);

  return (
    <div className={'user_left_sidebar'}>
      <p className={'user_left_sidebar__title'}>マイページ</p>

      <ul className={'user_left_sidebar__list'}>
        <li role='button' className={`user_left_sidebar__item ${isUserProfile ? 'active' : ''}`}>
          <img src="" />
          <a onClick={() => history.push(generatePath(ROUTES.USER, {userId}))}>
            <i className="bi bi-person-fill"></i>プロフィール
          </a>
        </li>
        <li role='button' className={`user_left_sidebar__item ${isUserEdit ? 'active' : ''}`}>
          <a onClick={() => history.push(generatePath(ROUTES.USER_EDIT, {userId}))}>
            <i className="bi bi-calendar-check"></i>会員情報設定
          </a>
        </li>
        <li role='button' className={`user_left_sidebar__item ${isUserBookings ? 'active' : ''}`}>
          <a onClick={() => history.push(generatePath(ROUTES.USER_BOOKINGS, {userId}))}>
            <i className="bi bi-gear"></i>予約済イベント一覧
          </a>
        </li>
        <li role='button' className={`user_left_sidebar__item ${isUserExperiencePosts ? 'active' : ''}`}>
          <a onClick={() => {
            history.push(generatePath(ROUTES.USER_POSTS, {userId}) + `?postType=${PostType.Experience}`);
          }}>
            <i className="bi bi-flag"></i>イベント体験記事執筆一覧
          </a>
        </li>
        <li role='button' className={`user_left_sidebar__item ${isUserAttractivePosts ? 'active' : ''}`}>
          <a onClick={() => {
            history.push(generatePath(ROUTES.USER_POSTS, {userId}) + `?postType=${PostType.Attractive}`);
          }}>
            <i className="bi bi-emoji-smile"></i>魅力発見記事執筆一覧
          </a>
        </li>
        <li role='button' className={`user_left_sidebar__item ${isUserPostsCreate ? 'active' : ''}`}>
          <a onClick={() => {
            if (!isUserPostsCreate) return;
            history.push(generatePath(ROUTES.USER_POSTS_CREATE, {userId, passportId, workshopId}));
          }}>
            <i className="bi bi-pen"></i>ライター入稿ページ
          </a>
        </li>
        <li role='button' className={'user_left_sidebar__item'}>
          <a onClick={() => history.push(ROUTES.SIGN_OUT)}>
            <i className="bi bi-box-arrow-left"></i>ログアウト
          </a>
        </li>
      </ul>
    </div>
  );
};

export default UserLeftSidebarRenderer;
