import React from 'react';
import { AdminNoticesCreateProvider } from './Provider';
import AdminNoticesCreateRenderer from './Renderer';

export const AdminNoticesCreate = () => {
  return (
    <AdminNoticesCreateProvider>
      <AdminNoticesCreateRenderer />
    </AdminNoticesCreateProvider>
  );
};
