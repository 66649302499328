import React from 'react';
import { CookiePolicyProvider } from './Provider';
import CookiePolicyRenderer from './Renderer';

export const CookiePolicy = () => {
  return (
    <CookiePolicyProvider>
      <CookiePolicyRenderer />
    </CookiePolicyProvider>
  );
};
