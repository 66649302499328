import React, { useMemo } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaginationBox } from '~/components/paginationBox';
import { PostModel } from '~/openapi/typescript-axios/version';
import { useAdminPosts } from './Provider';
import { StyledAdminTable } from '../components/StyledAdminTable';
import { AdminLeftSidebar } from '../components/leftSidebar';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '~/constants/routes';

type Props = {};

const AdminPostsRenderer: React.FC<Props> = () => {
  const history = useHistory();
  
  const {
    postType,
    postsList,
    postsPageCurrent,
    postsPageLast,
    setPostsPageCurrent,
  } = useAdminPosts();

  const posts: Array<PostModel> = useMemo(() => {
    return postsList.find((postsWithPager) => {
      return postsWithPager.pager.page === postsPageCurrent;
    })?.data || [];
  }, [postsPageCurrent, postsList]);

  return (
    <div className='d-flex flex-row'>
      <AdminLeftSidebar />

      <div className='container d-flex flex-column gap-3 p-5'>
        <span style={{fontSize: 24}}>{`${postType}一覧`}</span>

        <span
          className='m-auto fw-bolder p-2'
          hidden={posts.length > 0}
        >
          登録された現場がありません
        </span>

        {posts.length > 0 ? (
          <StyledAdminTable className='table table-bordered mb-3'>
            <thead>
              <tr className='border'>
                {/* 記事ID */}
                <th
                  scope='col'
                  className='col-3 border align-middle'
                  rowSpan={3}
                >
                  記事ID
                </th>

                {/* タイトル */}
                <th
                  scope='col'
                  className='col-9 border'
                  colSpan={6}
                >
                  タイトル
                </th>
              </tr>
              <tr className='border'>
                {/* 作成時刻  */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  作成時刻 
                </th>

                {/* 都道府県 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  都道府県
                </th>

                {/* 市区町村 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  市区町村
                </th>

                {/* 承認状況 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={2}>
                  承認状況
                </th>

                {/* 非公開 */}
                <th scope='col' className='col-2 border align-middle' rowSpan={2}>
                  非公開
                </th>

                {/* 編集 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  編集
                </th>
              </tr>

              <tr className='border'>
                {/* 修正時刻 */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  修正時刻
                </th>

                {/* タグ */}
                <th
                  scope='col'
                  className='col-2 border align-middle'
                  rowSpan={1}
                >
                  タグ
                </th>

                {/* 承認待ち中 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  承認待ち中
                </th>

                {/* 削除 */}
                <th scope='col' className='col-1 border align-middle' rowSpan={1}>
                  削除
                </th>
              </tr>
            </thead>

            <>
              {posts.map((post: PostModel, index: number) => {

                return (
                  <tbody
                    key={post.id}
                    className={`border table-row-${index % 2 === 0 ? 'even': 'odd'}`
                  }>
                    <tr>
                      {/* 記事ID */}
                      <th
                        className='col-3 border align-middle'
                        rowSpan={3}
                      >
                        {post.id}
                      </th>

                      {/* タイトル */}
                      <th
                        className='col-9 border'
                        colSpan={6}
                        role='button'
                      >
                        {post.title}
                      </th>
                    </tr>

                    <tr>
                      {/* 作成時刻  */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(post.createdAt).format('YYYY.MM.DD HH:mm:ss')}
                      </td>

                      {/* 都道府県 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >{post.id}</td>

                      {/* 市区町村 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >{post.id}</td>

                      {/* 承認状況 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={2}
                      >
                        {post.isApproved}
                      </td>

                      {/* 非公開 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={2}
                      >
                        {post.isHidden}
                      </td>

                      {/* 編集 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                        onClick={() => history.push(generatePath(ROUTES.ADMIN_POST_EDIT, {
                          postId: post.id,
                        }))}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </td>
                    </tr>

                    <tr>
                      {/* 修正時刻 */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {moment(post.updatedAt).format('YYYY.MM.DD HH:mm:ss')}
                      </td>

                      {/* タグ */}
                      <td
                        className='col-2 border align-middle'
                        rowSpan={1}
                      >
                        {post.tags}
                      </td>

                      {/* 承認待ち中 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        {post.isWaitingApproved}
                      </td>

                      {/* 削除 */}
                      <td
                        className='col-1 border align-middle'
                        rowSpan={1}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          </StyledAdminTable>
        ):(<></>)}

        {postsList.length ? (
          <PaginationBox
            paginationBoxClassNamePrefix='pagination_box_block'
            forcePage={postsPageCurrent}
            pageCount={postsPageLast}
            onPageChange={setPostsPageCurrent}
          />
        ):(<></>)}
      </div>
    </div>
  );
};

export default AdminPostsRenderer;
