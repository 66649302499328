import { doc, getDoc, setDoc, writeBatch } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import { REACT_APP_LOCALBRIDGE_ENV, auth, firestore } from '~/constants/firebase';
import getSearchTokenMap from '~/helpers/getSearchTokenMap';
import showError from '~/helpers/toaster/message/showError';
import showSuccess from '~/helpers/toaster/message/showSuccess';
import {
  BookingModel,
  BookingSource,
  GroupModel,
  PassportModel,
  PostModel,
  PostType,
  UniversityCollegeStudentGrade,
  UserGender,
  UserJobDropdown,
  UserModel,
  WorkshopModel,
} from '~/openapi/typescript-axios/version';
import { useAuth } from '~/providers/AuthProvider';
import { useLocalbridgeGroupApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeGroupApiProvider';
import { useLocalbridgePassportApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePassportApiProvider';
import { useLocalbridgePostApi } from '~/providers/LocalbridgeApiProvider/LocalbridgePostApiProvider';
import { useLocalbridgeUserApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeUserApiProvider';
import { useLocalbridgeWorkshopApi } from '~/providers/LocalbridgeApiProvider/LocalbridgeWorkshopApiProvider';

export const TestSearching = () => {
  const { thisUser, isServiceManager } = useAuth();

  const { searchUsers } = useLocalbridgeUserApi();
  const { searchGroups } = useLocalbridgeGroupApi();
  const { searchWorkshops } = useLocalbridgeWorkshopApi();
  const { searchPassports } = useLocalbridgePassportApi();
  const { searchPosts } = useLocalbridgePostApi();

  const setTestData = useCallback(async () => {
    if (!auth.currentUser) {
      return;
    }

    const batch = writeBatch(firestore);
    const now = Number(new Date());
    const rootRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;

    const userModel: UserModel = {
      id: auth.currentUser.uid,
      createdAt: now,
      updatedAt: now,
      photos: [],
      interests: [],
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      name: auth.currentUser.displayName || '',
      nickName: auth.currentUser.displayName || '',
      nameFurigana: auth.currentUser.displayName || '',
      studentGrade: UniversityCollegeStudentGrade.UniversityCollege1,
      description: '',
      job: UserJobDropdown.StudentUniversityBachelor,
      educationalInstitutionName: 'Some University',
      studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
      email: auth.currentUser.email || '',
      emailToken: getSearchTokenMap(auth.currentUser.email || ''),
      gender: UserGender.Male,
      phoneNumber: '',
      cityId: '130001',
      townId: '131211',
      detailAddress1: '',
      detailAddress2: '',
      postNumber: '',
      localAssociationIds: ['131211'],
      localCategorys: [],
      birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
    };
    const userRef = `${rootRef}/users/${userModel.id}`;
    batch.set(doc(firestore, userRef), userModel);

    const groupModel: GroupModel = {
      id: `testGroup${userModel.id}`,
      createdAt: now,
      updatedAt: now,
      photos: [],
      name: 'テストのために作った。\n検索性 をテストする.',
      nameToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      delegateName: '',
      delegateNameFurigana: '',
      groupEmail: userModel.email,
      groupPhoneNumber: userModel.phoneNumber,
      homepage: '',
      cityId: '130001',
      townId: '131211',
      detailAddress1: '',
      detailAddress2: '',
      postNumber: userModel.postNumber,
      userIdsManagerRole: [userModel.id],
      passportIcon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      description: '',
    };
    const groupRef = `${rootRef}/groups/${groupModel.id}`;
    batch.set(doc(firestore, groupRef), groupModel);

    const workshopModel: WorkshopModel = {
      id: `testWorkshop${userModel.id}`,
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: 'テストのために作った。\n検索性 をテストする.',
      titleToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      overview: 'テストのために作った。\n検索性 をテストする.',
      overviewToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      detail: 'テストのために作った。\n検索性 をテストする.',
      detailToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      notice: '',
      cityId: '130001',
      townId: '131211',
      detailAddress1: '',
      detailAddress2: '',
      groupId: groupModel.id,
      workshopStartAt: now,
      workshopEndAt: now,
      bookingStartAt: now,
      bookingEndAt: now,
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
      participationFee: 1000,
      isHidden: false,
      isWaitingApproved: false,
      isApproved: true,
      isInTopCarouselList: false,
      isFinishedWriting: false,
    };
    const workshopRef = `${rootRef}/workshops/${workshopModel.id}`;
    batch.set(doc(firestore, workshopRef), workshopModel);

    const passportModel: PassportModel = {
      id: `testPassport${userModel.id}`,
      createdAt: now,
      updatedAt: now,
      photos: [],
      userId: userModel.id,
      userIdToken: getSearchTokenMap(userModel.id),
      groupId: groupModel.id,
      groupCityId: groupModel.cityId, 
      groupTownId: groupModel.townId,
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    };
    const passportRef = `${rootRef}/passports/${passportModel.id}`;
    batch.set(doc(firestore, passportRef), passportModel);

    const postModel: PostModel = {
      id: `testPost${userModel.id}`,
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: 'テストのために作った。\n検索性 をテストする.',
      titleToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      content: 'テストのために作った。\n検索性 をテストする.',
      contentToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      contentData: '',
      cityId: '130001',
      townId: '131211',
      postType: PostType.Attractive,
      groupId: groupModel.id,
      passportId: passportModel.id,
      userId: userModel.id,
      workshopId: workshopModel.id,
      tags: [],
      isHidden: false,
      isWaitingApproved: false,
      isApproved: true,
      isInTopCarouselList: false,
      isFinishedWriting: false,
      // userReaction: {},
      workshopStartAt: now,
      workshopEndAt: now,
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
    };
    const postRef = `${rootRef}/posts/${postModel.id}`;
    batch.set(doc(firestore, postRef), postModel);

    await batch.commit();
  // eslint-disable-next-line
  }, [thisUser]);

  (window as any).setTestData = setTestData;

  const testSearching = useCallback(async (searchString: string) => {
    console.log('start');
    try {
      const searchResultUsers = await searchUsers({searchString});
      console.log(searchResultUsers);
      const searchResultGroups = await searchGroups({searchString});
      console.log(searchResultGroups);
      const searchResultWorkshops = await searchWorkshops({
        searchString,
        shouldShowNotHidden: true,
        shouldShowApproved: true,
      });
      console.log(searchResultWorkshops);
      const searchResultPassports = await searchPassports({searchString});
      console.log(searchResultPassports);
      const searchResultPosts = await searchPosts({
        postType: PostType.Attractive,
        searchString,
        shouldShowNotHidden: true,
        shouldShowApproved: true,
      });
      console.log(searchResultPosts);
    } catch (error) {
      console.log(error);
    }
    console.log('end');
  }, [
    searchUsers,
    searchGroups,
    searchWorkshops,
    searchPassports,
    searchPosts,
  ]);

  const makeWorkshop = useCallback(async ({workshopId, groupId}) => {
    if (!workshopId || !groupId) {
      showError({title: 'makeWorkshop', text: 'need workshopId, groupId'});
    }

    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const now = Number(new Date());
    const workshopModel: WorkshopModel = {
      id: workshopId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: 'テストのために作った。\n検索性 をテストする.',
      titleToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      overview: 'テストのために作った。\n検索性 をテストする.',
      overviewToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      detail: 'テストのために作った。\n検索性 をテストする.',
      detailToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      notice: 'テストテスト',
      cityId: '130001',
      townId: '131211',
      detailAddress1: 'テストテスト',
      detailAddress2: 'テストテスト',
      groupId,
      workshopStartAt: now,
      workshopEndAt: now,
      bookingStartAt: now,
      bookingEndAt: now,
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
      participationFee: 1000,
      isHidden: false,
      isWaitingApproved: false,
      isApproved: true,
      isInTopCarouselList: false,
      isFinishedWriting: false,
    };
    const workshopRef = `${localbridgeRef}/workshops/${workshopId}`;
    await setDoc(doc(firestore, workshopRef), workshopModel);
    showSuccess({title: 'makeWorkshop', text: 'done!'});
  }, []);

  const makeGroup = useCallback(async ({userId, groupId}) => {
    if (!userId || !groupId) {
      showError({title: 'makeGroup', text: 'need userId, groupId'});
    }
    
    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const userRef = `${localbridgeRef}/users/${userId}`;
    const userModel = (await getDoc(doc(firestore, userRef))).data();
    const now = Number(new Date());

    const groupModel: GroupModel = {
      id: groupId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      name: `テストのために作った${nanoid()}`,
      nameToken: getSearchTokenMap(`テストのために作った${nanoid()}`),
      delegateName: '',
      delegateNameFurigana: '',
      groupEmail: userModel?.email,
      groupPhoneNumber: userModel?.phoneNumber,
      homepage: 'https://realglobe.jp',
      cityId: '130001',
      townId: '131211',
      detailAddress1: 'テストテスト',
      detailAddress2: 'テストテスト',
      postNumber: userModel?.postNumber,
      userIdsManagerRole: [userModel?.id],
      passportIcon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      description: 'テストテスト',
    };

    const groupRef = `${localbridgeRef}/groups/${groupId}`;
    await setDoc(doc(firestore, groupRef), groupModel);
    showSuccess({title: 'makeGroup', text: 'done!'});
  }, []);

  const makePassport = useCallback(async ({passportId, userId, groupId}) => {
    if (!passportId || !userId || !groupId) {
      showError({title: 'makePassport', text: 'need passportId, userId, groupId'});
    }

    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const groupRef = `${localbridgeRef}/groups/${groupId}`;
    const groupModel = (await getDoc(doc(firestore, groupRef))).data();

    const now = Number(new Date());

    const passportModel: PassportModel = {
      id: passportId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      userId,
      userIdToken: getSearchTokenMap(userId),
      groupId: groupModel?.id || '',
      groupCityId: groupModel?.cityId || '',
      groupTownId: groupModel?.townId || '',
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
    };

    const passportRef = `${localbridgeRef}/passports/${passportId}`;
    await setDoc(doc(firestore, passportRef), passportModel);
    showSuccess({title: 'makePassport', text: 'done!'});
  }, []);

  const makePost = useCallback(async ({postId, passportId, userId, groupId, workshopId}) => {
    if (!postId || !passportId || !userId || !groupId || !workshopId) {
      showError({title: 'makePost', text: 'need postId, passportId, userId, groupId, workshopId'});
    }
    
    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const now = Number(new Date());

    const postModel: PostModel = {
      id: postId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      title: 'テストのために作った。\n検索性 をテストする.',
      titleToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      content: 'テストのために作った。\n検索性 をテストする.',
      contentToken: getSearchTokenMap('テストのために作った。\n検索性 をテストする.'),
      contentData: '',
      cityId: '130001',
      townId: '131211',
      postType: PostType.Attractive,
      groupId,
      passportId,
      userId,
      workshopId,
      tags: [],
      isHidden: false,
      isWaitingApproved: false,
      isApproved: true,
      isInTopCarouselList: false,
      isFinishedWriting: false,
      userReaction: {},
      workshopStartAt: now,
      workshopEndAt: now,
      mainPhoto: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      contentPhotos: [],
    };
    const postRef = `${localbridgeRef}/posts/${postId}`;
    await setDoc(doc(firestore, postRef), postModel);
    showSuccess({title: 'makePost', text: 'done!'});
  }, []);

  const makeUser = useCallback(async ({userId, userEmail}) => {
    if (!userId || !userEmail) {
      showError({title: 'makeUser', text: 'need userId, userEmail'});
    }

    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const now = Number(new Date());

    const userModel: UserModel = {
      id: userId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      interests: [],
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      name: 'テストテスト',
      nickName: 'テストテスト',
      nameFurigana: 'テストテスト',
      studentGrade: UniversityCollegeStudentGrade.UniversityCollege1,
      description: 'テストテスト',
      job: UserJobDropdown.StudentUniversityBachelor,
      educationalInstitutionName: 'Some University',
      studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
      email: userEmail,
      emailToken: getSearchTokenMap(userEmail),
      gender: UserGender.Male,
      phoneNumber: 'テストテスト',
      cityId: '130001',
      townId: '131211',
      detailAddress1: 'テストテスト',
      detailAddress2: 'テストテスト',
      postNumber: 'テストテスト',
      localAssociationIds: ['131211'],
      localCategorys: [],
      birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
    };

    const userRef = `${localbridgeRef}/users/${userId}`;
    await setDoc(doc(firestore, userRef), userModel);
    showSuccess({title: 'makeUser', text: 'done!'});
  }, []);

  const makeBooking = useCallback(async ({bookingId, userId, workshopId, groupId}) => {
    if (!bookingId || !workshopId || !groupId) {
      showError({title: 'makeBooking', text: 'need bookingId, workshopId, groupId'});
    }
    
    const localbridgeRef = `${REACT_APP_LOCALBRIDGE_ENV}/localbridge`;
    const userRef = `${localbridgeRef}/users/${userId}`;
    const userModel = (await getDoc(doc(firestore, userRef))).data();

    const now = Number(new Date());

    const bookingModel: BookingModel = {
      id: bookingId,
      createdAt: now,
      updatedAt: now,
      photos: [],
      userId,
      interests: [],
      icon: {id: '', url: '', name: '', width: 0, height: 0, size: 0},
      name: userModel?.name || '',
      nickName: userModel?.nickName || '',
      nameFurigana: userModel?.nameFurigana || '',
      studentGrade: UniversityCollegeStudentGrade.UniversityCollege1,
      description: 'テストテスト',
      job: UserJobDropdown.StudentUniversityBachelor,
      educationalInstitutionName: 'Some University',
      studentStartAt: [new Date(now).getFullYear(), new Date(now).getMonth() + 1],
      email: userModel?.email || '',
      emailToken: getSearchTokenMap(userModel?.email || ''),
      gender: UserGender.Male,
      phoneNumber: 'テストテスト',
      cityId: '130001',
      townId: '131211',
      detailAddress1: 'テストテスト',
      detailAddress2: 'テストテスト',
      postNumber: 'テストテスト',
      localAssociationIds: ['131211'],
      localCategorys: [],
      birthDay: [new Date(now).getFullYear(), new Date(now).getMonth() + 1, new Date(now).getDate()],
      workshopId,
      groupId,
      keyPoint: 'テストテスト',
      source: '' as BookingSource,
      message: 'テストテスト',
      purposes: ['テストテスト1', 'テストテスト2'],
    };

    const bookingRef = `${localbridgeRef}/bookings/${bookingId}`;
    await setDoc(doc(firestore, bookingRef), bookingModel);
    showSuccess({title: 'makeBooking', text: 'done!'});
  }, []);

  return (
    <>
      {isServiceManager ? <span>管理者モード</span> : <></>}
      <div className='d-flex flex-row'>
        <input className='form-control' style={{width: 200}} type='text' id='TestSearchingInput' />
        <button className='btn btn-outline-secondary' onClick={() => {
          const { value } =(document.getElementById('TestSearchingInput') as HTMLInputElement);
          testSearching(value);
        }}>search</button>
      </div>
      <div className='d-flex flex-row gap-2'>

        <div className='d-flex flex-column gap-2'>
          <div className='d-flex flex-column gap-2' style={{width: 400}}>
            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>userId</span>
              <input className='form-control' style={{width: 200}} type='text' id='userId' />
            </div>
            <div className='d-flex flex-row gap-2'>            
              <span className='my-auto' style={{width: 150}}>groupId</span>
              <input className='form-control' style={{width: 200}} type='text' id='groupId' />
            </div>
            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>workshopId</span>
              <input className='form-control' style={{width: 200}} type='text' id='workshopId' />
            </div>
            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>passportId</span>
              <input className='form-control' style={{width: 200}} type='text' id='passportId' />
            </div>
            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>postId</span>
              <input className='form-control' style={{width: 200}} type='text' id='postId' />
            </div>
            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>bookingId</span>
              <input className='form-control' style={{width: 200}} type='text' id='bookingId' />
            </div>

            <div className='d-flex flex-row gap-2'>
              <span className='my-auto' style={{width: 150}}>userEmail</span>
              <input className='form-control' style={{width: 200}} type='text' id='userEmail' />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column gap-2' style={{width: 200}}>
          <div className='d-flex flex-column gap-2'>
            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: userId } =(document.getElementById('userId') as HTMLInputElement);
              const { value: userEmail } =(document.getElementById('userEmail') as HTMLInputElement);
              makeUser({userId, userEmail});
            }}>makeUser</button>

            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: groupId } =(document.getElementById('groupId') as HTMLInputElement);
              const { value: userId } =(document.getElementById('userId') as HTMLInputElement);
              makeGroup({userId, groupId});
            }}>makeGroup</button>

            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: groupId } =(document.getElementById('groupId') as HTMLInputElement);
              const { value: workshopId } =(document.getElementById('workshopId') as HTMLInputElement);
              makeWorkshop({groupId, workshopId});
            }}>makeWorkshop</button>

            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: passportId } =(document.getElementById('passportId') as HTMLInputElement);
              const { value: userId } =(document.getElementById('userId') as HTMLInputElement);
              const { value: groupId } =(document.getElementById('groupId') as HTMLInputElement);
              makePassport({passportId, userId, groupId});
            }}>makePassport</button>

            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: passportId } =(document.getElementById('passportId') as HTMLInputElement);
              const { value: userId } =(document.getElementById('userId') as HTMLInputElement);
              const { value: groupId } =(document.getElementById('groupId') as HTMLInputElement);
              const { value: workshopId } =(document.getElementById('workshopId') as HTMLInputElement);
              makePost({passportId, userId, groupId, workshopId});
            }}>makePost</button>

            <button className='btn btn-outline-secondary' onClick={() => {
              const { value: bookingId } =(document.getElementById('bookingId') as HTMLInputElement);
              const { value: workshopId } =(document.getElementById('workshopId') as HTMLInputElement);
              const { value: userId } =(document.getElementById('userId') as HTMLInputElement);
              const { value: groupId } =(document.getElementById('groupId') as HTMLInputElement);
              makeBooking({bookingId, workshopId, userId, groupId});
            }}>makeBooking</button>
          </div>
        </div>
      </div>
    </>
  );
};
