import React from 'react';
import { useParentWorkshop } from '~/providers/ParentWorkshopProvider';
import { GroupWorkshopBookings } from './components/bookings';
import { LocalbridgeNavbar } from '~/components/localbridgeNavbar';
import { GroupLeftSidebar } from '../../components/leftSidebar';
import { LocalbridgeFooter } from '~/components/localbridgeFooter';

type Props = {};

const GroupWorkshopEditRenderer: React.FC<Props> = () => {
  const { workshopModel } = useParentWorkshop();

  if (!workshopModel) {
    return <></>;
  }

  return (
    <>
      <LocalbridgeNavbar />

      <GroupLeftSidebar />
      <GroupWorkshopBookings />

      <LocalbridgeFooter />
    </>
  );
};

export default GroupWorkshopEditRenderer;
