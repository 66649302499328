
import React, { useContext, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

interface AboutPassportValue {

}

const AboutPassportContext = React.createContext<AboutPassportValue | null>(null);

export function useAboutPassport(): AboutPassportValue {
  const state = useContext(AboutPassportContext);

  if (!state) {
    throw new Error('useAboutPassport must be used within AboutPassportProvider');
  }

  return state;
}

export function AboutPassportProvider({ children }: Props) {
  const providerValue = {

  };

  return (
    <AboutPassportContext.Provider value={providerValue}>
      {children}
    </AboutPassportContext.Provider>
  );
}
