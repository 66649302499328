import styled from 'styled-components';

export const StyledSignUpEmailVarification = styled.div`
  width: 80vw;
  height: 80vh;
  margin: auto;
  display: flex;
  flex-direction: column;

  .logo {
    @media (min-width: 768px) {
      max-width: 50%;
    }
    max-width: 100%;
  }
`;
